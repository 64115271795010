import React, { Component } from 'react';
import { func, shape, string } from 'prop-types';
import classnames from 'classnames';
import { DELIVERY_TYPES } from './constants';
import Email from './Email';
import RemoteServer from './RemoteServer';
import Credentials from './Credentials';
import inputStyles from '../inputs.module.scss';

export class DeliveryMethod extends Component {
  static propTypes = {
    currentValue: shape({
      deliveryType: string,
      email: string,
      ftpPassword: string,
      ftpUserName: string,
      ftpUrl: string,
      message: string,
    }).isRequired,
    name: string.isRequired,
    onChange: func,
  };

  handleInputChange = (fieldName, value) => {
    const { currentValue, name, onChange } = this.props;
    onChange({
      inputName: name,
      inputValue: {
        ...currentValue,
        [fieldName]: value,
      },
    });
  };

  render() {
    const { currentValue } = this.props;
    const selectedDeliveryMethod = currentValue.deliveryType;
    return (
      <div>
        <div className={inputStyles.FormInput}>
          <label>How would you like it sent?</label>
          <div className={inputStyles.ButtonRow}>
            {DELIVERY_TYPES.map(deliveryType => {
              const isActive = deliveryType === selectedDeliveryMethod;
              return (
                <button
                  className={classnames('btn', inputStyles.OptionButton, {
                    [inputStyles['OptionButton--active']]: isActive,
                  })}
                  key={deliveryType}
                  onClick={() =>
                    this.handleInputChange(
                      'deliveryType',
                      deliveryType.toUpperCase()
                    )
                  }
                  type="button"
                >
                  {deliveryType}
                </button>
              );
            })}
          </div>
        </div>
        {selectedDeliveryMethod === 'EMAIL' && (
          <Email
            emailValue={currentValue.email}
            messageValue={currentValue.message}
            onChange={this.handleInputChange}
          />
        )}
        {selectedDeliveryMethod !== 'EMAIL' && (
          <>
            <RemoteServer
              deliveryMethod={currentValue}
              onChange={this.handleInputChange}
            />
            <Credentials
              ftpCredentials={currentValue}
              onChange={this.handleInputChange}
            />
          </>
        )}
      </div>
    );
  }
}
