import React, { Component } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { arrayOf, bool, func, shape, string, oneOf } from 'prop-types';
import clonedeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';

import {
  getListObjectByTitle,
  getListItemsByTitle,
  moveItemFromSourceToDestination,
  throwDestinationListError,
} from './columnEditorUtils';
import List from './List';
import styles from './ColumnDndEditor.module.scss';

export default class ColumnDndEditor extends Component {
  static propTypes = {
    lists: arrayOf(
      shape({
        items: arrayOf(
          shape({
            propertyName: string.isRequired,
            displayName: string,
          })
        ).isRequired,
        listItemIcon: oneOf(['add', 'remove']),
        onClickDestination: string,
        title: string.isRequired,
      })
    ),
    onChange: func.isRequired,
    open: bool,
  };

  state = {
    hiddenItemsPerList: this.props.lists.reduce((obj, list) => {
      obj[list.title] = new Set();
      return obj;
    }, {}),
    lists: clonedeep(this.props.lists),
  };

  onListItemClick = ({ source }) => {
    const { lists } = this.state;
    const sourceList = getListObjectByTitle(source.droppableId, lists);
    const destinationList = getListObjectByTitle(
      sourceList.onClickDestination,
      lists
    );
    if (!destinationList) {
      return throwDestinationListError(sourceList.title);
    }
    const destination = {
      droppableId: destinationList.title,
      index: destinationList.items.length,
    };
    const updatedLists = moveItemFromSourceToDestination(
      source,
      destination,
      lists
    );
    this.setState({ lists: updatedLists });
  };

  onDragEnd = ({ source, destination }) => {
    if (!destination) {
      return; // Cancelled drag
    }
    const { lists } = this.state;
    const updatedLists = moveItemFromSourceToDestination(
      source,
      destination,
      lists
    );
    this.setState({
      lists: updatedLists,
    });
  };

  onReset = () => {
    this.setState({ lists: clonedeep(this.props.lists) });
  };

  onListSearch = (listToUpdate, searchResults) => {
    // Because indicies are important for DnD, we render every list item
    // and hide those that don't appear in search results. This allows
    // users to move items from a set of search results.
    const { lists } = this.state;
    const setOfSearchResults = new Set(searchResults.map(r => r.item));
    const itemsNotInSearchResults = getListItemsByTitle(
      listToUpdate,
      lists
    ).filter(item => !setOfSearchResults.has(item.propertyName));
    this.setState(currentState => {
      const hiddenItemsPerList = currentState.hiddenItemsPerList;
      hiddenItemsPerList[listToUpdate] = new Set(itemsNotInSearchResults);
      return {
        hiddenItemsPerList,
      };
    });
  };

  componentWillReceiveProps = ({ open, lists }) => {
    if (!isEqual(lists, this.state.lists)) {
      this.setState({ lists });
    }
    if (open === false) {
      this.onReset();
    }
  };

  render() {
    const { hiddenItemsPerList, lists } = this.state;
    const { onChange } = this.props;
    return (
      <div className={styles['ColumnDndEditor']}>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <div className={styles['ColumnDndEditor__columns']}>
            {lists.map((list, index) => (
              <Droppable
                droppableId={list.title}
                key={`${list.title}-${index}`}
              >
                {(provided, snapshot) => (
                  <div
                    className={styles['ColumnDndEditor__column']}
                    ref={provided.innerRef}
                  >
                    <List
                      icon={list.listItemIcon}
                      items={list.items}
                      onListItemClick={this.onListItemClick}
                      onSearch={this.onListSearch}
                      sorted={list.sorted}
                      title={list.title}
                      hiddenItems={hiddenItemsPerList[list.title]}
                    />
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            ))}
          </div>
        </DragDropContext>
        <div className={styles['ColumnDndEditor__button-panel']}>
          <button
            type="button"
            className={`btn btn--primary mt-4`}
            onClick={() => onChange(lists)}
          >
            Apply
          </button>
        </div>
      </div>
    );
  }
}
