import React from 'react';
import { oneOfType, arrayOf, node, func } from 'prop-types';
import FocusLock from 'react-focus-lock';

import styles from './Modal.module.scss';

export default function ModalCard({
  children,
  handleClose,
  isTranslationEnabled,
  ...restProps
}) {
  return (
    <FocusLock>
      <div
        style={restProps}
        onMouseDown={e => e.stopPropagation()}
        className={styles['ModalCard']}
        role="dialog"
        data-translate={isTranslationEnabled}
      >
        <button onClick={handleClose} className={styles['ModalCard__exit-btn']}>
          Close modal
        </button>
        {children}
      </div>
    </FocusLock>
  );
}

ModalCard.propTypes = {
  children: oneOfType([arrayOf(node), node]),
  handleClose: func.isRequired,
};
