import React, { useEffect, useState } from 'react';
import { func, number, oneOf, oneOfType, shape, string } from 'prop-types';
import { getToken } from '@pwr/auth-provider';
import moment from 'moment';
import apiFetch from 'api/fetch';
import { StatusDot } from 'components/StatusDot';
import { REPORT_SCHEDULE_RUN } from 'api/endpoints';

const RUN_STATUSES = {
  COMPLETED: {
    message: 'Success',
    color: 'green',
  },
  EMAIL_FAILED: {
    message: 'Email failed',
    color: 'red',
  },
  ETL_FAILED: {
    message: 'ETL Failed',
    color: 'red',
  },
  FAILED: {
    message: 'Failed',
    color: 'red',
  },
  CREATED: {
    message: 'Running',
    color: 'yellow',
  },
  RUNNING: {
    message: 'Running',
    color: 'yellow',
  },
  NEVER_RAN: {
    message: 'Never ran',
    color: 'gray',
  },
};

const RunStatusDot = ({ isLoading, error, response }) => {
  if (isLoading || !response) {
    return <StatusDot message="Loading" color="grey" />;
  }
  if (error) return <StatusDot message="Failed to fetch" color="grey" />;

  const runStatus = response.runStatus || 'NEVER_RAN';
  const { message, color } = RUN_STATUSES[runStatus] || RUN_STATUSES.NEVER_RAN;
  return <StatusDot message={message} color={color} />;
};

const LastRunMessage = ({ isLoading, error, response }) => {
  if (isLoading || !response)
    return <span data-translate={false}>{'00/00/0000'}</span>;
  if (error) return 'Unknown';
  const { runDate } = response;
  const momentDate = moment(new Date(runDate));
  return momentDate.isValid() ? (
    <span data-translate={false}>{momentDate.format('MM/DD/YYYY')}</span>
  ) : (
    'Never ran'
  );
};

export function LastRunAndStatus({ onChange, subscriptionId, value }) {
  const { isLoading, error, response } = useRunData(subscriptionId, value);
  useEffect(() => {
    if (value === null && response) {
      const { runDate, runStatus } = response;
      onChange(subscriptionId, {
        runDate: runDate ? runDate : 'Never ran',
        runStatus: runStatus ? runStatus : 'NEVER_RAN',
      });
    }
  }, [value, response, onChange, subscriptionId]);
  return (
    <>
      <td className="text-left" key={subscriptionId + 'last-run'}>
        <LastRunMessage
          error={error}
          isLoading={isLoading}
          response={value || response}
        />
      </td>
      <td
        className="text-left"
        key={subscriptionId + 'run-status'}
        data-translate={false}
      >
        <RunStatusDot
          error={error}
          isLoading={isLoading}
          response={value || response}
        />
      </td>
    </>
  );
}
LastRunAndStatus.propTypes = {
  onChange: func.isRequired,
  subscriptionId: oneOfType([string, number]).isRequired,
  value: shape({
    runDate: string,
    runStatus: oneOf(['COMPLETED', 'FAILED', 'RUNNING', 'NEVER_RAN']),
  }),
};

function useRunData(subscriptionId, value) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);

  useEffect(() => {
    if (value) return;
    let current = true;
    const fetchData = async () => {
      setIsLoading(true);
      const url =
        REPORT_SCHEDULE_RUN +
        '?page=0&size=1' +
        `&schedule_id=${subscriptionId}`;
      const options = {
        headers: {
          Authorization: getToken(),
          'Content-type': 'application/json',
        },
      };
      try {
        const response = await apiFetch(url, options).then(result =>
          result.json()
        );
        setResponse(response._embedded.reportScheduleRun[0] || {});
      } catch (error) {
        console.error(error);
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };
    current && fetchData();
    return () => (current = false);
  }, [subscriptionId, value]);

  return { isLoading, error, response };
}
