import React, { PureComponent } from 'react';
import { getToken } from '@pwr/auth-provider';
import { GET_ALL_CUSTOM_REPORTS } from 'api/endpoints';
import apiFetch from 'api/fetch';
import { ConfigContext } from 'contexts/ConfigContext';
import { ReportContentColumnar } from 'containers/ReportContent';
import Growler from 'components/Growler';
import ColumnarBuilder from '../../containers/ReportContent/TableColumnBuilder';
import MerchantDropDown from 'components/MerchantDropDown';
import DataExplorerList from './DataExplorerList';
import styles from './DataExplorer.module.scss';
import Page from 'pages/Page/Page';
import {
  mergeReports,
  reportIncludesMerchantGroupId,
} from './DataExplorerUtils';

const PAGE_URL = '/data-explorer';

class DataExplorer extends PureComponent {
  static contextType = ConfigContext;

  state = {
    activeReportId: null,
    customReports: null,
    exportButton: null,
    fetchErrorCode: null,
  };

  setActiveReportId = (activeReportId, reportUriTitle) => {
    this.setState({
      activeReportId,
    });
  };

  handlePageBack = () => {
    const { history } = this.props;
    this.setActiveReportId(null);
    history.push(PAGE_URL);
  };

  getReportById = (reportId, reports) => {
    if (!reports) return null;
    const { selectedMerchantGroupsByIds } = this.props;
    const stringOfMerchantGroupIds = [
      ...selectedMerchantGroupsByIds.keys(),
    ].join(',');
    const report = reports._embedded.reportTemplate.filter(
      report => report.reportIdentifier === reportId
    )[0];

    if (!report) return null;
    if (report && !report.custom) return report;

    const ownsReport = reportIncludesMerchantGroupId(
      report,
      stringOfMerchantGroupIds
    );
    return ownsReport ? report : null;
  };

  setActiveReportIdentifier = report => {
    this.setState(state => {
      return {
        activeReportId: report.reportIdentifier,
      };
    });
  };

  componentDidMount() {
    const { reportId } = this.props.match.params;

    apiFetch(GET_ALL_CUSTOM_REPORTS, {
      method: 'GET',
      headers: {
        Authorization: getToken(),
        'Content-Type': 'application/json',
      },
    })
      .then(res => res.json())
      .then(customReports => {
        this.setState({ customReports });
        const report = this.getReportById(reportId, customReports);
        if (report) {
          return this.setActiveReportIdentifier(report);
        }
      })
      .catch(error => {
        console.error(error);
        this.setState({ fetchErrorCode: error.status });
      });
  }

  componentDidUpdate(prevProps, prevState) {
    const { reportId } = this.props.match.params;
    const { customReports } = this.state;
    const { nonCustomReports } = this.props;

    const report = this.getReportById(
      reportId,
      mergeReports([customReports, nonCustomReports])
    );
    if (!report && prevState.activeReportId) {
      return this.setActiveReportId(null);
    }
    if (report && report.reportIdentifier !== prevState.activeReportId) {
      return this.setActiveReportIdentifier(report);
    }
  }

  dismissCustomGrowler = () => this.setState({ customGrowler: null });
  displayCustomGrowler = ({ children, title, type }) => {
    this.setState({
      customGrowler: {
        children,
        title,
        type,
      },
    });
  };

  render() {
    const {
      apps,
      isAdmin,
      isBrandEngageOnly,
      selectedMerchantsByIds,
      selectedMerchantGroupsByIds,
      name,
      nonCustomReports,
      onMerchantSelect,
    } = this.props;
    const { activeReportId, customReports, fetchErrorCode } = this.state;

    const navigationProps = {
      crumbs: [
        {
          label: 'Analytics',
          href: `/`,
        },
        {
          label: 'Data Explorer',
          href: PAGE_URL,
        },
      ],
      activePrimary: 'Analytics',
      activeSecondary: 'Data Explorer',
    };

    if (fetchErrorCode) {
      return (
        <Page
          apps={apps}
          isBrandEngageOnly={isBrandEngageOnly}
          name={name}
          navigationProps={navigationProps}
          selectedMerchantGroupsByIds={selectedMerchantGroupsByIds}
        >
          <Growler dismissible title={`Error: ${fetchErrorCode}`} type="alert">
            Failed to fetch reports, please try again in a few moments.
          </Growler>
        </Page>
      );
    }
    if (!nonCustomReports || !customReports) {
      return (
        <Page
          apps={apps}
          isBrandEngageOnly={isBrandEngageOnly}
          name={name}
          navigationProps={navigationProps}
          selectedMerchantGroupsByIds={selectedMerchantGroupsByIds}
        >
          <LoadingState />
        </Page>
      );
    }
    const customAndNonCustomReports = mergeReports([
      nonCustomReports,
      customReports,
    ]);
    const reportData = new ColumnarBuilder(
      customAndNonCustomReports,
      activeReportId
    );
    const merchantGroupIds = [...selectedMerchantGroupsByIds.keys()].join(',');
    const merchantIds = [...selectedMerchantsByIds.keys()].join(',');
    return (
      <Page
        apps={apps}
        isBrandEngageOnly={isBrandEngageOnly}
        name={name}
        navigationProps={navigationProps}
        selectedMerchantGroupsByIds={selectedMerchantGroupsByIds}
      >
        {!!this.state.customGrowler && (
          <Growler
            dismissible
            onDismiss={this.dismissCustomGrowler}
            title={this.state.customGrowler.title}
            type={this.state.customGrowler.type}
          >
            <p>{this.state.customGrowler.children}</p>
          </Growler>
        )}
        <section className="flex justify-between">
          <div>
            {shouldRenderMerchantDropdown(
              this.state.activeReportId,
              customAndNonCustomReports
            ) && (
              <MerchantDropDown
                selectedMerchantGroupsByIds={selectedMerchantGroupsByIds}
                onMerchantSelect={onMerchantSelect}
                selectedMerchantsByIds={selectedMerchantsByIds}
              />
            )}
          </div>
        </section>
        {!this.state.activeReportId && (
          <DataExplorerList
            setActiveReportId={this.setActiveReportId}
            reportsObject={customAndNonCustomReports}
            merchantGroupIds={merchantGroupIds}
          />
        )}
        {this.state.activeReportId && (
          <div
            data-testid="data-explorer"
            className={styles.ColumnarReport}
            data-translate={true}
          >
            <ReportContentColumnar
              isActive
              isAdmin={isAdmin}
              merchantGroupIds={merchantGroupIds}
              merchantIds={merchantIds}
              reportDefinition={reportData}
              reportIdentifier={activeReportId}
              setGrowlerProps={this.displayCustomGrowler}
              useUrlParams
            />
          </div>
        )}
      </Page>
    );
  }
}

export default DataExplorer;

function shouldRenderMerchantDropdown(activeReportIdentifier, reportsObject) {
  if (!activeReportIdentifier || !Object.entries(reportsObject).length === 0) {
    return false;
  }

  const reportDefinitions = reportsObject._embedded.reportTemplate;

  const activeReport = reportDefinitions.find(
    reportDefinition =>
      reportDefinition.reportIdentifier === activeReportIdentifier
  );

  if (!activeReport) return false;

  return activeReport.reportParameters.some(
    reportParameter => reportParameter.name === 'merchant_list'
  );
}

function LoadingState() {
  return (
    <div>
      <DataExplorerList
        isLoading
        merchantGroupIds={null}
        reportsObject={null}
        setActiveReportId={null}
      />
    </div>
  );
}
