import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import ExpandablePanel from 'components/Filters/ExpandablePanel';
import { func, number, object, oneOfType, string } from 'prop-types';
import { mapReportParametersToFilterComponents } from 'components/Filters/FilterUtils';
import { reportParameters } from 'utils/types';
import { encodeFilterState } from './ReportFiltersUtils';
import styles from './ReportFilters.module.scss';

function applyFilterStateToFilterDefinition(
  filterValues = {},
  filterDefinitions
) {
  return filterDefinitions.map(filterDefinition => {
    filterDefinition.value = filterValues[filterDefinition.name] || null;
    return filterDefinition;
  });
}

export default function ReportFilters({
  alwaysPresentFilters,
  filterDefinitions,
  filterId,
  filterState,
  merchantGroupIds,
  merchantIds,
  onSubmit = () => {},
  setFilterState,
  setFilterStateUrlParams = () => {},
}) {
  const merchantData = { merchantIds, merchantGroupIds };
  const currentFilterState = {
    ...filterState[filterId],
    ...alwaysPresentFilters,
  };

  const setAlwaysPresentFilters = () => {
    if (!alwaysPresentFilters) return;
    Object.keys(alwaysPresentFilters).forEach(filterName =>
      setFilterState(filterId, filterName, alwaysPresentFilters[filterName])
    );
    setFilterStateUrlParams(`?${encodeFilterState(currentFilterState)}`);
  };
  const handleChange = (name, value) => {
    setFilterState(filterId, name, value);
  };

  const handleSubmit = () => {
    onSubmit(currentFilterState);
    setFilterStateUrlParams(`?${encodeFilterState(currentFilterState)}`);
  };

  useEffect(() => {
    setAlwaysPresentFilters();
  }, [JSON.stringify(alwaysPresentFilters)]);

  const [filterComponents, setFilterComponents] = useState();
  useEffect(() => {
    const filters = mapReportParametersToFilterComponents(
      applyFilterStateToFilterDefinition(currentFilterState, filterDefinitions),
      handleChange,
      merchantData,
      filterId
    ).sort((f1, f2) => f1.props.displayOrder - f2.props.displayOrder);
    setFilterComponents(filters);
  }, [JSON.stringify(currentFilterState), filterDefinitions]);

  if (!filterComponents || filterComponents.length < 1) return null;
  return (
    <form
      onSubmit={event => {
        event.preventDefault();
        handleSubmit();
      }}
      id={`ReportFilters-${filterId}`}
      className={classnames('bg-white flex flex-row', styles.FilterForm__form)}
    >
      <ExpandablePanel
        filters={filterComponents}
        filterState={currentFilterState}
      >
        {filterComponents}
      </ExpandablePanel>
    </form>
  );
}

ReportFilters.propTypes = {
  additionalFilters: object,
  alwaysPresentFilters: object,
  filterDefinitions: reportParameters,
  filterId: oneOfType([string, number]),
  merchantGroupId: oneOfType([string, number]),
  merchantIds: oneOfType([string, number]),
  onSubmit: func,
  setFilterStateUrlParams: func,
};
