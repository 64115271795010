export function questionAnswersReportPageProps({
  isBrandEngageOnly,
  merchantGroupProperties,
}) {
  const { BRAND_ENGAGE_TYPE } = merchantGroupProperties;
  const reports = [
    {
      reportIdentifier: 'qa_workbook',
      type: 'tableau_workbook',
      workbookTabs: [
        {
          tableauIframeHeight: 1300,
          tableauTabUri: 'Display',
          tableauWorksheetName: 'Display',
          title: 'Display',
          urlFragment: 'display',
        },
        {
          tableauIframeHeight: 1300,
          tableauTabUri: 'Collect',
          tableauWorksheetName: 'Collect',
          title: 'Collect',
          urlFragment: 'collect',
        },
        ...(BRAND_ENGAGE_TYPE === 'retailer'
          ? [
              {
                tableauIframeHeight: 950,
                tableauTabUri: 'BrandEngage',
                tableauWorksheetName: 'Brand Engage',
                title: 'Brand Engage',
                urlFragment: 'brand-engage',
              },
            ]
          : []),
      ],
    },

    {
      reportIdentifier: 'all_questions',
      urlFragment: 'all-questions',
      title: 'Questions',
      type: 'columnar',
    },
    {
      reportIdentifier: 'all_answers',
      urlFragment: 'all-answers',
      title: 'Answers',
      type: 'columnar',
    },
  ];
  const DEFAULT_TAB = reports[0].workbookTabs[0].urlFragment;
  const PAGE_URL = '/questions-answers';
  const PAGE_TITLE = 'Questions & Answers';
  const NAV_TITLE = 'Q & A';

  const navigationProps = {
    crumbs: [
      {
        label: 'Analytics',
        href: `/`,
      },
      {
        label: PAGE_TITLE,
        href: PAGE_URL,
      },
    ],
    activePrimary: 'Analytics',
    activeSecondary: NAV_TITLE,
  };

  return {
    defaultTab: DEFAULT_TAB,
    navigationProps,
    pageUrl: PAGE_URL,
    reports,
  };
}
