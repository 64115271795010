export function ratingsReviewsReportPageProps() {
  const reports = [
    {
      reportIdentifier: 'ratings_and_reviews',
      type: 'tableau_workbook',
      workbookTabs: [
        {
          tableauIframeHeight: 1500,
          tableauTabUri: 'Display',
          tableauWorksheetName: 'Display',
          urlFragment: 'display',
          title: 'Display',
        },
        {
          tableauIframeHeight: 1900,
          tableauTabUri: 'Collect',
          tableauWorksheetName: 'Collect',
          urlFragment: 'collect',
          title: 'Collect',
        },
        {
          tableauIframeHeight: 1400,
          tableauTabUri: 'Trends',
          tableauWorksheetName: 'Trends',
          urlFragment: 'trends',
          title: 'Trends',
        },
        {
          tableauIframeHeight: 1200,
          tableauTabUri: 'ReviewForm',
          tableauWorksheetName: 'Review Form',
          urlFragment: 'review-form',
          title: 'Review Form',
        },
        {
          tableauIframeHeight: 1600,
          tableauTabUri: 'Observations',
          tableauWorksheetName: 'Observations',
          urlFragment: 'observations',
          title: 'Observations',
        },
        {
          tableauIframeHeight: 1500,
          tableauTabUri: 'MSQs',
          tableauWorksheetName: 'MSQs',
          urlFragment: 'msqs',
          title: 'MSQs',
        },
        {
          tableauIframeHeight: 1650,
          tableauTabUri: 'ShareTo',
          tableauWorksheetName: 'Share To',
          urlFragment: 'share-to',
          title: 'Share To',
        },
      ],
    },

    {
      reportIdentifier: 'all_reviews',
      urlFragment: 'all-reviews',
      title: 'Reviews',
      type: 'columnar',
    },
  ];
  const DEFAULT_TAB = reports[0].workbookTabs[0].urlFragment;
  const PAGE_URL = '/ratings-reviews';
  const PAGE_TITLE = 'Ratings & Reviews';
  const navigationProps = {
    crumbs: [
      {
        label: 'Analytics',
        href: `/`,
      },
      {
        label: PAGE_TITLE,
        href: `${PAGE_URL}/${DEFAULT_TAB}`,
      },
    ],
    activePrimary: 'Analytics',
    activeSecondary: PAGE_TITLE,
  };
  return {
    defaultTab: DEFAULT_TAB,
    navigationProps,
    pageUrl: PAGE_URL,
    reports,
  };
}
