const hasKey = (obj, key) => !!obj && key in obj;
/**
 * Finds a value of a property in an object using a dot notation string path.
 * For example: safeObjectDig(object, 'prop1.prop2.prop3'). It returns null
 * if the keypath is invalid.
 * @param {Object} obj
 * @param {String} keyPath The string of properties you want to traverse
 * @returns {*} returns the value corresponding to the terminal property
 * in the key path; if it doesn't exist, it returns null.
 */
export const safeObjectDig = (obj, keyPath) =>
  keyPath
    .split('.')
    .reduce(
      (nestedObj, key) => (hasKey(nestedObj, key) ? nestedObj[key] : null),
      obj
    );
