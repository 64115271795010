import { safeObjectDig } from 'utils/safeObjectDig';
import { mapColumnsForColumnarGrid } from './ReportContentUtils';

const flattenArray = arr => arr.reduce((curr, next) => curr.concat(next), []);

export default class TableColumnBuilder {
  constructor(data, reportIdentifier) {
    this.allReports = data
      ? safeObjectDig(data._embedded, 'reportTemplate')
      : {};
    this.reportIdentifier = reportIdentifier;
  }

  get report() {
    return this.allReports.filter(
      report => report.reportIdentifier === this.reportIdentifier
    );
  }

  get reportType() {
    return this.report[0].reportComponentGroups[0].exportEngine;
  }

  get reportName() {
    return this.report[0].name;
  }

  get reportId() {
    return this.report[0].reportId;
  }

  get reportComponentParameters() {
    // May be necessary for filters
    const reportParameters = flattenArray(
      this.report.map(report => safeObjectDig(report, 'reportParameters'))
    );
    return reportParameters;
  }

  get reportComponentParameterType() {
    // May be necessary for filters
    const reportComponentParameterType = flattenArray(
      this.reportComponentParameters.map(report =>
        safeObjectDig(report, 'type')
      )
    );
    return reportComponentParameterType;
  }

  get reportComponentGroups() {
    const reportComponentGroups = flattenArray(
      this.report.map(report => safeObjectDig(report, 'reportComponentGroups'))
    );
    return reportComponentGroups;
  }

  get reportComponentColumns() {
    const reportComponentColumns = flattenArray(
      this.reportComponentGroups.map(column =>
        safeObjectDig(column, 'reportComponentColumns')
      )
    );
    return reportComponentColumns.filter(col => !col.previewHiddenColumn);
  }

  get reportComponentColumnsDefault() {
    const reportComponentColumns = flattenArray(
      this.reportComponentGroups.map(column =>
        safeObjectDig(column, 'reportComponentColumns')
      )
    );
    return reportComponentColumns.filter(
      col => !col.optional && !col.previewHiddenColumn
    );
  }

  get defaultTableSort() {
    const tableSortParameter = this.report[0].reportParameters.filter(
      param => param.identifier === 'table_sort'
    )[0];
    return tableSortParameter
      ? tableSortParameter.defaults.toLowerCase()
      : `${this.reportComponentColumnsDefault[0].columnName} desc`;
  }

  get inactiveReportComponentColumnsDefault() {
    const reportComponentColumns = flattenArray(
      this.reportComponentGroups.map(column =>
        safeObjectDig(column, 'reportComponentColumns')
      )
    );
    return reportComponentColumns.filter(
      col => col.optional && !col.previewHiddenColumn
    );
  }

  get reportComponentColumnOrderedNames() {
    const order = flattenArray(
      this.reportComponentColumnsDefault.map(column =>
        safeObjectDig(column, 'columnName')
      )
    );
    return order;
  }

  get reportComponentColumnHeaders() {
    const headers = flattenArray(
      this.reportComponentColumnOrderedNames.map(header => ({
        propertyName: header,
        displayName: header.split('_').join(' '),
      }))
    );
    return headers;
  }

  get reportComponentGroupId() {
    return this.reportComponentGroups[0].id;
  }

  get reportList() {
    const sortByDisplayOrder = (a, b) => {
      const displayOrder1 = a.displayOrder;
      const displayOrder2 = b.displayOrder;
      if (displayOrder1 < displayOrder2) return -1;
      if (displayOrder1 > displayOrder2) return 1;
      return 0;
    };
    return this.reportComponentGroups[0].reportComponents
      .sort(sortByDisplayOrder)
      .map(component => component.uri);
  }

  getColumnsFromActiveColumns = activePropertyNames => {
    const reportComponentColumns = this.reportComponentColumns;
    let activeColumns, inactiveColumns;

    if (!activePropertyNames || !activePropertyNames.length) {
      return {
        activeColumns: activeColumns || null,
        inactiveColumns: inactiveColumns || null,
      };
    }

    activeColumns = reportComponentColumns
      .map(mapColumnsForColumnarGrid)
      .filter(col => activePropertyNames.indexOf(col.propertyName) > -1)
      .sort((a, b) => {
        const p1 = a.propertyName;
        const p2 = b.propertyName;
        return (
          activePropertyNames.indexOf(p1) - activePropertyNames.indexOf(p2)
        );
      });
    inactiveColumns = reportComponentColumns
      .map(mapColumnsForColumnarGrid)
      .filter(col => !activePropertyNames.indexOf(col.propertyName) > -1);

    return {
      activeColumns: activeColumns || null,
      inactiveColumns: inactiveColumns || null,
    };
  };
}
