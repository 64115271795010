export function followUpReportPageProps() {
  const reports = [
    {
      reportIdentifier: 'fup_workbook',
      type: 'tableau_workbook',
      workbookTabs: [
        {
          tableauIframeHeight: 1300,
          tableauTabUri: 'Email',
          tableauWorksheetName: 'Email',
          title: 'Email',
          urlFragment: 'email',
        },
        {
          tableauTabUri: 'SMS',
          tableauWorksheetName: 'SMS',
          title: 'SMS',
          urlFragment: 'sms',
        },
      ],
    },
    {
      reportIdentifier: 'email_listing_page',
      urlFragment: 'email-data',
      title: 'Email Data',
      type: 'columnar',
    },
    {
      reportIdentifier: 'sms_listing_page',
      urlFragment: 'sms-data',
      title: 'SMS Data',
      type: 'columnar',
    },
  ];
  const DEFAULT_TAB = reports[0].workbookTabs[0].urlFragment;
  const PAGE_URL = '/follow-up-program';
  const PAGE_TITLE = 'Follow Up Program';

  const navigationProps = {
    crumbs: [
      {
        label: 'Analytics',
        href: `/`,
      },
      {
        label: PAGE_TITLE,
        href: PAGE_URL,
      },
    ],
    activePrimary: 'Analytics',
    activeSecondary: PAGE_TITLE,
  };

  return {
    defaultTab: DEFAULT_TAB,
    navigationProps,
    pageUrl: PAGE_URL,
    reports,
  };
}
