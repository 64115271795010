window.ChurnZero = window.ChurnZero || [];

const envAppKey = (() => {
  const devKey = 'D7EUh2aNF5QSfEMeG7BBr6tWw2we2Enw5ZahWUYRCoQ';
  const qaKey = 'D7EUh2aNF5QSfEMeG7BBr6tWw2we2Enw5ZahWUYRCoQ';
  // const qaKey = 'fRuPAKoI_6GbqNA7pLdQE-yMJGKcahZx1v1SEqbmYT4';
  const prodKey = 'j6t4DTOWWGYxc5ot9qfCdAo4FFbUimTMx-OqUKZomQs';
  const buildEnv = process.env.REACT_APP_BUILD_ENV;

  if (buildEnv === 'prod') return prodKey;
  if (buildEnv === 'qa') return qaKey;

  return devKey;
})();

export const ChurnZero = {
  load() {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.async = true;
      document.head && document.head.appendChild(script);

      script.onload = resolve;
      script.onerror = () => {
        console.warn('ChurnZero was not loaded.');
        resolve();
      };
      script.src = '//analytics.churnzero.net/churnzero.js';
    });
  },

  identify({ email, accountId }) {
    window.ChurnZero.push(['setAppKey', envAppKey]);
    window.ChurnZero.push(['setContact', String(accountId), email]);
    window.ChurnZero.push(['setModule', 'Reporting']);
  },

  stop() {
    window.ChurnZero.push(['stop']);
  },
};
