import React, { useState } from 'react';
import { arrayOf, shape, func, number, oneOfType, string } from 'prop-types';
import { Select, useAsyncFilter } from '@pwr-ui/select';
import { getQueryParams } from 'utils';
import { fetchFilter } from 'api/fetchFilter';
import { useThrottle } from 'use-throttle';
import styles from './FetchSelect.module.scss';

const itemToString = item => (item !== null ? item.label : '');
const itemToKey = item => item.value;
const getSelectedItem = (currentValue, items) => {
  if (!currentValue) return null;
  if (typeof currentValue === 'object') return currentValue.value;
  return items.filter(item => item.value === currentValue)[0] || null;
};

export default function FetchSelect({
  currentValue,
  endpoint,
  filterDisplayName,
  filterName,
  merchantGroupIds,
  merchantIds,
  onChange,
}) {
  const fetchFilters = () => {
    return fetchFilter(endpoint, urlParams);
  };
  const currentValueFromUrlParam = typeof currentValue === 'string';
  const [inputValue, setInputValue] = useState('');
  const throttledInputValue = useThrottle(inputValue, 1500);

  const urlParams = getQueryParams({
    dateRange: '',
    groups: merchantGroupIds,
    merchants: merchantIds || '',
    search: inputValue,
    values: currentValueFromUrlParam ? currentValue : '',
  });

  const handleSelection = selectedItem => {
    const value = selectedItem ? selectedItem.value : '';
    onChange(filterName, value);
  };

  const { items = [], isLoading } = useAsyncFilter(
    fetchFilters,
    throttledInputValue
  );
  return (
    <div
      id={filterName}
      className={`${styles.FetchSelectContainer} form-group fl`}
    >
      <label htmlFor={filterName + '-menu'}>{filterDisplayName}</label>
      <Select
        id={filterName}
        itemToKey={itemToKey}
        itemToString={itemToString}
        inputValue={inputValue}
        onInputValueChange={setInputValue}
        items={items}
        isLoading={isLoading}
        value={getSelectedItem(currentValue, items)}
        onChange={handleSelection}
      />
    </div>
  );
}

FetchSelect.propTypes = {
  currentValue: oneOfType([
    arrayOf(
      shape({
        label: string,
        value: string,
      })
    ),
    string, // This is the case where filter values come from URL params
  ]),
  dateRange: string,
  endpoint: string.isRequired,
  filterName: string.isRequired,
  filterDisplayName: string.isRequired,
  filterType: string.isRequired,
  merchantGroupId: oneOfType([string, number]),
  merchantIds: oneOfType([string, number]),
  onChange: func,
};
