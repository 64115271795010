import React from 'react';
import classnames from 'classnames';
import { Tab as ReachTab } from '@reach/tabs';
import styles from './Tab.module.scss';

export function Tab({ isSelected, children, ...rest }) {
  return (
    <ReachTab
      style={isSelected ? { borderBottom: '1px solid white' } : {}}
      className={classnames(
        styles.Tab,
        'list-style-none border border-l-0 border-t-0 border-gray-80 text-xl text-gray-10 leading-none font-bold no-underline py-5 px-6',
        {
          'bg-gray-93': !isSelected,
          'bg-white': isSelected,
        }
      )}
      {...rest}
    >
      {children}
    </ReachTab>
  );
}
