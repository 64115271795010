import React from 'react';
import classnames from 'classnames';
import { bool, func, number } from 'prop-types';
import ExpandablePanelButton from './ExpandablePanelButton';
import styles from './ExpandOrApply.module.scss';

export default function ExpandOrApply({
  expanded,
  numberOfSelectedFilters,
  showExpandButton,
  toggleExpand,
}) {
  return (
    <section
      className={classnames('fl pt2 pr0 flex', styles.ExpandOrApply__buttons)}
    >
      {showExpandButton && (
        <ExpandablePanelButton
          count={numberOfSelectedFilters}
          onClick={toggleExpand}
          type={expanded ? 'collapse' : 'expand'}
        />
      )}
      <button
        className={classnames('btn btn--small btn--primary text-sm')}
        label="Apply"
        type="submit"
      >
        Apply
      </button>
    </section>
  );
}

ExpandOrApply.propTypes = {
  expanded: bool,
  numberOfSelectedFilters: number,
  showExpandButton: bool,
  toggleExpand: func,
};
