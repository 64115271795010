import { getToken } from '@pwr/auth-provider';
import apiFetch from 'api/fetch';
import { TEST_FTP } from 'api/endpoints';

/**
 * Check the supplied credentials for the remote server
 * @param {string} address
 * @param {string} username
 * @param {string} password
 */
export function remoteServerCredentialTest({
  deliveryType,
  ftpPassword,
  ftpUserName,
  ftpUrl,
}) {
  return new Promise(async (resolve, reject) => {
    if (!ftpPassword || !ftpUserName || !ftpUrl) {
      return resolve(false);
    }
    const headers = {
      headers: {
        Authorization: getToken(),
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        deliveryType,
        ftpurl: ftpUrl,
        password: ftpPassword,
        username: ftpUserName,
      }),
    };

    try {
      await apiFetch(TEST_FTP, headers);
      resolve(true);
    } catch (error) {
      reject(error);
    }
  });
}
