import React from 'react';
import { func, string } from 'prop-types';
import inputStyles from '../inputs.module.scss';
const SPACE_KEYCODE = 32;

export default function Email({ emailValue, messageValue, onChange }) {
  const handleInputChange = event => {
    const { name, value } = event.target;
    onChange(name, value);
  };
  const preventSpace = event => {
    if (event.keyCode === SPACE_KEYCODE) {
      event.preventDefault();
    }
  };
  return (
    <>
      <div className={inputStyles.FormInput}>
        <label htmlFor="email">
          <span className="text-red">*</span>Email to
        </label>
        <input
          className="form-control"
          id="email"
          name="email"
          onKeyDown={preventSpace}
          onChange={handleInputChange}
          placeholder="Separate emails by commas"
          type="email"
          value={emailValue}
          multiple
        />
      </div>
      <div className={inputStyles.FormInput}>
        <label htmlFor="message">Message</label>
        <textarea
          className="form-control"
          id="message"
          name="message"
          onChange={handleInputChange}
          placeholder="Add a message so recipients know what they are receiving."
          value={messageValue}
          spellCheck
        />
      </div>
    </>
  );
}
Email.propTypes = {
  emailValue: string.isRequired,
  messageValue: string.isRequired,
  onChange: func.isRequired,
};
