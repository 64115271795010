import React, { useState } from 'react';
import { arrayOf, func, number, shape, string, object } from 'prop-types';
import { rankings } from 'match-sorter';
import classnames from 'classnames';
import { ChevronDown } from 'icons/Icons';
import SearchBar from 'components/SearchBar';
import Pagination from 'components/Pagination';
import style from './Table.module.scss';

const searchBarStyles = {
  boxShadow: 'none',
  margin: 0,
  marginBottom: '2rem',
};

export function TableRow({ children, isActive, ...rest }) {
  return (
    <tr
      {...rest}
      className={classnames(style.TableRow, {
        [style['TableRow--active']]: isActive,
      })}
    >
      {children}
    </tr>
  );
}

export function TableCell({ children, hidden, isTranslationEnabled }) {
  return (
    <td
      className={classnames(
        {
          'visually-hidden': hidden === true,
          [style['TableRow__show-hidden-menu']]: hidden === false,
        },
        'text-left'
      )}
      data-translate={isTranslationEnabled}
    >
      {children}
    </td>
  );
}

export function Table({
  paginationOptions = {},
  renderTableBody,
  searchOptions,
  sortFns,
  tableData,
  tableHeaders,
}) {
  const [activeHeader, setActiveHeader] = useState(tableHeaders[0].key);
  const [activeHeaderDirection, setActiveHeaderDirection] = useState('desc');
  const handleActiveHeaderChange = clickedHeader => {
    if (activeHeader === clickedHeader) {
      setActiveHeaderDirection(
        activeHeaderDirection === 'desc' ? 'asc' : 'desc'
      );
      return;
    }
    setActiveHeader(clickedHeader);
    setActiveHeaderDirection('desc');
  };

  const [searchResults, setSearchResults] = useState(tableData);
  const [pageNumber, setPageNumber] = useState(1);
  const { rowsPerPage } = paginationOptions;
  const slice = items => {
    if (!rowsPerPage) return items;
    const end = rowsPerPage * pageNumber;
    const start = end - rowsPerPage;
    return items.slice(start, end);
  };
  const sort = items => {
    if (!sortFns) return items;
    const sortedItems = searchResults.sort(sortFns[activeHeader]);
    return activeHeaderDirection === 'desc'
      ? sortedItems
      : sortedItems.reverse();
  };
  const hasNoSearchResults = searchResults.length === 0 && tableData.length > 0;
  return (
    <>
      {searchOptions && (
        <SearchBar
          renderIcon
          style={searchBarStyles}
          searchOptions={{
            keys: searchOptions.keys,
            threshold: searchOptions.threshold || rankings.CONTAINS,
          }}
          placeholder={searchOptions.placeholder || 'Search by keyword'}
          items={tableData}
          onSearch={searchResults => {
            const clearedSearch = searchResults.length === tableData.length;
            if (!clearedSearch) setPageNumber(1);
            setSearchResults(searchResults);
          }}
        />
      )}
      <table className={style.Table}>
        <thead>
          <tr className="text-left">
            {tableHeaders.map(({ key, label }) => (
              <th key={key}>
                <div
                  onClick={() => sortFns[key] && handleActiveHeaderChange(key)}
                  className={style.Table__header}
                >
                  {label}
                  {sortFns && activeHeader === key && (
                    <ChevronDown
                      transform={
                        activeHeaderDirection === 'desc' ? '' : 'rotate(180)'
                      }
                      height={7}
                    />
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{renderTableBody(slice(sort(searchResults)))}</tbody>
      </table>
      {hasNoSearchResults && (
        <div className="py-8 text-center">
          <p>No subscription titles matched your search</p>
        </div>
      )}
      {rowsPerPage && searchResults.length > 0 && (
        <div className="flex justify-end">
          <Pagination
            currentPage={pageNumber}
            onChange={setPageNumber}
            totalPages={Math.ceil(
              searchResults.length / paginationOptions.rowsPerPage
            )}
          />
        </div>
      )}
    </>
  );
}

Table.propTypes = {
  paginationOptions: shape({ rowsPerPage: number.isRequired }),
  renderTableBody: func.isRequired,
  searchOptions: shape({
    keys: arrayOf(string).isRequired,
    threshold: number,
    placeholder: string,
  }),
  sortFns: object,
  tableData: arrayOf(object).isRequired,
  tableHeaders: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ).isRequired,
};
