import * as React from 'react';
import PropTypes from 'prop-types';
import { AppCues } from 'lib/appcues';
import { ChurnZero } from 'lib/churnzero';
import { SatisMeter } from 'lib/satismeter';
import jsCookie from 'js-cookie';

export class UserNPSUtils extends React.Component {
  static propTypes = {
    username: PropTypes.string,
    email: PropTypes.string,
    userid: PropTypes.number,
    admin: PropTypes.bool,
    accountId: PropTypes.number,
  };

  state = { pathname: window.location.pathname };

  async componentDidMount() {
    const { accountId, admin, email, username, userid } = this.props;

    const [satismeterLoaded, churnzeroLoaded, appcuesLoaded] = [
      SatisMeter.load(),
      ChurnZero.load(),
      AppCues.load(),
    ];

    await satismeterLoaded;
    SatisMeter.showSurvey(this.props);

    await churnzeroLoaded;
    ChurnZero.identify({ email, accountId });

    await appcuesLoaded;
    AppCues.identify(userid, {
      name: username,
      email: email,
      accountId,
      admin,
      userid,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { pathname } = window.location;
    if (pathname !== prevState.pathname) {
      AppCues.page();
    }
    const { accountId, email } = this.props;
    if (
      String(prevProps.accountId) !== String(accountId) ||
      prevProps.email !== email
    ) {
      ChurnZero.identify({ email, accountId });
    }
  }

  componentWillUnmount() {
    ChurnZero.stop();
  }

  render() {
    return null;
  }
}

export default class UserNPS extends React.Component {
  render() {
    if (this.props.admin) return null;

    if (jsCookie.get('run_as')) return null;

    return <UserNPSUtils key={this.props.accountId} {...this.props} />;
  }
}
