import React from 'react';
import { func, number } from 'prop-types';

import { PageButton } from './PageButton';
import {
  formatNumberWithCommas,
  handlePageButton,
  handlePreviousButton,
  handleNextButton,
  makePageNumbers,
} from './paginationUtils';
import styles from './Pagination.module.scss';

function makePageButton(currentPage, pageNumber, onChange) {
  const isActive = currentPage === pageNumber;
  return (
    <PageButton
      aria-label={`Page ${pageNumber}`}
      aria-current={isActive}
      active={isActive}
      currentPage={currentPage}
      key={pageNumber}
      onClick={() => handlePageButton(pageNumber, currentPage, onChange)}
    >
      {formatNumberWithCommas(pageNumber)}
    </PageButton>
  );
}

export default function PageButtons({ currentPage, totalPages, onChange }) {
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === totalPages;
  const { pagesBeforeElipsis, pagesAfterElipsis } = makePageNumbers(
    currentPage,
    totalPages
  );
  const showElipsis = pagesAfterElipsis.length > 0;
  if (!totalPages) {
    return null;
  }
  return (
    <div
      role="navigation"
      aria-label="Pagination navigation"
      className={styles['Pagination']}
    >
      <PageButton
        disabled={isFirstPage}
        onClick={() => handlePreviousButton(currentPage, onChange)}
        type="previous"
      >
        previous
      </PageButton>
      {pagesBeforeElipsis.map(pageNumber =>
        makePageButton(currentPage, pageNumber, onChange)
      )}
      {showElipsis && <p className={styles['elipsis']}>...</p>}
      {showElipsis &&
        pagesAfterElipsis.map(pageNumber =>
          makePageButton(currentPage, pageNumber, onChange)
        )}
      <PageButton
        disabled={isLastPage}
        onClick={() => handleNextButton(currentPage, totalPages, onChange)}
        type="next"
      >
        next
      </PageButton>
    </div>
  );
}

PageButtons.propTypes = {
  currentPage: number.isRequired,
  totalPages: number.isRequired,
  onChange: func.isRequired,
};
