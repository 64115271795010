import React, { createContext, useEffect, useReducer, useRef } from 'react';
import { getToken } from '@pwr/auth-provider';
import {
  getMerchantGroupFromCookie,
  setMerchantGroupCookie,
} from '@pwr/merchant-group-utils';
import jsCookie from 'js-cookie';
import apiFetch from 'api/fetch';
import {
  makeMerchantGroupPropertiesEndpoint,
  MERCHANT_GROUP_LIST,
} from 'api/endpoints';
import Page from 'pages/Page/Page';
import { Spinner } from 'components/Loaders';
import { useBrowserPersistenceApi } from './BrowserPersistenceContext';

const BROWSER_CACHE_KEYS = {
  FETCH_MERCHANT_GROUP_RESPONSE: 'fetch_merchant_group_response',
};

const GENERAL_ERROR = {
  title: 'Unable to load Analytics',
  message:
    'A database outage is preventing Analytics from loading for some users. We are working to restore service as quickly as possible.',
};

const MerchantGroupStateContext = createContext();
const MerchantGroupDispatchContext = createContext();

function getExtraMerchantGroupsFromCookie(env) {
  return jsCookie.get(`${env}analytics_extra_merchant_group_ids`);
}
function setExtraMerchantGroupsFromCookie(merchantGroupIdsString, env) {
  jsCookie.set(
    `${env}analytics_extra_merchant_group_ids`,
    merchantGroupIdsString,
    {
      expires: 7,
      path: '/',
      sameSite: 'strict',
    }
  );
}

const env = process.env.REACT_APP_BUILD_ENV;

const LoadingState = ({
  apps,
  merchantGroupList,
  selectedMerchantGroupsByIds,
  name,
}) => {
  const navigationProps = {
    crumbs: [
      {
        label: 'Analytics',
        href: `/`,
      },
    ],
    activePrimary: 'Analytics',
    activeSecondary: '',
  };

  return (
    <Page
      apps={apps}
      hasNoMerchantGroupSelected={!getMerchantGroupFromCookie(env)}
      merchantGroupList={merchantGroupList || []}
      name={name}
      navigationProps={navigationProps}
      selectedMerchantGroupsByIds={selectedMerchantGroupsByIds}
    >
      <div
        className="flex flex-column justify-center items-center center"
        style={{
          position: 'fixed',
          top: 'calc(50% - 50px)',
          left: '50%',
          transfrom: 'translate(-50%, -50%)',
        }}
      >
        <Spinner />
        <p className="pt-8">Your application is loading</p>
      </div>
    </Page>
  );
};

const initialState = {
  isFetchingMerchantGroupList: false,
  isFetchingMerchantGroupProperties: false,
  selectedMerchantsByIds: new Map(),
  selectedMerchantGroupsByIds: new Map(),
  merchantGroupList: null,
  merchantGroupListError: null,
  merchantGroupProperties: null,
  merchantGroupPropertiesError: null,
};

export function setSelectedMerchantGroupsByIds(dispatch, merchantGroupIds) {
  dispatch({
    type: 'select-merchant-groups-by-ids',
    payload: merchantGroupIds,
  });
}

export function setSelectedMerchantsByIds(dispatch, selectedMerchantsByIds) {
  dispatch({
    type: 'select-merchants',
    payload: selectedMerchantsByIds,
  });
}

export function MerchantGroupProvider({
  apps,
  children,
  name,
  programGroups,
  configErrorName,
  configHasLoaded,
}) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const prevselectedMerchantGroupsByIds = usePrevious(
    state.selectedMerchantGroupsByIds
  );

  const browserCache = useBrowserPersistenceApi();

  useEffect(
    function updateBrowserCacheWithSelectedMerchantGroups() {
      if (!prevselectedMerchantGroupsByIds) return;

      if (prevselectedMerchantGroupsByIds.size === 0) {
        return;
      }

      const previousMerchantGroupSelections = Array.from(
        prevselectedMerchantGroupsByIds.keys()
      )
        .sort()
        .join(',');
      const currentMerchantGroupSelections = Array.from(
        state.selectedMerchantGroupsByIds.keys()
      )
        .sort()
        .join(',');

      if (previousMerchantGroupSelections === currentMerchantGroupSelections) {
        return;
      }

      const valuesForCache = {
        extraMerchantGroups: [],
        merchantGroupList: state.merchantGroupList,
        primaryMerchantGroup: null,
      };

      const selectedMerchantGroups = Array.from(
        state.selectedMerchantGroupsByIds.entries()
      );
      if (selectedMerchantGroups.length > 0) {
        valuesForCache.primaryMerchantGroup = selectedMerchantGroups[0][1];
        valuesForCache.extraMerchantGroups = selectedMerchantGroups.slice(1);
      }

      browserCache
        .setItemAsync(
          BROWSER_CACHE_KEYS.FETCH_MERCHANT_GROUP_RESPONSE,
          valuesForCache
        )
        .then(() => {
          console.debug(
            `Updated ${BROWSER_CACHE_KEYS.FETCH_MERCHANT_GROUP_RESPONSE}`
          );
        })
        .catch(error => {
          console.debug(
            `Unable to update ${
              BROWSER_CACHE_KEYS.FETCH_MERCHANT_GROUP_RESPONSE
            }: ${error}`
          );
        });
    },
    [
      prevselectedMerchantGroupsByIds,
      state.selectedMerchantGroupsByIds,
      state.merchantGroupList,
      browserCache,
    ]
  );

  useEffect(() => {
    if (state.selectedMerchantGroupsByIds.size === 0) return;
    const listOfMerchantGroupIds = [
      ...state.selectedMerchantGroupsByIds.keys(),
    ];
    setMerchantGroupCookie(listOfMerchantGroupIds.shift(), env);
    setExtraMerchantGroupsFromCookie(listOfMerchantGroupIds.join(','), env);
  }, [state.selectedMerchantGroupsByIds]);

  useEffect(() => {
    function dispatchMerchantGroupValues({
      extraMerchantGroups,
      merchantGroupList,
      primaryMerchantGroup,
    }) {
      dispatch({
        type: 'select-merchant-groups',
        payload: new Map(
          primaryMerchantGroup
            ? [
                [primaryMerchantGroup.merchantGroupId, primaryMerchantGroup],
                ...extraMerchantGroups,
              ]
            : []
        ),
      });
      dispatch({
        type: 'set-merchant-group-list',
        payload: merchantGroupList,
      });
    }

    let cancelledHook = false;
    const fetchData = async () => {
      dispatch({ type: 'set-fetching-merchant-group-list', payload: true });

      const merchantGroupIdsFromExtraMerchantGroupsCookie = getExtraMerchantGroupsFromCookie(
        env
      );

      try {
        const freshMerchantGroupResponse = fetchMerchantGroupList(
          getMerchantGroupFromCookie(env),
          merchantGroupIdsFromExtraMerchantGroupsCookie
        )
          .then(merchantGroupResponse => {
            dispatchMerchantGroupValues(merchantGroupResponse);

            writeValueToCache(
              BROWSER_CACHE_KEYS.FETCH_MERCHANT_GROUP_RESPONSE,
              merchantGroupResponse,
              browserCache
            );

            return merchantGroupResponse;
          })
          .catch(error =>
            console.error(
              'Unable to fetch fresh merchant group response',
              error
            )
          );

        const cachedMerchantGroupResponse = await browserCache.getItemAsync(
          BROWSER_CACHE_KEYS.FETCH_MERCHANT_GROUP_RESPONSE
        );

        const merchantGroupValues = cachedMerchantGroupResponseIsValid(
          cachedMerchantGroupResponse
        )
          ? cachedMerchantGroupResponse
          : await freshMerchantGroupResponse;

        if (!cancelledHook) {
          dispatchMerchantGroupValues(merchantGroupValues);
        }
      } catch (error) {
        console.error(error);
        if (!cancelledHook) {
          dispatch({ type: 'set-merchant-group-list-error', payload: error });
        }
      } finally {
        dispatch({ type: 'set-fetching-merchant-group-list', payload: false });
      }
    };
    fetchData();
    return () => (cancelledHook = true);
  }, [browserCache]);

  useEffect(
    function fetchMgPropsIfMgSelected() {
      let didCancel = false;
      const {
        merchantGroupList,
        selectedMerchantGroupsByIds,
        merchantGroupProperties,
        merchantGroupPropertiesError,
        isFetchingMerchantGroupProperties: currentlyFetchingProps,
      } = state;
      if (!merchantGroupList || selectedMerchantGroupsByIds.size === 0) {
        return;
      }
      const merchantGroup = selectedMerchantGroupsByIds.values().next().value;
      const prevMerchantGroup =
        prevselectedMerchantGroupsByIds.values().next().value || {};
      const alreadyFetchedPropsForThisMg =
        merchantGroupProperties &&
        prevMerchantGroup.merchantGroupId === merchantGroup.merchantGroupId;

      if (alreadyFetchedPropsForThisMg || currentlyFetchingProps) {
        return;
      }
      const fetchData = async () => {
        if (currentlyFetchingProps || merchantGroupPropertiesError) return;
        try {
          if (!didCancel) {
            dispatch({
              type: 'set-fetching-merchant-group-properties',
              payload: true,
            });
            const merchantGroupProperties = await fetchMerchantGroupProps(
              merchantGroup.merchantGroupId,
              merchantGroup.merchants
            );

            dispatch({
              type: 'set-merchant-group-properties',
              payload: merchantGroupProperties,
            });
          }
        } catch (error) {
          console.error(error);
          dispatch({
            type: 'set-merchant-group-properties-error',
            payload: error,
          });
        } finally {
          dispatch({
            type: 'set-fetching-merchant-group-properties',
            payload: false,
          });
        }
      };
      fetchData();
      return () => (didCancel = true);
    },
    [prevselectedMerchantGroupsByIds, state]
  );

  const shouldRenderChildren =
    configHasLoaded && !configErrorName && !!state.merchantGroupProperties;
  const uiState = (function() {
    const merchantGroupIdFromCookie = getMerchantGroupFromCookie(env);
    const hasNoMerchantGroups =
      state.merchantGroupList && state.merchantGroupList.length === 0;

    const navigationProps = {
      crumbs: [
        {
          label: 'Analytics',
          href: `/`,
        },
      ],
      activePrimary: 'Analytics',
      activeSecondary: '',
    };

    if (configErrorName) {
      console.error(
        `Failed to fetch ${configErrorName}. Please try again in a few moments.`
      );

      return (
        <Page
          apps={apps}
          error={GENERAL_ERROR}
          hasNoMerchantGroupSelected={!merchantGroupIdFromCookie}
          merchantGroupList={state.merchantGroupList || []}
          name={name}
          navigationProps={navigationProps}
          selectedMerchantGroupsByIds={state.selectedMerchantGroupsByIds}
        />
      );
    }
    if (state.merchantGroupListError) {
      console.error('Failed to fetch merchant groups');
      return (
        <Page
          apps={apps}
          error={GENERAL_ERROR}
          hasNoMerchantGroupSelected={!merchantGroupIdFromCookie}
          merchantGroupList={[]}
          name={name}
          navigationProps={navigationProps}
          selectedMerchantGroupsByIds={state.selectedMerchantGroupsByIds}
        />
      );
    }
    if (hasNoMerchantGroups) {
      console.error('No available merchant groups');
      return (
        <Page
          apps={apps}
          error={GENERAL_ERROR}
          hasNoMerchantGroupSelected={!merchantGroupIdFromCookie}
          merchantGroupList={state.merchantGroupList || []}
          name={name}
          navigationProps={navigationProps}
          selectedMerchantGroupsByIds={state.selectedMerchantGroupsByIds}
        />
      );
    }
    if (state.merchantGroupPropertiesError) {
      console.log('Failed to fetch merchant group properties.');
      return (
        <Page
          apps={apps}
          error={GENERAL_ERROR}
          hasNoMerchantGroupSelected={!merchantGroupIdFromCookie}
          merchantGroupList={state.merchantGroupList || []}
          name={name}
          navigationProps={navigationProps}
          selectedMerchantGroupsByIds={state.selectedMerchantGroupsByIds}
        />
      );
    }
    return (
      <LoadingState
        apps={apps}
        name={name}
        merchantGroupList={state.merchantGroupList}
        selectedMerchantGroupsByIds={state.selectedMerchantGroupsByIds}
      />
    );
  })();
  return (
    <MerchantGroupStateContext.Provider value={state}>
      <MerchantGroupDispatchContext.Provider value={dispatch}>
        {shouldRenderChildren ? children : uiState}
      </MerchantGroupDispatchContext.Provider>
    </MerchantGroupStateContext.Provider>
  );
}

export function useMerchantGroupState() {
  const context = React.useContext(MerchantGroupStateContext);
  if (context === undefined) {
    throw new Error(
      'useMerchantGroupState must be used within a MerchantGroupProvider'
    );
  }
  return context;
}
export function useMerchantGroupDispatch() {
  const context = React.useContext(MerchantGroupDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useMerchantGroupDispatch must be used within a MerchantGroupProvider'
    );
  }
  return context;
}

function reducer(state, action) {
  switch (action.type) {
    case 'set-fetching-merchant-group-list':
      return {
        ...state,
        isFetchingMerchantGroupList: action.payload,
      };
    case 'set-merchant-group-list-error':
      return {
        ...state,
        merchantGroupListError: action.payload,
        merchantGroupList: [],
      };
    case 'set-merchant-group-list':
      return {
        ...state,
        merchantGroupList: action.payload,
      };
    case 'select-merchant-groups':
      return {
        ...state,
        selectedMerchantGroupsByIds: action.payload,
      };
    case 'select-merchant-groups-by-ids': {
      const selectedMerchantGroupIds = new Set(action.payload.split(','));
      const newSelectedMerchantGroupsByIds = state.merchantGroupList.reduce(
        (accumulator, merchantGroup) => {
          if (
            selectedMerchantGroupIds.has(String(merchantGroup.merchantGroupId))
          ) {
            accumulator.set(merchantGroup.merchantGroupId, merchantGroup);
          }
          return accumulator;
        },
        new Map()
      );
      return {
        ...state,
        selectedMerchantGroupsByIds: newSelectedMerchantGroupsByIds,
      };
    }
    case 'set-fetching-merchant-group-properties':
      return {
        ...state,
        isFetchingMerchantGroupProperties: action.payload,
      };
    case 'set-merchant-group-properties-error':
      return {
        ...state,
        merchantGroupPropertiesError: action.payload,
      };
    case 'set-merchant-group-properties':
      return {
        ...state,
        merchantGroupProperties: action.payload,
      };
    case 'select-merchants':
      return {
        ...state,
        selectedMerchantsByIds: action.payload,
      };
    default:
      throw new Error(`${action.type} not defined in reducer`);
  }
}

function fetchMerchantGroupList(
  merchantGroupIdFromCookie,
  merchantGroupIdsFromExtraMerchantGroupsCookie = ''
) {
  const headers = {
    headers: { Authorization: getToken() },
  };

  return apiFetch(MERCHANT_GROUP_LIST, headers)
    .then(response => response.json())
    .then(merchantGroupList => {
      const merchantGroupsFromCookies = merchantGroupList.filter(
        ({ merchantGroupId }) => {
          const mgIdString = String(merchantGroupId);
          return (
            merchantGroupIdFromCookie === mgIdString ||
            merchantGroupIdsFromExtraMerchantGroupsCookie
              .split(',')
              .includes(mgIdString)
          );
        }
      );

      const primaryMerchantGroup =
        merchantGroupList.length === 1
          ? merchantGroupList[0]
          : merchantGroupsFromCookies.find(
              mg => String(mg.merchantGroupId) === merchantGroupIdFromCookie
            );
      if (primaryMerchantGroup) {
        setMerchantGroupCookie(primaryMerchantGroup.merchantGroupId, env);
      }
      const extraMerchantGroups = merchantGroupsFromCookies
        .filter(mg => String(mg.merchantGroupId) !== merchantGroupIdFromCookie)
        .map(mg => [mg.merchantGroupId, mg]);
      return {
        extraMerchantGroups,
        merchantGroupList,
        primaryMerchantGroup,
      };
    });
}

async function fetchMerchantGroupProps(merchantGroupId, merchantList) {
  const headers = {
    headers: {
      Authorization: getToken(),
    },
  };

  const arrayOfMerchantGroupProperties = await apiFetch(
    makeMerchantGroupPropertiesEndpoint(merchantGroupId, [
      'ENABLE_INTERACTIVE_PULSE',
      'ENABLE_PRODUCT_SENTIMENT',
      'ENABLE_RETAILER_SENTIMENT',
      'ENABLE_BRAND_HEALTH_BENCHMARKING',
      'BRAND_ENGAGE_TYPE',
      'ENABLE_PRODUCT_ANSWERS',
      'IS_ACTIVE_MEMBER_OF_BRANDSHARE',
      'OCADO_PORTAL_LANGUAGES',
    ]),
    headers
  ).then(res => res.json());
  return convertPropertiesToObj(arrayOfMerchantGroupProperties);
}

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}
function convertPropertiesToObj(merchantGroupProperties = []) {
  return merchantGroupProperties.reduce((accumulator, property) => {
    const { key, value: rawValue, data_type } = property;
    if (data_type === 'boolean') {
      accumulator[key] = rawValue === 'true' ? true : false;
      return accumulator;
    }
    accumulator[key] = rawValue;
    return accumulator;
  }, {});
}

function writeValueToCache(key, value, browserCache) {
  return browserCache
    .setItemAsync(key, value)
    .then(() => {
      console.debug(`Updated ${key} in browser cache`);
    })
    .catch(error =>
      console.debug(`Unable to update ${key} in browser cache`, error)
    );
}

function cachedMerchantGroupResponseIsValid(cachedValue) {
  if (!cachedValue) return false;

  try {
    const cachedValueShapeIsOk =
      Array.isArray(cachedValue.merchantGroupList) &&
      Array.isArray(cachedValue.extraMerchantGroups) &&
      cachedValue.primaryMerchantGroup;

    if (
      String(getMerchantGroupFromCookie(env)) !==
      String(cachedValue.primaryMerchantGroup.merchantGroupId)
    ) {
      console.debug(
        'Incoming merchant group cookie conflicts with cached values, not using cached merchant group values'
      );
      return false;
    }

    console.debug(
      `${
        cachedValueShapeIsOk ? 'Using' : 'Not using'
      } cached merchant group values`
    );

    return cachedValueShapeIsOk;
  } catch (error) {
    console.error('Invalid cached merchant group response:', cachedValue);
    return false;
  }
}
