import React from 'react';
import classNames from 'classnames';
import styles from './LoadingBar.module.scss';

export function LoadingBar({ exactWidth, maxWidth = 75, minWidth = 100 }) {
  return (
    <div
      data-testid="loading-bar"
      aria-hidden={true}
      className={styles.LoadingBar}
    >
      <div
        className={classNames('bg-gray-93 rounded', styles.loadingPulses)}
        style={{
          height: '1rem',
          width:
            (exactWidth || randomIntFromInterval(minWidth, maxWidth)) + 'px',
        }}
      />
    </div>
  );
}

function randomIntFromInterval(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}
