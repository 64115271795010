import React, { useEffect, useState } from 'react';
import apiFetch from 'api/fetch';
import { GET_REPORT_DOWNLOAD_URLS } from 'api/endpoints';
import { Spinner } from 'components/Loaders/Spinner';
import { FilesIcon, PwrLogoIcon } from 'icons/Icons';

export function DownloadSubscriptionPage() {
  const fileId = window.location.hash.split('/file/').pop();
  return (
    <>
      <header className="flex justify-center bg-gray-20 p-2">
        <PwrLogoIcon width="40" height="40" />
      </header>
      <div
        style={{
          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 2px 2px 0px',
          left: '50%',
          maxHeight: 350,
          maxWidth: 400,
          position: 'fixed',
          top: '50%',
          transform: 'translate(-50%, -50%)',
        }}
        className="bg-white flex flex-col items-center justify-center p-4 break-words h-100 w-100"
      >
        <DownloadBox fileId={fileId} />
      </div>
    </>
  );
}

function DownloadBox({ fileId }) {
  const { error, response } = useSubscriptionFileData(fileId);

  if (error) {
    return (
      <div className="flex flex-col justify-center">
        <span
          className="text-center"
          style={{ fontSize: 72 }}
          aria-label="Sobbing face"
          role="img"
        >
          😢
        </span>
        {/* <FilesIcon fill="white" height={100} /> */}
        <h2 className="text-center my-4 p-1">
          We were unable to get your report!
        </h2>
        <p className="lh-copy">{error}</p>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://help.powerreviews.com/Content/Contact%20Us.htm"
          className="btn btn--secondary mt-4"
          to="/subscriptions"
        >
          Talk with our support team
        </a>
      </div>
    );
  }
  if (response.url) {
    return (
      <div className="flex flex-col justify-center">
        <FilesIcon fill="white" height={100} />
        <h2 className="text-center my-2">Download your report</h2>
        <p className="text-gray-35 lh-copy my-4 italic">
          <span className="font-bold">Note:</span> PowerReviews maintains files
          for one year. This subscription will be unreachable one year from the
          original delivery date.
        </p>
        <a
          style={{ lineBreak: 'anywhere' }}
          className="max-width-4 lh-copy"
          href={response.url}
        >
          {response.filename}
        </a>
      </div>
    );
  }
  return (
    <>
      <Spinner />
      <p className="text-gray-35 mt-8">Fetching file data</p>
    </>
  );
}

function useSubscriptionFileData(fileId) {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState({});

  useEffect(() => {
    if (!fileId) return;
    const FILE_NOT_FOUND_ERROR_TEXT = `PowerReviews maintains files for one year. This subscription expires
  one year from the original delivery date so it's possible that this
  subscription is expired.`;

    const INTERNAL_SERVER_ERROR_TEXT = `Server error—please try again in a few moments`;

    let didCancel = false;
    const fetchData = async () => {
      setIsLoading(true);
      const options = {
        headers: {
          'Content-type': 'application/json',
        },
        method: 'GET',
      };
      try {
        const url = `${GET_REPORT_DOWNLOAD_URLS}?token=${fileId}`;
        const subscriptionFileResponse = await apiFetch(url, options).then(
          res => res.json()
        );

        if (subscriptionFileResponse.error) {
          setError(FILE_NOT_FOUND_ERROR_TEXT);
          return;
        }
        // Note: the response is an obj with an `urls` property. Nobody knows why
        // this is `urls` and not `url`. It seems like we only actually use 1 url
        // according to Legacy UI source code
        if (!didCancel) {
          const filename = subscriptionFileResponse.urls[0]
            .split('com/')[1]
            .split('?')[0];
          setResponse({
            url: subscriptionFileResponse.urls[0],
            filename,
          });
        }
      } catch (error) {
        console.error(error.status);
        setError(
          error.status > 499
            ? INTERNAL_SERVER_ERROR_TEXT
            : FILE_NOT_FOUND_ERROR_TEXT
        );
      } finally {
        setIsLoading(false);
      }
    };
    if (!didCancel) fetchData();
    return () => (didCancel = true);
  }, [fileId]);
  return { error, isLoading, response };
}
