import getEndpointUrl from 'api/getEndpointUrl';
import { AUTH_SERVICE } from 'api/endpoints';
import { redirectToAuthService } from '@pwr/auth-provider';
import checkResponse from 'api/checkResponse';
import Errors, { NetworkingError } from 'lib/errors';

const apiFetch = (url, options) =>
  fetch(getEndpointUrl(url), options)
    .then(checkResponse)
    .catch(err => {
      if (err.name === 'TypeError') redirectToAuthService(AUTH_SERVICE);
      return Promise.reject(
        Errors.isHttpError(err) ? err : new NetworkingError()
      );
    });

export default apiFetch;
