import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { MultiSelect, useSmartFilter } from '@pwr-ui/select';
import {
  useMerchantGroupState,
  useMerchantGroupDispatch,
  setSelectedMerchantGroupsByIds,
} from 'contexts/MerchantGroupContext';
import { generateSecondaryMenuItems } from './navigationItems/secondaryMenuItems';
import {
  SideNav,
  TopNav,
  MerchantGroupPickerIcon,
} from '@pwr/nav-ui-components';
import { RunAsBanner } from 'components/RunAsBanner';

const environment =
  process.env.REACT_APP_BUILD_ENV !== 'prod'
    ? process.env.REACT_APP_BUILD_ENV
    : '';
const portalUrl = `//${environment}portal.powerreviews.com`;

const itemToString = item => {
  return item ? item.name || String(item.merchantGroupId) : '';
};
const itemToKey = item => item.merchantGroupId;

const MerchantGroupMultiSelect = ({
  selectedMerchantGroupsByIds,
  merchantGroups,
  id,
  onMerchantGroupChange,
}) => {
  const dispatch = useMerchantGroupDispatch();
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState(
    new Map(selectedMerchantGroupsByIds.entries())
  );

  const handleSelect = newselectedMerchantGroupsByIds => {
    setMenuIsOpen(true);
    setValue(newselectedMerchantGroupsByIds);
  };
  const handleMenuClose = () => {
    setMenuIsOpen(false);
    if (value.size === 0) return;
    const currentMerchantGroupIds = [
      ...selectedMerchantGroupsByIds.keys(),
    ].join(',');
    const nextMerchantGroupIds = [...value.keys()].join(',');
    if (currentMerchantGroupIds === nextMerchantGroupIds) {
      return;
    }
    setSelectedMerchantGroupsByIds(dispatch, nextMerchantGroupIds);
  };
  useEffect(
    function handleExternalMerchantGroupChange() {
      if (menuIsOpen) return;
      setValue(new Map(selectedMerchantGroupsByIds.entries()));
    },
    [selectedMerchantGroupsByIds, menuIsOpen]
  );

  const filteredItems = useSmartFilter(
    merchantGroups,
    itemToString,
    inputValue
  );
  return (
    <MultiSelect
      id={id}
      controlWidth={200}
      items={filteredItems}
      itemToKey={itemToKey}
      itemToString={itemToString}
      onChange={handleSelect}
      onMenuClose={handleMenuClose}
      value={value}
      inputValue={inputValue}
      onInputValueChange={setInputValue}
      menuWidth={300}
      placeholder={selected => {
        const numSelected = selected.size;
        if (numSelected === 0) return 'Select a merchant group';
        const primaryMerchantName = selected.values().next().value.name;
        if (numSelected === 1) return primaryMerchantName;
        return `${primaryMerchantName} and ${numSelected - 1} more`;
      }}
    />
  );
};

export function Navigation({
  activePrimary,
  activeSecondary,
  apps,
  crumbs,
  isBrandEngageOnly,
  name,
}) {
  const {
    selectedMerchantGroupsByIds,
    merchantGroupList,
    merchantGroupProperties,
  } = useMerchantGroupState();
  const areAppsReady = Boolean(apps.length);
  return (
    <>
      <RunAsBanner />
      <header>
        {areAppsReady && (
          <TopNav
            crumbs={crumbs.map(crumb => ({
              ...crumb,
              hrefProp: 'to',
              linkComponent: ({ children, className, to }) => (
                <Link className={className} to={to}>
                  {children}
                </Link>
              ),
            }))}
            username={name || 'Loading Username'}
            userItems={[
              {
                label: 'My Profile',
                href: `https://${portalUrl}/profile/edit`,
              },
              { label: 'Logout', href: `https://${portalUrl}/logout` },
            ]}
            merchantGroups={merchantGroupList || []}
            nameProp="name"
            idProp="merchantGroupId"
            selectedMerchantGroup={
              selectedMerchantGroupsByIds.values().next().value
            }
            helpItems={[
              {
                label: 'Documentation',
                href: 'http://help.powerreviews.com/Content/Home.htm',
              },
              {
                label: 'Tech Support',
                href: `https://${portalUrl}/tech_support`,
              },
            ]}
            renderMerchantGroups={({ merchantGroups, idProp }) => {
              if (
                merchantGroupList !== null &&
                merchantGroupList.length === 0
              ) {
                return (
                  <div className="flex items-center justify-center">
                    <MerchantGroupPickerIcon />
                    <p className="text-xs ml-1">No Available Merchant Groups</p>
                  </div>
                );
              }
              if (merchantGroups.length === 0) {
                return (
                  <div className="flex items-center justify-center">
                    <MerchantGroupPickerIcon />
                    <p className="text-xs ml-1">Loading merchant group</p>
                  </div>
                );
              }
              if (merchantGroups.length === 1) {
                const merchantGroup = merchantGroups[0];
                return (
                  <div className="flex items-center justify-center">
                    <MerchantGroupPickerIcon />
                    <p className="text-xs ml-1">{merchantGroup.name}</p>
                  </div>
                );
              }
              return (
                <div className="flex items-center">
                  <MerchantGroupPickerIcon />
                  <MerchantGroupMultiSelect
                    id={idProp}
                    merchantGroups={merchantGroups}
                    selectedMerchantGroupsByIds={selectedMerchantGroupsByIds}
                  />
                </div>
              );
            }}
          />
        )}
      </header>
      <aside className={`w5 min-vh-100 fixed top-0 left-0 bottom-0 z-10`}>
        <SideNav
          secondaryLinkComponent={({ children, className, to }) => {
            return to.indexOf('.com') > -1 ? (
              <a className={className} href={to}>
                {children}
              </a>
            ) : (
              <Link className={className} to={to}>
                {children}
              </Link>
            );
          }}
          secondaryHrefProp="to"
          activePrimary={areAppsReady ? activePrimary : ''}
          activeSecondary={activeSecondary}
          primaryMenuItems={apps}
          secondaryMenuItems={generateSecondaryMenuItems(
            isBrandEngageOnly,
            merchantGroupProperties || {},
            apps
          )}
        />
      </aside>
    </>
  );
}
