import Cookies from 'js-cookie';
const COOKIE_NAME = 'analytics_system_alerts';

export const Storage = {
  getDismissedAlerts() {
    const str = Cookies.get(COOKIE_NAME);
    return str ? str.split('|').filter(Boolean) : [];
  },
  setDismissedAlerts(ids) {
    Cookies.set(COOKIE_NAME, ids.join('|'), {
      expires: 7,
      sameSite: 'strict',
      secure: true,
    });
  },
  setDismissedAlert(id) {
    const next = [...Storage.getDismissedAlerts(), id];
    Storage.setDismissedAlerts(next);
  },
};
