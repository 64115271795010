import React, { useState } from 'react';
import { ratingsReviewsReportPageProps } from 'pages/RatingsReviews';
import { questionAnswersReportPageProps } from 'pages/QuestionsAnswers';
import { samplingReportPageProps } from 'pages/Sampling';
import { brandEngageReportPageProps } from 'pages/BrandEngage';
import { visualSocialReportPageProps } from 'pages/VisualSocial';
import { syndicationReportPageProps } from 'pages/Syndication';
import { intelligenceSuitePageProps } from 'pages/IntelligenceSuite';
import { siteAnalyticsReportPageProps } from 'pages/SiteAnalytics';
import { followUpReportPageProps } from 'pages/FollowUp';
import { brandHealthBenchmarkingPageProps } from 'pages/BrandHealthBenchmarking';
import { receiptReviewCollectPageProps } from 'pages/ReceiptReviewCollect';
import DataExplorer from 'pages/DataExplorer/DataExplorer';
import SubscriptionIndex from 'pages/Subscriptions/SubscriptionIndex';
import { SavedReportsIndex } from 'pages/SavedReports/SavedReportsIndex';
import UserNPS from 'components/UserNPS';
import { UserTranslation } from 'components/UserTranslation';
import styles from './Main.module.scss';
import {
  useMerchantGroupState,
  useMerchantGroupDispatch,
  setSelectedMerchantGroupsByIds,
  setSelectedMerchantsByIds,
} from 'contexts/MerchantGroupContext';
import { Route, Switch, Redirect, BrowserRouter } from 'react-router-dom';
import { ReportPage } from 'pages/ReportPage';
import Page from 'pages/Page/Page';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';

function Main({
  apps,
  email,
  isAdmin,
  name,
  nonCustomReports,
  programGroups,
  userid,
}) {
  const {
    selectedMerchantGroupsByIds,
    selectedMerchantsByIds,
    merchantGroupList,
    merchantGroupProperties,
  } = useMerchantGroupState();

  const onMerchantGroupSelect = selectedMerchantGroupIds => {
    return setSelectedMerchantGroupsByIds(dispatch, selectedMerchantGroupIds);
  };
  const onMerchantSelect = selectedMerchantGroupIds => {
    return setSelectedMerchantsByIds(dispatch, selectedMerchantGroupIds);
  };
  const [lastReportPageBreadcrumb, setLastReportPageBreadcrumb] = useState([]);

  const {
    merchantGroupId,
    ssAccountInformationId,
  } = selectedMerchantGroupsByIds.values().next().value;
  const dispatch = useMerchantGroupDispatch();
  const isBrandEngageOnly = calculateIsBrandEngage(
    merchantGroupProperties,
    programGroups
  );
  const isPwrClient = isBrandEngageOnly === false;

  const reportDefinitions = [
    {
      renderIf: isPwrClient,
      path: '/ratings-reviews',
      pageProps: ratingsReviewsReportPageProps(),
    },
    {
      renderIf: isPwrClient,
      path: '/sampling',
      pageProps: samplingReportPageProps(),
    },
    {
      renderIf: isPwrClient,
      path: '/follow-up-program',
      pageProps: followUpReportPageProps(),
    },
    {
      renderIf: merchantGroupProperties.ENABLE_PRODUCT_ANSWERS,
      path: '/questions-answers',
      pageProps: questionAnswersReportPageProps({
        isBrandEngageOnly,
        merchantGroupProperties,
      }),
    },
    {
      renderIf: merchantGroupProperties.BRAND_ENGAGE_TYPE === 'brand',
      path: '/brand-engage',
      pageProps: brandEngageReportPageProps(),
    },
    {
      renderIf: isPwrClient,
      path: '/visual-social',
      pageProps: visualSocialReportPageProps(),
    },
    {
      renderIf: merchantGroupProperties.IS_ACTIVE_MEMBER_OF_BRANDSHARE,
      path: '/syndication',
      pageProps: syndicationReportPageProps(),
    },
    {
      renderIf: Boolean(
        merchantGroupProperties.ENABLE_INTERACTIVE_PULSE ||
          merchantGroupProperties.ENABLE_PRODUCT_SENTIMENT
      ),
      path: '/product-sentiment',
      pageProps: intelligenceSuitePageProps(),
    },
    {
      renderIf: isPwrClient,
      path: '/site-analytics',
      pageProps: siteAnalyticsReportPageProps(),
    },
    {
      renderIf: isPwrClient,
      path: '/receipt-review-collect',
      pageProps: receiptReviewCollectPageProps(),
    },
    {
      renderIf: Boolean(
        merchantGroupProperties.ENABLE_RETAILER_SENTIMENT ||
          merchantGroupProperties.ENABLE_BRAND_HEALTH_BENCHMARKING
      ),
      path: '/brand-health-benchmarking',
      pageProps: brandHealthBenchmarkingPageProps(),
    },
  ];

  const reportPages = reportDefinitions.reduce(
    (accumulator, { path, pageProps }) => {
      const render = ({ location, history, match }) => {
        const { defaultTab, navigationProps, pageUrl, reports } = pageProps;
        const breadcrumbs = [
          ...navigationProps.crumbs,
          ...lastReportPageBreadcrumb,
        ];
        return (
          <ErrorBoundary>
            <Page
              apps={apps}
              isBrandEngageOnly={isBrandEngageOnly}
              name={name}
              navigationProps={{
                ...navigationProps,
                crumbs: breadcrumbs,
              }}
              selectedMerchantGroupsByIds={selectedMerchantGroupsByIds}
            >
              <ReportPage
                currentBreadcrumbs={breadcrumbs}
                defaultActiveTab={defaultTab}
                email={email}
                isAdmin={isAdmin}
                onMerchantSelect={onMerchantSelect}
                pageUrl={pageUrl}
                reportDefinitions={nonCustomReports}
                reports={reports}
                selectedMerchantGroupsByIds={selectedMerchantGroupsByIds}
                selectedMerchantsByIds={selectedMerchantsByIds}
                setLastBreadcrumb={setLastReportPageBreadcrumb}
                setSelectedMerchantGroupsByIds={onMerchantGroupSelect}
                userid={userid}
              />
            </Page>
          </ErrorBoundary>
        );
      };

      const reportPagePath = `${path}/:tab/:subscriptionOrSavedReportId?`;
      accumulator.push(
        <Route key={reportPagePath} path={reportPagePath} render={render} />,
        <Route key={path} path={path} render={render} />
      );
      return accumulator;
    },
    []
  );

  const allReportPageProps = reportDefinitions.map(r => r.pageProps);

  return (
    <BrowserRouter>
      <main className={`${styles.main} db cf min-vh-100 center ph5-l ph4`}>
        <UserNPS
          accountId={ssAccountInformationId}
          username={name}
          email={email}
          userid={userid}
          admin={isAdmin}
        />
        <UserTranslation />
        <Switch>
          {reportPages}
          <Route
            path="/data-explorer/:reportId"
            render={({ location, history, match }) => (
              <DataExplorer
                apps={apps}
                history={history}
                isAdmin={isAdmin}
                isBrandEngageOnly={isBrandEngageOnly}
                location={location}
                selectedMerchantGroupsByIds={selectedMerchantGroupsByIds}
                selectedMerchantsByIds={selectedMerchantsByIds}
                match={match}
                merchantGroupId={merchantGroupId}
                name={name}
                nonCustomReports={nonCustomReports}
                onMerchantSelect={onMerchantSelect}
              />
            )}
          />
          <Route
            path="/data-explorer"
            render={({ location, history, match }) => (
              <DataExplorer
                apps={apps}
                history={history}
                isAdmin={isAdmin}
                isBrandEngageOnly={isBrandEngageOnly}
                location={location}
                selectedMerchantGroupsByIds={selectedMerchantGroupsByIds}
                selectedMerchantsByIds={selectedMerchantsByIds}
                match={match}
                merchantGroupId={merchantGroupId}
                name={name}
                nonCustomReports={nonCustomReports}
                onMerchantSelect={onMerchantSelect}
              />
            )}
          />
          <Route
            path="/subscriptions"
            render={({ location, history, match }) => (
              <SubscriptionIndex
                allReportPageProps={allReportPageProps}
                apps={apps}
                email={email}
                history={history}
                isAdmin={isAdmin}
                isBrandEngageOnly={isBrandEngageOnly}
                location={location}
                match={match}
                merchantGroupList={merchantGroupList}
                name={name}
                nonCustomReports={nonCustomReports}
                onMerchantSelect={onMerchantSelect}
                selectedMerchantGroupsByIds={selectedMerchantGroupsByIds}
                selectedMerchantsByIds={selectedMerchantsByIds}
                setLastBreadcrumb={setLastReportPageBreadcrumb}
                setSelectedMerchantGroupsByIds={onMerchantGroupSelect}
              />
            )}
          />
          <Route
            path="/saved-reports"
            render={({ location, history, match }) => (
              <SavedReportsIndex
                allReportPageProps={allReportPageProps}
                apps={apps}
                isBrandEngageOnly={isBrandEngageOnly}
                setSelectedMerchantGroupsByIds={onMerchantGroupSelect}
                selectedMerchantGroupsByIds={selectedMerchantGroupsByIds}
                selectedMerchantsByIds={selectedMerchantsByIds}
                setLastBreadcrumb={setLastReportPageBreadcrumb}
                name={name}
                nonCustomReports={nonCustomReports}
                onMerchantSelect={onMerchantSelect}
              />
            )}
          />
          <Redirect
            to={
              isBrandEngageOnly ? '/brand-engage' : '/ratings-reviews/display'
            }
          />
        </Switch>
      </main>
    </BrowserRouter>
  );
}

export default Main;

function calculateIsBrandEngage(mgProperties, programGroups) {
  if (!mgProperties || !programGroups) return false;
  return (
    mgProperties.BRAND_ENGAGE_TYPE === 'brand' &&
    !!programGroups.BrandEngage &&
    Object.keys(programGroups).length === 1
  );
}
