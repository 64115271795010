import React, { useState } from 'react';
import {
  arrayOf,
  bool,
  element,
  func,
  number,
  object,
  shape,
  string,
} from 'prop-types';
import ColumnarGrid from 'components/ColumnarGrid';
import Pagination from 'components/Pagination';
import Window from 'components/ColumnarGrid/Window';
import EditColumns from 'components/ColumnDndEditor/EditColumns';
import Modal from 'components/Modal';
import ModalCard from 'components/Modal/ModalCard';
import styles from './ColumnarReport.module.scss';

const EmptyState = () => {
  return (
    <div style={{ height: 400 }} className={styles.EmptyState}>
      <div className="w-50 flex flex-column justify-center items-center">
        <p className="text-gray-35 mt-4">No data to show</p>
      </div>
    </div>
  );
};

export default function ColumnarReport({
  backButton,
  columnarReport,
  canEditReport,
  handleColumnarDrag,
  handleColumnChange,
  handlePageChange,
  handleSortChange,
  id,
  onEditReport,
  title,
}) {
  if (columnarReport.hasFailure) {
    throw new Error(columnarReport.error);
  }
  const [openSaveChanges, setOpenSaveChanges] = useState(false);
  const saveChangesOpen = () => setOpenSaveChanges(true);
  const saveChangesClose = () => setOpenSaveChanges(false);
  const LoadingState = () => {
    return (
      <>
        <Window horizontalScrollShadow={false} maxWidth={'100%'}>
          <ColumnarGrid
            activeColumn={columnarReport.activeColumn || ''}
            activeColumnDirection={columnarReport.activeColumnDirection || ''}
            columns={columnarReport.activeColumns || []}
            data={[]}
            id={id}
            isLoading={columnarReport.isLoading}
            onClick={handleSortChange}
            onDrag={handleColumnarDrag}
          />
        </Window>
      </>
    );
  };
  return (
    <>
      <div className="flex justify-between px-6 pt-4 bg-white">
        <div className="flex items-center">
          {backButton}
          {title && <h2 className="text-gray-35 text-left">{title}</h2>}
        </div>
        <div>
          <div className="flex">
            {columnarReport.activeColumns && (
              <EditColumns
                activeColumns={columnarReport.activeColumns}
                inactiveColumns={columnarReport.inactiveColumns}
                onChange={handleColumnChange}
              />
            )}

            {canEditReport && (
              <>
                <button onClick={saveChangesOpen} className="btn btn--tag ml-2">
                  Save changes to report
                </button>
                <Modal
                  closeOnBackgroundClick
                  handleClose={saveChangesClose}
                  open={openSaveChanges}
                >
                  <ModalCard
                    maxWidth={685}
                    height={200}
                    padding={'1rem'}
                    handleClose={saveChangesClose}
                  >
                    <div className="flex flex-column mt-6">
                      <h2>Update subscription</h2>
                      <p className="mt-4">
                        Changes made to the report preview will persist to your
                        subscription.
                      </p>
                      <div className="flex mt-4">
                        <button
                          onClick={saveChangesClose}
                          className="btn btn--alert mr-4"
                        >
                          Cancel
                        </button>
                        <button
                          onClick={() => {
                            saveChangesClose();
                            onEditReport();
                          }}
                          className="btn btn--primary"
                        >
                          Save changes
                        </button>
                      </div>
                    </div>
                  </ModalCard>
                </Modal>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="p-6 bg-white">
        {columnarReport.isLoading ? (
          <LoadingState />
        ) : (
          <>
            <Window
              horizontalScrollShadow={
                columnarReport.activeColumns.length > 5 &&
                columnarReport.columnarData.rows.length > 0
              }
              maxWidth={'100%'}
            >
              <ColumnarGrid
                activeColumn={columnarReport.activeColumn}
                activeColumnDirection={columnarReport.activeColumnDirection}
                columns={columnarReport.activeColumns}
                data={columnarReport.columnarData.rows}
                id={id}
                loading={columnarReport.isLoading}
                onClick={handleSortChange}
                onDrag={handleColumnarDrag}
              />
            </Window>
            {columnarReport.columnarData.rows.length === 0 && <EmptyState />}
            <div className="bg-white py-3 flex justify-end">
              <Pagination
                currentPage={Number(columnarReport.currentPageNumber)}
                totalPages={Number(columnarReport.totalColumnarPages)}
                onChange={handlePageChange}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
}

ColumnarReport.propTypes = {
  backButton: element,
  columnarReport: shape({
    activeColumn: string.isRequired,
    activeColumnDirection: string.isRequired,
    activeColumns: arrayOf(
      shape({
        displayName: string.isRequired,
        propertyName: string.isRequired,
      })
    ),
    currentPageNumber: number.isRequired,
    columnarData: shape({
      columns: arrayOf(
        shape({
          displayHeader: string.isRequired,
          name: string.isRequired,
        })
      ),
      orderByColumn: string.isRequired,
      orderByOrder: string,
      rows: arrayOf(object), // Row objects map to a column's `propertyName`
    }),
    isLoading: bool.isRequired,
    hasFailure: bool.isRequired,
    inactiveColumns: arrayOf(
      shape({
        displayName: string.isRequired,
        propertyName: string.isRequired,
      })
    ),
    totalColumnarPages: number,
  }),
  handleColumnChange: func.isRequired,
  handleSortChange: func.isRequired,
  handleColumnarDrag: func.isRequired,
  handlePageChange: func.isRequired,
  title: string,
};
