import { useEffect, useState } from 'react';
import { getToken } from '@pwr/auth-provider';
import { GET_TABLEAU_TICKET_URL, TABLEAU_SERVER_URL } from 'api/endpoints';
import { usePrevious } from 'utils';
import { LEGACY_TABLEAU_SERVER_URL } from './';

/**
 *
 * @param {string} tableauEmbedDivId The ID of the container that will hold the Tableau <iframe>.
 * @param {number} tableauEmbedIframeHeight The size of the <iframe>. This is needed to initialize the Tableau report and may change when changing tabs.
 * @param {string|null} tableauCustomViewUrl This is an optional string. If provided, the Tableau saved view (subscription) will be loaded.
 * @param {object|null} tableauEventHandlers This is an optional obj containing event handlers that users may want to tap in. Currently supported events: { userSetFilters: (event) => {} }
 * @param {string} tableauReportPath This is the combination of the Tableau workbook + the Tableau worksheet name. Examples: `RatingsReviews/Display` or `QuestionsAnswers/Collect`. These map to the the report on the Tableau server.
 * @param {string} merchantGroupIds This comma-separated list of merchant group IDs. This value is passed directly to the Tableau server and ultimately is used when populating the report with data.
 * @returns {object} The return object provides the user with a "handle" to the Tableau report. This is essential for doing things after the report embed loads, such as applying filters, changing the merchant group(s), or creating a subscription. It also provides users with an error message, should that occur, in addition to a function that may be used to retry the failed embed attempt.
 */
export function useTableauReport(
  tableauEmbedDivId,
  tableauEmbedIframeHeight,
  tableauCustomViewUrl,
  tableauEventHandlers,
  tableauReportPath,
  merchantGroupIds
) {
  const [ticket, setTicket] = useState(null);
  const [tableauReportHandle, setTableauReportHandle] = useState(null);
  const [error, setError] = useState(null);

  // This is available because of a <script> in index.html
  const tableauEmbedLibrary = {};

  useEffect(
    function fetchTableauAuthenticationTicket() {
      if (!ticket) {
        fetchTableauTicket();
      }
    },
    [ticket]
  );

  async function fetchTableauTicket() {
    try {
      setError(null);
      const ticket = await fetchTicket().then(ticket => {
        return ticket.replace(/"/g, '');
      });
      if (!ticket || ticket === '-1') {
        throw new Error('Server did not return auth ticket');
      }
      setTicket(ticket);
    } catch (error) {
      setError('Failed to obtain report authentication');
    }
  }

  const [url, setUrl] = useState(null);
  const env = process.env.REACT_APP_BUILD_ENV;
  useEffect(
    function setTableauEmbedUrlWhenTicketIsAvailable() {
      if (!ticket || url || !tableauReportPath) return;

      if (
        tableauCustomViewUrl &&
        !isLegacyTableauCustomViewUrl(tableauCustomViewUrl)
      ) {
        setUrl(customViewUrlWithAuthTicket(tableauCustomViewUrl, ticket));
        return;
      }
      const tableauEnv = env === 'prod' ? '' : 'QA';
      setUrl(`${TABLEAU_SERVER_URL}/views/${tableauEnv}${tableauReportPath}`);
    },
    [
      ticket,
      setUrl,
      url,
      merchantGroupIds,
      tableauReportPath,
      tableauCustomViewUrl,
      env,
    ]
  );

  const tableauEmbedIframeHeightOrDefault = tableauEmbedIframeHeight || 1550;
  const prevIframeHeight = usePrevious(tableauEmbedIframeHeightOrDefault);
  useEffect(
    function updateTableauIframeSize() {
      if (!tableauReportHandle) return;

      if (prevIframeHeight === tableauEmbedIframeHeightOrDefault) return;

      tableauReportHandle.setFrameSize(
        '100%',
        tableauEmbedIframeHeightOrDefault
      );
    },
    [prevIframeHeight, tableauEmbedIframeHeightOrDefault, tableauReportHandle]
  );

  const prevMerchantGroupIds = usePrevious(merchantGroupIds);
  useEffect(
    function updateAllWorksheetsOnMerchantGroupChange() {
      if (!tableauReportHandle || !merchantGroupIds) return;
      if (prevMerchantGroupIds === merchantGroupIds) return;

      tableauReportHandle.workbook.activeSheet.worksheets.forEach(
        reportComponent =>
          reportComponent.applyFilterAsync(
            'Client ID',
            merchantGroupIds.split(','),
            'replace'
          )
      );
    },
    [tableauReportHandle, merchantGroupIds, prevMerchantGroupIds]
  );

  useEffect(
    function setErrorIfLibraryIsUnavailable() {
      if (tableauEmbedLibrary === undefined) {
        // If this component is loading and we don't have the Tableau embed library,
        // then the Tableau server is down. This has happened many times in production.
        setError('Something went wrong when fetching the embed library.');
      }
    },
    [tableauEmbedLibrary]
  );

  const [didSetEventListeners, setDidSetEventListeners] = useState(false);
  useEffect(
    function registerTableauEmbedEventListeners() {
      if (
        !tableauEventHandlers ||
        !tableauReportHandle ||
        didSetEventListeners
      ) {
        return;
      }
      // if (!tableauReportHandle.addEventListener)

      setDidSetEventListeners(true);
    },
    [
      tableauReportHandle,
      tableauEventHandlers,
      didSetEventListeners,
      setDidSetEventListeners,
    ]
  );

  const prevUrl = usePrevious(url);
  const [tableauWebComponent, seTableauWebComponent] = useState();

  useEffect(
    function injectTableauReportWhenTableauUrlIsAvailable() {
      if (!tableauEmbedLibrary || !url || prevUrl === url) {
        return;
      }

      try {
        const viz = document.createElement('tableau-viz');
        viz.token = ticket;
        viz.src = url;
        viz.hideTabs = true;
        viz.width = '100%';
        viz.height = tableauEmbedIframeHeightOrDefault;
        viz.toolbar = 'hidden';
        viz.addFilter('Client ID', merchantGroupIds);
        viz.id = tableauEmbedDivId;
        viz.addEventListener('firstinteractive', _viz => {
          setTableauReportHandle(viz);
        });
        viz.addEventListener(
          'markselectionchanged',
          tableauEventHandlers.userSetFilters
        );
        viz.addEventListener(
          'parameterchanged',
          tableauEventHandlers.userSetFilters
        );
        viz.addEventListener(
          'filterchanged',
          tableauEventHandlers.userSetFilters
        );
        window.viz = viz;

        document
          .getElementById(tableauEmbedDivId + 'container')
          .appendChild(viz);
      } catch (error) {
        console.error(error);
        setError('Something went wrong when embedding the report.');
      }
    },
    [
      prevUrl,
      url,
      tableauEmbedLibrary,
      tableauEventHandlers,
      tableauEmbedDivId,
      tableauEmbedIframeHeightOrDefault,
      seTableauWebComponent,
      merchantGroupIds,
      ticket,
    ]
  );

  const errorIsRetryable = error && error.includes('authentication');
  return {
    errorMessage: error,
    handleTableauEmbedRetry: errorIsRetryable ? fetchTableauTicket : null,
    tableauReportHandle,
    tableauWebComponent,
  };
}
async function fetchTicket() {
  const options = {
    headers: {
      Authorization: getToken(),
      'Content-type': 'application/json',
    },
    method: 'POST',
  };
  return fetch(GET_TABLEAU_TICKET_URL, options).then(result => {
    if (!result.ok) {
      throw new Error(
        `Unable to GET Tableau authentication ticket from ${GET_TABLEAU_TICKET_URL} due to ${
          result.status
        } error.`
      );
    }
    return result.text();
  });
}

// const LEGACY_TABLEAU_SERVER_URL = '';
function isLegacyTableauCustomViewUrl(tableauCustomViewUrl = '') {
  if (!tableauCustomViewUrl) return false;

  return tableauCustomViewUrl.includes(LEGACY_TABLEAU_SERVER_URL);
}
function customViewUrlWithAuthTicket(customViewUrl, authTicket) {
  const url = new URL(customViewUrl);
  // url.pathname = `/trusted/${authTicket}` + url.pathname;
  return url.toString();
}
