import React from 'react';
import moment from 'moment';
import { reportingServicesUrl } from 'api/endpoints';
import Dropdown from './Dropdown';
import { FetchMultiselect, FetchSelect } from './FetchDropdown';
import { CategoryMultiselect } from './CategoryFilter';
import Text from './Text';
import NumericRange from './NumericRange';
import DateFilter from './DateFilter';
import presetDates from 'components/DatePicker/presetDates';
import { DEFAULT_DATE_RANGE } from 'components/DatePicker/constants';
import {
  CATEGORY_DROPDOWN,
  CATEGORY_ENDPOINT,
  DATE_RANGE,
  DROPDOWN,
  NUMERIC_RANGE,
  NUMERIC,
  RATING_DROPDOWN,
  REMOTE_DROPDOWN,
  REMOTE_FILTER_DROPDOWN,
  REMOTE_SELECT,
  TEXT,
} from './constants';

const normalizeDisplayLabel = str => {
  if (typeof str !== 'string') return '';
  str = str
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
    .replace(/([ID][Id])/g, 'ID');
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const setOfDropdownsWhereLabelIsValue = new Set(['reviewer_type']);

const normalizeDropdownItems = (items, filterName) => {
  const options = items.split(',').map(item => {
    // There is an issue where the server is sending a dropdown (maybe more?)
    // in flipped order. Instead of `dropdownValue|Dropdown Label`, reviewer_type is sent as:
    // `Anonymous|anonymous,Verified Buyer|verifiedBuyer`. But that doesn't even matter because
    // the AJAX call (in this case only) is actually expecting the label and not the value.
    const valueIsLabel = setOfDropdownsWhereLabelIsValue.has(filterName);
    const [label, value] = item.split('|');
    return {
      label: valueIsLabel ? value : label,
      value: value,
    };
  });
  return options;
};

const matched = filter => ({
  on: () => matched(filter),
  otherwise: () => filter,
});

const match = filter => ({
  on: (condition, func) =>
    condition(filter) ? matched(func(filter)) : match(filter),
  otherwise: func => func(filter),
});

export const mapReportParametersToFilterComponents = (
  filterDefinitions,
  handleChange,
  merchantData,
  filterId
) =>
  filterDefinitions
    .map(filter =>
      match(filter.reportParameterType)
        .on(
          type => type === DROPDOWN || type === RATING_DROPDOWN,
          () => (
            <Dropdown
              onChange={handleChange}
              currentValue={filter.value || []}
              key={filter.name + filterId}
              displayOrder={filter.displayOrder}
              filterName={filter.name}
              filterDisplayName={normalizeDisplayLabel(filter.displayLabel)}
              items={normalizeDropdownItems(filter.options, filter.name)}
              filterType={filter.reportParameterType}
            />
          )
        )
        .on(
          type => type === REMOTE_SELECT,
          () => (
            <FetchSelect
              {...merchantData}
              onChange={handleChange}
              currentValue={filter.value || null}
              key={filter.name + filterId}
              displayOrder={filter.displayOrder}
              endpoint={`${reportingServicesUrl}/${filter.options}`}
              filterName={filter.name}
              filterDisplayName={normalizeDisplayLabel(filter.displayLabel)}
              filterType={filter.reportParameterType}
            />
          )
        )
        .on(
          type => type === REMOTE_DROPDOWN || type === REMOTE_FILTER_DROPDOWN,
          () => (
            <FetchMultiselect
              {...merchantData}
              onChange={handleChange}
              currentValue={filter.value || []}
              key={filter.name + filterId}
              displayOrder={filter.displayOrder}
              endpoint={`${reportingServicesUrl}/${filter.options}`}
              filterName={filter.name}
              filterDisplayName={normalizeDisplayLabel(filter.displayLabel)}
              filterType={filter.reportParameterType}
            />
          )
        )
        .on(
          type => type === CATEGORY_DROPDOWN,
          () => (
            <CategoryMultiselect
              {...merchantData}
              onChange={handleChange}
              currentValue={filter.value || []}
              key={filter.name + filterId}
              displayOrder={filter.displayOrder}
              filterName={filter.name}
              filterDisplayName={normalizeDisplayLabel(filter.displayLabel)}
              filterType={filter.reportParameterType}
              // An alternative endpoint can be provided in Page Creator
              endpoint={
                filter.options
                  ? `${reportingServicesUrl}/${filter.options}`
                  : CATEGORY_ENDPOINT
              }
            />
          )
        )
        .on(
          type => type === TEXT,
          () => (
            <Text
              onChange={handleChange}
              currentValue={filter.value || ''}
              key={filter.name + filterId}
              displayOrder={filter.displayOrder}
              filterName={filter.name}
              filterDisplayName={normalizeDisplayLabel(filter.displayLabel)}
              filterType={filter.reportParameterType}
            />
          )
        )
        .on(
          type => type === NUMERIC,
          () => (
            <Text
              onChange={handleChange}
              currentValue={filter.value || ''}
              key={filter.name + filterId}
              displayOrder={filter.displayOrder}
              isNumeric={true}
              filterName={filter.name}
              filterDisplayName={normalizeDisplayLabel(filter.displayLabel)}
              filterType={filter.reportParameterType}
            />
          )
        )
        .on(
          type => type === NUMERIC_RANGE,
          () => {
            const defaultNumericRange = {
              to: { name: `${filter.name}_to`, value: '' },
              from: { name: `${filter.name}_from`, value: '' },
            };
            return (
              <NumericRange
                onChange={handleChange}
                currentValue={filter.value || defaultNumericRange}
                key={filter.name + filterId}
                displayOrder={filter.displayOrder}
                isNumeric={true}
                filterName={filter.name}
                filterDisplayName={normalizeDisplayLabel(filter.displayLabel)}
                filterType={filter.reportParameterType}
              />
            );
          }
        )
        .on(
          type => type === DATE_RANGE,
          () => {
            const { startDate, endDate } = presetDates[
              DEFAULT_DATE_RANGE.value
            ]({ startDate: moment(), endDate: moment() });
            const defaultDate = {
              selectedPreset: DEFAULT_DATE_RANGE,
              startDate,
              endDate,
            };
            return (
              <DateFilter
                onChange={handleChange}
                currentValue={filter.value || defaultDate}
                key={filter.name + filterId}
                displayOrder={-1}
                filterName={filter.name}
                filterDisplayName={normalizeDisplayLabel(filter.displayLabel)}
                filterType={filter.reportParameterType}
              />
            );
          }
        )
        .otherwise(type => null)
    )
    .filter(option => option !== null);
