import React from 'react';
import {
  arrayOf,
  bool,
  oneOfType,
  node,
  shape,
  string,
  instanceOf,
} from 'prop-types';
import { Navigation } from 'components/Navigation';
import Growler from 'components/Growler';
import { Spinner } from 'components/Loaders';
import { SystemAlerts, ALERTS } from 'components/SystemAlerts';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';

const LoadingState = ({ message }) => {
  return (
    <div
      className="flex flex-column justify-center items-center center"
      style={{
        position: 'fixed',
        top: 'calc(50% - 50px)',
        left: '50%',
        transfrom: 'translate(-50%, -50%)',
      }}
    >
      <Spinner />
      <p className="pt-8">{message}</p>
    </div>
  );
};

export default function Page({
  apps,
  children,
  error,
  selectedMerchantGroupsByIds,
  hasNoMerchantGroupSelected,
  isBrandEngageOnly,
  name,
  navigationProps,
  merchantGroupList,
}) {
  if (error) {
    return (
      <section key="no-merchant-cookie" className="page-container">
        <Navigation
          activePrimary={navigationProps.activePrimary}
          activeSecondary={navigationProps.activeSecondary}
          apps={apps}
          crumbs={navigationProps.crumbs}
          isBrandEngageOnly={isBrandEngageOnly}
          name={name}
        />
        <div style={{ position: 'fixed', left: 275, width: '70%' }}>
          <Growler type="alert" title={error.title}>
            {error.message}
          </Growler>
        </div>
      </section>
    );
  }
  const hasPrimaryMerchantGroup = selectedMerchantGroupsByIds.keys().next()
    .value;
  if (hasNoMerchantGroupSelected) {
    return (
      <section key="no-merchant-cookie" className="page-container">
        <Navigation
          activePrimary={navigationProps.activePrimary}
          activeSecondary={navigationProps.activeSecondary}
          apps={apps}
          crumbs={navigationProps.crumbs}
          isBrandEngageOnly={isBrandEngageOnly}
          name={name}
        />
        {merchantGroupList.length > 1 ? (
          <div style={{ position: 'fixed', left: 275, width: '70%' }}>
            <Growler type="alert" title="Select a merchant group to begin">
              <p className="text-left mt-1">
                Please select a merchant group in the top-right menu
              </p>
            </Growler>
          </div>
        ) : (
          <LoadingState message="Your application is loading" />
        )}
      </section>
    );
  }

  if (hasPrimaryMerchantGroup) {
    return (
      <section className="page-container">
        <Navigation
          activePrimary={navigationProps.activePrimary}
          activeSecondary={navigationProps.activeSecondary}
          apps={apps}
          crumbs={navigationProps.crumbs}
          isBrandEngageOnly={isBrandEngageOnly}
          name={name}
        />
        {Boolean(apps.length) ? <SystemAlerts allAlerts={ALERTS} /> : null}
        <ErrorBoundary>{children}</ErrorBoundary>
      </section>
    );
  }
  return (
    <section className="page-container">
      <Navigation
        activePrimary={navigationProps.activePrimary}
        activeSecondary={navigationProps.activeSecondary}
        apps={apps}
        crumbs={navigationProps.crumbs}
        isBrandEngageOnly={isBrandEngageOnly}
        name={name}
      />
      <LoadingState message="Your application is loading" />
    </section>
  );
}

Page.propTypes = {
  children: oneOfType([node, arrayOf(node)]),
  hasNoMerchantGroupSelected: bool,
  navigationProps: shape({
    crumbs: arrayOf(
      shape({
        label: string,
        href: string,
      })
    ),
    activePrimary: string.isRequired,
    activeSecondary: string.isRequired,
  }),
  selectedMerchantGroupsByIds: instanceOf(Map),
};
