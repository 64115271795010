import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { func, number, oneOfType, shape, string } from 'prop-types';
import styles from './NumericRange.module.scss';

function ensureShape(value, filterName) {
  if (typeof value === 'string') {
    // value is coming from an URL param
    const [fromValue, toValue] = value.split(',');
    return {
      to: {
        name: `${filterName}_to`,
        value: toValue,
      },
      from: {
        name: `${filterName}_from`,
        value: fromValue,
      },
    };
  }
  return value;
}

export default function NumericRange({
  currentValue,
  filterDisplayName,
  filterName,
  filterType,
  onChange,
}) {
  const [range, setRange] = useState(ensureShape(currentValue, filterName));

  const handleChange = event => {
    const { name, value } = event.target;
    setRange(currentRange => {
      currentRange[name]['value'] = value;
      return { ...currentRange };
    });
  };

  useEffect(() => {
    if (!range.to.value.length && !range.from.value.length) return;
    onChange(filterName, range);
  }, [range.to.value, range.from.value]);

  return (
    <div className={classnames(styles.NumericRange, `form-group fl`)}>
      <label htmlFor={filterName}>{filterDisplayName}</label>
      <div id={filterName} className={styles['NumericRange__range-inputs']}>
        <input
          className="form-control"
          name="from"
          onChange={handleChange}
          placeholder="From"
          type="text"
          value={range.from.value}
        />
        <input
          className="form-control"
          name="to"
          onChange={handleChange}
          placeholder="To"
          type="text"
          value={range.to.value}
        />
      </div>
    </div>
  );
}

NumericRange.propTypes = {
  currentValue: oneOfType([
    shape({
      to: shape({ name: string, value: oneOfType([string, number]) }),
      from: shape({ name: string, value: oneOfType([string, number]) }),
    }),
    string, // This will be the case when the current value comes from a URL param
  ]),
  filterDisplayName: string.isRequired,
  filterName: string.isRequired,
  filterType: string.isRequired,
  onChange: func,
};
