import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import axios, { CancelToken } from 'axios';
import { Menu, MenuButton, MenuItem, MenuList } from '@reach/menu-button';
import { getToken } from '@pwr/auth-provider';
import {
  GET_REPORT_BOOKMARK_COMPONENT,
  GET_REPORT_BOOKMARK_PAGES,
  REPORT_DOWNLOAD,
} from 'api/endpoints';
import { FloatingMenuContainer } from 'components/FloatingMenuContainer';
import { TooltipButton } from 'components/TooltipButton';
import { DownloadIcon } from 'icons/Icons';
import SubscriptionForm from 'components/SubscriptionForm';
import {
  SaveReportForm,
  SaveReportFormModalButton,
  GENERAL_DESCRIPTION,
} from 'components/SaveReportForm/SaveReportForm';
import {
  createSavedReport,
  updateReportBookmark,
} from 'components/SubscriptionForm/SubscriptionUtils';
import ReportFilters from 'components/Filters/ReportFilters/ReportFilters';
import ErrorBoundary from 'components/ErrorBoundary';
import ColumnarReport from 'components/ColumnarReport';
import { filterStateToReportingServicesFormat } from 'utils/parameter-formatting';
import { useUrlParamDecoder } from './useUrlParamDecoder';
import { useUrlParamUpdater } from './useUrlParamUpdater';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { usePrevious } from 'utils';
import { merchantDataToPayload, filterStateToPayload } from 'utils/fetch';
import apiFetch from 'api/fetch';
import { Spinner } from 'components/Loaders/Spinner';

const EXPORT_OPTIONS = [
  {
    _delimiter: 'COMMA',
    _exportType: 'csv',
    label: 'CSV: Comma-Separated',
  },
  {
    _delimiter: 'TAB',
    _exportType: 'csv',
    label: 'CSV: Tab-Separated',
  },
  {
    _delimiter: 'PIPE',
    _exportType: 'csv',
    label: 'CSV: Pipe-Separated',
  },
  {
    _delimiter: 'SOH',
    _exportType: 'csv',
    label: 'CSV: Top-Of-Header',
  },
  {
    _delimiter: null,
    _exportType: 'xls',
    label: 'Excel',
  },
];

export function ReportContentColumnar({
  canEditReport = false,
  id,
  initialFilterState,
  isActive,
  isAdmin,
  merchantGroupIds,
  merchantIds,
  onEditReport = () => {},
  reportBookmark,
  reportDefinition,
  reportIdentifier,
  reportSchedule,
  setActiveReportFilterState,
  setGrowlerProps,
  useUrlParams,
  userOwnsSavedReport,
  setExportButton,
  setLastBreadcrumb,
}) {
  const {
    reportComponentColumnsDefault: defaultActiveColumns,
    reportComponentParameters: filterDefinitions,
    inactiveReportComponentColumnsDefault: defaultInactiveColumns,
    defaultTableSort,
    reportComponentGroupId,
    reportName: reportTitle,
  } = reportDefinition;
  const allReportColumns = [...defaultActiveColumns, ...defaultInactiveColumns];

  const { decodedFilterState } = useUrlParamDecoder();

  const { table_columns, table_sort } = {
    ...initialFilterState,
    ...decodedFilterState,
  };
  const [tableSort, setTableSort] = useState(table_sort || defaultTableSort);
  const [activeColumns, setActiveColumns] = useState(
    table_columns
      ? table_columns
          .split(',')
          .map(columnName =>
            allReportColumns.find(col => columnName === col.columnName)
          )
          .filter(Boolean)
          .map(columnarFormat)
      : defaultActiveColumns.map(columnarFormat)
  );
  const [inactiveColumns, setInactiveColumns] = useState(
    table_columns
      ? allReportColumns
          .filter(col => !table_columns.split(',').includes(col.columnName))
          .filter(Boolean)
          .map(columnarFormat)
      : defaultInactiveColumns.map(columnarFormat)
  );

  const [includeMetadata, setIncludeMetadata] = useState(
    reportSchedule ? reportSchedule.metadataIncluded : null
  );

  const [filterState, setFilterState] = useState(() => ({
    review_date: 'LAST_6_MONTHS',
    table_columns: activeColumns.map(col => col.propertyName).join(','),
    table_sort: tableSort,
    ...(isActive ? decodedFilterState : null),
    ...initialFilterState,
  }));
  const setUrlParams = useUrlParamUpdater();
  const prevIsActive = usePrevious(isActive);
  if (isActive && !prevIsActive && useUrlParams) {
    setUrlParams(filterState);
  }

  if (isActive && setActiveReportFilterState) {
    setActiveReportFilterState(filterState);
  }

  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  const [nextFilterState, setNextFilterState] = useState(filterState);
  const reportDataRequestPayload = filterStateToReportingServicesFormat(
    {
      ...nextFilterState,
      merchant_group_list: merchantGroupIds,
      merchant_list: merchantIds || null,
      table_columns: activeColumns.map(col => col.propertyName).join(','),
      table_sort: tableSort,
    },
    filterDefinitions
  );

  const { handleExport, isExporting } = useExportColumnarReport(
    reportDefinition,
    setGrowlerProps
  );

  const [activeColumn, activeColumnDirection] = tableSort.split(' ');
  const fetchDataDependencies = {
    currentPageNumber,
    reportComponentGroupId,
    reportDataRequestPayload,
  };
  const [hasInitialFetch, setHasInitialFetch] = useState(isActive);
  const { data: columnarData, error: errorFetchingColumnarData } = useQuery(
    hasInitialFetch && ['fetchReportData', fetchDataDependencies],
    fetchReportData
  );
  const { data: totalColumnarPages, error: errorFetchingPages } = useQuery(
    hasInitialFetch && ['fetchReportPageTotal', fetchDataDependencies],
    fetchReportPageTotal
  );
  useEffect(() => {
    if (isActive && !columnarData && !totalColumnarPages) {
      setHasInitialFetch(true);
    }
  }, [isActive, columnarData, totalColumnarPages]);

  const fetchReportDataRef = useRef();
  const fetchReportPageTotalRef = useRef();

  function fetchReportData({
    currentPageNumber,
    reportComponentGroupId,
    reportDataRequestPayload,
  }) {
    const source = CancelToken.source();
    if (fetchReportDataRef.current) {
      fetchReportDataRef.current.cancel('OUTDATED_REQUEST');
    }
    fetchReportDataRef.current = source;
    const promise = axios({
      method: 'PUT',
      url:
        GET_REPORT_BOOKMARK_COMPONENT +
        `/${reportComponentGroupId}` +
        `?limit=50&page=${currentPageNumber}`,
      headers: { Authorization: getToken() },
      data: reportDataRequestPayload,
      cancelToken: source.token,
    });
    return promise.then(res => res.data);
  }

  function fetchReportPageTotal({
    currentPageNumber,
    reportComponentGroupId,
    reportDataRequestPayload,
  }) {
    const source = CancelToken.source();
    if (fetchReportPageTotalRef.current) {
      fetchReportPageTotalRef.current.cancel('OUTDATED_REQUEST');
    }
    fetchReportPageTotalRef.current = source;

    const promise = axios({
      method: 'PUT',
      url: GET_REPORT_BOOKMARK_PAGES + `/${reportComponentGroupId}?limit=50`,
      headers: { Authorization: getToken() },
      data: reportDataRequestPayload,
      cancelToken: source.token,
    });

    return promise.then(res => res.data);
  }
  const [updatedReportSchedule, setUpdatedReportSchedule] = useState(null);

  const formattedFilterState = filterStateToReportingServicesFormat(
    filterState || {},
    reportDefinition.reportComponentParameters
  );

  const { url } = useRouteMatch();

  const savedReportName = (() => {
    // There are two locations that the name might appear.
    if (reportSchedule) return reportSchedule.name;
    if (reportBookmark) return reportBookmark.name;

    return null;
  })();

  const [reportBookmarkName, setReportBookmarkName] = useState(savedReportName);

  const [didSetLastBreadcrumbOnInit, setDidSetLastBreadcrumbOnInit] = useState(
    false
  );
  useEffect(() => {
    if (!didSetLastBreadcrumbOnInit && reportBookmarkName) {
      setDidSetLastBreadcrumbOnInit(true);
      setLastBreadcrumb({ label: reportBookmarkName });
    }
  }, [reportBookmarkName, setLastBreadcrumb, didSetLastBreadcrumbOnInit]);

  const [sharedWith, setSharedWith] = useState(
    reportBookmark ? reportBookmark.reportBookmarkShares : []
  );

  const [currentExportType, setCurrentExportType] = useState(null);
  function ExportButtons() {
    return EXPORT_OPTIONS.map(({ _delimiter, _exportType, label }) => (
      <MenuItem
        disabled={isExporting}
        onSelect={() => {
          handleExport(reportDataRequestPayload, {
            _delimiter,
            _exportType,
          });
          setCurrentExportType(_delimiter);
        }}
        key={_delimiter}
      >
        <div className="flex items-center">
          <p className={isExporting ? 'text-gray-80' : 'text-gray-35'}>
            {label}
          </p>
          {isExporting &&
            (currentExportType === _delimiter && (
              <Spinner style={{ height: 10, width: 10, marginLeft: 10 }} />
            ))}
        </div>
      </MenuItem>
    ));
  }

  const [, /* isChildMenuOpen */ setIsChildMenuOpen] = useState(false);

  const dataFetched =
    Boolean(columnarData) && typeof totalColumnarPages === 'number';
  const { replace } = useHistory();

  const payloadString = JSON.stringify(formattedFilterState);

  const SubscriptionButton = React.useMemo(() => {
    if (reportSchedule) {
      const reportScheduleMerchantData = {
        merchantGroupIds: initialFilterState.merchant_group_list,
        merchantIds: initialFilterState.merchant_list,
      };
      return (
        <SubscriptionForm
          title={`Edit: ${(updatedReportSchedule || reportSchedule).name}`}
          reportType={reportDefinition.reportType}
          isAdmin={isAdmin}
          merchantIds={reportScheduleMerchantData.merchantIds}
          merchantGroupIds={reportScheduleMerchantData.merchantGroupIds}
          reportComponentGroups={reportDefinition.reportComponentGroups[0]}
          reportParameters={reportDefinition.reportComponentParameters}
          filterState={formattedFilterState}
          displayCustomGrowler={setGrowlerProps}
          subscription={updatedReportSchedule || reportSchedule}
          onSubscriptionEdit={formInput => {
            setLastBreadcrumb({
              label: formInput.name,
              href: url,
            });
            setUpdatedReportSchedule(currentState => {
              const {
                delivery,
                exportOptions,
                frequency,
                name,
                selectCreator,
              } = formInput;
              const _reportSchedule = currentState || reportSchedule;
              const updatedRecipients = _reportSchedule.reportScheduleRecipients.slice(
                0
              );
              updatedRecipients[0] = {
                ..._reportSchedule.reportScheduleRecipients[0],
                ...delivery,
              };
              return {
                ..._reportSchedule,
                ...exportOptions,
                ...frequency,
                ...selectCreator,
                name,
                reportScheduleRecipients: updatedRecipients,
              };
            });
          }}
        />
      );
    }
    return (
      <SubscriptionForm
        title={`Subscribe to: ${
          reportDefinition.reportComponentGroups[0].name
        }`}
        isAdmin={isAdmin}
        merchantIds={merchantIds}
        merchantGroupIds={merchantGroupIds}
        reportType={reportDefinition.reportType}
        reportComponentGroups={reportDefinition.reportComponentGroups[0]}
        reportParameters={reportDefinition.reportComponentParameters}
        filterState={formattedFilterState}
        displayCustomGrowler={setGrowlerProps}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formattedFilterState, merchantGroupIds, merchantIds]);

  const SaveReportButton = React.useMemo(() => {
    if (reportBookmark) {
      const reportBookmarkMerchantData = {
        merchantGroupIds: initialFilterState.merchant_group_list,
        merchantIds: initialFilterState.merchant_list,
      };

      async function handleUpdateSavedReport(formInput) {
        const {
          reportComponentGroup: { _links },
        } = reportBookmark;

        const bookmarkPayload = {
          ...reportBookmark,
          reportComponentGroup: _links.self.href,
        };
        delete bookmarkPayload.reportBookmarkParameters;
        // ! There is a race condition in RP Services
        // where updating shares throws 409 conflicts.
        setReportBookmarkName(formInput.bookmarkName);
        setLastBreadcrumb({
          label: formInput.bookmarkName,
          href: url,
        });
        await updateReportBookmark({
          // Workaround for the above bug by clearing shares.
          bookmarkName: formInput.bookmarkName,
          reportBookmark: bookmarkPayload,
          sharedWith: null,
        });
        const result = await updateReportBookmark({
          // Safely update the shares
          bookmarkName: formInput.bookmarkName,
          reportBookmark: bookmarkPayload,
          sharedWith: formInput.selectedProfileIds,
        });
        setSharedWith(result.reportBookmarkShares);
        return;
      }

      return (
        <SaveReportFormModalButton
          buttonText={
            userOwnsSavedReport
              ? 'Edit your Saved Report'
              : 'Save a copy of this shared report'
          }
        >
          <SaveReportForm
            onSubmit={async formInput => {
              if (userOwnsSavedReport) {
                return await handleUpdateSavedReport(formInput);
              }
              await createSavedReport({
                filterState: formattedFilterState,
                formInput,
                merchantGroupIds,
                merchantIds,
                reportComponentGroups:
                  reportDefinition.reportComponentGroups[0],
                reportParameters: reportDefinition.reportComponentParameters,
              });
            }}
            name={`${reportBookmarkName}${userOwnsSavedReport ? '' : ' copy'}`}
            title={
              userOwnsSavedReport
                ? `Edit: ${reportBookmarkName}`
                : `Create a copy of: ${reportBookmarkName} that you can modify and share.`
            }
            description="Change the name of your saved report or who its shared with"
            merchantGroupIds={reportBookmarkMerchantData.merchantGroupIds}
            reportBookmarkShares={sharedWith}
          />
        </SaveReportFormModalButton>
      );
    }
    return (
      <SaveReportFormModalButton>
        <SaveReportForm
          merchantGroupIds={merchantGroupIds}
          onSubmit={async formInput => {
            await createSavedReport({
              filterState: formattedFilterState,
              formInput,
              merchantGroupIds,
              merchantIds,
              reportComponentGroups: reportDefinition.reportComponentGroups[0],
              reportParameters: reportDefinition.reportComponentParameters,
            });
          }}
          title={`Save report: ${
            reportDefinition.reportComponentGroups[0].name
          }`}
          description={GENERAL_DESCRIPTION}
        />
      </SaveReportFormModalButton>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantGroupIds, merchantIds, payloadString, sharedWith]);

  return (
    <div
      style={{ minHeight: 200 }}
      className="bg-white relative"
      data-testid={reportComponentGroupId}
    >
      {isActive && (
        <FloatingMenuContainer>
          <ul className="list-reset">
            <li>
              <Menu id="share-subscribe-export-menu">
                {({ isExpanded }) => {
                  setIsChildMenuOpen(isExpanded);
                  return (
                    <>
                      <MenuButton>
                        <TooltipButton
                          tooltipText="Export, Subscribe, and Save"
                          tooltipOrigin="left"
                          disabled={isExpanded}
                        >
                          <DownloadIcon
                            fill={isExpanded ? '#0d64b5' : '#000'}
                          />
                        </TooltipButton>
                      </MenuButton>
                      <MenuList>
                        <p className="p-3 text-gray-20">
                          <strong>Export</strong>
                        </p>
                        <ExportButtons />
                        <div
                          className="border border-b border-t-0 p-1 border-bg-gray-95"
                          style={{ width: '100%', height: 1 }}
                        />
                        <p className="p-3 text-gray-20">
                          <strong>Subscribe &amp; Save</strong>
                        </p>
                        {/* TODO: Refactor subscribe and save components */}
                        <MenuItem
                          onSelect={() => {
                            /* TODO: Refactor Subscription Form */
                          }}
                        >
                          {SubscriptionButton}
                        </MenuItem>
                        <MenuItem
                          onSelect={() => {
                            /* TODO: Refactor Subscription Form */
                          }}
                        >
                          {SaveReportButton}
                        </MenuItem>
                      </MenuList>
                    </>
                  );
                }}
              </Menu>
            </li>
          </ul>
        </FloatingMenuContainer>
      )}
      <ReportFilters
        alwaysPresentFilters={{
          table_columns: reportDataRequestPayload.table_columns,
          table_sort: reportDataRequestPayload.table_sort,
        }}
        filterDefinitions={filterDefinitions}
        filterId={reportIdentifier}
        filterState={{ [reportIdentifier]: filterState }}
        merchantGroupIds={merchantGroupIds}
        merchantIds={merchantIds}
        onSubmit={submittedState => {
          setNextFilterState(currState => ({
            ...currState,
            ...submittedState,
          }));
        }}
        setFilterState={(reportIdentifier, filterName, filterValue) => {
          setFilterState(filterState => ({
            ...filterState,
            [filterName]: filterValue,
          }));
        }}
        setFilterStateUrlParams={searchParams => {
          if (!isActive || !useUrlParams) return '';
          return replace({ search: searchParams });
        }}
      />
      <ErrorBoundary
        key={errorFetchingColumnarData}
        errorMessage={`Failed to load ${reportTitle} report`}
      >
        <ColumnarReport
          canEditReport={canEditReport}
          onEditReport={onEditReport}
          columnarReport={{
            columnarData,
            activeColumn,
            activeColumnDirection,
            error: errorFetchingColumnarData || errorFetchingPages,
            hasFailure:
              // Todo: there's really no need for this prop (refactor)
              Boolean(errorFetchingColumnarData) || Boolean(errorFetchingPages),
            inactiveColumns,
            activeColumns,
            isLoading: dataFetched ? false : true,
            currentPageNumber,
            totalColumnarPages,
          }}
          handleMetadataChange={setIncludeMetadata}
          includeMetadata={includeMetadata}
          handleColumnarDrag={setActiveColumns}
          handleColumnChange={([activeColumnList, inactiveColumnList]) => {
            setActiveColumns(activeColumnList.items);
            setInactiveColumns(inactiveColumnList.items);
          }}
          handlePageChange={setCurrentPageNumber}
          handleSortChange={({ activeColumn, activeColumnDirection }) => {
            setTableSort(`${activeColumn} ${activeColumnDirection}`);
          }}
          id={reportComponentGroupId}
          title={reportTitle}
        />
      </ErrorBoundary>
    </div>
  );
}

function columnarFormat(column) {
  // TODO: This format was a prolly mistake. I might/should refactor this?
  // I tried to make <ColumnarGrid /> unaware of reporting services column
  // obj names... it makes things clumsy here tho
  return {
    propertyName: column.columnName,
    displayName: column.displayHeader,
    ...column,
  };
}

function useExportColumnarReport(reportDefinition, setGrowlerProps) {
  const [isExporting, setIsExporting] = useState(false);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);
  async function handleExport(filterState, exportType) {
    setGrowlerProps({
      children: `Generating an export for ${
        reportDefinition.reportName
      } report.`,
      title: 'Export processing',
      type: 'info',
    });
    const url = `${REPORT_DOWNLOAD}/${
      reportDefinition.reportComponentGroupId
    }/download`;
    const payload = {
      ...filterStateToPayload(
        filterState,
        reportDefinition.reportComponentParameters
      ),
      ...merchantDataToPayload({
        merchantGroupIds: filterState.merchant_group_list,
        merchantIds: filterState.merchant_list,
      }),
      _delimiter: exportType._delimiter,
      _exportType: exportType._exportType,
    };
    try {
      setError(null);
      setResponse(null);
      setIsExporting(true);
      const response = await apiFetch(url, {
        method: 'PUT',
        headers: {
          Authorization: getToken(),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      }).then(res => res.json());
      setResponse(response);
      window.location.assign(response.url);
      setGrowlerProps({
        children:
          "Your exported data will be in your brower's downloads folder.",
        title: 'Export downloaded',
        type: 'success',
      });
    } catch (error) {
      setError(error);
      setGrowlerProps({
        children: 'Failed to generate export. Please try again.',
        title: 'Export failed',
        type: 'alert',
      });
    } finally {
      setIsExporting(false);
    }
  }
  return { error, handleExport, isExporting, response };
}
