import React from 'react';
import { bool, string } from 'prop-types';
import { Dots } from 'components/Loaders';

export function ButtonOrSuccess({
  buttonText,
  disabled,
  isLoading,
  successMessage,
}) {
  if (isLoading) {
    return <Dots />;
  }
  if (successMessage) {
    return <div className="text-sm font-bold py-1">{successMessage}</div>;
  }
  return (
    <button disabled={disabled} className="btn btn--primary">
      {buttonText}
    </button>
  );
}

ButtonOrSuccess.propTypes = {
  buttonText: string.isRequired,
  disabled: bool,
  isLoading: bool,
  successMessage: string,
};
