import React, { useEffect, useState } from 'react';
import { arrayOf, bool, func, object, shape, string, number } from 'prop-types';
import { getToken } from '@pwr/auth-provider';
import classnames from 'classnames';
import { Prompt } from 'react-router-dom';
import axios from 'axios';
import { Menu, MenuButton, MenuItem, MenuList } from '@reach/menu-button';
import fetch from 'api/fetch';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import { FloatingMenuContainer } from 'components/FloatingMenuContainer';
import { TooltipButton } from 'components/TooltipButton';
import {
  CircularArrowIcon,
  CurvedArrowPointingLeftIcon,
  CurvedArrowPointingRightIcon,
  DownloadIcon,
  FloppyDiskIcon,
} from 'icons/Icons';
import SubscriptionForm from 'components/SubscriptionForm';
import Modal from 'components/Modal';
import ModalCard from 'components/Modal/ModalCard';
import {
  SaveReportForm,
  SaveReportFormModalButton,
  GENERAL_DESCRIPTION,
} from 'components/SaveReportForm/SaveReportForm';
import './shareSubscribeExportMenu.scss';
import { reportingServicesUrl } from 'api/endpoints';
import { useTableauReport } from './hooks';
import { LEGACY_TABLEAU_SERVER_URL } from './';

export function ReportContentTableau({
  initialFilterState = {},
  isAdmin,
  merchantGroupIds,
  merchantIds,
  reportDefinition,
  selectedWorkbookTab: incomingWorkbookTab,
  setExportButton,
  workbookTabs,
  onReportBookmarkUpdate,
  reportBookmark: initialReportBookmark,
  reportIdentifier,
  reportSchedule,
  setGrowlerProps,
  setTableauCustomViewUrl = () => {},
  setLastBreadcrumb,
  userOwnsSavedReport,
}) {
  const [selectedWorkbookTab, setMostRecentlyVisitedWorkbook] = useState(
    incomingWorkbookTab
  );

  useEffect(() => {
    if (!incomingWorkbookTab.tableauTabUri) return;

    setMostRecentlyVisitedWorkbook(incomingWorkbookTab);
  }, [
    selectedWorkbookTab,
    setMostRecentlyVisitedWorkbook,
    incomingWorkbookTab,
  ]);

  const workbookUri =
    reportDefinition.reportComponentGroups[0].reportComponents[0].uri;

  const [userMadeChanges, setUserMadeChanges] = useState(false);
  const tableauCustomViewUrl = initialFilterState.tableau_custom_view_url;
  const tableauEmbedDivId = `tableauVizContainer${workbookUri}`;
  const selectedWorkbookTabOrDefault =
    selectedWorkbookTab.tableauTabUri ||
    workbookTabs.find(tab => tab.tableauTabUri).tableauTabUri;
  const tableauReportPath =
    workbookUri && selectedWorkbookTab
      ? `${workbookUri}/${selectedWorkbookTabOrDefault}`
      : null;

  const tableauEmbedReportHeight =
    (selectedWorkbookTab && selectedWorkbookTab.tableauIframeHeight) || 1550;

  const tableauReportEventHandlers = {
    userSetFilters: function handleUserSetFilters() {
      setUserMadeChanges(true);
    },
    onFirstInteractive: function handleOnFirstInteractive(event) {},
  };
  const {
    tableauReportHandle,
    errorMessage: tableauEmbedErrorMessage,
    handleTableauEmbedRetry,
  } = useTableauReport(
    tableauEmbedDivId,
    tableauEmbedReportHeight,
    tableauCustomViewUrl,
    tableauReportEventHandlers,
    tableauReportPath,
    merchantGroupIds
  );
  const activeSheetName =
    tableauReportHandle && tableauReportHandle.workbook
      ? tableauReportHandle.workbook.activeSheet.name
      : null;
  const shouldChangeActiveSheet = !tableauReportHandle
    ? false
    : activeSheetName !== selectedWorkbookTab.tableauWorksheetName;

  const [isChangingActiveSheet, setIsChangingActiveSheet] = useState(false);
  useEffect(
    function handleChangeActiveSheet() {
      if (
        isChangingActiveSheet ||
        !selectedWorkbookTab ||
        !activeSheetName ||
        !tableauReportHandle ||
        !shouldChangeActiveSheet
      ) {
        return;
      }

      setIsChangingActiveSheet(true);

      if (
        tableauReportHandle &&
        tableauReportHandle.workbook &&
        selectedWorkbookTab &&
        selectedWorkbookTab.tableauWorksheetName
      ) {
        tableauReportHandle.workbook
          .activateSheetAsync(selectedWorkbookTab.tableauWorksheetName)
          .then(() => setIsChangingActiveSheet(false));
      }
    },
    [
      activeSheetName,
      isChangingActiveSheet,
      shouldChangeActiveSheet,
      tableauReportHandle,
      tableauReportPath,
      selectedWorkbookTab,
    ]
  );

  setTableauCustomViewUrl(tableauCustomViewUrl);
  const isLegacy = isLegacyTableauCustomViewUrl(tableauCustomViewUrl);

  useEffect(() => {
    if (isLegacy) {
      setGrowlerProps({
        title: 'Are custom filters missing?',
        type: 'warning',
        children: (
          <p className="text-md">
            Due to a recent system upgrade, some custom filters in your
            subscription may have been lost. If that's the case,{' '}
            <strong>reselect</strong> any needed filters and{' '}
            <strong>resave</strong> the report in the right-hand menu.
          </p>
        ),
      });
    }
  }, [isLegacy, setGrowlerProps, tableauCustomViewUrl]);

  async function handleOnSubscribe({
    formInput,
    resetForm,
    setHasSubmissionError,
    setIsSubmitting,
  }) {
    try {
      setIsSubmitting(true);
      setHasSubmissionError(false);

      const reportBookmark = await createReportBookmark(
        formInput.name,
        reportDefinition.reportComponentGroups[0]._links.self.href
      );
      const reportBookmarkUri = reportBookmark._links.self.href;
      const tableauCustomViewUrl = await createTableauCustomView(
        tableauReportHandle,
        reportBookmarkUri
      );

      await createReportBookmarkParameters({
        merchantGroupIds,
        merchantIds,
        reportBookmarkUri,
        reportDefinition,
        tableauCustomViewUrl,
      });

      const reportSchedule = await createOrUpdateReportSchedule(
        formInput,
        reportBookmarkUri
      );
      const reportScheduleUri = reportSchedule._links.self.href;

      await createOrUpdateReportScheduleRecipient(formInput, reportScheduleUri);
      resetForm();
      setGrowlerProps({
        type: 'success',
        title: 'Subscription created',
        children: `Created ${formInput.frequency.frequency.toLowerCase()} subscription: ${
          formInput.name
        }`,
      });
    } catch (error) {
      console.error(error);
      setHasSubmissionError(true);
    } finally {
      setIsSubmitting(false);
    }
  }

  async function handleOnSubscriptionEdit({
    setModalOpen,
    formInput,
    setHasSubmissionError,
    setIsSubmitting,
  }) {
    try {
      setIsSubmitting(true);
      setHasSubmissionError(false);

      const reportBookmarkUri =
        reportingServicesUrl +
        `/reportBookmark/${reportSchedule.reportBookmark.id}`;
      await createOrUpdateReportSchedule(
        formInput,
        reportBookmarkUri,
        reportSchedule
      );

      const reportScheduleUri =
        reportingServicesUrl + `/reportSchedule/${reportSchedule.id}`;
      await createOrUpdateReportScheduleRecipient(
        formInput,
        reportScheduleUri,
        reportSchedule
      );

      setModalOpen(false);
      setGrowlerProps({
        type: 'success',
        title: 'Subscription updated',
        children: `Updated ${formInput.frequency.frequency.toLowerCase()} subscription: ${
          formInput.name
        }`,
      });
    } catch (error) {
      console.error(error);
      setHasSubmissionError(true);
    } finally {
      setIsSubmitting(false);
    }
  }

  const subscriptionButton = reportSchedule ? (
    // Editing a subscription
    <SubscriptionForm
      disabled={tableauReportHandle === null}
      filterState={{}}
      isAdmin={isAdmin}
      merchantGroupIds={merchantGroupIds}
      merchantIds={merchantIds}
      onSubmit={({
        setModalOpen,
        formInput,
        setHasSubmissionError,
        setIsSubmitting,
      }) => {
        handleOnSubscriptionEdit({
          setModalOpen,
          formInput,
          setHasSubmissionError,
          setIsSubmitting,
        });
      }}
      reportComponentGroups={reportDefinition.reportComponentGroups[0]}
      reportParameters={reportDefinition.reportComponentParameters}
      reportType={reportDefinition.reportType}
      title={`Edit: ${reportSchedule.name}`}
      subscription={reportSchedule}
    />
  ) : (
    // Creating a subscription
    <SubscriptionForm
      disabled={tableauReportHandle === null}
      filterState={{}}
      isAdmin={isAdmin}
      merchantGroupIds={merchantGroupIds}
      merchantIds={merchantIds}
      onSubmit={handleOnSubscribe}
      reportComponentGroups={reportDefinition.reportComponentGroups[0]}
      reportParameters={reportDefinition.reportComponentParameters}
      reportType={reportDefinition.reportType}
      title={`Subscribe to: ${reportDefinition.reportComponentGroups[0].name}`}
    />
  );

  const [reportBookmark, setReportBookmark] = useState(
    () => initialReportBookmark
  );
  useEffect(
    function syncLastNavBreadcrumb() {
      if (!reportBookmark || !reportBookmark['name']) return;

      setLastBreadcrumb({
        label: reportBookmark.name,
        href: `${reportBookmark.id}`,
      });
    },
    [reportBookmark, setLastBreadcrumb]
  );

  async function handleCreateSavedReport(formInput) {
    const reportComponentGroup =
      reportDefinition.reportComponentGroups[0]._links.self.href;
    const reportBookmark = await createReportBookmark(
      formInput.bookmarkName,
      reportDefinition.reportComponentGroups[0]._links.self.href
    );
    await updateReportBookmark({
      reportBookmark,
      reportComponentGroup,
      formInput,
    });

    const reportBookmarkUri = reportBookmark._links.self.href;
    const tableauCustomViewUrl = await createTableauCustomView(
      tableauReportHandle,
      reportBookmarkUri
    );

    await createReportBookmarkParameters({
      merchantGroupIds,
      merchantIds,
      reportBookmarkUri,
      reportDefinition,
      tableauCustomViewUrl,
    });
  }
  async function handleUpdateSavedReport(formInput) {
    const reportComponentGroup =
      initialReportBookmark.reportComponentGroup._links.self.href;
    const updatedReportBookmark = await updateReportBookmark({
      reportBookmark: reportBookmark,
      formInput,
      reportComponentGroup,
    });
    setReportBookmark(updatedReportBookmark);
    onReportBookmarkUpdate(updatedReportBookmark);
    setUserMadeChanges(false);
  }

  const saveReportButton = (
    <SaveReportFormModalButton
      buttonText={userOwnsSavedReport ? 'Edit Saved Report' : 'Save Report'}
      disabled={tableauReportHandle === null}
    >
      <SaveReportForm
        merchantGroupIds={merchantGroupIds}
        onSubmit={
          userOwnsSavedReport
            ? formInput => {
                handleUpdateSavedReport(formInput);
              }
            : handleCreateSavedReport
        }
        title={
          userOwnsSavedReport ? 'Edit this saved report' : 'Save this Report'
        }
        description={
          userOwnsSavedReport
            ? 'Change the name of your saved report or who its shared with.'
            : GENERAL_DESCRIPTION
        }
        name={!reportBookmark ? '' : reportBookmark.name}
        reportBookmarkShares={
          !reportBookmark ? [] : reportBookmark.reportBookmarkShares
        }
      />
    </SaveReportFormModalButton>
  );

  function restoreTableauSelectedMerchantGroups() {
    tableauReportHandle.workbook.activeSheet.worksheets.forEach(
      reportComponent =>
        reportComponent.applyFilterAsync(
          'Client ID',
          merchantGroupIds.split(','),
          'replace'
        )
    );
  }

  const exportOptions = [
    {
      key: 'pdf',
      label: 'PDF',
      onClick: () => {
        tableauReportHandle.displayDialogAsync('export-pdf');
      },
    },
    {
      key: 'image',
      label: 'Image',
      onClick: () => {
        tableauReportHandle.exportImageAsync();
      },
    },
  ];

  function ReportMenu() {
    const [isChildMenuOpen, setIsChildMenuOpen] = useState(false);

    return (
      <FloatingMenuContainer>
        <ul className="list-reset">
          <li>
            <button
              disabled={!tableauReportHandle}
              style={{ opacity: tableauReportHandle ? 1 : 0.5 }}
              onClick={() => {
                if (!tableauReportHandle) return;

                tableauReportHandle.undoAsync().then(() => {
                  // When Tableau "undoes" it also clears the merchant
                  // group ID. We have to restore this.
                  restoreTableauSelectedMerchantGroups();
                });
              }}
            >
              <TooltipButton
                label="Undo action made to report"
                tooltipText="Undo"
                tooltipOrigin="left"
                disabled={isChildMenuOpen}
              >
                <CurvedArrowPointingLeftIcon />
              </TooltipButton>
            </button>
          </li>
          <li>
            <button
              disabled={!tableauReportHandle}
              style={{ opacity: tableauReportHandle ? 1 : 0.5 }}
              onClick={() => {
                if (!tableauReportHandle) return;

                tableauReportHandle
                  .redoAsync()
                  .then(restoreTableauSelectedMerchantGroups);
              }}
            >
              <TooltipButton
                label="Redo action made to report"
                tooltipText="Redo"
                tooltipOrigin="left"
                disabled={isChildMenuOpen}
              >
                <CurvedArrowPointingRightIcon />
              </TooltipButton>
            </button>
          </li>
          <li>
            <button
              disabled={!tableauReportHandle}
              style={{ opacity: tableauReportHandle ? 1 : 0.5 }}
              onClick={() => {
                if (!tableauReportHandle) return;

                tableauReportHandle
                  .revertAllAsync()
                  .then(restoreTableauSelectedMerchantGroups);
              }}
            >
              <TooltipButton
                label="Revert all actions made to report"
                tooltipText="Revert all changes"
                tooltipOrigin="left"
                disabled={isChildMenuOpen}
              >
                <CircularArrowIcon />
              </TooltipButton>
            </button>
          </li>
          <li>
            <Menu id="share-subscribe-export-menu">
              {({ isExpanded }) => {
                setIsChildMenuOpen(isExpanded);
                return (
                  <>
                    <MenuButton>
                      <TooltipButton
                        onClick={() => {}}
                        label="Open menu to export, subscribe, and save report"
                        tooltipText="Export, Subscribe, and Save"
                        tooltipOrigin="left"
                        disabled={isExpanded}
                      >
                        <DownloadIcon fill={isExpanded ? '#0d64b5' : '#000'} />
                      </TooltipButton>
                    </MenuButton>
                    <MenuList>
                      <p className="p-3 text-gray-20">
                        <strong>Export</strong>
                      </p>
                      {exportOptions.map(({ label, key, onClick }) => (
                        <MenuItem onSelect={onClick} key={key}>
                          <p className="text-gray-35">{label}</p>
                        </MenuItem>
                      ))}
                      <div
                        className="border border-b border-t-0 p-1 border-bg-gray-95"
                        style={{ width: '100%', height: 1 }}
                      />
                      <p className="p-3 text-gray-20">
                        <strong>Subscribe &amp; Save</strong>
                      </p>
                      {/* TODO: Refactor subscribe and save components */}
                      <MenuItem onSelect={() => {}}>
                        {subscriptionButton}
                      </MenuItem>
                      <MenuItem onSelect={() => {}}>
                        {saveReportButton}
                      </MenuItem>
                    </MenuList>
                  </>
                );
              }}
            </Menu>
          </li>
          {userMadeChanges && (reportBookmark || reportSchedule) && (
            <li>
              <button
                className="w-100 flex items-center justify-center"
                aria-label="Save changes made to subscribed report"
                onClick={() => {
                  setModalIsOpen(true);
                }}
              >
                <TooltipButton
                  tooltipOrigin="left"
                  tooltipText="Save changes made to subscribed report"
                >
                  <div className="wiggle">
                    <FloppyDiskIcon />
                  </div>
                </TooltipButton>
              </button>
            </li>
          )}
        </ul>
      </FloatingMenuContainer>
    );
  }

  const [updateCustomViewError, setUpdateCustomViewError] = useState(false);
  const [isUpdatingCustomView, setIsUpdatingCustomView] = useState(false);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const subsriptionOrSavedReport = reportSchedule
    ? 'Subscription'
    : 'Saved Report';

  return (
    <ErrorBoundary ErrorMessage="We encountered an error while loading your report. Please rerfresh and try again.">
      <div
        className={classnames('bg-white p-4 relative', {
          'bg-white': reportDefinition.reportComponentParameters.length > 0,
        })}
        style={{ minHeight: 500 }}
      >
        {(reportSchedule || initialReportBookmark) && (
          <>
            <Prompt
              when={userMadeChanges}
              message={`You have unsaved changes to your ${subsriptionOrSavedReport.toLowerCase()}: "${
                reportSchedule
                  ? reportSchedule.name
                  : initialReportBookmark.name
              }," are you sure you want to leave?`}
            />
            <ConfirmReportChangesModal
              error={updateCustomViewError}
              isOpen={modalIsOpen}
              isSaving={isUpdatingCustomView}
              onClose={() => setModalIsOpen(false)}
              onSaveChanges={async () => {
                try {
                  setUpdateCustomViewError(null);
                  setIsUpdatingCustomView(true);

                  await updateCustomViewForSubscriptionOrSavedReport({
                    tableauReportHandle,
                    reportSchedule,
                    reportBookmark: initialReportBookmark,
                  });
                  setGrowlerProps({
                    type: 'success',
                    title: `${subsriptionOrSavedReport} updated`,
                    children: `Successfully saved changes to ${
                      reportSchedule ? 'subscription' : 'saved report'
                    }: "${
                      reportSchedule
                        ? reportSchedule.name
                        : initialReportBookmark.name
                    }."`,
                  });
                  setUserMadeChanges(false);
                  setModalIsOpen(false);
                } catch (error) {
                  console.error(
                    'Unable to update Tableau custom view: ',
                    error
                  );
                  setUpdateCustomViewError(
                    error.message ? error.message : String(error)
                  );
                  setModalIsOpen(true);
                } finally {
                  setIsUpdatingCustomView(false);
                }
              }}
              title={
                reportSchedule ? 'Update subscription' : 'Update saved report'
              }
              // TODO: once we have the ability to revert changes, mention that in the message
              message={
                <>
                  <p className="mt-4">
                    All of the changes made in the report preview will persist
                    to your existing {subsriptionOrSavedReport.toLowerCase()}: "
                    {reportSchedule
                      ? reportSchedule.name
                      : initialReportBookmark.name}
                    ."
                  </p>
                  <p className="mt-4">
                    If you wish to discard your changes, simply refresh this
                    page in your browser.
                  </p>
                </>
              }
            />
          </>
        )}

        <ErrorMessage
          error={tableauEmbedErrorMessage}
          handleRetry={handleTableauEmbedRetry}
        />
        {tableauReportHandle && <ReportMenu />}
        <div id={tableauEmbedDivId + 'container'} />
      </div>
    </ErrorBoundary>
  );
}

const workbookObj = shape({
  tableauIframeHeight: number,
  tableauTabUri: string.isRequired,
  tableauWorksheetName: string.isRequired,
  title: string.isRequired,
  urlFragment: string.isRequired,
});
ReportContentTableau.propTypes = {
  merchantGroupIds: string.isRequired,
  merchantIds: string,
  reportDefinition: object.isRequired,
  selectedWorkbookTab: workbookObj,
  setExportButton: func.isRequired,
  useUrlParams: bool,
  workbookTabs: arrayOf(workbookObj).isRequired,
};

function ErrorMessage({ error, handleRetry }) {
  if (!error) return null;
  return (
    <div
      data-testid="ticket-retry"
      style={{ minHeight: 400 }}
      className="text-center flex flex-col items-center justify-center"
    >
      <h1 className="text-lg text-gray-35 m-0 mb-1">{error}</h1>
      <p className="text-gray-35">Please try again in a few moments</p>
      {handleRetry && (
        <button
          className="btn btn--primary mt-3 mb-0 btn--small"
          onClick={handleRetry}
        >
          Retry
        </button>
      )}
    </div>
  );
}

async function createReportBookmark(name, reportComponentGroup) {
  const url = reportingServicesUrl + '/reportBookmark';
  const requestOptions = {
    body: JSON.stringify({
      name,
      reportComponentGroup,
      visibleToAll: false,
    }),
    headers: {
      Authorization: getToken(),
      'Content-type': 'application/json',
    },
    method: 'POST',
  };
  return fetch(url, requestOptions).then(response => response.json());
}

async function createTableauCustomView(tableauReportHandle, reportBookmarkUri) {
  return (
    tableauReportHandle.workbook
      // Every custom view requires a unique name. This name will never
      // be surfaced to the end user, since we are embedding Tableau into
      // our own portal. The Report Bookmark URI is unique for every
      // subscription, so let's use that. Moreover, this makes it convenient
      // to query Tableau's DB for custom views since each will have a name
      // corresponding to its report book mark.
      .saveCustomViewAsync(reportBookmarkUri.split('/').pop())
      .then((customView, error) => {
        if (error) {
          console.error(`Error during .saveCustomViewAsync`);
        }
        return customView.url;
      })
  );
}

async function createReportBookmarkParameters({
  merchantGroupIds,
  merchantIds,
  reportBookmarkUri,
  reportDefinition,
  tableauCustomViewUrl,
}) {
  const { reportComponentParameters } = reportDefinition;
  const reportParameterValues = {
    merchant_group_list: merchantGroupIds,
    merchant_list: merchantIds || null,
    tableau_custom_view_url: tableauCustomViewUrl,
  };
  const url = reportingServicesUrl + '/reportBookmarkParameter';

  const createReportBookmarkParameterPromises = reportComponentParameters
    .map(reportBookmarkParameter => ({
      reportBookmark: reportBookmarkUri,
      reportParameter: reportBookmarkParameter._links.self.href,
      value: reportParameterValues[reportBookmarkParameter.name],
    }))
    .map(requestPayload => {
      const requestOptions = {
        body: JSON.stringify(requestPayload),
        headers: {
          Authorization: getToken(),
          'Content-type': 'application/json',
        },
        method: 'POST',
      };
      return fetch(url, requestOptions).then(response => response.json());
    });
  return Promise.all(createReportBookmarkParameterPromises);
}
async function createOrUpdateReportSchedule(
  formInput,
  reportBookmarkUri,
  reportSchedule
) {
  const {
    exportOptions: { delimiter, exportType },
    frequency: { frequency, isUsingWeekday, monthDay, monthWeek, weekDay },
    name,
    selectCreator: { createdForProfileId, createdForProfileName },
  } = formInput;

  const url =
    reportingServicesUrl +
    `/reportSchedule${reportSchedule ? '/' + reportSchedule.id : ''}`;
  if (reportSchedule) {
    delete reportSchedule._links;
  }

  const requestPayload = {
    ...(reportSchedule && {
      createdByProfileId: reportSchedule.createdByProfileId,
      createdTime: reportSchedule.createdTime,
      id: reportSchedule.id,
    }),
    createdForProfileId,
    createdForProfileName,
    delimiter,
    exportType,
    frequency,
    monthDay: isUsingWeekday ? null : monthDay,
    monthWeek: isUsingWeekday ? monthWeek : null,
    name,
    reportBookmark: reportBookmarkUri,
    weekDay,
  };
  const requestOptions = {
    body: JSON.stringify(requestPayload),
    headers: {
      Authorization: getToken(),
      'Content-type': 'application/json',
    },
    method: reportSchedule ? 'PUT' : 'POST',
  };

  return fetch(url, requestOptions).then(response => response.json());
}
async function createOrUpdateReportScheduleRecipient(
  formInput,
  reportScheduleUri,
  reportSchedule
) {
  const {
    delivery: {
      deliveryType,
      email,
      ftpPassword,
      ftpUrl,
      ftpUserName,
      message,
    },
  } = formInput;
  const reportScheduleRecipientId = reportSchedule
    ? reportSchedule.reportScheduleRecipients[0].id
    : null;
  const requestPayload = {
    ...(reportSchedule && {
      createdByProfileId:
        reportSchedule.reportScheduleRecipients[0].createdByProfileId,
      createdTime: reportSchedule.reportScheduleRecipients[0].createdTime,
      id: reportScheduleRecipientId,
    }),
    deliveryType,
    email,
    ftpPassword,
    ftpUrl,
    ftpUserName,
    message,
    reportSchedule: reportScheduleUri,
  };
  const url =
    reportingServicesUrl +
    `/reportScheduleRecipient${
      reportSchedule ? '/' + reportScheduleRecipientId : ''
    }`;
  const requestOptions = {
    body: JSON.stringify(requestPayload),
    headers: {
      Authorization: getToken(),
      'Content-type': 'application/json',
    },
    method: reportSchedule ? 'PUT' : 'POST',
  };
  return fetch(url, requestOptions).then(response => response.json());
}

function ConfirmReportChangesModal({
  error,
  isOpen,
  isSaving,
  message,
  onClose,
  onSaveChanges,
  title,
}) {
  return (
    <Modal closeOnBackgroundClick open={isOpen} handleClose={onClose}>
      <ModalCard maxWidth={585} padding={'1rem'} handleClose={onClose}>
        <div className="flex flex-column mt-6">
          <h2>{title}</h2>
          {message}
          <div className="flex mt-6 pb-2">
            <button onClick={onClose} className="btn btn--alert mr-4">
              Cancel
            </button>
            <button
              onClick={onSaveChanges}
              className={classnames('btn btn--primary', {
                'btn--loading': isSaving,
              })}
            >
              Save changes
            </button>
          </div>
          {error && <p>{error}</p>}
        </div>
      </ModalCard>
    </Modal>
  );
}

async function updateReportBookmark({
  reportBookmark,
  formInput,
  reportComponentGroup,
}) {
  delete reportBookmark.reportBookmarkParameters;

  const reportBookmarkUri =
    reportingServicesUrl + `/reportBookmark/${reportBookmark.id}`;
  const requestOptions = {
    headers: {
      Authorization: getToken(),
      'Content-type': 'application/json',
    },
    method: 'PUT',
    body: JSON.stringify({
      ...reportBookmark,
      isShared: false,
      name: formInput.bookmarkName,
      reportComponentGroup,
      reportBookmarkShares: [],
    }),
  };

  const newShares = formInput.selectedProfileIds.split(',').filter(Boolean);

  if (reportBookmark.reportBookmarkShares.length > 0) {
    // There is an RP Services race condition bug that we can
    // avoid by clearing shares first
    const updatedReportBookmark = await fetch(
      reportBookmarkUri,
      requestOptions
    );
    if (newShares.length === 0) {
      return updatedReportBookmark;
    }
  }
  // TODO: refactor as base req body
  const reportBookmarkSharesPayload = {
    ...reportBookmark,
    isShared: false,
    reportComponentGroup,
    name: formInput.bookmarkName,
    reportBookmarkShares: newShares.map(profileId => ({
      reportBookmark: reportBookmarkUri,
      sharedWith: profileId,
    })),
  };
  return fetch(reportBookmarkUri, {
    ...requestOptions,
    body: JSON.stringify(reportBookmarkSharesPayload),
  }).then(response => response.json());
}

function isLegacyTableauCustomViewUrl(tableauCustomViewUrl = '') {
  if (!tableauCustomViewUrl) return false;

  return tableauCustomViewUrl.includes(LEGACY_TABLEAU_SERVER_URL);
}

async function updateCustomViewForSubscriptionOrSavedReport({
  tableauReportHandle,
  reportSchedule,
  reportBookmark,
}) {
  try {
    const reportBookmarkParameters = reportSchedule
      ? reportSchedule.reportBookmark.reportBookmarkParameters
      : reportBookmark.reportBookmarkParameters;

    const tableauReportBookmarkParameter = extractTableauReportBookmarkParameter(
      reportBookmarkParameters
    );

    const tableauReportBookmarkUri = tableauReportBookmarkParameter._links.self.href
      .split('{?projection}')
      .shift();
    const newTableauCustomViewUrl = await createTableauCustomView(
      tableauReportHandle,
      tableauReportBookmarkUri
    );

    await updateReportBookmarkParameter(
      tableauReportBookmarkParameter,
      newTableauCustomViewUrl
    );
  } catch (error) {
    console.error('Unable to migrate custom view URL: ', error);
    throw new Error('Something went wrong while trying to update this report.');
  }
}

async function updateReportBookmarkParameter(
  reportBookmarkParameter,
  newValue
) {
  try {
    // The URL will look like: 'https://qa-reporting-services-api.powerreviews.com/reportBookmarkParameter/796627{?projection}'
    const reportBookmarkUri = reportBookmarkParameter._links.self.href
      .split('{?projection}')
      .shift();
    const putBody = {
      // Reporting Services API (weirdly) requires `createdTime` and `createdBy`
      createdTime: reportBookmarkParameter.createdTime,
      createdByProfileId: reportBookmarkParameter.createdByProfileId,
      value: newValue,
    };
    await axios.put(reportBookmarkUri, putBody, {
      headers: {
        Authorization: getToken(),
        'Content-type': 'application/json',
      },
    });
  } catch (error) {
    console.error('Unable to update report bookmark: ', error);
  }
}

function extractTableauReportBookmarkParameter(reportBookmarkParameters) {
  try {
    return reportBookmarkParameters
      .filter(({ value }) => typeof value === 'string')
      .find(({ value }) => stringIsTableauCustomViewUrl(value));
  } catch (error) {
    console.error(
      'Unable to extract tableau custom view URL from report bookmark parameters: ' +
        reportBookmarkParameters
    );
  }
}

const TABLEAU_SERVER_DOMAIN = 'tableau';
function stringIsTableauCustomViewUrl(string = '') {
  return string && string.includes(TABLEAU_SERVER_DOMAIN);
}
