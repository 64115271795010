import React, { useState, useEffect } from 'react';
import styles from './MerchantDropDown.module.scss';
import { MultiSelect, useSimpleFilter } from '@pwr-ui/select';
import { usePrevious } from 'utils';

const itemToString = item => (item !== null ? item['name'] : '');
const itemToKey = item => item.merchantId;
function makePlaceholder(selectedMerchantsByIds) {
  const numberOfSelected = selectedMerchantsByIds.size;
  if (numberOfSelected === 0) return 'All Merchants';
  if (numberOfSelected === 1) {
    return selectedMerchantsByIds.values().next().value.name;
  }
  return `${numberOfSelected} merchant${
    numberOfSelected === 1 ? '' : 's'
  } selected`;
}
function MerchantDropDown({
  initialMerchantIds = '',
  selectedMerchantsByIds,
  selectedMerchantGroupsByIds,
  onMerchantSelect,
}) {
  const merchants = [...selectedMerchantGroupsByIds.values()].reduce(
    (accumulator, mg) => {
      return [...accumulator, ...mg.merchants];
    },
    []
  );
  const mapOfInitialMerchantIds = initialMerchantIds
    ? initialMerchantIds.split(',').reduce((accumulator, merchantId) => {
        const merchant = merchants.find(merchant => {
          return merchant.merchantId === Number(merchantId);
        });
        if (merchant) {
          accumulator.set(Number(merchantId), merchant);
        }
        return accumulator;
      }, new Map())
    : new Map();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [selections, setSelections] = useState(
    new Map([
      ...selectedMerchantsByIds.entries(),
      ...mapOfInitialMerchantIds.entries(),
    ])
  );

  const filteredItems = useSimpleFilter(merchants, itemToString, inputValue);
  const handleSelect = newSelections => {
    setIsMenuOpen(true);
    if (!isMenuOpen && newSelections.size === 0) {
      onMerchantSelect(newSelections);
    }
    setSelections(newSelections);
  };

  const previousMapOfInitialMerchantIds = usePrevious(mapOfInitialMerchantIds);
  useEffect(() => {
    if (!previousMapOfInitialMerchantIds || !mapOfInitialMerchantIds) return;

    if (previousMapOfInitialMerchantIds.size !== mapOfInitialMerchantIds.size) {
      setSelections(mapOfInitialMerchantIds);
    }
  }, [previousMapOfInitialMerchantIds, mapOfInitialMerchantIds, setSelections]);

  const handleMenuClose = () => {
    setIsMenuOpen(false);
    onMerchantSelect(selections);
  };
  return (
    <div className={styles.MerchantDropDown} data-translate={true}>
      <MultiSelect
        id="merchant-dropdown"
        inputValue={inputValue}
        items={filteredItems}
        itemToKey={itemToKey}
        itemToString={itemToString}
        onChange={handleSelect}
        onInputValueChange={setInputValue}
        value={selections}
        placeholder={makePlaceholder}
        onMenuClose={handleMenuClose}
      />
    </div>
  );
}

export default MerchantDropDown;
