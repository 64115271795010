import React from 'react';
import { object, string } from 'prop-types';
import style from './MerchantName.module.scss';

function merchantGroupListToNames(commaSepList, mgIdNameDictionary = {}) {
  const names = (commaSepList || '')
    .split(',')
    .map(merchantGroupId => mgIdNameDictionary[merchantGroupId] || '')
    .join(', ');
  return names;
}

export function MerchantName({ merchantGroup, mgIdNameDictionary }) {
  const name = merchantGroupListToNames(merchantGroup, mgIdNameDictionary);
  if (!merchantGroup || !mgIdNameDictionary) return null;
  if (name.length > 33) {
    const truncated = name.slice(0, 30) + '...';
    return (
      <div className={style.MerchantNameContainer}>
        <p>{truncated}</p>
        <p className={style.FullName}>{name}</p>
      </div>
    );
  }
  return <p>{name}</p>;
}

MerchantName.propTypes = {
  merchantGroup: string.isRequired,
  mgIdNameDictionary: object.isRequired,
};
