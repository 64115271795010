import React, { useEffect, useState } from 'react';
import { Redirect, useParams, useHistory } from 'react-router-dom';
import { instanceOf, string } from 'prop-types';
import { getToken } from '@pwr/auth-provider';
import { Tabs, TabList, TabPanels, TabPanel } from '@reach/tabs';
import { Tab } from 'components/Tab';
import { SavedReportSearchList } from './SavedReportSearchList';
import { FIND_PROFILES } from 'api/endpoints';
import apiFetch from 'api/fetch';
import { AlertIcon } from 'icons/Icons';
import { Spinner } from 'components/Loaders/Spinner';

const ErrorState = () => {
  return (
    <div
      style={{ minHeight: 400 }}
      className="bg-white py-8 px-12 flex justify-center items-center"
    >
      <div className="w-50">
        <AlertIcon width={100} fill="rgb(85, 85, 85)" />
        <p className="text-left my-6">
          We couldn't fetch your merchant group's profiles. Please try again in
          a few moments
        </p>
      </div>
    </div>
  );
};

const LoadingState = () => {
  return (
    <div
      style={{ minHeight: 400 }}
      className="py-8 px-12 flex justify-center items-center"
    >
      <div>
        <Spinner />
        <p className="text-gray-35 mt-8">Fetching profiles</p>
      </div>
    </div>
  );
};

export function SavedReports({
  initialTab,
  selectedMerchantGroupsByIds,
  pageUrl,
}) {
  const merchantGroupIds = [...selectedMerchantGroupsByIds.keys()].join(',');
  const { error, isLoading, response } = useUserProfiles(merchantGroupIds);

  const tabs = [
    {
      id: 'my-reports',
      title: 'My Reports',
      fetchReportsEndpoint: 'findByProfileIdOrderByNameAsc',
      urlFragment: '/my-reports',
    },
    {
      id: 'shared-with-me',
      title: 'Shared With Me',
      fetchReportsEndpoint: 'findBookmarksSharedWithMe',
      urlFragment: '/shared-with-me',
    },
  ];
  const { tabId } = useParams();
  const { push } = useHistory();

  const [currentSelectedTabIndex, setCurrentSelectedTabIndex] = useState(
    tabs.findIndex(subscriptionTab => subscriptionTab.id === tabId)
  );
  useEffect(() => {
    const nextIndex = tabs.findIndex(
      subscriptionTab => subscriptionTab.id === tabId
    );
    if (nextIndex === currentSelectedTabIndex) return;
    setCurrentSelectedTabIndex(nextIndex);
  }, [currentSelectedTabIndex, setCurrentSelectedTabIndex, tabs, tabId]);

  function handleTabSelection(selectedIndex) {
    if (selectedIndex === currentSelectedTabIndex) return;
    setCurrentSelectedTabIndex(selectedIndex);
    push({
      pathname: pageUrl + tabs[selectedIndex].urlFragment,
    });
  }

  const validTab = tabs.some(tab => tab.id === initialTab);
  if (!validTab) return <Redirect to={`/saved-reports/${tabs[0].id}`} />;

  return (
    <Tabs
      className="mt-4 bg-gray-93"
      index={currentSelectedTabIndex}
      onChange={handleTabSelection}
      data-translate={true}
    >
      <TabList>
        {tabs.map(tab => {
          return (
            <Tab data-testid={tab.title.toLowerCase()} key={tab.id + 'tab'}>
              {tab.title}
            </Tab>
          );
        })}
      </TabList>
      <div className="bg-white shadow">
        <TabPanels>
          {tabs.map((tab, index) => (
            <TabPanel key={tab.id}>
              {error !== null ? (
                <ErrorState />
              ) : isLoading ? (
                <LoadingState />
              ) : (
                <SavedReportSearchList
                  merchantGroupIds={merchantGroupIds}
                  endpoint={tab.fetchReportsEndpoint}
                  profiles={response}
                  tabId={tab.id}
                  isActive={currentSelectedTabIndex === index}
                />
              )}
            </TabPanel>
          ))}
        </TabPanels>
      </div>
    </Tabs>
  );
}

SavedReports.propTypes = {
  initialTab: string,
  pageUrl: string.isRequired,
  selectedMerchantGroupsByIds: instanceOf(Map).isRequired,
};

export function useUserProfiles(merchantGroupIds) {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (merchantGroupIds === null) return;
    let current = true;
    const fetchProfiles = async () => {
      const url = FIND_PROFILES + `&merchantGroupsIds=${merchantGroupIds}`;
      setIsLoading(true);
      const options = {
        headers: {
          Authorization: getToken(),
          'Content-type': 'application/json',
        },
      };
      try {
        const response = await apiFetch(url, options).then(res => res.json());
        const profiles = response._embedded.profiles
          .filter(profile => profile.values[0].displayable)
          .map(({ profileId, values }) => {
            const { userValue } = values[0];
            return {
              profileId,
              userValue,
            };
          });
        setResponse(profiles);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
      return () => (current = false);
    };
    current && fetchProfiles();
  }, [merchantGroupIds]);
  return {
    response,
    error,
    isLoading,
  };
}
