export function brandHealthBenchmarkingPageProps() {
  const reports = [
    {
      reportIdentifier: 'retailer_sentiment_workbook',
      type: 'tableau_workbook',
      workbookTabs: [
        {
          tableauTabUri: 'BrandRank',
          tableauWorksheetName: 'Brand Rank',
          title: 'Brand Rank',
          urlFragment: 'brand-rank',
        },
        {
          tableauTabUri: 'ProductRank',
          tableauWorksheetName: 'Product Rank',
          title: 'Product Rank',
          urlFragment: 'product-rank',
        },
        {
          tableauTabUri: 'Sentiment',
          tableauWorksheetName: 'Sentiment',
          title: 'Sentiment',
          urlFragment: 'sentiment',
        },
        {
          tableauTabUri: 'Compare',
          tableauWorksheetName: 'Compare',
          title: 'Compare',
          urlFragment: 'compare',
        },
      ],
    },
  ];
  const DEFAULT_TAB = reports[0].workbookTabs[0].urlFragment;
  const PAGE_URL = '/brand-health-benchmarking';
  const PAGE_TITLE = 'Brand Health Benchmarking';

  const navigationProps = {
    crumbs: [
      {
        label: 'Analytics',
        href: `/`,
      },
      {
        label: PAGE_TITLE,
        href: PAGE_URL,
      },
    ],
    activePrimary: 'Analytics',
    activeSecondary: PAGE_TITLE,
  };

  return {
    defaultTab: DEFAULT_TAB,
    navigationProps,
    pageUrl: PAGE_URL,
    reports,
  };
}
