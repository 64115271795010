export function generateSecondaryMenuItems(
  isBrandEngageOnly,
  mgProperties,
  apps
) {
  const isPwrClient = isBrandEngageOnly === false;
  const allPossibleMenuItems = [
    {
      renderIf: isPwrClient,
      label: 'Ratings & Reviews',
      href: '/ratings-reviews/display',
    },
    {
      renderIf: isPwrClient,
      label: 'Sampling',
      href: '/sampling/display',
    },
    {
      renderIf: isPwrClient,
      label: 'Receipt Review Collect',
      href: '/receipt-review-collect',
    },
    {
      renderIf: isPwrClient,
      label: 'Visual & Social',
      href: '/visual-social/display',
    },
    {
      renderIf: mgProperties.ENABLE_PRODUCT_ANSWERS,
      label: 'Q & A',
      href: '/questions-answers/display',
    },
    {
      renderIf: mgProperties.BRAND_ENGAGE_TYPE === 'brand',
      label: 'Brand Engage',
      href: '/brand-engage/collect',
    },
    {
      renderIf: mgProperties.IS_ACTIVE_MEMBER_OF_BRANDSHARE,
      label: 'Syndication',
      href: '/syndication',
    },
    {
      renderIf: isPwrClient,
      label: 'Follow Up Program',
      href: '/follow-up-program',
    },
    {
      renderIf: Boolean(
        mgProperties.ENABLE_INTERACTIVE_PULSE ||
          mgProperties.ENABLE_PRODUCT_SENTIMENT
      ),
      label: 'Product Sentiment',
      href: '/product-sentiment',
    },
    {
      renderIf: isPwrClient,
      label: 'Site Analytics',
      href: '/site-analytics',
    },
    {
      renderIf: Boolean(
        mgProperties.ENABLE_RETAILER_SENTIMENT ||
          mgProperties.ENABLE_BRAND_HEALTH_BENCHMARKING
      ),
      label: 'Brand Health Benchmarking',
      href: '/brand-health-benchmarking',
    },
    {
      label: 'Data Explorer',
      href: '/data-explorer',
    },
    {
      label: 'Subscriptions',
      href: '/subscriptions',
    },
    {
      label: 'Saved Reports',
      href: '/saved-reports',
    },
  ];

  if (Object.keys(mgProperties).length === 0) return [];

  return allPossibleMenuItems.filter(({ renderIf }) => {
    return renderIf === undefined ? true : Boolean(renderIf);
  });
}
