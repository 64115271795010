import moment from 'moment';
import {
  LAST_12_MONTHS,
  LAST_6_MONTHS,
  LAST_3_MONTHS,
  LAST_30_DAYS,
  LAST_7_DAYS,
  LAST_2_DAYS,
  LAST_DAY,
  THIS_MONTH,
  LAST_MONTH,
  THIS_YEAR,
  LAST_YEAR,
  THIS_QUARTER,
  LAST_QUARTER,
} from './constants';

export const presetDates = {
  [LAST_12_MONTHS]: ({ startDate, endDate }) => ({
    startDate: startDate.subtract(1, 'year').startOf('month'),
    endDate: endDate.endOf('month'),
  }),
  [LAST_6_MONTHS]: ({ startDate, endDate }) => ({
    startDate: startDate.subtract(5, 'month').startOf('month'),
    endDate: endDate.endOf('month'),
  }),
  [LAST_3_MONTHS]: ({ startDate, endDate }) => ({
    startDate: startDate.subtract(2, 'month').startOf('month'),
    endDate: endDate.endOf('month'),
  }),
  [LAST_30_DAYS]: ({ startDate, endDate }) => ({
    startDate: startDate.subtract(30, 'day'),
    endDate,
  }),
  [LAST_7_DAYS]: ({ startDate, endDate }) => ({
    startDate: startDate.subtract(7, 'day'),
    endDate,
  }),
  [LAST_2_DAYS]: ({ startDate, endDate }) => ({
    startDate: startDate.subtract(2, 'day'),
    endDate,
  }),
  [LAST_DAY]: ({ startDate, endDate }) => ({
    startDate: startDate.subtract(1, 'day'),
    endDate,
  }),
  [THIS_MONTH]: ({ startDate, endDate }) => ({
    startDate: startDate.startOf('month'),
    endDate: endDate.endOf('month'),
  }),
  [LAST_MONTH]: ({ startDate, endDate }) => ({
    startDate: startDate.subtract(1, 'months').startOf('month'),
    endDate: endDate.subtract(1, 'months').endOf('month'),
  }),
  [THIS_YEAR]: ({ startDate, endDate }) => ({
    startDate: moment({
      year: startDate.year(),
      month: 0,
      day: 1,
      hour: startDate.hour(),
      minute: startDate.minute(),
    }),
    endDate,
  }),
  [LAST_YEAR]: ({ startDate, endDate }) => ({
    startDate: moment({
      year: startDate.subtract(1, 'year').year(),
      month: 0,
      day: 1,
      hour: startDate.hour(),
      minute: startDate.minute(),
    }),
    endDate: moment({
      year: endDate.subtract(1, 'year').year(),
      month: 11,
      day: 31,
      hour: endDate.hour(),
      minute: endDate.minute(),
    }),
  }),
  [THIS_QUARTER]: ({ startDate, endDate }) => {
    switch (startDate.quarter()) {
      case 1:
        return {
          startDate: moment({
            year: startDate.year(),
            month: 0,
            day: 1,
            hour: startDate.hour(),
            minute: startDate.minute(),
          }),
          endDate: moment({
            year: endDate.year(),
            month: 2,
            day: 31,
            hour: endDate.hour(),
            minute: endDate.minute(),
          }),
        };
      case 2:
        return {
          startDate: moment({
            year: startDate.year(),
            month: 3,
            day: 1,
            hour: startDate.hour(),
            minute: startDate.minute(),
          }),
          endDate: moment({
            year: endDate.year(),
            month: 5,
            day: 30,
            hour: endDate.hour(),
            minute: endDate.minute(),
          }),
        };
      case 3:
        return {
          startDate: moment({
            year: startDate.year(),
            month: 6,
            day: 1,
            hour: startDate.hour(),
            minute: startDate.minute(),
          }),
          endDate: moment({
            year: endDate.year(),
            month: 8,
            day: 30,
            hour: endDate.hour(),
            minute: endDate.minute(),
          }),
        };
      case 4:
        return {
          startDate: moment({
            year: startDate.year(),
            month: 9,
            day: 1,
            hour: startDate.hour(),
            minute: startDate.minute(),
          }),
          endDate: moment({
            year: endDate.year(),
            month: 11,
            day: 31,
            hour: endDate.hour(),
            minute: endDate.minute(),
          }),
        };
      default:
        return {
          startDate: null,
          endDate: null,
        };
    }
  },
  [LAST_QUARTER]: ({ startDate, endDate }) => {
    switch (startDate.quarter()) {
      case 1:
        return {
          startDate: moment({
            year: startDate.subtract(1, 'year').year(),
            month: 9,
            day: 1,
            hour: startDate.hour(),
            minute: startDate.minute(),
          }),
          endDate: moment({
            year: endDate.subtract(1, 'year').year(),
            month: 11,
            day: 31,
            hour: endDate.hour(),
            minute: endDate.minute(),
          }),
        };
      case 2:
        return {
          startDate: moment({
            year: startDate.year(),
            month: 0,
            day: 1,
            hour: startDate.hour(),
            minute: startDate.minute(),
          }),
          endDate: moment({
            year: endDate.year(),
            month: 2,
            day: 31,
            hour: endDate.hour(),
            minute: endDate.minute(),
          }),
        };
      case 3:
        return {
          startDate: moment({
            year: startDate.year(),
            month: 3,
            day: 1,
            hour: startDate.hour(),
            minute: startDate.minute(),
          }),
          endDate: moment({
            year: endDate.year(),
            month: 5,
            day: 30,
            hour: endDate.hour(),
            minute: endDate.minute(),
          }),
        };
      case 4:
        return {
          startDate: moment({
            year: startDate.year(),
            month: 6,
            day: 1,
            hour: startDate.hour(),
            minute: startDate.minute(),
          }),
          endDate: moment({
            year: endDate.year(),
            month: 8,
            day: 30,
            hour: endDate.hour(),
            minute: endDate.minute(),
          }),
        };
      default:
        return {
          startDate: null,
          endDate: null,
        };
    }
  },
};

export default presetDates;
