import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import { bool, func } from 'prop-types';

import styles from './Modal.module.scss';

export default class Modal extends Component {
  static propTypes = {
    closeOnBackgroundClick: bool,
    handleClose: func.isRequired,
    open: bool.isRequired,
  };
  state = { modalRootSet: false };
  modalRoot = document.createElement('div');

  componentWillReceiveProps = ({ open }) => {
    this.toggleModalRoot(open);
  };

  handleEscape = ({ key }) => {
    if (key === 'Escape') {
      this.props.handleClose();
    }
  };

  toggleModalRoot = open => {
    const { modalRootSet } = this.state;
    if (open && !modalRootSet) {
      this.modalRoot.setAttribute('id', 'modal-root');
      document.body.appendChild(this.modalRoot);
      this.setState({ modalRootSet: true });
    }
    if (!open && modalRootSet) {
      this.modalRoot.remove();
      this.setState({ modalRootSet: false });
    }
  };
  render() {
    const { children, closeOnBackgroundClick, handleClose } = this.props;
    return createPortal(
      <div
        className={styles['Modal']}
        onMouseDown={closeOnBackgroundClick ? handleClose : () => {}}
        onKeyDown={this.handleEscape}
      >
        {this.props.open && children}
      </div>,
      this.modalRoot
    );
  }
}
