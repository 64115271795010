import { bool, shape, number, string, arrayOf } from 'prop-types';

export const merchantGroupList = arrayOf(
  shape({
    merchantGroupId: number.isRequired,
    merchants: arrayOf(
      shape({
        merchantId: number.isRequired,
        name: string, // This should be required... is it just QA data?
      })
    ),
    name: string, // This should be required... is it just QA data?
    productAnswers: bool,
    ssAccountInformation: number,
  })
);
