import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Page from 'pages/Page/Page';
import { Subscriptions } from './Subscriptions';
import ErrorBoundary from 'components/ErrorBoundary';
import { EditSubscription } from 'pages/EditSubscription';

class SubscriptionIndex extends Component {
  state = {
    reportSchedule: null,
    lastBreadcrumb: null,
    merchantGroupPickerPositionedOverReport: null,
  };

  setLastBreadcrumb = lastBreadcrumb => this.setState({ lastBreadcrumb });

  setMerchantGroupPickerPositionedOverReport = merchantGroupPickerPositionedOverReport =>
    this.setState({ merchantGroupPickerPositionedOverReport });

  render() {
    const {
      allReportPageProps,
      apps,
      setSelectedMerchantGroupsByIds,
      email,
      isAdmin,
      isBrandEngageOnly,
      selectedMerchantsByIds,
      selectedMerchantGroupsByIds,
      merchantGroupList,
      onMerchantSelect,
      name,
      nonCustomReports,
    } = this.props;

    const navigationProps = {
      crumbs: [
        {
          label: 'Analytics',
          href: `/`,
        },
        ...(this.state.lastBreadcrumb || []),
      ],
      activePrimary: 'Analytics',
      activeSecondary: 'Subscriptions',
    };
    return (
      <ErrorBoundary errorMessage="Failed to load subscription, please try again in a few moments">
        <Page
          apps={apps}
          isBrandEngageOnly={isBrandEngageOnly}
          name={name}
          navigationProps={navigationProps}
          selectedMerchantGroupsByIds={selectedMerchantGroupsByIds}
        >
          <Switch>
            <Route
              path="/subscriptions/:tab(my-subscriptions|all-subscriptions|subscriptions-created-for-clients)"
              render={props => (
                <Subscriptions
                  isAdmin={isAdmin}
                  selectedMerchantGroupsByIds={selectedMerchantGroupsByIds}
                  merchantGroupList={merchantGroupList}
                  nonCustomReports={nonCustomReports}
                />
              )}
            />
            <Route
              path="/subscriptions/:reportScheduleId"
              render={props => (
                <>
                  <div className="flex mt-4">
                    {this.state.merchantGroupPickerPositionedOverReport}
                  </div>
                  <EditSubscription
                    allReportPageProps={allReportPageProps}
                    setSelectedMerchantGroupsByIds={
                      setSelectedMerchantGroupsByIds
                    }
                    isAdmin={isAdmin}
                    email={email}
                    selectedMerchantsByIds={selectedMerchantsByIds}
                    selectedMerchantGroupsByIds={selectedMerchantGroupsByIds}
                    setMerchantGroupPickerPositionedOverReport={merchantPicker => {
                      if (this.state.merchantGroupPickerPositionedOverReport)
                        return;

                      this.setMerchantGroupPickerPositionedOverReport(
                        merchantPicker
                      );
                    }}
                    nonCustomReports={nonCustomReports}
                    setLastBreadcrumb={this.setLastBreadcrumb}
                    onMerchantSelect={onMerchantSelect}
                    {...props}
                  />
                </>
              )}
            />

            <Redirect to="/subscriptions/my-subscriptions" />
          </Switch>
        </Page>
      </ErrorBoundary>
    );
  }
}
export default SubscriptionIndex;
