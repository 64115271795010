import { arrayOf, number, shape, string, object } from 'prop-types';

export const regenerateData = shape({
  regenerateData: shape({
    apps: arrayOf(
      shape({
        label: string.isRequired,
        href: string.isRequired,
      })
    ).isRequired,
    env: string.isRequired,
    expiration: number.isRequired,
    programGroups: object.isRequired,
    token: string.isRequired,
  }),
});
