import React, { useEffect, useState } from 'react';
import deepEquals from 'lodash.isequal';
import { Prompt, Redirect } from 'react-router-dom';
import { useHistory } from 'react-router';
import { Spinner } from 'components/Loaders/Spinner';
import { bool, func, instanceOf, object } from 'prop-types';
import Growler from 'components/Growler';
import {
  constructFilterStateFromReportSchedule,
  reportComponentGroupIdToReportTemplate,
  updateReportBookmarkParameters,
} from './EditSubscriptionUtils';
import { useReportSchedule, useAllCustomReports } from './';
import {
  ReportContentColumnar,
  ReportContentTableau,
} from 'containers/ReportContent';
import { mergeReports } from 'pages/DataExplorer/DataExplorerUtils';
import TableBuilder from 'containers/ReportContent/TableColumnBuilder';
import MerchantDropDown from 'components/MerchantDropDown';
import {
  filterStateToReportingServicesFormat,
  reportingServicesFormatToFilterState,
} from 'utils/parameter-formatting';
import { updateReportBookmarkMetadata } from '../../components/SubscriptionForm/SubscriptionUtils';

const subscriptionNotFound = error => error && error.status === 404;

export function EditSubscription({
  allReportPageProps,
  email,
  isAdmin,
  match,
  nonCustomReports,
  onMerchantSelect,
  selectedMerchantGroupsByIds,
  selectedMerchantsByIds,
  selectedWorkbookTab,
  setLastBreadcrumb,
  setSelectedMerchantGroupsByIds,
  workbookTabs,
  setGrowlerProps: optionalSetGrowlerProps,
  setReportBookmark = () => {},
  setTableauCustomViewUrl = () => {},
  setMerchantGroupPickerPositionedOverReport = () => {},
}) {
  const currentMerchantGroupList = mapToKeyString(selectedMerchantGroupsByIds);

  const { replace } = useHistory();

  const [
    userTouchedMerchantDropdown,
    setUserTouchedMerchantDropdown,
  ] = useState(false);

  const { reportScheduleId } = match.params;
  const [filterState, setFilterState] = useState(null);

  const [
    currentMerchantGroupsMatchReport,
    setCurrentMerchantGroupsMatchReport,
  ] = useState(false);
  useEffect(() => {
    if (!filterState || currentMerchantGroupsMatchReport) return;
    const { merchant_group_list } = filterState;

    if (!merchant_group_list) return;

    if (merchant_group_list === currentMerchantGroupList)
      if (currentMerchantGroupsMatchReport) return;

    setCurrentMerchantGroupsMatchReport(true);
    setSelectedMerchantGroupsByIds(merchant_group_list);
  }, [
    filterState,
    setSelectedMerchantGroupsByIds,
    currentMerchantGroupList,
    currentMerchantGroupsMatchReport,
  ]);
  const [lastSavedFilterState, setLastSavedFilterState] = useState(false);
  const [savingSubscriptionChanges, setSavingSubscriptionChanges] = useState(
    false
  );
  const {
    error: reportScheduleError,
    isLoading: reportScheduleIsLoading,
    response: reportSchedule,
  } = useReportSchedule(reportScheduleId);

  const [reportScheduleName, setReportScheduleName] = useState(null);
  useEffect(() => {
    if (!reportSchedule) return;
    setReportScheduleName(reportSchedule.name);
  }, [reportSchedule]);
  useEffect(() => {
    if (!reportScheduleName) return;
    setLastBreadcrumb([
      {
        label: 'Subscriptions',
        href: '/subscriptions',
      },
      {
        label: reportScheduleName || '',
        href: match.url,
      },
    ]);
    return () => setLastBreadcrumb([]);
  }, [reportScheduleName, setLastBreadcrumb, match.url]);
  const {
    error: customReportsError,
    isLoading: customReportsLoading,
    response: customReports,
  } = useAllCustomReports();

  const [sentTableBuilder, setSentTableBuilder] = useState(false);

  const [dataExplorerGrowlerProps, setDataExplorerGrowlerProps] = useState(
    null
  );

  const setGrowlerProps =
    optionalSetGrowlerProps || setDataExplorerGrowlerProps;

  if (subscriptionNotFound(reportScheduleError)) {
    return <Redirect push to="/subscriptions" />;
  }
  if (reportScheduleIsLoading || customReportsLoading) {
    return (
      <div
        style={{ height: 700 }}
        className="bg-white flex flex-col justify-center items-center shadow mt-4"
      >
        <Spinner />

        <p className="text mt-2">
          <>
            Loading{' '}
            <span className="font-bold">
              {reportSchedule ? reportSchedule.name : ''}
            </span>
          </>
        </p>
      </div>
    );
  }
  if (customReportsError) {
    return (
      <Growler type="alert" title="Error">
        Something went wrong when fetching your subscriptions. Please try again
        in a few moments.
      </Growler>
    );
  }

  const customAndNonCustomReports = mergeReports([
    nonCustomReports,
    customReports,
  ]);

  const reportComponentGroupId =
    reportSchedule.reportBookmark.reportComponentGroup.id;
  const reportTemplate = reportComponentGroupIdToReportTemplate(
    reportComponentGroupId,
    customAndNonCustomReports
  );
  const tableBuilder = new TableBuilder(
    customAndNonCustomReports,
    reportTemplate.reportIdentifier
  );

  if (!sentTableBuilder && tableBuilder) {
    setSentTableBuilder(true);
    setReportBookmark(tableBuilder);
  }

  const activeReportDefinition = tableBuilder.report.pop();

  if (activeReportDefinition && allReportPageProps) {
    const pageProps = allReportPageProps.find(pageProps => {
      return pageProps.reports.find(
        r => r.reportIdentifier === activeReportDefinition.reportIdentifier
      );
    });

    if (pageProps) {
      // If there are no page props, then this report doesn't
      // live in a report page (i.e., Ratings & Reviews or Q&A)
      // but is an isolated report in Data Explorer
      replace(`${pageProps.pageUrl}/subscriptions/${reportSchedule.id}`);
    }
  }

  const initialFilterState = {
    table_columns: tableBuilder.reportComponentColumnsDefault
      .map(column => column.columnName)
      .join(','),
    ...constructFilterStateFromReportSchedule(
      reportSchedule,
      customAndNonCustomReports
    ),
  };
  if (!filterState) {
    setFilterState(
      reportingServicesFormatToFilterState(
        initialFilterState,
        tableBuilder.reportComponentParameters
      )
    );
    setLastSavedFilterState(initialFilterState);
  }
  const reportScheduleMerchantData = {
    merchantGroupIds: initialFilterState.merchant_group_list,
    merchantIds: initialFilterState.merchant_list,
  };
  const filterStateChanged = (filterState1, filterState2) => {
    if (!filterState1 || !filterState2) return false;
    return !deepEquals(
      filterStateToReportingServicesFormat(
        filterState1,
        tableBuilder.reportComponentParameters
      ),
      filterStateToReportingServicesFormat(
        filterState2,
        tableBuilder.reportComponentParameters
      )
    );
  };
  const formattedFilterState = reportingServicesFormatToFilterState(
    filterState || {},
    tableBuilder.reportComponentParameters
  );

  const currentMerchantListString = userTouchedMerchantDropdown
    ? mapToKeyString(selectedMerchantsByIds)
    : lastSavedFilterState.merchant_list;

  const userHasUnsavedChanges = (() => {
    if (userTouchedMerchantDropdown) return true;

    if (!lastSavedFilterState) return false;
    const previous = {
      merchant_group_list: reportScheduleMerchantData.merchantGroupIds,
      merchant_list: reportScheduleMerchantData.merchantIds,
      // Overwrite merchant_list and merchant_group_list if user changes them
      ...lastSavedFilterState,
    };
    const current = {
      ...filterState,
      // Because the merchant filter is at the global level it's treated a
      // bit differently than the other filters that live nearby the report.
      // `merchant_list` is missing from filter state, so we add it just
      // to make sure that we can accurately toggle the "Save Changes" button
      merchant_group_list: currentMerchantGroupList,
      merchant_list: currentMerchantListString,
    };
    return filterStateChanged(previous, current);
  })();

  const paramNameValue = tableBuilder.reportComponentParameters.reduce(
    (accumulator, { id, identifier }) => ({
      ...accumulator,
      [id]: identifier,
    }),
    {}
  );
  const handleSubscriptionSave = () => {
    setSavingSubscriptionChanges(true);
    const reportBookmarkParameters = reportSchedule.reportBookmark.reportBookmarkParameters.map(
      param => ({
        ...param,
        identifier: paramNameValue[param.reportParameter.id],
      })
    );

    updateReportBookmarkMetadata(reportSchedule.reportBookmark.id);

    updateReportBookmarkParameters(reportBookmarkParameters, {
      ...filterStateToReportingServicesFormat(
        formattedFilterState,
        tableBuilder.reportComponentParameters
      ),
      merchant_list: currentMerchantListString,
      merchant_group_list: currentMerchantGroupList,
    })
      .then(() => {
        const filterStateWithMerchantList = {
          ...formattedFilterState,
          merchant_list: currentMerchantListString,
          merchant_group_list: currentMerchantGroupList,
        };
        setLastSavedFilterState(filterStateWithMerchantList);
        setUserTouchedMerchantDropdown(false);
        setSavingSubscriptionChanges(false);
        setGrowlerProps({
          type: 'success',
          title: 'Success',
          children: `You've successfully updated ${reportScheduleName}`,
          dismissible: true,
          onDismiss: () => setGrowlerProps(null),
        });
      })
      .catch(error => {
        console.error(error);
        setGrowlerProps({
          type: 'alert',
          title: 'Error',
          children:
            'Something went wrong while updating your subscription. Please try again in a few moments',
          dismissible: true,
          onDismiss: () => setGrowlerProps(null),
        });
      });
  };

  setMerchantGroupPickerPositionedOverReport(
    showMerchantDropdown(
      activeReportDefinition,
      customAndNonCustomReports._embedded.reportTemplate
    ) ? (
      <div>
        <MerchantDropDown
          initialMerchantIds={reportScheduleMerchantData.merchantIds}
          selectedMerchantGroupsByIds={selectedMerchantGroupsByIds}
          onMerchantSelect={mapOfSelectedMerchants => {
            setUserTouchedMerchantDropdown(true);
            onMerchantSelect(mapOfSelectedMerchants);
          }}
          selectedMerchantsByIds={selectedMerchantsByIds}
        />
      </div>
    ) : null
  );

  return (
    <div>
      <Prompt
        when={userHasUnsavedChanges}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      {dataExplorerGrowlerProps && (
        <Growler
          dismissible
          onDismiss={() => setGrowlerProps(null)}
          {...dataExplorerGrowlerProps}
        >
          {dataExplorerGrowlerProps.children}
        </Growler>
      )}
      <div
        style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 2px 2px 0px' }}
        className="bg bg-white"
      >
        {tableBuilder.reportType === 'SNOWFLAKE' && (
          <ReportContentColumnar
            canEditReport={userHasUnsavedChanges && !savingSubscriptionChanges}
            initialFilterState={initialFilterState}
            isActive
            isAdmin={isAdmin}
            merchantGroupIds={currentMerchantGroupList}
            merchantIds={currentMerchantListString}
            onEditReport={handleSubscriptionSave}
            reportDefinition={tableBuilder}
            reportIdentifier={tableBuilder.reportIdentifier}
            reportSchedule={reportSchedule}
            setActiveReportFilterState={setFilterState}
            setLastBreadcrumb={finalBreadCrumb => {
              setLastBreadcrumb([
                {
                  label: 'Subscriptions',
                  href: '/subscriptions',
                },
                finalBreadCrumb,
              ]);
            }}
            setGrowlerProps={setGrowlerProps}
            useUrlParams={false}
          />
        )}
        {tableBuilder.reportType === 'TABLEAU' && (
          <ReportContentTableau
            canEditReport={userHasUnsavedChanges && !savingSubscriptionChanges}
            initialFilterState={initialFilterState}
            isActive
            isAdmin={isAdmin}
            merchantGroupIds={reportScheduleMerchantData.merchantGroupIds}
            merchantIds={currentMerchantListString}
            reportDefinition={tableBuilder}
            reportIdentifier={tableBuilder.reportIdentifier}
            reportSchedule={reportSchedule}
            setActiveReportFilterState={setFilterState}
            setGrowlerProps={setGrowlerProps}
            setLastBreadcrumb={finalBreadCrumb => {
              setLastBreadcrumb([
                {
                  label: 'Subscriptions',
                  href: '/subscriptions',
                },
                finalBreadCrumb,
              ]);
            }}
            selectedWorkbookTab={selectedWorkbookTab}
            setTableauCustomViewUrl={setTableauCustomViewUrl}
            workbookTabs={workbookTabs}
          />
        )}
      </div>
    </div>
  );
}
EditSubscription.propTypes = {
  setSelectedMerchantGroupsByIds: func.isRequired,
  isAdmin: bool.isRequired,
  selectedMerchantGroupsByIds: instanceOf(Map).isRequired,
  selectedMerchantsByIds: instanceOf(Map).isRequired,
  match: object.isRequired,
  nonCustomReports: object.isRequired,
  setLastBreadcrumb: func.isRequired,
};

function mapToKeyString(map) {
  return Array.from(map.keys()).join(',');
}

function showMerchantDropdown(activeReportDefinition, reports) {
  const { exportEngine } = activeReportDefinition.reportComponentGroups[0];

  if (exportEngine !== 'SNOWFLAKE') return false;

  return activeReportDefinition.hideMerchantDropdown ? false : true;
}
