export {
  BRAND as BRAND_ENDPOINT,
  CATEGORY as CATEGORY_ENDPOINT,
} from 'api/endpoints';

export const CATEGORY_DROPDOWN = 'categories';
export const DATE_RANGE = 'date_range';
export const DROPDOWN = 'dropdown';
export const NUMERIC = 'numeric';
export const NUMERIC_RANGE = 'numeric_range';
export const RATING_DROPDOWN = 'rating_dropdown';
export const REMOTE_DROPDOWN = 'remote_dropdown';
export const REMOTE_FILTER_DROPDOWN = 'remote_filter_dropdown';
export const REMOTE_SELECT = 'remote_select';
export const TEXT = 'text';

export const GENERIC_REMOTE_FILTER = {
  name: '',
  displayOrder: 1,
  options: '',
  displayLabel: '',
  reportParameterType: REMOTE_FILTER_DROPDOWN,
};
export const BRAND_FILTER = {
  name: 'brand_id',
  displayOrder: 1,
  options: 'filters/brands',
  displayLabel: 'Brand',
  reportParameterType: REMOTE_FILTER_DROPDOWN,
};

export const CATEGORY_FILTER = {
  name: 'category_id',
  displayOrder: 1,
  options: 'filters/categories',
  displayLabel: 'Category',
  reportParameterType: REMOTE_FILTER_DROPDOWN,
};

export const PRODUCT_FILTER = {
  name: 'product_name',
  displayOrder: 1,
  displayLabel: 'Product',
  reportParameterType: TEXT,
};
export const PAGE_ID_FILTER = {
  name: 'page_id',
  displayOrder: 1,
  displayLabel: 'Page ID',
  reportParameterType: TEXT,
};
export const DATE_FILTER = {
  name: 'review_date',
  displayOrder: 1,
  displayLabel: 'Date',
  reportParameterType: DATE_RANGE,
};
