import moment from 'moment';
import {
  GET_REPORT_BOOKMARK_COMPONENT,
  GET_REPORT_BOOKMARK_PAGES,
} from 'api/endpoints';
import { getToken } from '@pwr/auth-provider';
import apiFetch from 'api/fetch';
import { safeObjectDig } from 'utils/safeObjectDig';
import presetDates from 'components/DatePicker/presetDates';
import { DEFAULT_DATE_RANGE } from 'components/DatePicker/constants';
import { selectedMerchantsToStringOfIds } from 'components/MerchantDropDown/utils';

const arrayToString = (arr, delimiter) => arr.join(`${delimiter}`);

const stringifyArrays = reportParameters => {
  return Object.entries(reportParameters).reduce(
    (accumulator, [filterName, filterValue]) => {
      if (Array.isArray(filterValue)) {
        accumulator[filterName] = `[${filterValue
          .map(filterValue => `"${filterValue}"`)
          .join(',')}]`;
        return accumulator;
      }
      accumulator[filterName] = filterValue;
      return accumulator;
    },
    {}
  );
};

function makeFetchReportUrl(reportId, pageLimit, currentPageNumber) {
  return `${GET_REPORT_BOOKMARK_COMPONENT}/${reportId}?limit=${pageLimit}&page=${currentPageNumber}`;
}
function makeFetchReportPageTotalUrl(pageLimit, reportComponentGroupId) {
  return `${GET_REPORT_BOOKMARK_PAGES}/${reportComponentGroupId}/?limit=${pageLimit}`;
}

function makeRequestBody({
  activeColumn,
  activeColumnDirection,
  columns,
  reportParameters,
}) {
  return JSON.stringify({
    ...stringifyArrays(reportParameters),
    table_columns: columns,
    table_sort: `${activeColumn} ${activeColumnDirection}`,
  });
}

function makeRequestHeaders(parameters) {
  const requestBody = makeRequestBody(parameters);
  return {
    headers: {
      Authorization: getToken(),
      'Content-Type': 'application/json',
    },
    method: 'PUT',
    body: requestBody,
  };
}
export const ISO_FORMAT = 'YYYY-MM-DD';
export const inputObjectsToParameterObject = (filters, filter) => {
  filters[filter.name] = filter.value;
  return filters;
};

export function fetchReportData({
  activeColumn,
  activeColumnDirection,
  columns,
  currentPageNumber,
  pageLimit,
  reportId,
  reportParameters,
}) {
  const url = makeFetchReportUrl(reportId, pageLimit, currentPageNumber);
  const headers = makeRequestHeaders({
    activeColumn,
    activeColumnDirection,
    columns: arrayToString(columns, ','),
    reportParameters,
  });
  return new Promise(async (resolve, reject) => {
    try {
      const result = await apiFetch(url, headers);
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
}

export function fetchReportPageTotal({
  activeColumn,
  activeColumnDirection,
  columns,
  currentPageNumber,
  pageLimit,
  reportId,
  reportParameters,
}) {
  const url = makeFetchReportPageTotalUrl(pageLimit, reportId);
  const headers = makeRequestHeaders({
    activeColumn,
    activeColumnDirection,
    columns: arrayToString(columns, ','),
    reportParameters,
  });
  return new Promise(async (resolve, reject) => {
    try {
      const results = await apiFetch(url, headers);
      resolve(results);
    } catch (error) {
      reject(error);
    }
  });
}

export function merchantDataToColumnarParams({
  selectedMerchantsByIds,
  selectedMerchantGroupsByIds,
}) {
  const merchant_group_list = [...selectedMerchantGroupsByIds.keys()].join(',');
  const merchantIds = Array.from(selectedMerchantsByIds.values())
    .map(({ merchantId }) => merchantId)
    .join(',');
  return {
    merchant_group_list: merchant_group_list,
    merchant_list: merchantIds ? merchantIds : null,
  };
}

function formatDateRange({ startDate, endDate, selectedPreset }) {
  if (selectedPreset) {
    return selectedPreset.value;
  }
  return `${startDate},${endDate}`;
}

function formatNumericRange({ to, from }) {
  const fromAsString = from.value ? from.value : '';
  const toAsString = to.value ? to.value : '';
  return `${fromAsString},${toAsString}`;
}

function formatColumnarParam(value) {
  const isObj = typeof value === 'object';
  const isDropdown = Array.isArray(value);
  const isDateRange = isObj ? safeObjectDig(value, 'startDate') : null;
  const isNumericRange = isObj ? safeObjectDig(value, 'to') : null;
  if (isDropdown) {
    return JSON.stringify(value.map(selectedItem => selectedItem.value));
  }
  if (isDateRange) {
    return formatDateRange(value);
  }
  if (isNumericRange) {
    return formatNumericRange(value);
  }
  return String(value);
}

export function filterStateToColumnarParams(filterState) {
  const columnarFilterParams = {};
  for (let filter in filterState) {
    const filterName = filter === 'date_range' ? 'review_date' : filter;
    const paramValue = formatColumnarParam(filterState[filter]);
    const emptyMultiselect = paramValue === '[]';
    if (paramValue && !emptyMultiselect) {
      columnarFilterParams[filterName] = paramValue;
    }
  }
  return columnarFilterParams;
}

export function merchantDataToJasperParams({
  selectedMerchantsByIds,
  selectedMerchantGroupsByIds,
}) {
  const merchant_group_list = [...selectedMerchantGroupsByIds.keys()].join(',');
  const merchantIds = selectedMerchantsToStringOfIds(selectedMerchantsByIds);
  return {
    merchant_group_list: [merchant_group_list],
    merchant_list: [!!merchantIds ? merchantIds : ''],
  };
}

function formatJasperParam(value) {
  const isObj = typeof value === 'object';
  const isDropdown = Array.isArray(value);
  const isNumericRange = isObj ? safeObjectDig(value, 'to') : null;
  if (isDropdown) {
    if (typeof value[0] === 'string') {
      return [value.join(',')];
    }
    const commaSeparatedValues = value
      .map(selectedItem => selectedItem.value)
      .join(',');
    return Boolean(commaSeparatedValues) ? [commaSeparatedValues] : [];
  }
  if (isNumericRange) {
    return [formatNumericRange(value)];
  }
  return [String(value)];
}

export function filterStateToJasperParams(filterDefinitions, filterState) {
  const jasperFilterParams = {};
  for (let filter in filterState) {
    const isDateRangeObj =
      typeof filterState[filter] === 'object'
        ? !!safeObjectDig(filterState[filter], 'startDate')
        : false;
    const isDateString = filter === 'review_date' && !isDateRangeObj;
    const paramValue = isDateRangeObj
      ? filterState[filter]
      : formatJasperParam(filterState[filter]);
    const emptyMultiselect =
      Array.isArray(paramValue) && paramValue.length === 0;
    if (!emptyMultiselect && !isDateRangeObj) {
      jasperFilterParams[filter] = paramValue;
    }
    if (isDateString) {
      const dateValue = filterState[filter];
      if (!presetDates[dateValue]) {
        const [startDate, endDate] = dateValue.split(',');
        jasperFilterParams['start_date'] = formatJasperParam(startDate);
        jasperFilterParams['end_date'] = formatJasperParam(endDate);
        return;
      }
      const { startDate, endDate } = presetDates[dateValue]({
        startDate: moment(),
        endDate: moment(),
      });
      jasperFilterParams['start_date'] = formatJasperParam(
        startDate.format(ISO_FORMAT)
      );
      jasperFilterParams['end_date'] = formatJasperParam(
        endDate.format(ISO_FORMAT)
      );
    }
    if (isDateRangeObj) {
      jasperFilterParams['start_date'] = formatJasperParam(
        paramValue.startDate
      );
      jasperFilterParams['end_date'] = formatJasperParam(paramValue.endDate);
    }
  }

  const defaultDate = presetDates[DEFAULT_DATE_RANGE.value]({
    startDate: moment(),
    endDate: moment(),
  });
  const defaultDateParams = {
    current_date: [moment().format(ISO_FORMAT)],
    start_date: [defaultDate.startDate.format(ISO_FORMAT)],
    end_date: [defaultDate.endDate.format(ISO_FORMAT)],
  };

  return {
    ...defaultDateParams,
    ...jasperFilterParams,
  };
}

export function mapColumnsForColumnarGrid(column) {
  return {
    displayName: column.displayHeader,
    propertyName: column.columnName,
    externalLink: column.externalLink || false,
    linkExpression: column.linkExpression || null,
    previewFormat: column.previewFormat || null,
  };
}
