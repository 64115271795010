import React, { Component } from 'react';
import classnames from 'classnames';
import {
  arrayOf,
  bool,
  func,
  object,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import isEqual from 'lodash.isequal';
import matchSorter from 'match-sorter';

import styles from './SearchBar.module.scss';

export default class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: this.props.items,
      searchTerm: '',
      originalItems: this.props.items,
    };
  }
  static propTypes = {
    items: oneOfType([arrayOf(string), arrayOf(object)]),
    onSearch: func.isRequired,
    placeholder: string,
    renderIcon: bool,
    searchOptions: shape({
      keys: arrayOf(string).isRequired,
    }),
  };

  handleSearch = () => {
    const { items, onSearch, searchOptions } = this.props;
    const { searchTerm } = this.state;
    if (searchTerm.length < 1) {
      return onSearch(items);
    }
    const searchResults = matchSorter(items, searchTerm.trim(), searchOptions);
    return onSearch(searchResults);
  };

  handleChange = event => {
    const searchTerm = event.target.value;
    this.setState({ searchTerm }, this.handleSearch);
  };

  componentWillUpdate = nextProps => {
    if (!isEqual(this.state.items.length, nextProps.items.length)) {
      this.setState({ items: nextProps.items }, () => {
        this.handleSearch();
      });
    }
  };

  render() {
    const { placeholder, renderIcon } = this.props;
    const { searchTerm } = this.state;
    return (
      <div className={styles['SearchBar']}>
        <input
          className={classnames(styles['SearchBar__input'], {
            [styles['SearchBar__icon']]: renderIcon,
          })}
          style={{ ...this.props.style }}
          type="text"
          onChange={this.handleChange}
          value={searchTerm}
          placeholder={placeholder || 'Search...'}
        />
      </div>
    );
  }
}
