import React from 'react';
import { bool, func } from 'prop-types';
import Toggle from '@atlaskit/toggle';
import { TooltipButton } from 'components/TooltipButton';
import { InformationSource } from 'icons/Icons';

export function SubscriptionMetadata({ initialValue, onChange, value }) {
  return (
    <div className="flex items-center pr-2">
      <label htmlFor="include-metadata">
        <span className="font-bold text-sm">Include report header</span>
      </label>
      <TooltipButton
        label="Include report header"
        tooltipText={<TooltipText />}
        tooltipOrigin="bottom"
        onClick={() => {}}
      >
        <div
          style={{
            display: 'flex',
            // Pull the icon closer to for aesthetics
            marginLeft: '-0.25rem',
          }}
        >
          <InformationSource height="14" width="14" />
        </div>
      </TooltipButton>
      <Toggle
        defaultChecked={typeof initialValue === 'boolean' ? initialValue : true}
        id="include-metadata"
        name="include-metadata-toggle"
        onChange={onChange}
        isChecked={value}
        size="large"
      />
    </div>
  );
}

SubscriptionMetadata.propTypes = {
  initialValue: bool,
  onChange: func.isRequired,
  value: bool.isRequired,
};

function TooltipText() {
  return (
    <div style={{ width: 250 }}>
      If enabled, the first two rows will contain the report's name and the data
      date range
    </div>
  );
}
