import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory, useLocation, Link } from 'react-router-dom';
import { Tabs, TabList, TabPanels, TabPanel } from '@reach/tabs';
import { usePrevious } from 'utils';
import { Tab } from 'components/Tab';
import {
  ReportContentColumnar,
  ReportContentTableau,
} from 'containers/ReportContent';
import { nonCustomReports as reportDefinitionsType } from 'utils/types';
import ReportDefinitionParser from 'containers/ReportContent/TableColumnBuilder';
import MerchantDropDown from 'components/MerchantDropDown';
import Growler from 'components/Growler';
import { EditSubscription } from 'pages/EditSubscription';
import { EditSavedReport } from 'pages/SavedReports/EditSavedReport';
import { AlertIcon } from 'icons/Icons';

export function ReportPage({
  defaultActiveTab,
  email,
  isAdmin,
  onMerchantSelect,
  pageUrl,
  reportDefinitions,
  reports,
  selectedMerchantGroupsByIds,
  selectedMerchantsByIds,
  setLastBreadcrumb,
  setSelectedMerchantGroupsByIds,
  userid,
}) {
  const { tab, subscriptionOrSavedReportId } = useParams();

  // This complication exists because Tableau reports use their own tab
  // navigation, but we hide them and, instead, display the Tableau
  // workbook tabs in the native reporting UI tabs
  const flattenedReports = reports.reduce((acc, reportObj) => {
    if (reportObj.type === 'tableau_workbook') {
      reportObj.workbookTabs.forEach(tabProps =>
        acc.push({ ...tabProps, reportIdentifier: reportObj.reportIdentifier })
      );
      return acc;
    }
    acc.push(reportObj);
    return acc;
  }, []);

  const urlParamMatchesReport = flattenedReports.some(
    report => tab === report.urlFragment
  );

  const { replace } = useHistory();
  const { pathname } = useLocation();

  if (
    !urlParamMatchesReport &&
    tab !== 'saved-reports' &&
    tab !== 'subscriptions'
  ) {
    replace({
      pathname: `${pageUrl}/${defaultActiveTab}`,
    });
  }

  const [currentSelectedTabIndex, setCurrentSelectedTabIndex] = useState(() => {
    return urlParamMatchesReport
      ? flattenedReports.findIndex(report => tab === report.urlFragment)
      : 0;
  });

  const { reportIdentifier } = flattenedReports[currentSelectedTabIndex];
  const activeReportDefinition = new ReportDefinitionParser(
    reportDefinitions,
    reportIdentifier
  );

  const [tableauCustomViewUrl, setTableauCustomViewUrl] = useState(null);
  const [reportBookmark, setReportBookmark] = useState(null);
  const [
    merchantGroupPickerPositionedOverReport,
    setMerchantGroupPickerPositionedOverReport,
  ] = useState(null);

  const merchantGroupIds = [...selectedMerchantGroupsByIds.keys()].join(',');
  const merchantIds = [...selectedMerchantsByIds.keys()].join(',');
  const [growlerProps, setGrowlerProps] = useState(null);
  function handleGrowlerDismiss() {
    setGrowlerProps(null);
  }

  function handleTabSelection(selectedIndex) {
    if (selectedIndex === currentSelectedTabIndex) return;

    setCurrentSelectedTabIndex(selectedIndex);

    const shouldRewriteUrl = tab !== 'subscriptions' && tab !== 'saved-reports';
    if (shouldRewriteUrl) {
      replace({
        pathname: `${pageUrl}/${flattenedReports[selectedIndex].urlFragment}`,
      });
    }
  }

  const tabButtons = flattenedReports.reduce((acc, report) => {
    acc.push(
      <Tab
        data-testid={report.title.toLowerCase()}
        key={report.urlFragment + 'tab'}
      >
        {report.title}
      </Tab>
    );
    return acc;
  }, []);

  const tabContent = reports.reduce((tabContent, report, reportIndex) => {
    const reportDefinition = new ReportDefinitionParser(
      reportDefinitions,
      report.reportIdentifier
    );

    if (report.type === 'tableau_workbook') {
      /**
       * Tabeau workbooks are a special case. We need to use the native
       * Tableau tabs, but we don't want the Tableau tab UI. To solve this,
       * <ReportContentTableau /> uses the Tableau embed library to switch
       * Tableau report tabs. This creates a mismatch between the number of
       * tab buttons and the number of tab panels.
       */
      const rangeOfIndices = Array(report.workbookTabs.length)
        .fill(reportIndex)
        .map((reportIndex, tableauTabIndex) => reportIndex + tableauTabIndex);
      const isTableauWorksheetActive = rangeOfIndices.includes(
        currentSelectedTabIndex
      );

      tabContent.push(
        <TableaWorkbook
          key={report.reportIdentifier}
          isActive={isTableauWorksheetActive}
        >
          <ReportContentTableau
            isAdmin={isAdmin}
            merchantGroupIds={merchantGroupIds}
            merchantIds={merchantIds}
            reportDefinition={reportDefinition}
            reportIdentifier={report.reportIdentifier}
            selectedWorkbookTab={flattenedReports[currentSelectedTabIndex]}
            setGrowlerProps={setGrowlerProps}
            setTableauCustomViewUrl={setTableauCustomViewUrl}
            useUrlParams={false}
            workbookTabs={report.workbookTabs}
          />
        </TableaWorkbook>
      );

      report.workbookTabs.forEach(tab =>
        // As mentioned above, each Tableau report is a workbook
        // with many reports. Thus we have one <ReportContentTableau />
        tabContent.push(<TabPanel key={tab.urlFragment} />)
      );
      return tabContent;
    }

    tabContent.push(
      <TabPanel key={report.reportIdentifier}>
        <ReportContentColumnar
          isActive={tab === report.urlFragment}
          isAdmin={isAdmin}
          merchantGroupIds={merchantGroupIds}
          merchantIds={merchantIds}
          reportDefinition={reportDefinition}
          reportIdentifier={report.reportIdentifier}
          setGrowlerProps={setGrowlerProps}
          useUrlParams
        />
      </TabPanel>
    );
    return tabContent;
  }, []);

  const [reportBookmarkBreadcrumbs, setReportBookmarkBreadcrumbs] = useState(
    []
  );
  const previousCrumbs = usePrevious(reportBookmarkBreadcrumbs);

  const crumbsChanged =
    JSON.stringify(previousCrumbs) !==
    JSON.stringify(reportBookmarkBreadcrumbs);

  useEffect(
    function setBreadcrumbsWhenSubscriptionNameIsLoaded() {
      if (!crumbsChanged) return;

      setLastBreadcrumb(reportBookmarkBreadcrumbs);
    },
    [crumbsChanged, setLastBreadcrumb, reportBookmarkBreadcrumbs]
  );

  function setBreadcrumbsIfDifferent(incomingBreadcrumbs) {
    if (
      JSON.stringify(reportBookmarkBreadcrumbs) !==
      JSON.stringify(incomingBreadcrumbs)
    ) {
      setReportBookmarkBreadcrumbs(incomingBreadcrumbs);
    }
  }

  let workbookTabs = [];
  let reportProperties;

  if (reportBookmark) {
    reportProperties = reports.find(report => {
      return report.reportIdentifier === reportBookmark.reportIdentifier;
    });
    const isTableauReport =
      reportProperties && reportProperties.type === 'tableau_workbook';

    workbookTabs = isTableauReport
      ? reportProperties.workbookTabs
      : [reportProperties];

    const tableauWorkbookIndex = tableauCustomViewUrl
      ? workbookTabs.findIndex(report => {
          return tableauCustomViewUrl.includes(`/${report.tableauTabUri}/`);
        })
      : null;

    if (
      tableauWorkbookIndex !== null &&
      currentSelectedTabIndex !== tableauWorkbookIndex &&
      !workbookTabs[currentSelectedTabIndex]
    ) {
      setCurrentSelectedTabIndex(
        tableauWorkbookIndex === -1 ? 0 : tableauWorkbookIndex
      );
    }
  }

  const [originalTableauViewMounted, setOriginalTableauViewMounted] = useState(
    false
  );

  useEffect(
    function activateSavedWorkbookTab() {
      if (!tableauCustomViewUrl || originalTableauViewMounted) return;

      const savedWorkbookIndex = tableauCustomViewUrl
        ? workbookTabs.findIndex(report => {
            return tableauCustomViewUrl.includes(`/${report.tableauTabUri}/`);
          })
        : null;

      if (savedWorkbookIndex !== currentSelectedTabIndex) {
        setCurrentSelectedTabIndex(
          savedWorkbookIndex === -1 ? 0 : savedWorkbookIndex
        );
        setOriginalTableauViewMounted(true);
      }
    },
    [
      currentSelectedTabIndex,
      originalTableauViewMounted,
      setOriginalTableauViewMounted,
      tableauCustomViewUrl,
      workbookTabs,
    ]
  );

  if (reportProperties && reportProperties.type === 'columnar') {
    // Columnar reports will only have one tab
    if (currentSelectedTabIndex !== 0) {
      setCurrentSelectedTabIndex(0);
    }
  }

  if (tab === 'saved-reports' || tab === 'subscriptions') {
    // We need to render not only the worksheet that was saved, but
    // the entire work book. We do this by getting the reportIdentifier
    // and finding the matching Tableau workbook

    const returnToReportsButton = (() => {
      if (!reportProperties || !workbookTabs[currentSelectedTabIndex]) {
        return null;
      }

      const isTableauReport = reportProperties.type === 'tableau_workbook';

      const urlFragment = isTableauReport
        ? workbookTabs[currentSelectedTabIndex].urlFragment
        : reportProperties.urlFragment;

      return (
        <Link
          className="btn btn--primary absolute pin-r"
          style={{
            top: '50%',
            right: 0,
            transform: 'translate(-1.25rem, -50%)',
          }}
          onClick={() => {
            // This is complicated because Tableau reports have their own
            // concept of tabs.
            if (tableauCustomViewUrl) {
              // We have ourselves a Tableau report. In order to know which
              // tab to activate in the portal, we have to parse the Tableau
              // Custom View address
              workbookTabs.findIndex(report => {
                return tableauCustomViewUrl.includes(
                  `/${report.tableauTabUri}/`
                );
              });
            } else {
              const reportPageActiveTab = flattenedReports.findIndex(report => {
                return (
                  report.reportIdentifier === reportBookmark.reportIdentifier
                );
              });
              setCurrentSelectedTabIndex(reportPageActiveTab);
            }
            setReportBookmark(null);
          }}
          to={`${pageUrl}/${urlFragment}`}
        >
          Default View
        </Link>
      );
    })();

    if (reportBookmark && !reportProperties) {
      return (
        <ReportIsNoLongerAvailable
          reportName={reportBookmark.report.pop().name}
          tab={tab}
        />
      );
    }

    const tabComponent = (
      <Tabs
        className="mt-4 bg-gray-93"
        index={currentSelectedTabIndex}
        onChange={handleTabSelection}
      >
        <TabList className="relative">
          {workbookTabs.map(report => (
            <Tab>{report.title}</Tab>
          ))}
          {returnToReportsButton}
        </TabList>

        <TabPanels />
      </Tabs>
    );

    return (
      <>
        {!!growlerProps && (
          <Growler
            dismissible
            onDismiss={handleGrowlerDismiss}
            title={growlerProps.title}
            type={growlerProps.type}
          >
            {growlerProps.children}
          </Growler>
        )}
        <div className="flex mt-4">
          {merchantGroupPickerPositionedOverReport}
        </div>
        <div className="shadow">
          {tabComponent}
          {tab === 'subscriptions' ? (
            <EditSubscription
              setGrowlerProps={setGrowlerProps}
              setSelectedMerchantGroupsByIds={setSelectedMerchantGroupsByIds}
              isAdmin={isAdmin}
              email={email}
              selectedMerchantsByIds={selectedMerchantsByIds}
              selectedMerchantGroupsByIds={selectedMerchantGroupsByIds}
              nonCustomReports={reportDefinitions}
              setLastBreadcrumb={setLastBreadcrumb}
              onMerchantSelect={onMerchantSelect}
              match={{
                params: { reportScheduleId: subscriptionOrSavedReportId },
              }}
              selectedWorkbookTab={flattenedReports[currentSelectedTabIndex]}
              workbookTabs={workbookTabs}
              setReportBookmark={setReportBookmark}
              setTableauCustomViewUrl={setTableauCustomViewUrl}
              setMerchantGroupPickerPositionedOverReport={merchantPicker => {
                if (merchantGroupPickerPositionedOverReport) return;

                setMerchantGroupPickerPositionedOverReport(merchantPicker);
              }}
            />
          ) : (
            <EditSavedReport
              isAdmin={isAdmin}
              nonCustomReports={reportDefinitions}
              onMerchantSelect={onMerchantSelect}
              reportBookmarkId={subscriptionOrSavedReportId}
              selectedMerchantGroupsByIds={selectedMerchantGroupsByIds}
              selectedMerchantsByIds={selectedMerchantsByIds}
              setLastBreadcrumb={setBreadcrumbsIfDifferent}
              setSelectedMerchantGroupsByIds={setSelectedMerchantGroupsByIds}
              selectedWorkbookTab={flattenedReports[currentSelectedTabIndex]}
              setReportBookmark={setReportBookmark}
              setGrowlerProps={setGrowlerProps}
              setTableauCustomViewUrl={setTableauCustomViewUrl}
              userid={userid}
              setMerchantGroupPickerPositionedOverReport={merchantPicker => {
                if (merchantGroupPickerPositionedOverReport) return;

                setMerchantGroupPickerPositionedOverReport(merchantPicker);
              }}
              workbookTabs={workbookTabs}
            />
          )}
        </div>
      </>
    );
  }

  setBreadcrumbsIfDifferent(
    urlPathToTerminalBreadcrumb(
      pathname
        .split('/')
        .filter(Boolean)
        .slice(1)
        .join('/')
    )
  );

  return (
    <div data-translate={true}>
      {activeReportDefinition && (
        <>
          {!!growlerProps && (
            <Growler
              dismissible
              onDismiss={handleGrowlerDismiss}
              title={growlerProps.title}
              type={growlerProps.type}
            >
              {growlerProps.children}
            </Growler>
          )}
          <section className="flex justify-between">
            <div>
              {activeReportDefinition.reportType !== 'TABLEAU' &&
                showMerchantDropdown(reportIdentifier, reports) && (
                  <MerchantDropDown
                    selectedMerchantsByIds={selectedMerchantsByIds}
                    selectedMerchantGroupsByIds={selectedMerchantGroupsByIds}
                    onMerchantSelect={onMerchantSelect}
                  />
                )}
            </div>
          </section>
        </>
      )}
      <div className="shadow">
        <Tabs
          className="mt-4 bg-gray-93"
          index={currentSelectedTabIndex}
          onChange={handleTabSelection}
        >
          <TabList>{tabButtons}</TabList>
          <TabPanels>{tabContent}</TabPanels>
        </Tabs>
      </div>
    </div>
  );
}

ReportPage.propTypes = {
  defaultActiveTab: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  pageUrl: PropTypes.string.isRequired,
  onMerchantSelect: PropTypes.func.isRequired,
  reportDefinitions: reportDefinitionsType.isRequired,
  reports: PropTypes.arrayOf(
    PropTypes.shape({
      hideMerchantDropdown: PropTypes.bool,
      reportIdentifier: PropTypes.string.isRequired,
      urlFragment: PropTypes.string,
      title: PropTypes.string,
      type: PropTypes.oneOf(['columnar', 'tableau_workbook']).isRequired,
    })
  ),
  selectedMerchantGroupIds: PropTypes.instanceOf(Map),
  selectedMerchantsByIds: PropTypes.instanceOf(Map),
};

function showMerchantDropdown(activeReportIdentifier, reports) {
  const activeReportDefinition =
    reports.find(
      report => report.reportIdentifier === activeReportIdentifier
    ) || {};
  return activeReportDefinition.hideMerchantDropdown ? false : true;
}

function TableaWorkbook({ children, isActive }) {
  return (
    <div
      tabIndex={isActive ? 0 : -1}
      className={isActive ? '' : 'visually-hidden'}
    >
      {children}
    </div>
  );
}

function urlPathToTerminalBreadcrumb(url) {
  if (!url) return '';

  const specialCases = {
    // q&a would be translated to Q&a
    'q&a': 'Q&A',
    // This awkwardness comes from having to rename "All Reviews"
    // to "Reviews," but not to break browser bookmarks made to
    // URLs like: `/ratings-reviews/all-reviews`
    'all-reviews': 'Reviews',
  };

  return url
    .split('/')
    .filter(Boolean)
    .map(kebabCaseString => {
      return {
        label:
          specialCases[url] ||
          kebabCaseString
            .split('-')
            .map(part => `${part[0].toUpperCase()}${part.slice(1)}`)
            .join(' '),
        href: '',
      };
    });
}

function ReportIsNoLongerAvailable({ tab }) {
  return (
    <div
      style={{
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 2px 2px 0px',
        minHeight: 400,
      }}
      className="bg-white flex flex-column items-center justify-center py-12 mt-4"
    >
      <AlertIcon width={65} fill="rgb(85, 85, 85)" />
      <div className="text-left flex flex-column justify-center items-center w-50">
        <h2 className="mt-4">
          This report is no longer available in Analytics
        </h2>
        <p className="mt-4 lh-copy">
          This report is no longer available in Analytics. Please delete this
          report and save a new report or reach out to Client Success for
          support.
        </p>
        <div className="flex">
          <Link className="btn btn--primary mt-8" to={`/${tab}`}>
            Go back to your {tab}
          </Link>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://help.powerreviews.com/Content/Contact%20Us.htm"
            className="btn btn--secondary mt-8 ml-4"
            to="/subscriptions"
          >
            Contact support
          </a>
        </div>
      </div>
    </div>
  );
}
