export const SORT_DIRECTION = {
  ASC: 'asc',
  DESC: 'desc',
};

export const DEFAULT_COLUMN_WIDTH = 250;
const WIDE_COLUMN = 550;
const SMALL_COLUMN = 200;
export const columnSizes = new Map([
  ['answer_text', WIDE_COLUMN],
  ['answered', SMALL_COLUMN],
  ['created_date', SMALL_COLUMN],
  ['expert_answer_count', SMALL_COLUMN],
  ['expert', SMALL_COLUMN],
  ['helpful', SMALL_COLUMN],
  ['is_pwr_publishable', SMALL_COLUMN],
  ['not_helpful', SMALL_COLUMN],
  ['page_id_variant', SMALL_COLUMN],
  ['question_text', WIDE_COLUMN],
  ['review_bottomline', SMALL_COLUMN],
  ['review_comments', WIDE_COLUMN],
  ['review_id', SMALL_COLUMN],
  ['review_rating', SMALL_COLUMN],
  ['reviewer_type', SMALL_COLUMN],
  ['site_status', SMALL_COLUMN],
  ['source', SMALL_COLUMN],
  ['time_to_answer', SMALL_COLUMN],
  ['type', SMALL_COLUMN],
  ['ugc_id', SMALL_COLUMN],
]);

export const swapArrayItemsByIndex = (items, sourceIndex, destinationIndex) => {
  const arrayCopy = [...items];
  const itemToMove = arrayCopy.splice(sourceIndex, 1)[0];
  arrayCopy.splice(destinationIndex, 0, itemToMove);
  return arrayCopy;
};
