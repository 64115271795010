import React from 'react';
import cookies from 'js-cookie';

const containerStyles = {
  height: 50,
  marginBottom: '1rem',
  width: '100%',
};

const bannerStyles = {
  backgroundColor: '#d0021b',
  color: '#FFF',
  left: 100,
  opacity: '0.75',
  padding: '0.25rem',
  position: 'absolute',
  textAlign: 'center',
  top: 0,
  width: '100%',
};

export function RunAsBanner() {
  const shouldShowRunAsBanner = cookies.get('run_as');

  if (!shouldShowRunAsBanner) return null;

  return (
    <div style={containerStyles}>
      <div style={bannerStyles} role="banner">
        <p className="text-lg">
          You're currently browsing Analytics in Run-As mode.
        </p>
      </div>
    </div>
  );
}
