import { arrayOf, bool, shape, string, number } from 'prop-types';
import { merchant } from './merchant';
import { regenerateData } from './regenerateData';

export const merchantGroup = shape({
  regenerateData: regenerateData,
  selectedMerchantGroup: shape({
    merchantGroupId: number,
    merchants: arrayOf(merchant),
    name: string,
    productAnswers: bool,
    ssAccountInformationId: number,
  }),
});
