import { useEffect, useState } from 'react';
import { getToken } from '@pwr/auth-provider';
import apiFetch from 'api/fetch';
import { REPORT_SCHEDULE } from 'api/endpoints';

export function useReportSchedule(reportScheduleId) {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let current = true;
    if (!reportScheduleId) return;

    setIsLoading(true);

    const options = {
      headers: {
        Authorization: getToken(),
        'Content-type': 'application/json',
      },
    };
    const url =
      REPORT_SCHEDULE +
      `/${reportScheduleId}` +
      '?projection=completeReportScheduleProjection';
    const fetchData = () => {
      apiFetch(url, options)
        .then(response => response.json())
        .then(json => {
          setResponse(json);
          setIsLoading(false);
        })
        .catch(setError);
    };
    current && fetchData();
    return () => (current = false);
  }, [reportScheduleId]);
  return { error, isLoading, response };
}
