export const DISPLAY_FORMAT = 'L';
export const ISO_FORMAT = 'YYYY-MM-DD';
export const ALT_FORMAT = 'MM/DD/YYYY';
export const ISO_MONTH_FORMAT = 'YYYY-MM';

export const START_DATE = 'startDate';
export const END_DATE = 'endDate';

export const HORIZONTAL_ORIENTATION = 'horizontal';

export const LAST_12_MONTHS = 'LAST_12_MONTHS';
export const LAST_6_MONTHS = 'LAST_6_MONTHS';
export const LAST_3_MONTHS = 'LAST_3_MONTHS';
export const LAST_30_DAYS = 'LAST_30_DAYS';
export const LAST_7_DAYS = 'LAST_7_DAYS';
export const LAST_2_DAYS = 'LAST_2_DAYS';
export const LAST_DAY = 'LAST_DAY';
export const THIS_MONTH = 'THIS_MONTH';
export const LAST_MONTH = 'LAST_MONTH';
export const THIS_YEAR = 'THIS_YEAR';
export const LAST_YEAR = 'LAST_YEAR';
export const THIS_QUARTER = 'THIS_QUARTER';
export const LAST_QUARTER = 'LAST_QUARTER';

export const DEFAULT_DATE_RANGE = {
  value: LAST_6_MONTHS,
  label: 'Last 6 months',
};

export const PRESET_DATE_RANGES = [
  { value: LAST_12_MONTHS, label: 'Last 12 months' },
  { value: LAST_6_MONTHS, label: 'Last 6 months' },
  { value: LAST_3_MONTHS, label: 'Last 3 months' },
  { value: LAST_30_DAYS, label: 'Last 30 days' },
  { value: LAST_7_DAYS, label: 'Last 7 days' },
  { value: LAST_2_DAYS, label: 'Last 2 days' },
  { value: LAST_DAY, label: 'Last day' },
  { value: THIS_MONTH, label: 'This month' },
  { value: LAST_MONTH, label: 'Last month' },
  { value: THIS_YEAR, label: 'This year' },
  { value: LAST_YEAR, label: 'Last year' },
  { value: THIS_QUARTER, label: 'This quarter' },
  { value: LAST_QUARTER, label: 'Last quarter' },
];
