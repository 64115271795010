import moment from 'moment';
import { safeObjectDig } from 'utils/safeObjectDig';
import presetDates from 'components/DatePicker/presetDates';
const ISO_FORMAT = 'YYYY-MM-DD';

function includeValue(value) {
  if (!value) return false;
  if (Array.isArray(value) && value.length < 1) return false;
  if (typeof value === 'string' && value.length < 1) return false;
  return true;
}

function dropdownToPayloadValue(dropdown) {
  if (typeof dropdown[0] === 'string') {
    return JSON.stringify(dropdown);
  }
  return JSON.stringify(dropdown.map(item => String(item.value)));
}

function dateRangeToPayloadValue(dateRange) {
  const usingSelectedPreset = !!safeObjectDig(dateRange, 'selectedPreset');
  if (usingSelectedPreset) return dateRange.selectedPreset.value;
  return `${dateRange.startDate},${dateRange.endDate}`;
}

function numericRangeToPayloadValue({ to, from }) {
  const toValue = String(to.value);
  const fromValue = String(from.value);
  return `${fromValue || ''},${toValue || ''}`;
}

function formatValue(value) {
  const isRangeObject = typeof value === 'object';
  const isDropdown = Array.isArray(value);
  const isDateRange = isRangeObject && !!safeObjectDig(value, 'startDate');
  const isNumericRange = isRangeObject && !!safeObjectDig(value, 'to');
  if (isDropdown) return dropdownToPayloadValue(value);
  if (isDateRange) return dateRangeToPayloadValue(value);
  if (isNumericRange) return numericRangeToPayloadValue(value);
  return String(value);
}

export function reviewDateToStartEndDate(dateRange) {
  const isDateRange =
    typeof dateRange === 'object' && !!safeObjectDig(dateRange, 'startDate');
  if (isDateRange) {
    return {
      start_date: dateRange.startDate,
      end_date: dateRange.endDate,
    };
  }
  const selectedPreset = presetDates[dateRange];
  if (selectedPreset) {
    const dates = selectedPreset({ startDate: moment(), endDate: moment() });
    return {
      start_date: dates.startDate.format(ISO_FORMAT),
      end_date: dates.endDate.format(ISO_FORMAT),
    };
  }
  const dateString = dateRange.split(',');
  if (!selectedPreset && dateString.length === 2) {
    return {
      start_date: dateString[0],
      end_date: dateString[1],
    };
  }
}

export function filterStateToPayload(filterState) {
  let startDateEndDate;
  const payload = Object.keys(filterState).reduce((payload, name) => {
    const value = filterState[name];
    if (name === 'review_date') {
      startDateEndDate = reviewDateToStartEndDate(value);
    }
    if (includeValue(value)) {
      payload[name] = formatValue(value);
    }
    return payload;
  }, {});

  return {
    ...payload,
    ...startDateEndDate,
  };
}
