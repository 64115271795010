import { useLocation } from 'react-router-dom';
import { encodeFilterState } from 'components/Filters/ReportFilters/ReportFiltersUtils';

export function useUrlParamDecoder() {
  const { search } = useLocation();
  const decodedFilterState = Array.from(
    new URLSearchParams(search).entries()
  ).reduce((acc, [key, value]) => {
    acc[key === 'date_range' ? 'review_date' : key] = value;
    return acc;
  }, {});

  return { decodedFilterState, encodeFilterState };
}
