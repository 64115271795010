import {
  arrayOf,
  bool,
  func,
  number,
  shape,
  string,
  oneOfType,
} from 'prop-types';
import { nonCustomReports } from './nonCustomReports';

export const config = shape({
  activeReportId: string,
  apps: arrayOf(
    shape({
      label: string,
      href: string,
    })
  ),
  configHasLoaded: bool,
  currentMerchantGroup: string,
  email: string,
  env: string,
  isAdmin: bool,
  merchantGroupList: arrayOf(
    shape({
      merchantGroupId: number,
      merchants: arrayOf(
        shape({
          merchantId: oneOfType([string, number]),
          name: string,
        })
      ),
      name: string,
      productAnswers: bool,
      ssAccountInformationId: number,
    })
  ),
  merchantProperties: shape({
    ENABLE_PRODUCT_ANSWERS: bool,
    SOCIAL_COLLECTION_PROPERTY_ID: bool,
  }),
  name: string,
  nonCustomReports,
  token: string,
  userid: number,
  updateCurrentMerchantGroup: func,
});
