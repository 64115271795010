export function siteAnalyticsReportPageProps() {
  const reports = [
    {
      reportIdentifier: 'site_analytics_workbook',
      type: 'tableau_workbook',
      workbookTabs: [
        {
          tableauIframeHeight: 1000,
          tableauTabUri: 'UGC',
          tableauWorksheetName: 'UGC',
          title: 'UGC',
          type: 'tableau',
          urlFragment: 'ugc',
        },
        {
          tableauTabUri: 'Review',
          tableauWorksheetName: 'Review',
          title: 'Review',
          type: 'tableau',
          urlFragment: 'review',
        },
        {
          tableauIframeHeight: 1000,
          tableauTabUri: 'Media',
          tableauWorksheetName: 'Media',
          title: 'Media',
          type: 'tableau',
          urlFragment: 'media',
        },
        {
          tableauIframeHeight: 1000,
          tableauTabUri: 'QA',
          tableauWorksheetName: 'Q&A',
          title: 'Q&A',
          type: 'tableau',
          urlFragment: 'question-and-answer',
        },
        {
          tableauIframeHeight: 1300,
          tableauTabUri: 'ReviewSearch',
          tableauWorksheetName: 'Review Search',
          title: 'Review Search',
          type: 'tableau',
          urlFragment: 'review-search',
        },
      ],
    },
  ];
  const DEFAULT_TAB = reports[0].workbookTabs[0].urlFragment;
  const PAGE_URL = '/site-analytics';
  const PAGE_TITLE = 'Site Analytics';

  const navigationProps = {
    crumbs: [
      {
        label: 'Analytics',
        href: `/`,
      },
      {
        label: PAGE_TITLE,
        href: PAGE_URL,
      },
    ],
    activePrimary: 'Analytics',
    activeSecondary: PAGE_TITLE,
  };

  return {
    defaultTab: DEFAULT_TAB,
    navigationProps,
    pageUrl: PAGE_URL,
    reports,
  };
}
