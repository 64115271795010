import React, { Component } from 'react';
import { func, string, shape } from 'prop-types';
import isEqual from 'lodash.isequal';
import throttle from 'lodash.throttle';
import { Dots } from 'components/Loaders';
import { remoteServerCredentialTest } from './DeliveryMethodUtils';

export default class Credentials extends Component {
  static propTypes = {
    ftpCredentials: shape({
      ftpPassword: string,
      ftpUserName: string,
      ftpUrl: string,
    }).isRequired,
    onChange: func.isRequired,
  };
  jik;
  state = {
    activeRequest: null,
    hasValidCredentials: false,
  };

  componentDidUpdate(prevProps) {
    const hasSameCredentials = isEqual(
      Object.values(prevProps.ftpCredentials),
      Object.values(this.props.ftpCredentials)
    );
    if (hasSameCredentials) {
      return;
    }
    this.testCredentials();
  }

  replaceActiveRequest = newRequest => {
    const { activeRequest } = this.state;
    if (activeRequest) {
      activeRequest.cancel();
    }
    this.setState({
      activeRequest: newRequest,
    });
  };

  componentDidMount() {
    // The component may be hydrated data from a saved subscription
    // so it should test the credentials
    this.testCredentials();
  }

  componentWillUnmount() {
    this.replaceActiveRequest(null);
  }

  testCredentials() {
    const activeRequest = this.makeThrottledRequest;
    this.replaceActiveRequest(activeRequest);
    activeRequest();
  }

  // The request to check the user's *TP credentials throttled to
  // reduce the number of requests made and give us the ability
  makeThrottledRequest = throttle(
    async () => {
      const { deliveryType, ftpCredentials } = this.props;
      let isValid;
      try {
        isValid = await remoteServerCredentialTest({
          deliveryType,
          ...ftpCredentials,
        });
      } catch (error) {
        console.error(error);
      }
      this.setState({
        hasValidCredentials: isValid,
        activeRequest: null,
      });
    },
    500,
    { leading: false }
  );

  render() {
    const { activeRequest, hasValidCredentials } = this.state;
    const { ftpUserName, ftpPassword, ftpUrl } = this.props.ftpCredentials;
    const isMissingCredential = !ftpUserName || !ftpPassword || !ftpUrl;
    const credentialFeedback = `${
      hasValidCredentials ? 'Valid' : 'Invalid'
    } credentials`;

    if (isMissingCredential) {
      return null;
    }
    return (
      <div className="flex items-center mb-2">
        {activeRequest && <Dots />}
        {!activeRequest && <p>{credentialFeedback}</p>}
      </div>
    );
  }
}
