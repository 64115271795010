import { array, arrayOf, bool, shape, string, number } from 'prop-types';

export const reportSchedule = shape({
  createdByProfileId: number.isRequired,
  createdForProfileId: number,
  createdForProfileName: string,
  createdTime: string.isRequired,
  dateRangeValue: string.isRequired,
  delimiter: string,
  exportType: string.isRequired,
  filename: string,
  frequency: string,
  id: number.isRequired,
  merchantGroupIds: string,
  merchantIds: string,
  message: string,
  monthDay: number,
  monthWeek: number,
  name: string.isRequired,
  reportBookmark: shape({
    id: number,
    createdTime: string,
    updatedTime: string,
    createdByProfileId: number,
    updateByProfileId: number,
    profileId: number,
    profileName: string,
    name: string,
    visibleToAll: bool,
    reportBookmarkShares: array,
  }).isRequired,
  reportScheduleRecipients: arrayOf(
    shape({
      version: string,
      createdByProfileId: number,
      updateByProfileId: number,
      createdTime: string,
      updatedTime: string,
      email: string,
      deliveryType: string,
      ftpUrl: string,
      ftpUserName: string,
      ftpPassword: string,
      message: string,
      id: number,
      _links: shape({
        self: shape({
          href: string,
        }),
        reportSchedule: shape({
          href: string,
        }),
      }),
    })
  ).isRequired,
  updateByProfileId: number,
  updatedTime: string,
  version: number,
  weekDay: string,
});
