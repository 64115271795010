import React from 'react';
import { oneOfType, object, string } from 'prop-types';
import FocusLock from 'react-focus-lock';
import moment from 'moment';
import {
  START_DATE,
  END_DATE,
  PRESET_DATE_RANGES,
  DEFAULT_DATE_RANGE,
  HORIZONTAL_ORIENTATION,
  ALT_FORMAT,
  ISO_FORMAT,
} from './constants';
import presetDates from './presetDates';
import { Select } from '@pwr-ui/select';
import {
  DayPickerRangeController,
  DateRangePickerInputController,
} from 'react-dates';
import isEqual from 'lodash.isequal';
import nanoid from 'nanoid';
import './DatePicker.scss';

class DatePicker extends React.Component {
  state = {
    focusedInput: this.props.autoFocusEndDate ? END_DATE : START_DATE,
    currentDate: moment(),
    startDate: null,
    endDate: null,
    isDatePickerOpen: false,
    selectedPreset: DEFAULT_DATE_RANGE,
    dateInfo: 'Date Info',
  };

  static propTypes = {
    currentValue: oneOfType([
      object,
      string, // This is the case where the current value is coming from a URL param
    ]),
  };

  elementId = `DatePicker_${nanoid()}`;

  handleCurrentValue(currentValue) {
    const valueIsFromUrl = typeof currentValue === 'string';
    if (valueIsFromUrl) {
      let [startDate, endDate] = currentValue.split(',');
      const isSelectedPreset = currentValue.includes('_');
      if (isSelectedPreset) {
        const defaultDates = this.getPresetDate(currentValue);
        startDate = defaultDates.startDate.format(ISO_FORMAT);
        endDate = defaultDates.endDate.format(ISO_FORMAT);
      }

      return {
        startDate,
        endDate,
        selectedPreset: isSelectedPreset
          ? PRESET_DATE_RANGES.find(date => date.value === currentValue)
          : null,
      };
    }
    return currentValue;
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    const currentValue = this.handleCurrentValue(this.props.currentValue);
    const { startDate, endDate, selectedPreset } = currentValue;
    return this.setState({
      startDate: moment(startDate),
      endDate: moment(endDate),
      selectedPreset,
    });
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (!this.props.currentValue) {
      return;
    }
    if (isEqual(this.props.currentValue, prevProps.currentValue)) {
      return;
    }
    const { startDate, endDate, selectedPreset } = this.props.currentValue;
    this.setState({
      startDate: moment(startDate),
      endDate: moment(endDate),
      selectedPreset,
    });
  };

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  getPresetDate = type => {
    if (!presetDates[type]) {
      return {
        startDate: null,
        endDate: null,
      };
    }
    return presetDates[type]({ startDate: moment(), endDate: moment() });
  };

  onDatesChangePreset = presetDateSelection => {
    if (!presetDateSelection) return;
    const dateValue = this.getPresetDate(presetDateSelection.value);
    this.setState(
      {
        ...this.state,
        ...dateValue,
        selectedPreset: presetDateSelection,
      },
      () => {
        this.props.onDateSubmit(this.state);
      }
    );
  };

  onDateSubmit = () => {
    this.props.onDateSubmit(this.state);
  };

  onDatesChange = ({ startDate, endDate }) => {
    this.setState(
      {
        startDate,
        endDate,
        selectedPreset: null,
      },
      () => {
        this.props.onDateSubmit(this.state);
      }
    );
  };

  onFocusChange = focusedInput => {
    this.setState({
      // Force the focusedInput to always be truthy so that dates are always selectable
      focusedInput: !focusedInput ? START_DATE : focusedInput,
    });
  };

  handleClickOutside = event => {
    if (!this.state.isDatePickerOpen) {
      return;
    }
    const datePickerEl = document.getElementById(this.elementId);

    if (!datePickerEl.contains(event.target)) {
      this.setState({
        isDatePickerOpen: false,
      });
    }
  };
  handleEscape = ({ keyCode }) =>
    keyCode === 27 && this.setState({ isDatePickerOpen: false });

  render() {
    const { focusedInput, selectedPreset, startDate, endDate } = this.state;
    const startDateString = startDate && startDate.format(ALT_FORMAT);
    const endDateString = endDate && endDate.format(ALT_FORMAT);

    let dateSelectPlaceholder = selectedPreset ? selectedPreset.label : '';

    if (!selectedPreset && (startDateString && endDateString)) {
      dateSelectPlaceholder = `${startDateString} - ${endDateString}`;
    }
    return (
      <section id={this.elementId} className="DatePicker form-group fl w-20">
        <label htmlFor="date-picker">Date</label>
        <button
          id="date-picker"
          type="button"
          className="DatePicker__button form-control form-control--menu-toggle"
          onClick={() =>
            this.setState({ isDatePickerOpen: !this.state.isDatePickerOpen })
          }
        >
          {dateSelectPlaceholder}
        </button>
        {!!this.state.isDatePickerOpen && (
          <FocusLock>
            <div
              onKeyDown={this.handleEscape}
              role="menu"
              className="DatePicker__container"
            >
              <button
                type="button"
                onClick={() => this.setState({ isDatePickerOpen: false })}
                className="exit-button"
              >
                Close date picker
              </button>
              <div className="form-group">
                <label htmlFor="preset-dates">Preset Dates</label>
                <Select
                  id="preset-dates"
                  controlWidth="100%"
                  menuWidth="100%"
                  items={PRESET_DATE_RANGES}
                  itemToString={item => (item != null ? item['label'] : '')}
                  itemToKey={item => item.value}
                  value={selectedPreset}
                  onChange={this.onDatesChangePreset}
                />
              </div>
              <div className="form-group">
                <label>Date Range</label>
                <DateRangePickerInputController
                  endDate={endDate}
                  isEndDateFocused={focusedInput === 'endDate'}
                  isOutsideRange={this.props.isOutsideRange}
                  isStartDateFocused={focusedInput === 'startDate'}
                  onDatesChange={this.onDatesChange}
                  onFocusChange={this.onFocusChange}
                  startDate={startDate}
                />
              </div>
              <DayPickerRangeController
                key={selectedPreset + startDate + endDate}
                startDateOffset={this.props.startDateOffset}
                endDateOffset={this.props.endDateOffset}
                minDate={this.props.minDate}
                maxDate={this.props.maxDate}
                renderCalendarDay={this.props.renderCalendarDay}
                renderDayContents={this.props.renderDayContents}
                minimumNights={this.props.minimumNights}
                isDayBlocked={this.props.isDayBlocked}
                isDayHighlighted={this.props.isDayHighlighted}
                enableOutsideDays={this.props.enableOutsideDays}
                orientation={this.props.orientation}
                verticalHeight={this.props.verticalHeight}
                withPortal={this.props.withPortal}
                initialVisibleMonth={this.props.initialVisibleMonth}
                numberOfMonths={this.props.numberOfMonths}
                keepOpenOnDateSelect={this.props.keepOpenOnDateSelect}
                renderCalendarInfo={this.props.renderCalendarInfo}
                isRTL={this.props.isRTL}
                renderMonthText={this.props.renderMonthText}
                renderMonthElement={this.props.renderMonthElement}
                navPrev={this.props.navPrev}
                navNext={this.props.navNext}
                monthFormat={this.props.monthFormat}
                onDatesChange={this.onDatesChange}
                onFocusChange={this.onFocusChange}
                focusedInput={focusedInput}
                startDate={startDate}
                endDate={endDate}
              />
            </div>
          </FocusLock>
        )}
      </section>
    );
  }
}

DatePicker.defaultProps = {
  startDateOffset: undefined,
  endDateOffset: undefined,
  minDate: null,
  maxDate: moment().add(1, 'year'),
  // day presentation and interaction related props
  renderCalendarDay: undefined,
  renderDayContents: null,
  minimumNights: 1,
  isDayBlocked: () => false,
  isDayHighlighted: () => false,
  enableOutsideDays: false,
  isOutsideRange: () => false,

  // calendar presentation and interaction related props
  orientation: HORIZONTAL_ORIENTATION,
  verticalHeight: undefined,
  withPortal: false,
  initialVisibleMonth: null,
  numberOfMonths: 1,
  onOutsideClick() {},
  keepOpenOnDateSelect: false,
  renderCalendarInfo: null,
  isRTL: false,
  renderMonthText: null,
  renderMonthElement: null,

  // navigation related props
  navPrev: null,
  navNext: null,
  onPrevMonthClick() {},
  onNextMonthClick() {},

  // internationalization
  monthFormat: 'MMMM YYYY',
};

export default DatePicker;
