import React, { useEffect, useReducer, useState } from 'react';
import { arrayOf, number, shape, string, oneOf } from 'prop-types';
import { Link } from 'react-router-dom';
import { getToken } from '@pwr/auth-provider';
import { useQuery } from 'react-query';
import { REPORT_BOOKMARK } from 'api/endpoints';
import apiFetch from 'api/fetch';
import {
  AlertIcon,
  BoxWithOutboundArrowIcon,
  PencilAndPaperIcon,
  FilesIcon,
  CopiesIcon,
  TrashIcon,
} from 'icons/Icons';
import { Spinner } from 'components/Loaders/Spinner';
import { Table, TableRow, TableCell, tableUtils } from 'components/Table';
import { TooltipButton } from 'components/TooltipButton';
import Modal from 'components/Modal';
import ModalCard from 'components/Modal/ModalCard';
import { SaveReportForm } from 'components/SaveReportForm/SaveReportForm';
import { createSavedReport } from 'components/SubscriptionForm/SubscriptionUtils';
import { useEditSavedReport } from './EditSavedReport';

const ErrorState = () => {
  return (
    <div
      style={{ minHeight: 400 }}
      className="py-8 px-12 flex justify-center items-center"
    >
      <div className="w-50">
        <AlertIcon width={100} fill="rgb(85, 85, 85)" />
        <p className="text-left my-6">
          We couldn't fetch your saved reports. Please try again in a few
          moments
        </p>
      </div>
    </div>
  );
};

const LoadingState = () => {
  return (
    <div
      style={{ minHeight: 400 }}
      className="py-8 px-12 flex justify-center items-center"
    >
      <div>
        <Spinner />
        <p className="text-gray-35 mt-8">Fetching reports</p>
      </div>
    </div>
  );
};

const EmptyState = ({ tabId }) => {
  return (
    <div
      style={{ minHeight: 400 }}
      className="py-8 px-12 flex justify-center items-center"
    >
      <div className="w-50 flex flex-col items-center">
        <FilesIcon fill="white" height={100} />
        <h1 className="my-2 text-gray-35">No reports found!</h1>
        <p className="text-left my-6">
          {tabId === 'shared-with-me'
            ? `Did you know that you can save and share reports? Reports shared with you will be here`
            : `Do you have a report that is important to you? Save the report with the
        filters and views you have set. Just click the "Save & Share" button
        in the top right menu.`}
        </p>
        <Link className="btn btn--primary" to="/">
          Check out some reports!
        </Link>
      </div>
    </div>
  );
};
EmptyState.propTypes = {
  tabId: oneOf(['my-reports', 'shared-with-me']),
};

const ACTION_TYPES = {
  CLOSE_MODAL: 'CLOSE_MODAL',
  OPEN_COPY_DIALOG: 'OPEN_COPY_DIALOG',
  OPEN_DELETE_DIALOG: 'OPEN_DELETE_DIALOG',
};
const ResultsState = ({
  merchantGroupIds,
  profiles,
  savedReports,
  setSavedReports,
  tabId,
}) => {
  const initialState = {
    modalIsOpen: false,
    modalContent: null,
    reportBookmark: {},
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const closeModal = () => dispatch({ type: ACTION_TYPES.CLOSE_MODAL });

  const userIdNameDict = profiles.reduce(
    (dictionary, { profileId, userValue }) => {
      dictionary[profileId] = userValue;
      return dictionary;
    },
    {}
  );
  const sortFns = {
    name: (...args) => tableUtils.sortFns.compareText(...args, 'name'),
    createdTime: (...args) =>
      tableUtils.sortFns.compareDateStrings(...args, 'createdTime'),
    updatedTime: (...args) =>
      tableUtils.sortFns.compareDateStrings(...args, 'updatedTime'),
    reportComponentGroup: (a, b) => {
      const reportName1 = a.name;
      const reportName2 = b.name;
      return reportName1.localeCompare(reportName2);
    },
    reportBookmarkShares: (a, b) => {
      const shareObj1 = a.reportBookmarkShares[0] || {};
      const shareObj2 = b.reportBookmarkShares[0] || {};
      const profileName1 = userIdNameDict[shareObj1.sharedWith] || 'one person';
      const profileName2 = userIdNameDict[shareObj2.sharedWith] || 'one person';
      return profileName1.localeCompare(profileName2);
    },
  };
  const userOwnsSavedReport = tabId === 'my-reports';
  const tableHeaders = [
    {
      key: 'name',
      label: 'Name',
    },
    {
      key: 'reportComponentGroup',
      label: 'Report',
    },
    {
      key: 'createdTime',
      label: 'Created',
    },
    {
      key: 'updatedTime',
      label: 'Updated',
    },
    ...(userOwnsSavedReport
      ? [
          {
            key: 'reportBookmarkShares',
            label: 'Shared With',
          },
        ]
      : []),
  ];
  const [activeRow, setActiveRow] = useState(null);
  const renderTableBody = tableData => {
    return tableData.map((rowObj, index) => {
      const isActive = activeRow === rowObj.id;
      return (
        <TableRow
          isActive={isActive}
          onFocus={() => setActiveRow(rowObj.id)}
          onMouseEnter={() => setActiveRow(rowObj.id)}
          onMouseLeave={() => setActiveRow(null)}
          onBlur={() => setActiveRow(null)}
          key={rowObj.id}
        >
          {tableHeaders.map(({ key, label }) => {
            const reactKey = rowObj.id + key;
            const value = rowObj[key];
            if (key === 'name') {
              return (
                <TableCell key={reactKey} isTranslationEnabled={false}>
                  <Link to={`${tabId}/${rowObj.id}`}>{value}</Link>
                </TableCell>
              );
            }
            if (key === 'createdTime') {
              const formattedCreatedTime = tableUtils.formatFns.formatMomentObj(
                value
              );
              return (
                <TableCell
                  key={reactKey}
                  isTranslationEnabled={
                    formattedCreatedTime !== 'Invalid date' && false
                  }
                >
                  {formattedCreatedTime}
                </TableCell>
              );
            }
            if (key === 'updatedTime') {
              const { createdByProfileId, updateByProfileId } = rowObj;
              const updatedByAnotherUser =
                createdByProfileId !== updateByProfileId;
              return (
                <TableCell key={reactKey} isTranslationEnabled={false}>
                  {tableUtils.formatFns.formatMomentObj(value)}{' '}
                  {updatedByAnotherUser ? `by ${updateByProfileId}` : ''}
                </TableCell>
              );
            }
            if (key === 'reportBookmarkShares') {
              const { reportBookmarkShares = [] } = rowObj;
              let reportShareLabel;
              if (reportBookmarkShares.length === 0) {
                reportShareLabel = 'Not shared';
              } else if (reportBookmarkShares.length === 1) {
                reportShareLabel = 'One Person';
              } else {
                reportShareLabel = `Shared with ${
                  reportBookmarkShares.length
                } people`;
              }
              return (
                <td className="text-left" key={reactKey}>
                  {reportShareLabel}
                </td>
              );
            }
            if (key === 'reportComponentGroup') {
              return <TableCell key={reactKey}>{value.name}</TableCell>;
            }
            return <TableCell key={reactKey}>{value}</TableCell>;
          })}
          <TableCell hidden={!isActive}>
            <Link
              style={{ color: 'black' }}
              to={`/saved-reports/${tabId}/${rowObj.id}`}
              aria-label={`${userOwnsSavedReport ? 'Edit' : 'View'} ${
                rowObj.name
              }`}
            >
              <TooltipButton
                tooltipOrigin="top"
                tooltipText={userOwnsSavedReport ? 'Edit' : 'View'}
              >
                {userOwnsSavedReport ? (
                  <PencilAndPaperIcon />
                ) : (
                  <BoxWithOutboundArrowIcon />
                )}
              </TooltipButton>
            </Link>
            <button
              aria-label={`Copy ${rowObj.name}`}
              onClick={() => {
                dispatch({
                  type: ACTION_TYPES.OPEN_COPY_DIALOG,
                  payload: {
                    reportBookmark: rowObj,
                  },
                });
              }}
            >
              <TooltipButton tooltipOrigin="top" tooltipText="Copy">
                <CopiesIcon />
              </TooltipButton>
            </button>
            {userOwnsSavedReport && (
              <button
                aria-label={`Delete ${rowObj.name}`}
                onClick={() => {
                  dispatch({
                    type: ACTION_TYPES.OPEN_DELETE_DIALOG,
                    payload: {
                      reportBookmark: rowObj,
                    },
                  });
                }}
              >
                <TooltipButton tooltipOrigin="top" tooltipText={`Delete`}>
                  <TrashIcon />
                </TooltipButton>
              </button>
            )}
          </TableCell>
        </TableRow>
      );
    });
  };
  const CopyReportDialog = ({ isActive, handleClose }) => {
    const {
      isLoading,
      error,
      savedFilterState,
      reportComponentGroups,
      reportParameters,
    } = useEditSavedReport(state.reportBookmark.id);
    if (!isActive) return null;
    if (error) {
      return (
        <div className="my-4">
          <div className="flex items-center">
            <AlertIcon width={50} fill="rgb(85, 85, 85)" />
            <h3 className="ml-2">Something went wrong</h3>
          </div>
          <p className="py-4">
            Failed to fetch report data for:{' '}
            <strong data-translate={false}>{state.reportBookmark.name}</strong>.
            Please try again in a few moments.
          </p>
          <button type="button" onClick={handleClose} className="btn">
            Close
          </button>
        </div>
      );
    }
    const merchantGroupIds = savedFilterState
      ? savedFilterState.merchant_group_list
      : null;
    return (
      <SaveReportForm
        isLoading={isLoading || !merchantGroupIds}
        description="You can create a separate copy of this report for yourself"
        isOpen={isActive}
        merchantGroupIds={merchantGroupIds}
        name={state.reportBookmark.name + ' copy'}
        onSubmit={async formInput => {
          try {
            const copiedReport = await createSavedReport({
              filterState: savedFilterState,
              formInput,
              merchantGroupIds: merchantGroupIds,
              merchantIds: savedFilterState.merchant_group_list || null,
              reportComponentGroups,
              reportParameters,
            });
            if (userOwnsSavedReport) {
              setSavedReports([...savedReports, copiedReport]);
            }
            handleClose();
          } catch (error) {
            console.error(error);
          }
        }}
        reportBookmarkShares={[]}
        setIsOpen={handleClose}
        title="Save a copy of this report"
      />
    );
  };
  const DeleteReportDialog = ({
    handleClose,
    handleDelete,
    isActive,
    reportBookmark,
  }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [deleteResponse, setDeleteResponse] = useState(null);
    const [deleteError, setDeleteError] = useState(null);
    if (!isActive) return null;

    const onDelete = async () => {
      setIsLoading(true);
      setDeleteError(null);
      try {
        await handleDelete(reportBookmark);
        setDeleteResponse(true);
        setSavedReports(
          savedReports.filter(({ id }) => id !== reportBookmark.id)
        );
        handleClose();
      } catch (error) {
        console.error(error);
        setDeleteError(error);
      } finally {
        setIsLoading(false);
      }
    };
    return (
      <div className="flex flex-col justify-center py-4 px-4">
        <h3>Delete this saved report</h3>
        <p role="alert" aria-live="polite" className="py-4">
          {deleteError && (
            <>
              Failed to delete:{' '}
              <strong data-translate={false}>{reportBookmark.name}</strong>;
              please try again
            </>
          )}
          {deleteResponse && (
            <>
              Successfully deleted:{' '}
              <strong data-translate={false}>{reportBookmark.name}</strong>
            </>
          )}
          {!deleteError && !deleteResponse && (
            <>
              You are about to delete:{' '}
              <strong data-translate={false}>{reportBookmark.name}</strong>
            </>
          )}
        </p>

        <div>
          <button
            disabled={isLoading}
            onClick={handleClose}
            className="btn mr-2"
          >
            Cancel
          </button>
          <button
            disabled={isLoading}
            onClick={onDelete}
            className="btn btn--negative"
          >
            Delete
          </button>
        </div>
      </div>
    );
  };
  return (
    <>
      <Modal
        closeOnBackgroundClick
        handleClose={closeModal}
        open={state.modalIsOpen}
      >
        <ModalCard
          padding="1rem"
          handleClose={closeModal}
          isTranslationEnabled={true}
        >
          <CopyReportDialog
            isActive={state.modalContent === ACTION_TYPES.OPEN_COPY_DIALOG}
            isCopying={false}
            handleClose={closeModal}
            reportBookmark={state.reportBookmark}
          />
          <DeleteReportDialog
            isActive={state.modalContent === ACTION_TYPES.OPEN_DELETE_DIALOG}
            isDeleting={false}
            handleClose={closeModal}
            handleDelete={deleteReportBookmark}
            reportBookmark={state.reportBookmark}
          />
        </ModalCard>
      </Modal>
      <div className="py-8 px-12">
        <div className="w-100">
          <Table
            tableData={savedReports}
            tableHeaders={tableHeaders}
            paginationOptions={{ rowsPerPage: 25 }}
            searchOptions={{
              keys: ['name'],
              placeholder: `Search ${savedReports.length} item${
                savedReports.length === 1 ? '' : 's'
              }`,
            }}
            sortFns={sortFns}
            renderTableBody={renderTableBody}
          />
        </div>
      </div>
    </>
  );
};

ResultsState.propTypes = {
  savedReports: arrayOf(
    shape({
      name: string.isRequired,
      id: number.isRequired,
      createdTime: string.isRequired,
      updatedTime: string.isRequired,
      reportBookmarkShares: arrayOf(
        // Empty array if never shared
        shape({
          sharedWith: number,
        })
      ),
    })
  ).isRequired,
};

export function SavedReportSearchList({
  endpoint,
  merchantGroupIds,
  profiles,
  tabId,
  isActive,
}) {
  const { data, error } = useQuery(
    isActive && ['fetch_saved_reports', { endpoint }],
    () => fetchSavedReports(endpoint)
  );
  const [savedReports, setSavedReports] = useState([]);
  useEffect(() => {
    if (!data) return;
    setSavedReports(data);
  }, [data]);
  if (error !== null) return <ErrorState />;
  if (!data) return <LoadingState />;
  if (savedReports.length === 0) return <EmptyState tabId={tabId} />;

  return (
    <ResultsState
      merchantGroupIds={merchantGroupIds}
      profiles={profiles}
      savedReports={savedReports}
      setSavedReports={setSavedReports}
      tabId={tabId}
    />
  );
}

SavedReportSearchList.propTypes = {
  endpoint: string.isRequired,
  profiles: arrayOf(
    shape({
      profileId: number,
      userValue: string,
    })
  ),
};

function reducer(state, action) {
  switch (action.type) {
    case ACTION_TYPES.CLOSE_MODAL:
      return {
        ...state,
        modalIsOpen: false,
      };
    case ACTION_TYPES.OPEN_COPY_DIALOG:
      return {
        ...state,
        modalIsOpen: true,
        modalContent: ACTION_TYPES.OPEN_COPY_DIALOG,
        reportBookmark: action.payload.reportBookmark,
      };
    case ACTION_TYPES.OPEN_DELETE_DIALOG:
      return {
        ...state,
        modalIsOpen: true,
        modalContent: ACTION_TYPES.OPEN_DELETE_DIALOG,
        reportBookmark: action.payload.reportBookmark,
      };
    default:
      throw new Error(`${action.type} is not a defined action type`);
  }
}

function deleteReportBookmark(reportBookmark) {
  const url = REPORT_BOOKMARK + `/${reportBookmark.id}`;
  const options = {
    headers: {
      Authorization: getToken(),
      'Content-type': 'application/json',
    },
    method: 'DELETE',
  };
  return apiFetch(url, options);
}

async function fetchSavedReports(endpoint) {
  const MAX_NUM_REPORTS_PER_PAGE = 20;
  const options = {
    headers: {
      Authorization: getToken(),
      'Content-type': 'application/json',
    },
  };
  const baseUrl = `${REPORT_BOOKMARK}/search/${endpoint}?projection=partialReportBookmarkProjection`;

  const fetchFirstPageOfResults = await apiFetch(
    baseUrl + `&page=0&size=20`,
    options
  ).then(res => res.json());
  const totalNumberOfReports = fetchFirstPageOfResults.page.totalElements;
  if (totalNumberOfReports <= MAX_NUM_REPORTS_PER_PAGE) {
    return fetchFirstPageOfResults._embedded.reportBookmark;
  }

  const fetchAllElements = await apiFetch(
    baseUrl + `&page=0&size=${totalNumberOfReports}`,
    options
  ).then(res => res.json());
  return fetchAllElements._embedded.reportBookmark;
}
