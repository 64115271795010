export function visualSocialReportPageProps() {
  const reports = [
    {
      reportIdentifier: 'vs_workbook',
      type: 'tableau_workbook',
      workbookTabs: [
        {
          tableauIframeHeight: 1110,
          tableauTabUri: 'Display',
          tableauWorksheetName: 'Display',
          title: 'Display',
          urlFragment: 'display',
        },
        {
          tableauIframeHeight: 1300,
          tableauTabUri: 'Collect',
          tableauWorksheetName: 'Collect',
          title: 'Collect',
          urlFragment: 'collect',
        },
        {
          tableauIframeHeight: 1300,
          tableauTabUri: 'Images',
          tableauWorksheetName: 'Images',
          title: 'Images',
          urlFragment: 'images',
        },
        // {
        //   tableauTabUri: 'SocialCollection',
        //   tableauWorksheetName: 'Social Collection',
        //   title: 'Social Collection',
        //   urlFragment: 'social-collection',
        // },
      ],
    },
    {
      reportIdentifier: 'all_media',
      urlFragment: 'media',
      title: 'Media',
      type: 'columnar',
    },
  ];
  const DEFAULT_TAB = reports[0].workbookTabs[0].urlFragment;
  const PAGE_URL = '/visual-social';
  const PAGE_TITLE = 'Visual & Social';

  const navigationProps = {
    crumbs: [
      {
        label: 'Analytics',
        href: `/`,
      },
      {
        label: PAGE_TITLE,
        href: PAGE_URL,
      },
    ],
    activePrimary: 'Analytics',
    activeSecondary: PAGE_TITLE,
  };

  return {
    defaultTab: DEFAULT_TAB,
    navigationProps,
    pageUrl: PAGE_URL,
    reports,
  };
}
