import React from 'react';
import { arrayOf, element, string } from 'prop-types';

const FILTERS_PER_ROW = 4;
export default function FilterRows({ buttons, filters, id }) {
  const numberOfFilters = filters.length;
  const filterWidth = 245;
  const filterGridWidth =
    numberOfFilters >= 4 ? '100%' : filterWidth * numberOfFilters;
  const filterRows = [];
  for (let i = 0; i < filters.length; i += FILTERS_PER_ROW) {
    const row = filters.slice(i, i + FILTERS_PER_ROW);
    filterRows.push(row);
  }

  return (
    <div className="flex items-start">
      <div style={{ maxWidth: filterGridWidth, width: '100%' }}>
        {filterRows.map((row, index) => {
          return (
            <div key={id + index + row.key} className="cf ph2-ns ">
              {row.map(rowItem =>
                React.cloneElement(rowItem, { key: rowItem.key })
              )}
            </div>
          );
        })}
      </div>
      {filters.length > 0 && buttons}
    </div>
  );
}

FilterRows.propTypes = {
  buttons: element.isRequired,
  filters: arrayOf(element).isRequired,
  id: string.isRequired,
};
