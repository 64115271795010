import React, { Component } from 'react';
import { Select } from '@pwr-ui/select';
import { bool, func, string, shape, oneOf } from 'prop-types';
import { exportTypeSelectItems, delimiterSelectItems } from '../constants';
import styles from './inputs.module.scss';

const tableauFileTypeOptions = [
  {
    label: 'PDF',
    value: 'pdf',
  },
  {
    label: 'Image',
    value: 'png',
  },
];
export class ExportOptions extends Component {
  static propTypes = {
    currentValue: shape({
      delimiter: string,
      exportType: string,
    }),
    hasError: bool,
    name: string,
    onChange: func.isRequired,
    reportType: oneOf(['SNOWFLAKE', 'TABLEAU']).isRequired,
  };

  handleChange = (inputName, selection) => {
    const { currentValue, name, onChange } = this.props;
    const { delimiter, exportType } = currentValue;
    onChange({
      inputName: name,
      inputValue: {
        delimiter,
        exportType,
        ...{ [inputName]: selection.value },
      },
    });
  };

  render() {
    const { currentValue, reportType } = this.props;

    const onChange = (name, selection) => {
      if (!selection) return;
      this.handleChange(name, selection);
    };
    if (reportType === 'TABLEAU') {
      const selectedFileType =
        tableauFileTypeOptions
          .filter(item => {
            return item.value === currentValue['exportType'];
          })
          .pop() || tableauFileTypeOptions[0];
      return (
        <div className="form-group">
          <label htmlFor="tableau-subscription-formats">
            <span className="text-red">*</span>Report file type
          </label>

          <Select
            id="tableau-subscription-formats"
            items={tableauFileTypeOptions}
            itemToKey={item => item.value}
            itemToString={item => (item !== null ? item['label'] : '')}
            onChange={selection => onChange('exportType', selection)}
            value={selectedFileType}
          />
        </div>
      );
    }

    const showDelimiterSelect = currentValue.exportType !== 'xls';
    const selectedDelimiter = delimiterSelectItems.filter(item => {
      return item.value === currentValue['delimiter'];
    })[0];
    const selectedExportType = exportTypeSelectItems.filter(item => {
      return item.value === currentValue['exportType'];
    })[0];
    return (
      <div className={styles.FormInput}>
        <label htmlFor="export-file-types">Export file type</label>
        <Select
          id="export-file-types"
          items={exportTypeSelectItems || []}
          itemToKey={item => item.value}
          itemToString={item => (item != null ? item['label'] : '')}
          onChange={selection => onChange('exportType', selection)}
          value={selectedExportType}
        />
        {showDelimiterSelect && (
          <div className="mt-4">
            <label htmlFor="csv-delimiter-options">
              Choose a CSV delimiter
            </label>
            <Select
              id="csv-delimiter-options"
              items={delimiterSelectItems || []}
              itemToKey={item => item.value}
              itemToString={item => (item != null ? item['label'] : '')}
              onChange={selection => onChange('delimiter', selection)}
              value={selectedDelimiter}
            />
          </div>
        )}
      </div>
    );
  }
}
