import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { getToken } from '@pwr/auth-provider';
import { Link, Prompt, Redirect } from 'react-router-dom';
import deepEquals from 'lodash.isequal';
import { REPORT_BOOKMARK } from 'api/endpoints';
import {
  ReportContentColumnar,
  ReportContentTableau,
} from 'containers/ReportContent';
import apiFetch from 'api/fetch';
import { AlertIcon } from 'icons/Icons';
import { Spinner } from 'components/Loaders';
import { useAllCustomReports } from 'pages/EditSubscription';
import { mergeReports } from 'pages/DataExplorer/DataExplorerUtils';
import TableBuilder from 'containers/ReportContent/TableColumnBuilder';
import { updateReportBookmarkParameters } from '../EditSubscription/EditSubscriptionUtils';
import { ConfigContext } from 'contexts/ConfigContext';
import MerchantDropDown from 'components/MerchantDropDown';
import Growler from 'components/Growler';
import { filterStateToReportingServicesFormat } from 'utils/parameter-formatting';

const savedReportNotFound = error => error && error.status === 404;

const LoadingState = ({ reportBookmarkId }) => {
  return (
    <div
      style={{
        minHeight: 400,
      }}
      className="bg-white py-8 px-12 flex justify-center items-center shadow pt-4"
    >
      <div>
        <Spinner />
        <p className="text-gray-35 mt-8">
          Loading saved report {reportBookmarkId}
        </p>
      </div>
    </div>
  );
};

const DeprecatedReportNotice = ({ bookmarkName, reportName }) => {
  return (
    <div
      style={{
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 2px 2px 0px',
        minHeight: 400,
      }}
      className="bg-white flex flex-column items-center justify-center py-12 mt-4"
    >
      <AlertIcon width={150} fill="rgb(85, 85, 85)" />
      <div className="text-left w-50 flex flex-column justify-center items-center">
        <h2 className="mt-4">This bookmark is deprecated!</h2>
        <p className="mt-4 lh-copy">
          Your saved report: <strong>{bookmarkName}</strong> is pointing to the{' '}
          <strong>{reportName}</strong> report which is scheduled for removal in
          November. This saved report should be recreated with one of the new
          reporting pages in order to ensure uninterrupted service. Please
          delete <strong>{bookmarkName}</strong> and save a new report or reach
          out to Client Success for support.
        </p>
        <div className="flex">
          <Link className="btn btn--primary mt-8" to="/saved-reports">
            Go back to your saved reports
          </Link>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://help.powerreviews.com/Content/Contact%20Us.htm"
            className="btn btn--secondary mt-8 ml-4"
            to="/subscriptions"
          >
            Contact support
          </a>
        </div>
      </div>
    </div>
  );
};

const ErrorState = () => {
  return (
    <div
      style={{
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 2px 2px 0px',
        minHeight: 400,
      }}
      className="bg-white flex flex-column items-center justify-center py-12 mt-4"
    >
      <p>
        Something went wrong when loading your saved report. Please try again in
        a few moments
      </p>
    </div>
  );
};
export function EditSavedReport({
  allReportPageProps,
  isAdmin,
  nonCustomReports,
  onMerchantSelect,
  reportBookmarkId,
  selectedMerchantGroupsByIds,
  selectedMerchantsByIds,
  selectedWorkbookTab,
  setGrowlerProps,
  setLastBreadcrumb,
  setMerchantGroupPickerPositionedOverReport = () => {},
  setReportBookmark = () => {},
  setSelectedMerchantGroupsByIds,
  setTableauCustomViewUrl,
  tabId,
  userid,
  workbookTabs,
}) {
  const [filterState, setFilterState] = useState(null);
  const [lastSavedFilterState, setLastSavedFilterState] = useState({});
  const currentMerchantGroupList = mapToKeyString(selectedMerchantGroupsByIds);
  const [sentTableBuilder, setSentTableBuilder] = useState(false);

  const [
    userTouchedMerchantDropdown,
    setUserTouchedMerchantDropdown,
  ] = useState(false);

  const [
    currentMerchantGroupsMatchReport,
    setCurrentMerchantGroupsMatchReport,
  ] = useState(false);
  useEffect(() => {
    if (!filterState || currentMerchantGroupsMatchReport) return;
    const { merchant_group_list } = filterState;

    if (!merchant_group_list) return;

    if (merchant_group_list === currentMerchantGroupList) return;

    if (currentMerchantGroupsMatchReport) return;

    setSelectedMerchantGroupsByIds(merchant_group_list);
    setCurrentMerchantGroupsMatchReport(true);
  }, [
    filterState,
    setSelectedMerchantGroupsByIds,
    currentMerchantGroupList,
    currentMerchantGroupsMatchReport,
  ]);

  const [savingSubscriptionChanges, setSavingSubscriptionChanges] = useState(
    false
  );

  const [dataExplorerGrowlerProps, setDataExplorerGrowlerProps] = useState(
    null
  );

  const {
    allCustomReports,
    error,
    isLoading,
    reportBookmark,
    savedFilterState,
  } = useEditSavedReport(reportBookmarkId);

  const { replace } = useHistory();

  if (isLoading) {
    return <LoadingState reportBookmarkId={reportBookmarkId} />;
  }

  if (error) return <ErrorState />;

  if (savedReportNotFound(error)) {
    return <Redirect to={`/saved-reports/${tabId}`} />;
  }
  const customAndNonCustomReports = mergeReports([
    nonCustomReports,
    allCustomReports,
  ]);
  const {
    name: bookmarkName,
    reportComponentGroup: { id, exportEngine },
    reportBookmarkParameters,
  } = reportBookmark;

  const reportTemplate = getReportTemplate(customAndNonCustomReports, id);
  if (isDeprecatedReport(reportTemplate)) {
    return (
      <DeprecatedReportNotice
        bookmarkName={bookmarkName}
        reportName={reportTemplate.name}
      />
    );
  }
  const tableBuilder = new TableBuilder(
    customAndNonCustomReports,
    reportTemplate.reportIdentifier
  );

  if (!sentTableBuilder && tableBuilder) {
    setSentTableBuilder(true);
    setReportBookmark(tableBuilder);
  }

  if (!filterState) {
    setFilterState(savedFilterState);
    setLastSavedFilterState(savedFilterState);
  }
  const reportBookmarkMerchantData = {
    merchantGroupIds: savedFilterState.merchant_group_list,
    merchantIds: savedFilterState.merchant_list,
  };

  const currentMerchantListString = userTouchedMerchantDropdown
    ? mapToKeyString(selectedMerchantsByIds)
    : lastSavedFilterState.merchant_list;

  const handleEditReport = async () => {
    setSavingSubscriptionChanges(true);
    const paramIdToName = tableBuilder.reportComponentParameters.reduce(
      (accumulator, { id, identifier }) => ({
        ...accumulator,
        [id]: identifier,
      }),
      {}
    );
    const rbpsWithIdentifier = reportBookmarkParameters.map(param => ({
      ...param,
      identifier: paramIdToName[param.reportParameter.id],
    }));
    try {
      await updateReportBookmarkParameters(rbpsWithIdentifier, {
        ...filterStateToReportingServicesFormat(
          filterState,
          tableBuilder.reportComponentParameters
        ),
        merchant_list: currentMerchantListString,
        merchant_group_list: currentMerchantGroupList,
      });
      setGrowlerProps({
        type: 'success',
        title: 'Report bookmark updated',
        children: (
          <p>
            You've successfully updated <strong>{reportBookmark.name}</strong>
          </p>
        ),
        dismissible: true,
        onDismiss: () => setGrowlerProps(null),
      });
      const filterStateWithMerchantList = {
        ...filterState,
        merchant_list: currentMerchantListString,
        merchant_group_list: currentMerchantGroupList,
      };
      setLastSavedFilterState(filterStateWithMerchantList);
      setUserTouchedMerchantDropdown(false);
    } catch (error) {
      setGrowlerProps({
        type: 'alert',
        title: 'Error',
        children:
          'Something went wrong while updating your bookmark. Please try again in a few moments',
        dismissible: true,
        onDismiss: () => setGrowlerProps(null),
      });
    } finally {
      setSavingSubscriptionChanges(false);
    }
  };

  const filterStateChanged = (filterState1, filterState2) => {
    if (!filterState1 || !filterState2) return false;
    return !deepEquals(
      filterStateToReportingServicesFormat(
        filterState1,
        tableBuilder.reportComponentParameters
      ),
      filterStateToReportingServicesFormat(
        filterState2,
        tableBuilder.reportComponentParameters
      )
    );
  };

  const userOwnsSavedReport = userid === reportBookmark.createdByProfileId;
  const savedReportsUrl = '/saved-reports';
  const breadcrumbs = [
    { label: 'Saved Reports', href: savedReportsUrl },
    {
      label: userOwnsSavedReport ? 'My Reports' : 'Shared with Me',
      href: `${savedReportsUrl}${
        userOwnsSavedReport ? '/my-reports' : '/shared-with-me'
      }`,
    },
  ];

  if (tableBuilder && allReportPageProps) {
    const pageProps = allReportPageProps.find(pageProps => {
      return pageProps.reports.find(
        r => r.reportIdentifier === tableBuilder.reportIdentifier
      );
    });

    if (pageProps) {
      // If there are no page props, then this report doesn't
      // live in a report page (i.e., Ratings & Reviews or Q&A)
      // but is an isolated report in Data Explorer
      replace(`${pageProps.pageUrl}/saved-reports/${reportBookmark.id}`);
    }
  }

  const userHasUnsavedChanges = (() => {
    if (userTouchedMerchantDropdown) return true;

    const previous = lastSavedFilterState;
    const current = {
      ...filterState,
      // Because the merchant filter is at the global level it's treated a
      // bit differently than the other filters that live nearby the report.
      // `merchant_list` is missing from filter state, so we add it just
      // to make sure that we can accurately toggle the "Save Changes" button
      merchant_list: currentMerchantListString,
      merchant_group_list: currentMerchantGroupList,
    };
    return filterStateChanged(previous, current);
  })();

  setMerchantGroupPickerPositionedOverReport(
    <div className="flex justify-between">
      {tableBuilder.reportType !== 'TABLEAU' && (
        <div>
          <MerchantDropDown
            initialMerchantIds={reportBookmarkMerchantData.merchantIds}
            selectedMerchantsByIds={new Map(selectedMerchantsByIds.entries())}
            selectedMerchantGroupsByIds={
              new Map(selectedMerchantGroupsByIds.entries())
            }
            onMerchantSelect={mapOfSelectedMerchants => {
              setUserTouchedMerchantDropdown(true);
              onMerchantSelect(mapOfSelectedMerchants);
            }}
          />
        </div>
      )}
    </div>
  );

  const canEditReport =
    userOwnsSavedReport && userHasUnsavedChanges && !savingSubscriptionChanges;
  return (
    <>
      {dataExplorerGrowlerProps && (
        <div className="mb-2">
          <Growler
            dismissible
            onDismiss={() => setGrowlerProps(null)}
            {...dataExplorerGrowlerProps}
          >
            {dataExplorerGrowlerProps.children}
          </Growler>
        </div>
      )}
      <div
        style={{
          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 2px 2px 0px',
          minHeight: 400,
        }}
        className="bg-white"
      >
        <Prompt
          when={userOwnsSavedReport && userHasUnsavedChanges}
          message="You have unsaved changes, are you sure you want to leave?"
        />

        {exportEngine === 'SNOWFLAKE' && (
          <ReportContentColumnar
            canEditReport={canEditReport}
            initialFilterState={savedFilterState}
            isActive
            merchantGroupIds={reportBookmarkMerchantData.merchantGroupIds}
            merchantIds={currentMerchantListString}
            onEditReport={handleEditReport}
            reportBookmark={reportBookmark}
            reportDefinition={tableBuilder}
            reportIdentifier={tableBuilder.reportIdentifier}
            setActiveReportFilterState={setFilterState}
            setLastBreadcrumb={finalBreadcrumbObj => {
              setLastBreadcrumb([...breadcrumbs, finalBreadcrumbObj]);
            }}
            setGrowlerProps={setGrowlerProps || setDataExplorerGrowlerProps}
            useUrlParams={false}
            userOwnsSavedReport={userOwnsSavedReport}
          />
        )}
        {exportEngine === 'TABLEAU' && (
          <ReportContentTableau
            canEditReport={userHasUnsavedChanges && !savingSubscriptionChanges}
            initialFilterState={savedFilterState}
            isActive
            isAdmin={isAdmin}
            merchantGroupIds={reportBookmarkMerchantData.merchantGroupIds}
            merchantIds={currentMerchantListString}
            onReportBookmarkUpdate={reportBookmark => {}}
            reportBookmark={reportBookmark}
            reportDefinition={tableBuilder}
            reportIdentifier={tableBuilder.reportIdentifier}
            setGrowlerProps={setGrowlerProps}
            setLastBreadcrumb={finalBreadcrumbObj => {
              setLastBreadcrumb([...breadcrumbs, finalBreadcrumbObj]);
            }}
            selectedWorkbookTab={selectedWorkbookTab}
            setTableauCustomViewUrl={setTableauCustomViewUrl}
            workbookTabs={workbookTabs}
            userOwnsSavedReport={userOwnsSavedReport}
          />
        )}
      </div>
    </>
  );
}

EditSavedReport.propTypes = {
  selectedMerchantGroupsByIds: PropTypes.instanceOf(Map).isRequired,
  selectedMerchantsByIds: PropTypes.instanceOf(Map).isRequired,
  nonCustomReports: PropTypes.object,
  reportBookmarkId: PropTypes.string.isRequired,
};

export function useEditSavedReport(reportBookmarkId) {
  const { nonCustomReports } = useContext(ConfigContext);
  const {
    error: fetchAllCustomError,
    isLoading: fetchAllCustomIsLoading,
    response: allCustomReports,
  } = useAllCustomReports();
  const {
    error: fetchReportBookmarkError,
    isLoading: fetchReportBookmarkIsLoading,
    response: reportBookmark,
  } = useReportBookmark(reportBookmarkId);
  const [savedFilterState, setSavedFilterState] = useState(null);
  const [tableBuilder, setTableBuilder] = useState(null);
  useEffect(() => {
    if (!reportBookmark || !allCustomReports) return;
    if (savedFilterState && tableBuilder) return;
    const {
      reportComponentGroup: { id },
    } = reportBookmark;
    const customAndNonCustomReports = mergeReports([
      nonCustomReports,
      allCustomReports,
    ]);

    const reportTemplate = getReportTemplate(customAndNonCustomReports, id);
    const _tableBuilder = new TableBuilder(
      customAndNonCustomReports,
      reportTemplate.reportIdentifier
    );
    setTableBuilder(_tableBuilder);
    const _savedFilterState = {
      // When a user creates a report in legacy without altering
      // the order or choice of columns, reporting-services doesn't
      // explicitly save them; instead, the frontend needs to assume
      // that the report uses the default columns defined in page
      // creator (which can change at any time). If we have an explict
      // `table_columns` it'll override the default one here
      table_columns: _tableBuilder.reportComponentColumnsDefault
        .map(column => column.columnName)
        .join(','),
      ...getFilterState(
        reportTemplate,
        reportBookmark.reportBookmarkParameters
      ),
    };
    setSavedFilterState(_savedFilterState);
  }, [
    reportBookmark,
    allCustomReports,
    nonCustomReports,
    savedFilterState,
    tableBuilder,
  ]);

  return {
    reportBookmark,
    allCustomReports,
    isLoading:
      !savedFilterState ||
      fetchReportBookmarkIsLoading ||
      fetchAllCustomIsLoading,
    error: fetchAllCustomError || fetchReportBookmarkError,
    savedFilterState,
    reportComponentGroups: tableBuilder
      ? tableBuilder.reportComponentGroups[0]
      : null,
    reportParameters: tableBuilder
      ? tableBuilder.reportComponentParameters
      : null,
  };
}

function useReportBookmark(reportId) {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!reportId) return;
    let current = true;

    const fetchReportId = async () => {
      setIsLoading(true);
      const options = {
        headers: {
          Authorization: getToken(),
          'Content-type': 'application/json',
        },
      };
      const url =
        REPORT_BOOKMARK +
        `/${reportId}/` +
        '?projection=completeReportBookmarkProjection';
      try {
        const reportBookmark = await apiFetch(url, options).then(res =>
          res.json()
        );
        setResponse(reportBookmark);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };
    current && fetchReportId();
    return () => (current = false);
  }, [reportId]);
  return {
    response,
    error,
    isLoading,
  };
}

function isDeprecatedReport(reportTemplate) {
  return reportTemplate.reportComponentGroups.length > 1;
}

function getReportTemplate(allReports, reportComponentGroupId) {
  return allReports._embedded.reportTemplate.find(({ reportComponentGroups }) =>
    reportComponentGroups.find(({ id }) => id === reportComponentGroupId)
  );
}

function getFilterState(reportTemplate, reportBookmarkParameters) {
  const idNameDictionary = reportTemplate.reportParameters.reduce(
    (dictionary, { id, name }) => ({
      ...dictionary,
      [id]: name,
    }),
    {}
  );
  const filterState = reportBookmarkParameters.reduce(
    (filterState, { reportParameter, value }) => {
      const filterName = idNameDictionary[reportParameter.id];
      const validFilter = filterName && value;
      // All unused parameters will have a `null` value; we don't want
      // to include those nulls except for the `merchant_list` parameter
      if (validFilter || filterName === 'merchant_list') {
        const isDropdownFilter = value && value.indexOf('[') > -1;
        filterState[filterName] = isDropdownFilter
          ? JSON.parse(value).join(',')
          : value;
      }
      return filterState;
    },
    {}
  );
  return filterState;
}

function mapToKeyString(map) {
  return Array.from(map.keys()).join(',');
}
