import { arrayOf, number, shape, string } from 'prop-types';
export const reportParameters = arrayOf(
  shape({
    createdByProfileId: number,
    createdTime: string,
    defaults: string,
    displayLabel: string,
    displayOrder: number,
    emptyValue: string,
    id: number,
    identifier: string,
    name: string,
    options: string,
    reportParameterType: string,
    updateByProfileId: number,
    updatedTime: string,
    version: number,
    _links: shape({
      self: shape({}),
      reportComponentParameters: shape({}),
    }),
  })
);
