import moment from 'moment';

function compareDateStrings(a, b, dataKey) {
  let date1 = moment(a[dataKey]);
  let date2 = moment(b[dataKey]);

  if (!date1.isValid()) {
    date1 = moment(Date.now());
  }
  if (!date2.isValid()) {
    date2 = moment(Date.now());
  }

  if (date1.isAfter(date2)) return -1;
  if (!date1.isAfter(date2)) return 1;
  return 0;
}

function compareText(a, b, dataKey) {
  return a[dataKey].localeCompare(b[dataKey]);
}

function formatMomentObj(string) {
  const momentObj = moment(string);
  return momentObj.isValid() ? momentObj.format('MM/DD/YYYY') : 'Invalid date';
}

export const tableUtils = {
  sortFns: {
    compareDateStrings,
    compareText,
  },
  formatFns: {
    formatMomentObj,
  },
};
