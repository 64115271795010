export function brandEngageReportPageProps() {
  const reports = [
    {
      reportIdentifier: 'brand_engage_workbook',
      type: 'tableau_workbook',
      workbookTabs: [
        {
          tableauTabUri: 'Questions',
          tableauWorksheetName: 'Questions',
          urlFragment: 'q&a',
          title: 'Q&A',
        },
        {
          tableauTabUri: 'Responses',
          tableauWorksheetName: 'Responses',
          urlFragment: 'responses',
          title: 'Responses',
        },
      ],
    },
    {
      reportIdentifier: 'brand_questions',
      urlFragment: 'brand-questions',
      title: 'Questions',
      type: 'columnar',
    },
    {
      reportIdentifier: 'brand_answers',
      urlFragment: 'brand-answers',
      title: 'Answers',
      type: 'columnar',
    },
    {
      reportIdentifier: 'brand_reviews',
      urlFragment: 'brand-reviews',
      title: 'Reviews',
      type: 'columnar',
    },
  ];
  const DEFAULT_TAB = reports[0].workbookTabs[0].urlFragment;
  const PAGE_URL = '/brand-engage';
  const PAGE_TITLE = 'Brand Engage';

  const navigationProps = {
    crumbs: [
      {
        label: 'Analytics',
        href: `/`,
      },
      {
        label: PAGE_TITLE,
        href: PAGE_URL,
      },
    ],
    activePrimary: 'Analytics',
    activeSecondary: PAGE_TITLE,
  };

  return {
    defaultTab: DEFAULT_TAB,
    navigationProps,
    pageUrl: PAGE_URL,
    reports,
  };
}
