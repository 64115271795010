import { getToken } from '@pwr/auth-provider';
import { GET_REPORT_DOWNLOAD_URLS } from 'api/endpoints';
import apiFetch from 'api/fetch';

export function tokenToDownload(token) {
  const options = {
    headers: {
      Authorization: getToken(),
      'Content-type': 'application/json',
    },
  };
  const url = GET_REPORT_DOWNLOAD_URLS + `?token=${token}`;
  return apiFetch(url, options)
    .then(response => response.json())
    .then(({ urls }) => {
      urls.forEach(url => window.location.assign(url));
      Promise.resolve();
    })
    .catch(Promise.reject);
}
