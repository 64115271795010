import moment from 'moment';
import { filterStateToColumnarParams } from 'containers/ReportContent/ReportContentUtils';
import { safeObjectDig } from 'utils/safeObjectDig';
import presetDates from 'components/DatePicker/presetDates';

function formatMultipleValues(value) {
  const dropdownString = typeof value === 'string';
  const dropdownArray = Array.isArray(value);
  if (dropdownArray) {
    return value.map(item => {
      const isSelectObj = item.value;
      if (isSelectObj) return item.value;
      return item;
    });
  }
  if (dropdownString) {
    return value.split(',');
  }
  return value.map(dropdownItem => dropdownItem.value);
}

function canSendMultipleValues(filterType) {
  return /dropdown|categories/gi.test(filterType);
}

export function reportingServicesFormatToFilterState(
  filterState = {},
  filterDefinitions = []
) {
  const filterTypes = filterDefinitions.reduce(
    (accumulator, { reportParameterType, identifier }) => {
      accumulator[identifier] = reportParameterType;
      return accumulator;
    },
    {}
  );

  const payload = Object.entries(filterState).reduce(
    (acccumulator, [filterName, filterValue]) => {
      const typeOfFilter = filterTypes[filterName];
      if (typeOfFilter === 'text') {
        acccumulator[filterName] = filterStateToColumnarParams(filterState)[
          filterName
        ];
        return acccumulator;
      }
      if (typeOfFilter === 'date_range') {
        acccumulator[filterName] = filterStateToColumnarParams(filterState)[
          filterName
        ];
        return acccumulator;
      }
      if (typeOfFilter === 'numeric_range') {
        acccumulator[filterName] = filterStateToColumnarParams(filterState)[
          filterName
        ];
        return acccumulator;
      }
      if (canSendMultipleValues(typeOfFilter)) {
        if (filterValue.length === 0) return acccumulator;
        acccumulator[filterName] = formatMultipleValues(filterValue);
        return acccumulator;
      }
      acccumulator[filterName] = filterValue;
      return acccumulator;
    },
    {}
  );
  return payload;
}

export function filterStateToReportingServicesFormat(
  filterState = {},
  filterDefinitions = []
) {
  return stringifyArrays(
    reportingServicesFormatToFilterState(filterState, filterDefinitions)
  );
}

function reviewDateToStartEndDate(dateRange) {
  if (!dateRange) return null;
  const ISO_FORMAT = 'YYYY-MM-DD';
  const isDateRange =
    typeof dateRange === 'object' && !!safeObjectDig(dateRange, 'startDate');
  if (isDateRange) {
    return {
      start_date: dateRange.startDate,
      end_date: dateRange.endDate,
    };
  }
  const selectedPreset = presetDates[dateRange];
  if (selectedPreset) {
    const dates = selectedPreset({ startDate: moment(), endDate: moment() });
    return {
      start_date: dates.startDate.format(ISO_FORMAT),
      end_date: dates.endDate.format(ISO_FORMAT),
    };
  }
  const dateString = dateRange.split(',');
  if (!selectedPreset && dateString.length === 2) {
    return {
      start_date: dateString[0],
      end_date: dateString[1],
    };
  }
}

function stringifyArrays(filterState) {
  const formattedFilterState = Object.entries(filterState).reduce(
    (accumulator, [filterName, filterValue]) => {
      if (Array.isArray(filterValue)) {
        accumulator[filterName] = `[${filterValue
          .map(filterValue => `"${filterValue}"`)
          .join(',')}]`;
        return accumulator;
      }
      accumulator[filterName] = filterValue;
      return accumulator;
    },
    {}
  );

  return {
    ...formattedFilterState,
    ...reviewDateToStartEndDate(formattedFilterState.review_date),
  };
}
