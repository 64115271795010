import React, { useState } from 'react';
import {
  arrayOf,
  bool,
  func,
  node,
  oneOfType,
  oneOf,
  string,
} from 'prop-types';
import classnames from 'classnames';

export default function Growler({
  children,
  dismissible,
  title,
  type,
  onDismiss,
}) {
  const [dismissed, setDismissed] = useState(false);

  const handleDismiss = () => {
    if (onDismiss) {
      onDismiss();
    }
    setDismissed(true);
  };

  if (dismissed) return null;

  return (
    <div
      className={classnames('text-left', `growler growler--${type}`, {
        'growler--dismissible': dismissible,
      })}
    >
      {title && <h3 className="growler__heading">{title}</h3>}
      {children}
      <button onClick={handleDismiss} className="growler__dismiss">
        Close
      </button>
    </div>
  );
}
Growler.propTypes = {
  children: oneOfType([node, arrayOf(node)]).isRequired,
  dismissible: bool,
  title: string,
  type: oneOf(['alert', 'info', 'success', 'warning']).isRequired,
  onDismiss: func,
};
