import React from 'react';
import { arrayOf, bool, number, object, shape, string } from 'prop-types';
import { Row } from './';
import styles from './ColumnarGrid.module.scss';
import { DEFAULT_COLUMN_WIDTH } from './ColumnarGridUtils';
import { LoadingBar } from '../LoadingBar/LoadingBar';

export const GridRows = ({ columns, isLoading, rows }) => {
  // Each row object needs to be transformed into a row array.
  // The row array contains the text content of each cell as
  // well as the width of the cell. This row array will be the
  // order of cells that get flexed into a row in the table.
  const cells = rows.map(rowObject => {
    return columns.map(columnObject => {
      let cellContent = rowObject[columnObject.propertyName];
      const multiWord =
        typeof cellContent === 'string' && cellContent.split(' ')[1];
      if (columnObject.externalLink) {
        const href = columnarCellStringInterpolator(
          columnObject.linkExpression,
          rowObject
        );
        cellContent = (
          <a href={href} rel="noopener noreferrer" target="_blank">
            {cellContent}
          </a>
        );
      }
      if (columnObject.previewFormat) {
        cellContent = columnarCellStringInterpolator(
          columnObject.previewFormat,
          rowObject
        );
      }
      return {
        cellContent,
        width: columnObject.columnWidth,
        wordBreak: multiWord ? 'unset' : 'break-all',
      };
    });
  });

  function DummyRows() {
    const dummyCells = (columns || []).map(columnObj => {
      return {
        width: columnObj.columnWidth || DEFAULT_COLUMN_WIDTH,
        cellContent: <LoadingBar exactWidth={columnObj.columnWidth} />,
      };
    });
    return Array(50)
      .fill(null)
      .map(() => <Row isLoading cells={dummyCells} />);
  }

  return (
    <div className={styles.GridBody} data-translate={false}>
      {isLoading ? (
        <DummyRows />
      ) : (
        cells.map((cells, index) => <Row key={index} cells={cells} />)
      )}
    </div>
  );
};

GridRows.propTypes = {
  columns: arrayOf(
    shape({
      propertyName: string.isRequired,
      displayName: string,
      columnWidth: number.isRequired,
      externalLink: bool,
      linkExpression: string,
    })
  ).isRequired,
  rows: arrayOf(object).isRequired,
};

export function columnarCellStringInterpolator(
  originalString = '',
  dataObj = {}
) {
  return Object.entries(dataObj).reduce((accumulator, [key, value]) => {
    const allOcurrencesOfKey = new RegExp(`{${key}}`, 'g');
    return accumulator.replace(allOcurrencesOfKey, value);
  }, originalString);
}
