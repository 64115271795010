import React from 'react';
import { useMerchantGroupState } from 'contexts/MerchantGroupContext';

export const UserTranslation = () => {
  const {
    merchantGroupProperties: { OCADO_PORTAL_LANGUAGES },
  } = useMerchantGroupState();
  const api_key = process.env.REACT_APP_WEGLOT_API_KEY;

  React.useEffect(() => {
    let script;
    let loadWeglotScript = async () =>
      new Promise((resolve, reject) => {
        script = document.createElement('script');
        script.async = true;
        script.onload = resolve;
        script.onerror = reject;
        const ref = document.getElementsByTagName('script')[0];
        ref
          ? ref.parentNode.insertBefore(script, ref)
          : document.head && document.head.appendChild(script);

        script.src = 'https://cdn.weglot.com/weglot.min.js';
      });

    const weglotContainer = document.querySelector('.weglot-container');

    if (OCADO_PORTAL_LANGUAGES) {
      if (OCADO_PORTAL_LANGUAGES.length && window.Weglot == null) {
        const defaultLanguage = 'en';
        if (OCADO_PORTAL_LANGUAGES === defaultLanguage) {
          return;
        }
        loadWeglotScript()
          .then(() => {
            window.Weglot &&
              window.Weglot.initialize({
                api_key,
                cache: true,
                whitelist: [
                  {
                    value: '[data-translate="true"]',
                  },
                  {
                    value: 'nav',
                  },
                  {
                    value: '.TopNav',
                  },
                ],
                excluded_blocks: [
                  {
                    value: '[data-translate="false"]',
                  },
                  {
                    value: '.UpperRightMenu > :first-child',
                  },
                  {
                    value: '.UpperRightMenu > :nth-child(2) span',
                  },
                  {
                    value: '[id^=merchant-dropdown-item]',
                  },
                  {
                    value: '[id^=category_id-item]',
                  },
                  {
                    value: '[id^=merchant-item]',
                  },
                  {
                    value: '[id^=brand_id-item]',
                  },
                  {
                    value: '[id^=retailer_id-item]',
                  },
                  {
                    value: '[id^=locale-item]',
                  },
                ],
              });
          })
          .then(() => {
            window.Weglot.on('switchersReady', () => {
              let languageList = OCADO_PORTAL_LANGUAGES.split(/\s*,\s*/);
              let langOptions = document.querySelectorAll(
                '.weglot-container li'
              );
              if (!languageList.includes(defaultLanguage)) {
                languageList.push(defaultLanguage);
              }
              for (let i = 0; i < langOptions.length; i++) {
                if (languageList.includes(langOptions[i].dataset.l)) return;

                langOptions[i].style.display = 'none';
              }
            });
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        weglotContainer && (weglotContainer.style.display = 'block');
      }
    } else {
      window.Weglot && window.Weglot.switchTo('en');
      weglotContainer && (weglotContainer.style.display = 'none');
    }

    return () => {
      if (script != null) document.head.removeChild(script);
    };
  }, [OCADO_PORTAL_LANGUAGES, api_key]);

  return null;
};
