import React, { useState } from 'react';
import { func, number, string } from 'prop-types';
import { Select } from '@pwr-ui/select';
import {
  DAYS_OF_WEEK,
  MONTHLY_OPTIONS,
  ORDINAL_WEEK_NUMBERS,
} from './constants';

function findSelectedItemFromList(selectedItem, list) {
  return list.filter(({ value }) => value === selectedItem)[0];
}

export default function Monthly({
  isMonthDay,
  isUsingWeekday,
  monthDay,
  monthWeek,
  onChange,
  weekDay,
}) {
  const [usingWeekday, setUsingWeekday] = useState(isUsingWeekday);

  const selectedMonthlyOption = findSelectedItemFromList(
    usingWeekday ? 'weekOfMonth' : 'dayOfMonth',
    MONTHLY_OPTIONS
  );
  const selectedOrdinalMonthWeek = findSelectedItemFromList(
    monthWeek,
    ORDINAL_WEEK_NUMBERS
  );
  const selectedWeekDay = findSelectedItemFromList(weekDay, DAYS_OF_WEEK);

  const handleUsingWeekday = selection => {
    setUsingWeekday(selection);
    onChange('isUsingWeekday', selection);
  };
  return (
    <section className="w-full flex">
      <div className="mb-2 w-1/2 mr-1">
        <Select
          id="month-selector"
          items={MONTHLY_OPTIONS}
          itemToString={item => (item != null ? item['label'] : '')}
          itemToKey={item => item.value}
          value={selectedMonthlyOption}
          onChange={selectedOptions => {
            if (!selectedOptions) return;
            const selectedWeekDay = selectedOptions.value === 'weekOfMonth';
            handleUsingWeekday(selectedWeekDay);
          }}
        />
      </div>
      {!usingWeekday && (
        // Reporting services expects days of the month to
        // be zero-indexed, hence I +1 for the value and
        // -1 for the value sent to `onChange1
        <input
          className="form-control"
          id="monthDay"
          max={28}
          min={1}
          name="monthDay"
          type="number"
          value={monthDay > 0 ? monthDay + 1 : 1}
          style={{ width: '50%' }}
          onChange={event => {
            const { name, value } = event.target;
            onChange(name, value - 1);
          }}
        />
      )}
      {usingWeekday && (
        <div className="flex w-1/2">
          <div className="w-1/2 mr-1">
            <Select
              id="ordinal-selector"
              items={ORDINAL_WEEK_NUMBERS}
              itemToString={item => (item != null ? item['label'] : '')}
              itemToKey={item => item.value}
              onChange={selectDayNumber => {
                if (!selectDayNumber) return;
                onChange('monthWeek', selectDayNumber.value);
              }}
              value={selectedOrdinalMonthWeek}
            />
          </div>
          <div className="w-1/2">
            <Select
              id="day-selector"
              items={DAYS_OF_WEEK}
              itemToString={item => (item != null ? item['label'] : '')}
              itemToKey={item => item.value}
              onChange={selectDayNumber => {
                if (!selectDayNumber) return;
                onChange('weekDay', selectDayNumber.value);
              }}
              value={selectedWeekDay}
            />
          </div>
        </div>
      )}
    </section>
  );
}

Monthly.propTypes = {
  monthDay: number,
  monthWeek: string,
  onChange: func.isRequired,
  weekDay: string,
};
