export const AppCues = {
  load() {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.async = true;
      document.head && document.head.appendChild(script);

      script.onload = resolve;
      script.onerror = () => {
        console.warn('Appcues was not loaded.');
        resolve();
      };
      script.src = '//fast.appcues.com/39717.js';
    });
  },

  identify(...args) {
    if (window.Appcues && typeof window.Appcues.identify === 'function') {
      window.Appcues.identify(...args);
    }
  },

  page() {
    if (window.Appcues && typeof window.Appcues.page === 'function') {
      window.Appcues.page();
    }
  },
};
