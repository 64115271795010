import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from 'containers/App';
import { DownloadSubscriptionPage } from 'containers/DownloadSubscriptionPage';
import * as serviceWorker from './serviceWorker';

// Make sure we're in a Browser-like environment before importing polyfills
// This prevents `fetch()` from being imported in a Node test environment
if (typeof window !== 'undefined') {
  // fetch() polyfill for making API calls.
  require('whatwg-fetch');
}

const env = process.env.REACT_APP_BUILD_ENV;
const { hash, host, pathname } = window.location;
const actualAppName = host.split('.').shift();
const expectedAppName = (env === 'prod' ? '' : `${env}-`) + 'analytics';
const isSubscriptionDownload = hash.includes('#/download/file/');

if (actualAppName !== expectedAppName && actualAppName !== 'local') {
  window.location = `https://${expectedAppName}.powerreviews.com${
    isSubscriptionDownload ? '/' + hash : pathname
  }`;
} else {
  const app = isSubscriptionDownload ? <DownloadSubscriptionPage /> : <App />;
  ReactDOM.render(app, document.getElementById('root'));
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
