import ExtendableError from 'es6-error';

export class HttpError extends ExtendableError {
  constructor(message, status) {
    super(message);
    this.status = status;
  }
}

export class ClientError extends HttpError {}

export class ServerError extends HttpError {}

export class NetworkingError extends ExtendableError {
  constructor(message = 'Connection Error') {
    super(message);
  }
}

export default {
  isHttpError(err) {
    return err instanceof HttpError;
  },
  isClientError(err) {
    return err instanceof ClientError;
  },
  isServerError(err) {
    return err instanceof ServerError;
  },
  isNetworkingError(err) {
    return err instanceof NetworkingError;
  },
};
