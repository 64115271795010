import { ClientError, ServerError } from 'lib/errors';

export default function checkResponse(response) {
  if (!response.ok) {
    const { status, statusText } = response;

    return Promise.reject(
      status < 500
        ? new ClientError(statusText, status)
        : new ServerError(statusText, status)
    );
  }

  return response;
}
