import React from 'react';
import { getToken } from '@pwr/auth-provider';
import { Link } from 'react-router-dom';
import moment from 'moment';
import apiFetch from 'api/fetch';
import { REPORT_SCHEDULE_RUN } from 'api/endpoints';
import { StatusDot } from 'components/StatusDot';
import { MerchantName } from '../MerchantName';

const DEFAULT_RUN_OBJ = {
  errorMessage: null,
  runDate: null,
  runStatus: 'NEVER_RAN',
  token: null,
};

export function merchantGroupListToNames(commaSepList, merchantGroupList) {
  const idToName = merchantGroupList.reduce((accumulator, merchantGroup) => {
    accumulator[merchantGroup.merchantGroupId] = merchantGroup;
    return accumulator;
  }, {});
  const names = commaSepList
    .split(',')
    .map(merchantGroupId => {
      return idToName[merchantGroupId] ? idToName[merchantGroupId].name : '';
    })
    .join(', ');
  return <MerchantName name={names} />;
}
export function compareMomentStrings(descending, a, b, property) {
  let date1 = moment(a[property]);
  let date2 = moment(b[property]);

  if (!date1.isValid()) {
    date1 = moment(Date.now());
  }
  if (!date2.isValid()) {
    date2 = moment(Date.now());
  }

  if (date1.isAfter(date2)) return descending ? -1 : 1;
  if (!date1.isAfter(date2)) return descending ? 1 : -1;
  return 0;
}

export function addReportScheduleRuns(reportScheduleBlob) {
  const options = {
    headers: {
      Authorization: getToken(),
      'Content-type': 'application/json',
    },
  };
  const reportScheduleItems = reportScheduleBlob._embedded.reportSchedule;
  return new Promise(async (resolve, reject) => {
    const promises = reportScheduleItems.map(async reportSchedule => {
      const mostRecentRunUrl =
        REPORT_SCHEDULE_RUN +
        `?schedule_id=${reportSchedule.id}` +
        '&page=0&size=1';
      const mostRecentRun = await apiFetch(mostRecentRunUrl, options)
        .then(res => res.json())
        .then(res => res._embedded.reportScheduleRun[0]);
      return {
        ...reportSchedule,
        mostRecentRun: mostRecentRun
          ? {
              errorMessage: mostRecentRun.errorMessage,
              runDate: mostRecentRun.runDate,
              runStatus: mostRecentRun.runStatus,
              token: mostRecentRun.token,
            }
          : DEFAULT_RUN_OBJ,
      };
    });
    await Promise.all(promises).then(reportSchedulesWithRuns => {
      return resolve({
        ...reportScheduleBlob,
        _embedded: { reportSchedule: reportSchedulesWithRuns },
      });
    });
  });
}
export function fetchAllReportSchedules(endpoint) {
  const options = {
    headers: {
      Authorization: getToken(),
      'Content-type': 'application/json',
    },
  };
  return new Promise(async (resolve, reject) => {
    const firstReportScheduleUrl = endpoint + `&page=0&size=1`;
    const firstReportSchedule = await apiFetch(firstReportScheduleUrl, options)
      .then(res => res.json())
      .catch(error => {
        console.error(
          `Unable to fetch subscriptions from ${firstReportSchedule}`,
          error
        );
        reject(error);
      });
    if (firstReportSchedule.page.totalElements <= 1) {
      return resolve(firstReportSchedule);
    }
    const allReportSchedulesUrl =
      endpoint + `&page=0&size=${firstReportSchedule.page.totalElements}`;
    const allReportSchedules = await apiFetch(allReportSchedulesUrl, options)
      .then(res => res.json())
      .catch(reject);
    resolve(allReportSchedules);
  });
}

export function formatForTable(reportScheduleItems, merchantGroupList) {
  const dateFormat = 'MM/DD/YYYY';
  const properCase = word =>
    `${word[0].toUpperCase()}${word.slice(1).toLowerCase()}`;
  const runStatusToMessage = {
    COMPLETED: {
      message: 'Success',
      color: 'green',
    },
    FAILED: {
      message: 'Failed',
      color: 'red',
    },
    RUNNING: {
      message: 'Running',
      color: 'yellow',
    },
    NEVER_RAN: {
      message: 'Never ran',
      color: 'gray',
    },
  };
  return reportScheduleItems.map(
    ({
      createdTime,
      frequency,
      updatedTime,
      mostRecentRun = DEFAULT_RUN_OBJ,
      name,
      id,
      merchantGroupIds,
      ...restProps
    }) => {
      const runStatus =
        runStatusToMessage[mostRecentRun.runStatus] ||
        runStatusToMessage.FAILED;
      const lastRunTimestamp = !mostRecentRun.runDate
        ? 'Never ran'
        : moment(mostRecentRun.runDate).format(dateFormat);
      return {
        createdTime: moment(createdTime).format(dateFormat),
        updatedTime: moment(updatedTime).format(dateFormat),
        mostRecentRun:
          mostRecentRun.runStatus === 'RUNNING'
            ? 'Currently running'
            : lastRunTimestamp || runStatus.defaultMessage,
        status: (
          <StatusDot message={runStatus.message} color={runStatus.color} />
        ),
        name: (
          <Link to={`subscriptions/${id}`}>
            <h4>{name}</h4>
          </Link>
        ),
        frequency: properCase(frequency),
        id,
        merchantGroupIds: merchantGroupListToNames(
          merchantGroupIds,
          merchantGroupList
        ),
        ...restProps,
      };
    }
  );
}
