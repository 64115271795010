import { useEffect, useState } from 'react';
import { getToken } from '@pwr/auth-provider';
import { REPORT_SCHEDULE, REPORT_BOOKMARK } from 'api/endpoints';
import apiFetch from 'api/fetch';

export function useDeleteByScheduleId(scheduleId, onDelete) {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!scheduleId) {
      setResponse(null);
      setError(null);
      setIsLoading(false);
      return;
    }

    const url =
      REPORT_SCHEDULE +
      `/${scheduleId}` +
      '?projection=partialReportScheduleProjection';
    const fetchData = () => {
      const options = {
        headers: {
          Authorization: getToken(),
          'Content-type': 'application/json',
        },
      };
      setIsLoading(true);
      setError(null);
      apiFetch(url, options)
        .then(response => response.json())
        .then(async ({ id, reportBookmark }) => {
          await apiFetch(`${REPORT_SCHEDULE}/${id}`, {
            method: 'DELETE',
            ...options,
          }).catch(setError);
          return await apiFetch(`${REPORT_BOOKMARK}/${reportBookmark.id}`, {
            method: 'DELETE',
            ...options,
          }).catch(setError);
        })
        .then(response => {
          setIsLoading(false);
          setResponse(response);
        })
        .then(() => onDelete(scheduleId))
        .catch(error => {
          setIsLoading(false);
          setError(error);
        });
    };
    fetchData();
  }, [scheduleId]);
  return { error, isLoading, response };
}
