export const DAYS_OF_WEEK = [
  { label: 'Sunday', value: 'SUNDAY' },
  { label: 'Monday', value: 'MONDAY' },
  { label: 'Tuesday', value: 'TUESDAY' },
  { label: 'Wednesday', value: 'WEDNESDAY' },
  { label: 'Thursday', value: 'THURSDAY' },
  { label: 'Friday', value: 'FRIDAY' },
  { label: 'Saturday', value: 'SATURDAY' },
];

// The month selector takes labels 1-28, but the values
// are zero-indexed days 0-27
export const DAY_NUMBERS_OF_MONTH = [...Array(28).keys()].map(dayNumber => ({
  label: dayNumber + 1,
  value: dayNumber,
}));

export const ORDINAL_WEEK_NUMBERS = [
  { label: 'First', value: 'FIRST' },
  { label: 'Second', value: 'SECOND' },
  { label: 'Third', value: 'THIRD' },
  { label: 'Fourth', value: 'FOURTH' },
  { label: 'Last', value: 'LAST' },
];

export const BLANK_DROPDOWN_ITEM = {
  label: '(none)',
  value: '',
};

export const SUBSCRIPTION_FREQUENCIES = ['MONTHLY', 'WEEKLY', 'DAILY'];

export const MONTHLY_OPTIONS = [
  {
    label: 'Day of the Month',
    value: 'dayOfMonth',
  },
  {
    label: 'Week of the Month',
    value: 'weekOfMonth',
  },
];
