const SATIS_METER_KEY = 'G7upTSYKz1mzGoJ3';

window.satismeter =
  window.satismeter ||
  function() {
    (window.satismeter.q = window.satismeter.q || []).push(arguments);
  };
window.satismeter.l = +new Date();

export const SatisMeter = {
  load() {
    return new Promise(resolve => {
      const script = document.createElement('script');
      script.async = true;
      document.head && document.head.appendChild(script);

      script.onload = resolve;
      script.onerror = () => {
        console.warn('Satismeter was not loaded');
        resolve();
      };
      script.src = '//app.satismeter.com/satismeter.js';
    });
  },

  showSurvey(props) {
    const { email, username, userid } = props;

    window.satismeter({
      writeKey: SATIS_METER_KEY,
      userId: userid,
      traits: {
        name: username,
        email: email,
      },
    });
  },
};
