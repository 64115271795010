import React, { Component } from 'react';
import { array, arrayOf, bool, func, oneOf, shape, string } from 'prop-types';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import isEqual from 'lodash.isequal';

import { GridHeader, GridRows } from './';
import {
  columnSizes,
  swapArrayItemsByIndex,
  SORT_DIRECTION,
  DEFAULT_COLUMN_WIDTH,
} from './ColumnarGridUtils';
import styles from './ColumnarGrid.module.scss';

export default class ColumnarGrid extends Component {
  static propTypes = {
    activeColumn: string.isRequired,
    activeColumnDirection: oneOf(['asc', 'desc']).isRequired,
    columns: arrayOf(
      shape({
        propertyName: string.isRequired,
        displayName: string, // Optional property for prettiness
      })
    ).isRequired,
    isLoading: bool,
    onClick: func,
    onDrag: func,
    data: array.isRequired,
  };

  state = {
    columns: this.props.columns,
  };

  componentWillUpdate = ({ columns }) => {
    if (!isEqual(columns, this.state.columns)) {
      this.setState({ columns });
    }
  };

  toggleactiveColumnDirection = currentDirection => {
    if (currentDirection === SORT_DIRECTION.ASC) {
      return SORT_DIRECTION.DESC;
    }
    return SORT_DIRECTION.ASC;
  };

  onDragEnd = result => {
    const { destination, source } = result;
    if (!destination) {
      // Drag was cancelled
      return;
    }
    const updatedColumnSequence = swapArrayItemsByIndex(
      this.state.columns,
      source.index,
      destination.index
    );
    this.setState({ columns: updatedColumnSequence });
    this.props.onDrag(updatedColumnSequence);
  };

  onUpdateActiveColumn = clickedColumnId => {
    const { columns } = this.state;
    const { activeColumn, onClick, activeColumnDirection } = this.props;
    const clickedActiveColumn = activeColumn === clickedColumnId;

    onClick({
      columns: columns.map(column => column.propertyName),
      activeColumn: clickedActiveColumn ? activeColumn : clickedColumnId,
      activeColumnDirection: clickedActiveColumn
        ? this.toggleactiveColumnDirection(activeColumnDirection)
        : SORT_DIRECTION.DESC, // Default sort direction
    });
  };

  render() {
    const { columns } = this.state;
    const { data, activeColumn, activeColumnDirection, isLoading } = this.props;
    const columnsAndWidth = columns.map(column => ({
      ...column,
      columnWidth: columnSizes.get(column.propertyName)
        ? columnSizes.get(column.propertyName)
        : DEFAULT_COLUMN_WIDTH,
    }));

    if (columns.length <= 0) {
      return <div>There are no columns selected</div>;
    }
    if (!columns || columns.length < 1 || !columns[0].propertyName) {
      throw new Error(
        'Columns must be an array of objects with the shape: { propertyName: string }. One column object must be provided.'
      );
    }

    return (
      <div className={styles.ColumnarContainer}>
        <div className={styles.ColumnarGrid}>
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable
              direction="horizontal"
              droppableId="columns"
              type="COLUMN"
            >
              {(droppableProvided, snapshot) => (
                <GridHeader
                  activeColumn={activeColumn}
                  activeColumnDirection={activeColumnDirection}
                  columns={columnsAndWidth}
                  innerRef={droppableProvided.innerRef}
                  droppableProvided={droppableProvided}
                  onClick={this.onUpdateActiveColumn}
                />
              )}
            </Droppable>
            <GridRows
              isLoading={isLoading}
              columns={columnsAndWidth}
              rows={data}
            />
          </DragDropContext>
        </div>
      </div>
    );
  }
}
