import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { bool, func, number, object } from 'prop-types';
import SearchBar from 'components/SearchBar';
import {
  isValidColumnar,
  reportIncludesMerchantGroupId,
  sortByReportName,
} from './DataExplorerUtils';
import styles from './DataExplorerList.module.scss';
import { LoadingBar } from 'components/LoadingBar/LoadingBar';

const NoSearchResults = () => (
  <p className="pt-2 pb-2 center">No reports matched that search</p>
);

export default function DataExplorerList({
  isLoading,
  merchantGroupIds,
  reportsObject,
  setActiveReportId,
}) {
  const reportsArray = isLoading ? [] : reportsObject._embedded.reportTemplate;
  const [reports, setReports] = useState(() =>
    reportsArray.sort(sortByReportName)
  );

  const merchantHasCustomReports =
    reportsArray
      .filter(report => report.custom)
      .filter(report => reportIncludesMerchantGroupId(report, merchantGroupIds))
      .length > 0;

  const customReports = reports
    .filter(report => report.custom)
    .filter(report => reportIncludesMerchantGroupId(report, merchantGroupIds))
    .filter(isValidColumnar);
  const nonCustomReports = reports
    .filter(report => !report.custom)
    .filter(isValidColumnar);

  return (
    <div className={styles.DataExplorerList} data-translate={true}>
      <div className={styles.DataExplorerList__filters}>
        <label className="w-100" htmlFor="data-explorer-search">
          <p className="text-xs uppercase text-gray-20 font-bold pb-1">
            Keyword
          </p>
          <SearchBar
            id="data-explorer-search"
            items={reportsArray}
            onSearch={setReports}
            placeholder="Filter reports by name"
            searchOptions={{ keys: ['name'] }}
            style={{
              border: '1px solid #eee',
              borderRadius: 3,
              boxShadow: 'none',
              fontSize: 14,
              margin: 0,
              padding: 5,
            }}
          />
        </label>
      </div>
      <hr className="hr" />
      <div className={styles.DataExplorerList__list}>
        {merchantHasCustomReports && (
          <>
            <h2 className="text-gray-20">Premium Reports</h2>
            {customReports.length === 0 && <NoSearchResults />}
            <ul>
              {customReports.map(report => (
                <li key={report.id}>
                  <Link
                    to={`/data-explorer/${report.reportIdentifier}`}
                    onClick={() => setActiveReportId(report.reportIdentifier)}
                  >
                    {report.name}
                  </Link>
                  <p className="text-sm text-gray-20">{report.description}</p>
                </li>
              ))}
            </ul>
          </>
        )}
        <h2 className="text-gray-20">Reports</h2>
        {isLoading ? (
          <SkeletonLoadingState />
        ) : (
          nonCustomReports.length === 0 && <NoSearchResults />
        )}
        <ul>
          {nonCustomReports.map(report => (
            <li key={report.id}>
              <Link
                to={`/data-explorer/${report.reportIdentifier}`}
                onClick={() => setActiveReportId(report.reportIdentifier)}
              >
                {report.name}
              </Link>
              <p className="text-sm text-gray-20">{report.description}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
DataExplorerList.propTypes = {
  isLoading: bool,
  merchantGroupId: number,
  reportsObject: object,
  setActiveReportId: func,
};

function SkeletonLoadingState() {
  return (
    <div
      aria-hidden={true}
      className="w-100"
      data-testid="dataExploreLoadingSkeleton"
    >
      <ul>
        {Array(20)
          .fill()
          .map((_, index) => (
            <li key={index}>
              <div className="mb-2">
                <LoadingBar minWidth={250} maxWidth={500} />
              </div>
              <LoadingBar minWidth={500} maxWidth={845} />
            </li>
          ))}
      </ul>
    </div>
  );
}
