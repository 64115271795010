import React, { useEffect, useState } from 'react';
import { MultiSelect, useSimpleFilter } from '@pwr-ui/select';
import { arrayOf, func, number, shape, string } from 'prop-types';
import { FilesIcon } from 'icons/Icons';
import { AlertIcon } from 'icons/Icons';
import { useUserProfiles } from 'pages/SavedReports/SavedReports';
import Modal from 'components/Modal';
import ModalCard from 'components/Modal/ModalCard';

export const GENERAL_DESCRIPTION =
  'Save a report and the filters used. You can share, export, and create subscriptions from this saved report.';

const ProfilePicker = ({
  disabled,
  isLoading,
  profiles,
  reportBookmarkShares = [],
  onChange,
  error,
}) => {
  const setOfSharedWithIds = new Set(
    reportBookmarkShares.map(({ sharedWith }) => sharedWith)
  );
  const initialSelectedProfilesMap = profiles
    .filter(({ profileId }) => setOfSharedWithIds.has(profileId))
    .reduce((map, profile) => {
      map.set(profile.profileId, profile);
      return map;
    }, new Map());

  const [selectedProfiles, setSelectedProfiles] = useState(null);

  useEffect(() => {
    if (!selectedProfiles) return;
    onChange(selectedProfiles);
  }, [selectedProfiles, onChange]);

  const [inputValue, setInputValue] = useState('');
  const itemToString = item => (item !== null ? item['userValue'] : '');
  const itemToKey = item => item.profileId;
  const filteredItems = useSimpleFilter(profiles, itemToString, inputValue);

  if (error) {
    return (
      <div aria-live="polite" className="flex items-center justify-center my-8">
        <AlertIcon width="60" fill="rgb(85, 85, 85)" />
        <p className="ml-2">
          Failed to fetch your profiles. You can reload this page or proceed if
          you don't wish to share this report
        </p>
      </div>
    );
  }

  return (
    <div className="form-group">
      <label htmlFor="profiles-label">Select team members</label>
      <MultiSelect
        disabled={isLoading || disabled}
        isLoading={isLoading}
        id="profiles"
        controlWidth="100%"
        menuWidth="100%"
        inputValue={inputValue}
        onInputValueChange={setInputValue}
        items={filteredItems}
        itemToString={itemToString}
        itemToKey={itemToKey}
        value={selectedProfiles || initialSelectedProfilesMap}
        onChange={setSelectedProfiles}
        placeholder={selected => {
          if (isLoading) return 'Loading profiles';
          const numSelected = selected.size;
          if (numSelected === 0) return 'Select a profile';
          if (numSelected === 1) {
            return selected.values().next().value.userValue;
          }
          return `${numSelected} profiles selected`;
        }}
      />
    </div>
  );
};

const SaveReportFormButton = ({ buttonText, children, disabled, isLegacy }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <button
        className={isLegacy ? 'btn bg-gray-80' : 'text-gray-35 text-left w-100'}
        disabled={disabled}
        onClick={() => setIsOpen(isOpen => !isOpen)}
      >
        {buttonText || 'Save Report'}
      </button>
      {children({ isOpen, setIsOpen })}
    </>
  );
};

export const SaveReportFormModalButton = ({
  buttonText,
  children,
  disabled,
  isLegacy,
}) => {
  return (
    <SaveReportFormButton
      isLegacy={isLegacy}
      disabled={disabled}
      buttonText={buttonText}
    >
      {({ isOpen, setIsOpen }) => (
        <Modal
          closeOnBackgroundClick
          handleClose={() => setIsOpen(false)}
          open={isOpen}
        >
          <ModalCard
            padding="1rem"
            maxWidth={500}
            handleClose={() => setIsOpen(false)}
            isTranslationEnable={true}
          >
            {children}
          </ModalCard>
        </Modal>
      )}
    </SaveReportFormButton>
  );
};

export function SaveReportForm({
  description,
  isLoading,
  merchantGroupIds,
  name,
  onSubmit,
  reportBookmarkShares = [],
  title,
}) {
  const {
    isLoading: isLoadingProfiles,
    error,
    response: profiles,
  } = useUserProfiles(merchantGroupIds);
  const [selectedProfilesMap, setSelectedProfilesMap] = useState(
    reportBookmarkShares.reduce((accumulator, reportShare) => {
      accumulator.set(reportShare.sharedWith, reportShare);
      return accumulator;
    }, new Map())
  );

  const [bookmarkName, setBookmarkName] = useState(name || '');
  const [isSaving, setIsSaving] = useState(false);
  const [hasSavingError, setHasSavingError] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  const handleSubmit = async event => {
    event.preventDefault();
    setIsSaving(true);
    setHasSavingError(false);
    setIsSaved(false);
    const selectedProfileIdsString = selectedProfilesMap
      ? [...selectedProfilesMap.keys()].join(',')
      : null;
    const selectedProfiles = selectedProfilesMap
      ? [...selectedProfilesMap.values()].map(profile => ({
          sharedWith: profile.profileId,
          ...profile,
        }))
      : [];
    try {
      await onSubmit({
        bookmarkName,
        selectedProfileIds: selectedProfileIdsString,
        selectedProfiles,
      });
      setIsSaved(true);
      setHasSavingError(false);
    } catch (error) {
      console.error(error);
      setHasSavingError(true);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="my-4">
        <div className="flex items-center my-4">
          <FilesIcon fill="white" height={50} />
          <h3 className="ml-2">{title}</h3>
        </div>
        <p>{description}</p>
      </div>
      <div className="form-group">
        <label htmlFor="name">
          <span className="text-alert">*</span>Name
        </label>
        <input
          disabled={isSaved || isSaving}
          className="form-control"
          id="name"
          type="text"
          placeholder="Name your saved report"
          value={bookmarkName}
          onChange={event => setBookmarkName(event.target.value)}
          autoFocus
        />
      </div>
      <ProfilePicker
        disabled={isSaved || isSaving}
        error={error}
        isLoading={isLoading || isLoadingProfiles}
        profiles={profiles || []}
        reportBookmarkShares={reportBookmarkShares || []}
        onChange={setSelectedProfilesMap}
      />
      <div className="flex justify-between items-center">
        <button
          disabled={
            isSaved || isLoadingProfiles || isSaving || bookmarkName.length < 1
          }
          className="btn btn--primary"
          type="submit"
        >
          {isSaving ? 'Saving report' : 'Save report'}
        </button>
        <p role="alert" aria-live="polite">
          {isSaved && 'Report saved!'}
          {hasSavingError && 'An error occured, please try again!'}
        </p>
      </div>
    </form>
  );
}

SaveReportForm.propTypes = {
  description: string.isRequired,
  name: string,
  profiles: arrayOf(
    shape({
      profileId: number,
      userValue: string,
    })
  ),
  merchantGroupIds: string,
  onSubmit: func.isRequired,
  sharedWithProfileId: number,
  title: string.isRequired,
};
