import React from 'react';
import classnames from 'classnames';
import { oneOf, string } from 'prop-types';
import style from './StatusDot.module.scss';

export function StatusDot({ message, color }) {
  const COLOR_TO_CLASSNAME = {
    red: 'bg-alert',
    green: 'bg-success',
    yellow: 'bg-warning',
    gray: 'bg-gray-70',
    grey: 'bg-gray-70',
  };
  return (
    <div className="flex items-center">
      <div
        className={classnames(COLOR_TO_CLASSNAME[color.toLowerCase()], {
          [style.glowing]: color.toLowerCase() === 'yellow',
        })}
        style={{ borderRadius: '50%', height: 10, marginRight: 5, width: 10 }}
      />
      <p style={{ fontSize: 0, color: 'transparent' }}>{message}</p>
    </div>
  );
}

StatusDot.propTypes = {
  message: string,
  color: oneOf(['red', 'yellow', 'green', 'grey', 'gray']),
};
