import { safeObjectDig } from 'utils/safeObjectDig';

export const hasColumns = report => {
  return report.reportComponentGroups[0].reportComponentColumns.length > 0;
};
export const hasOneReportComponentGroup = report => {
  return report.reportComponentGroups.length === 1;
};
export const isValidColumnar = report => {
  return hasColumns(report) && hasOneReportComponentGroup(report);
};
export const reportIncludesMerchantGroupId = (
  report,
  merchantGroupIdsString
) => {
  const setOfSelectedMerchantGroupIds = new Set(
    merchantGroupIdsString.split(',')
  );
  const reportOwners = report.reportTemplateMerchantGroups;
  return reportOwners.some(({ merchantGroupId }) =>
    setOfSelectedMerchantGroupIds.has(String(merchantGroupId))
  );
};
export const sortByReportName = (a, b) => {
  return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
};

export function mergeReports(arrayOfReportObjects) {
  if (!Array.isArray(arrayOfReportObjects))
    throw new Error('Provide an array of report reponses to merge');
  return {
    _embedded: {
      reportTemplate: arrayOfReportObjects.reduce((acc, curr) => {
        if (!safeObjectDig(curr || {}, '_embedded.reportTemplate')) return acc;
        return [...acc, ...curr._embedded.reportTemplate];
      }, []),
    },
  };
}
