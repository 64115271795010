import React, { useState } from 'react';
import { arrayOf, shape, func, number, oneOfType, string } from 'prop-types';
import { MultiSelect } from '@pwr-ui/select';
import { useThrottle } from 'use-throttle';
import { useQuery } from 'react-query';
import { getQueryParams } from 'utils';
import { fetchFilter } from 'api/fetchFilter';
import styles from './FetchMultiselect.module.scss';

const itemToString = item => (item !== null ? item.label : '');
const itemToKey = item => item.value;
const arrayOfValuesToMap = item => [itemToKey(item), item];
const getSelectedItems = (currentValue, items) => {
  const arrayOfPrimitives = typeof currentValue[0] !== 'object';
  if (arrayOfPrimitives) {
    const selectedItems = items.filter(item =>
      currentValue.includes(item.value)
    );
    return new Map(selectedItems.map(arrayOfValuesToMap));
  }
  if (Array.isArray(currentValue)) {
    return new Map(currentValue.map(arrayOfValuesToMap));
  }
  const currentValuesFromUrl = currentValue.split(',');
  return new Map(
    items
      .filter(item => currentValuesFromUrl.includes(item.value))
      .map(arrayOfValuesToMap)
  );
};

export default function FetchMultiselect({
  currentValue,
  endpoint,
  filterDisplayName,
  filterName,
  merchantGroupIds,
  merchantIds,
  onChange,
}) {
  const currentValueFromUrlParam = typeof currentValue === 'string';
  const currentValueFromSubscription = typeof currentValue[0] === 'string';

  const [inputValue, setInputValue] = useState('');
  const throttledInputValue = useThrottle(inputValue, 1500);

  const urlParams = getQueryParams({
    dateRange: '',
    groups: merchantGroupIds,
    merchants: merchantIds || '',
    search: throttledInputValue,
    values: (() => {
      if (currentValueFromUrlParam) return currentValue;
      if (currentValueFromSubscription) return currentValue.join(',');
      return '';
    })(),
  });

  const handleSelection = selectedItems => {
    onChange(filterName, [...selectedItems.values()]);
  };

  const { data: items, isLoading } = useQuery(
    [
      'fetchItems',
      {
        endpoint,
        urlParams,
      },
    ],
    ({ endpoint, urlParams }) => {
      return fetchFilter(endpoint, urlParams);
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  return (
    <div
      id={filterName}
      className={`${styles.FetchMultiselectContainer} form-group fl`}
    >
      <label htmlFor={filterName + '-menu'}>{filterDisplayName}</label>
      <MultiSelect
        id={filterName} // '-menu' is appended to this id
        itemToKey={itemToKey}
        itemToString={itemToString}
        inputValue={inputValue}
        onInputValueChange={setInputValue}
        items={items}
        isLoading={isLoading}
        value={getSelectedItems(currentValue || [], items || [])}
        onChange={handleSelection}
      />
    </div>
  );
}

FetchMultiselect.propTypes = {
  currentValue: oneOfType([
    arrayOf(
      shape({
        label: string,
        value: string,
      })
    ),
    string, // This is the case where filter values come from URL params
  ]),
  dateRange: string,
  endpoint: string.isRequired,
  filterName: string.isRequired,
  filterDisplayName: string.isRequired,
  filterType: string.isRequired,
  merchantGroupId: oneOfType([string, number]),
  merchantIds: oneOfType([string, number]),
  onChange: func,
};
