import React, { Component } from 'react';
import { string } from 'prop-types';
import { BrowserPersistenceContext } from 'contexts/BrowserPersistenceContext';

export default class ErrorBoundary extends Component {
  static propTypes = {
    errorMessage: string,
  };

  static contextType = BrowserPersistenceContext;

  state = { hasError: false };

  componentDidCatch(error) {
    console.error(error);

    // This is a unrecoverable error, so let's make sure it's not cached
    // so that a subsequent refresh gets the chance to fetch new values
    console.debug('Cleared Analytics browser cache');
    this.context.clearCache();

    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          style={{ height: 400 }}
          className="flex items-center justify-center bg-white py-5 text-center mt-4 shadow"
        >
          <p>
            {this.props.errorMessage ||
              'Unable to load Analytics, please refresh and try again.'}
          </p>
        </div>
      );
    }
    return this.props.children;
  }
}
