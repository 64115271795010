import React from 'react';
import { func, string } from 'prop-types';
import DatePicker from 'components/DatePicker';
const ISO_FORMAT = 'YYYY-MM-DD';

class DateFilter extends React.Component {
  static propTypes = {
    onChange: func,
    filterName: string.isRequired,
    filterType: string.isRequired,
  };

  onDateSubmit = ({ startDate, endDate, selectedPreset }) => {
    if (startDate && endDate) {
      this.props.onChange(this.props.filterName, {
        startDate: startDate.format(ISO_FORMAT),
        endDate: endDate.format(ISO_FORMAT),
        selectedPreset,
      });
    }
  };
  render() {
    return (
      <DatePicker
        currentValue={this.props.currentValue}
        {...this.props}
        onDateSubmit={this.onDateSubmit}
      />
    );
  }
}

export default DateFilter;

DateFilter.defaultProps = {
  filterName: 'review_date',
  filterType: 'date_range',
};
