export function syndicationReportPageProps() {
  const reports = [
    {
      reportIdentifier: 'syndication_workbook',
      type: 'tableau_workbook',
      workbookTabs: [
        {
          tableauIframeHeight: 1750,
          tableauTabUri: 'Inbound',
          tableauWorksheetName: 'Inbound',
          title: 'Inbound',
          urlFragment: 'inbound',
        },
        {
          tableauIframeHeight: 1750,
          tableauTabUri: 'Outbound',
          tableauWorksheetName: 'Outbound',
          title: 'Outbound',
          urlFragment: 'outbound',
        },
      ],
    },
  ];
  const DEFAULT_TAB = reports[0].workbookTabs[0].urlFragment;
  const PAGE_URL = '/syndication';
  const PAGE_TITLE = 'Syndication';

  const navigationProps = {
    crumbs: [
      {
        label: 'Analytics',
        href: `/`,
      },
      {
        label: PAGE_TITLE,
        href: PAGE_URL,
      },
    ],
    activePrimary: 'Analytics',
    activeSecondary: PAGE_TITLE,
  };

  return {
    defaultTab: DEFAULT_TAB,
    navigationProps,
    pageUrl: PAGE_URL,
    reports,
  };
}
