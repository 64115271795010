import React from 'react';
import { arrayOf, element, number, oneOfType, shape, string } from 'prop-types';
import classnames from 'classnames';

import styles from './ColumnarGrid.module.scss';

export const Row = ({ cells }) => {
  return (
    <div className={classnames('text-sm', styles['GridRow'])}>
      {cells.map(({ cellContent, width, wordBreak }, index) => (
        <div
          className={classnames(styles['Cell'])}
          key={index}
          style={{ width, wordBreak }}
        >
          {cellContent}
        </div>
      ))}
    </div>
  );
};

Row.propTypes = {
  cells: arrayOf(
    shape({
      cellContent: oneOfType([element, string]), // Some cells can be empty
      width: number.isRequired,
      wordBreak: string.isRequired,
    })
  ).isRequired,
};
