import React, { Component } from 'react';
import { bool, func, number, oneOf, shape, string } from 'prop-types';
import classnames from 'classnames';
import { Select } from '@pwr-ui/select';
import Monthly from './Monthly';
import { DAYS_OF_WEEK, SUBSCRIPTION_FREQUENCIES } from './constants';
import inputStyles from '../inputs.module.scss';

const titleCase = allCapsTitle => {
  // Used only for the MONTHLY / WEEKLY / DAILY buttons
  return `${allCapsTitle[0]}${allCapsTitle.slice(1).toLowerCase()}`;
};

export class Frequency extends Component {
  static propTypes = {
    currentValue: shape({
      isUsingWeekday: bool,
      isMonthDay: bool,
      frequency: oneOf(SUBSCRIPTION_FREQUENCIES).isRequired,
      monthDay: number,
      monthWeek: string,
      weekDay: string,
    }),
    onChange: func.isRequired,
    name: string.isRequired,
  };

  state = { selectedFrequency: this.props.currentValue.frequency };

  handleSelectedFrequency(selectedFrequency) {
    this.setState({
      selectedFrequency,
    });
    this.handleChange('frequency', selectedFrequency);
  }

  handleChange = (inputName, selection) => {
    const { currentValue, name, onChange } = this.props;
    const {
      isUsingWeekday,
      frequency,
      monthDay,
      monthWeek,
      weekDay,
    } = currentValue;
    onChange({
      inputName: name,
      inputValue: {
        isUsingWeekday,
        frequency,
        monthDay,
        monthWeek,
        weekDay,
        ...{ [inputName]: selection },
      },
    });
  };

  render() {
    const selectedFrequency = this.state.selectedFrequency;
    const {
      isUsingWeekday,
      monthDay,
      monthWeek,
      weekDay,
    } = this.props.currentValue;
    const selectedWeekDay = DAYS_OF_WEEK.filter(({ value }) => {
      return value === weekDay;
    })[0];

    return (
      <>
        <div className={'mb-2 ' + inputStyles.FormInput}>
          <label htmlFor="">How often do you want the report?</label>
          <div className={inputStyles.ButtonRow}>
            {SUBSCRIPTION_FREQUENCIES.map(frequencyOption => (
              <button
                key={frequencyOption}
                className={classnames('btn', inputStyles.OptionButton, {
                  [inputStyles['OptionButton--active']]:
                    frequencyOption === selectedFrequency,
                })}
                type="button"
                onClick={() => this.handleSelectedFrequency(frequencyOption)}
              >
                {titleCase(frequencyOption)}
              </button>
            ))}
          </div>
        </div>
        {selectedFrequency === 'MONTHLY' && (
          <Monthly
            isUsingWeekday={isUsingWeekday}
            monthDay={monthDay}
            monthWeek={monthWeek}
            onChange={this.handleChange}
            weekDay={weekDay}
          />
        )}
        {selectedFrequency === 'WEEKLY' && (
          <Select
            id="weekly-options"
            items={DAYS_OF_WEEK}
            itemToString={item => (item != null ? item['label'] : '')}
            itemToKey={item => item.value}
            value={selectedWeekDay}
            onChange={selectedItem => {
              if (!selectedItem) return;
              this.handleChange('weekDay', selectedItem.value);
            }}
          />
        )}
      </>
    );
  }
}
