import React, { createContext, useContext } from 'react';
import { useBrowserCache } from 'use-browser-cache';

export const BrowserPersistenceContext = createContext();

export function BrowserPersistenceProvider({ children }) {
  const browserCache = useBrowserCache();

  return (
    <BrowserPersistenceContext.Provider value={browserCache}>
      {browserCache.cacheIsReady ? children : null}
    </BrowserPersistenceContext.Provider>
  );
}

export function useBrowserPersistenceApi() {
  const context = useContext(BrowserPersistenceContext);
  if (context === undefined) {
    throw new Error(
      'useBrowserPersistenceApi must be used within a BrowserPersistenceProvider'
    );
  }

  return context;
}
