import React, { useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { SavedReports } from './SavedReports';
import Page from 'pages/Page/Page';
import { EditSavedReport } from './EditSavedReport';

const PAGE_URL = '/saved-reports';

export function SavedReportsIndex({
  allReportPageProps,
  apps,
  isAdmin,
  isBrandEngageOnly,
  name,
  nonCustomReports,
  onMerchantSelect,
  selectedMerchantGroupsByIds,
  selectedMerchantsByIds,
  setSelectedMerchantGroupsByIds,
  userid,
}) {
  const [lastBreadcrumb, setLastBreadcrumb] = useState(null);

  const navigationProps = {
    crumbs: [
      {
        label: 'Analytics',
        href: `/`,
      },
      ...(lastBreadcrumb || []),
    ],
    activePrimary: 'Analytics',
    activeSecondary: 'Saved Reports',
  };
  return (
    <Page
      apps={apps}
      isBrandEngageOnly={isBrandEngageOnly}
      name={name}
      navigationProps={navigationProps}
      selectedMerchantGroupsByIds={selectedMerchantGroupsByIds}
    >
      <Switch>
        <Route
          exact
          path="/saved-reports/:tabId/:reportId"
          render={props => {
            const { reportId, tabId } = props.match.params;
            return (
              <EditSavedReport
                allReportPageProps={allReportPageProps}
                isAdmin={isAdmin}
                nonCustomReports={nonCustomReports}
                selectedMerchantGroupsByIds={selectedMerchantGroupsByIds}
                selectedMerchantsByIds={selectedMerchantsByIds}
                setSelectedMerchantGroupsByIds={setSelectedMerchantGroupsByIds}
                setLastBreadcrumb={setLastBreadcrumb}
                onMerchantSelect={onMerchantSelect}
                reportBookmarkId={reportId}
                tabId={tabId}
                userid={userid}
              />
            );
          }}
        />
        <Route
          exact
          path="/saved-reports/:tabId"
          render={props => {
            return (
              <SavedReports
                pageUrl={PAGE_URL}
                initialTab={props.match.params.tabId}
                selectedMerchantGroupsByIds={selectedMerchantGroupsByIds}
              />
            );
          }}
        />
        <Redirect to="/saved-reports/my-reports" />
      </Switch>
    </Page>
  );
}
