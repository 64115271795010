import React, { useEffect, useState } from 'react';
import Growler from 'components/Growler';
import { Storage } from './storage';
import moment from 'moment';

const isActiveAlert = () => {
  const now = moment();
  return ({ start, end }) => now.isBetween(start, end);
};

function getActiveAlerts(allAlerts) {
  const dismissedAlertIds = new Set(Storage.getDismissedAlerts());
  const initialAlerts = allAlerts
    .filter(isActiveAlert())
    .filter(({ id }) => dismissedAlertIds.has(id) === false);

  return initialAlerts;
}

export function SystemAlerts({ allAlerts }) {
  const [alerts, setAlerts] = useState(() => getActiveAlerts(allAlerts));
  const dismissAlert = id => {
    setAlerts(prev => prev.filter(alert => alert.id !== id));
    Storage.setDismissedAlert(id);
  };

  useEffect(() => {
    const refreshAlerts = () => {
      const next = getActiveAlerts(allAlerts);
      setAlerts(next);
    };
    // check alerts every 5 mins
    let interval = setInterval(refreshAlerts, 5 * 60 * 1000);
    return () => clearInterval(interval);
  });

  useEffect(() => {
    // prevent the cookie from growing indefinitely
    // remove inactive alerts that are stored as dismissed
    const activeAlerts = new Set(
      allAlerts.filter(isActiveAlert()).map(alert => alert.id)
    );
    const pruned = Storage.getDismissedAlerts().filter(id =>
      activeAlerts.has(id)
    );
    Storage.setDismissedAlerts(pruned);
  }, [alerts, allAlerts]);

  return (
    <>
      {alerts.map(entry => {
        const { id, title, type, message, dismissable } = entry;
        return (
          <Growler
            key={id}
            dismissible={dismissable}
            onDismiss={() => dismissAlert(id)}
            title={title}
            type={type}
          >
            <p className="pt-1">{message}</p>
          </Growler>
        );
      })}
    </>
  );
}
