import clonedeep from 'lodash.clonedeep';

function swapItemsInDifferentLists(
  sourceList,
  destinationList,
  sourceIndex,
  destinationIndex
) {
  const elementToMove = sourceList.splice(sourceIndex, 1)[0];
  destinationList.splice(destinationIndex, 0, elementToMove);
  return [sourceList, destinationList];
}

function swapItemsInSameList(list, sourceIndex, destinationIndex) {
  const elementToMove = list.splice(sourceIndex, 1)[0];
  list.splice(destinationIndex, 0, elementToMove);
  return list;
}

export function moveItemFromSourceToDestination(source, destination, lists) {
  const listsCopy = clonedeep(lists);
  const sourceListTitle = source.droppableId;
  const destinationListTitle = destination.droppableId;
  const listsByTitle = listsCopy.map(list => list.title);

  if (destinationListTitle === sourceListTitle) {
    // We are moving an item within its home list
    const indexOfListToUpdate = listsByTitle.indexOf(sourceListTitle);
    swapItemsInSameList(
      listsCopy[indexOfListToUpdate].items,
      source.index,
      destination.index
    );
  } else {
    // We are moving an item from its home list to new list
    const sourceList = listsCopy[listsByTitle.indexOf(sourceListTitle)];
    const destinationList =
      listsCopy[listsByTitle.indexOf(destinationListTitle)];
    swapItemsInDifferentLists(
      sourceList.items,
      destinationList.items,
      source.index,
      destination.index
    );
  }
  return listsCopy;
}

export function getListObjectByTitle(titleOfListToFind, lists) {
  const list = lists.filter(list => list.title === titleOfListToFind)[0];
  if (!list || list.length < 1) {
    return null;
  }
  return list;
}
export function getListItemsByTitle(titleOfListToFind, lists) {
  const list = getListObjectByTitle(titleOfListToFind, lists);
  if (!list) {
    return null;
  }
  return list.items;
}

export function throwDestinationListError(listTitle) {
  throw new Error(
    `${listTitle} list object has no onClickDestination or it's malformed`
  );
}

export function alphabeticalSort(item1, item2) {
  return item1.propertyName.localeCompare(item2.propertyName, {
    sensitivity: 'base',
  });
}
