import React from 'react';
import styles from './Dots.module.scss';

export function Dots() {
  return (
    <div className={styles.LoadingDots}>
      <div className={styles.Dot} />
      <div className={styles.Dot} />
      <div className={styles.Dot} />
    </div>
  );
}
