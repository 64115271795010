import { useEffect, useState } from 'react';
import { getToken } from '@pwr/auth-provider';
import { REPORT_SCHEDULE_RUN } from 'api/endpoints';
import apiFetch from 'api/fetch';

export function useScheduleIdToRuns(scheduleId, pageNumber) {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!scheduleId) return;
    setIsLoading(true);
    const options = {
      headers: {
        Authorization: getToken(),
        'Content-type': 'application/json',
      },
    };
    const url = `${REPORT_SCHEDULE_RUN}?schedule_id=${scheduleId}&page=${pageNumber -
      1}&size=20`;
    const fetchData = () => {
      setIsLoading(true);
      setError(null);
      apiFetch(url, options)
        .then(response => response.json())
        .then(json => {
          setIsLoading(false);
          setResponse(json);
        })
        .catch(setError);
    };
    fetchData();
  }, [pageNumber, scheduleId]);
  return { error, isLoading, response };
}
