import React, { Component, useState } from 'react';
import { func, number, shape, string, oneOfType } from 'prop-types';
import { Select, useSimpleFilter } from '@pwr-ui/select';
import apiFetch from 'api/fetch';
import { FIND_PROFILES } from 'api/endpoints';
import { getToken } from '@pwr/auth-provider';
import { BLANK_DROPDOWN_ITEM } from './Frequency/constants';
import styles from './inputs.module.scss';

const itemToString = item => (item !== null ? item['label'] : '');
const itemToKey = item => item.value;
function ProfileSelect({ items, handleChange, value }) {
  const [inputValue, setInputValue] = useState('');
  const filteredItems = useSimpleFilter(items, itemToString, inputValue);
  return (
    <div className={styles.MerchantDropDown}>
      <Select
        isLoading={items.length < 1}
        id="merchant-dropdown"
        inputValue={inputValue}
        items={filteredItems}
        itemToKey={itemToKey}
        itemToString={itemToString}
        onChange={selectedItem => {
          if (!selectedItem) return;
          handleChange(selectedItem);
        }}
        onInputValueChange={setInputValue}
        value={value}
      />
    </div>
  );
}

export class SelectCreator extends Component {
  static propTypes = {
    currentValue: shape({
      createdByProfileId: oneOfType([number, string]),
      createdForProfileId: oneOfType([number, string]),
      createdForProfileName: string,
    }),
    merchantGroupIds: string.isRequired,
    name: string.isRequired,
    onChange: func.isRequired,
  };

  state = { selectItems: [] };

  async componentDidMount() {
    const { merchantGroupIds } = this.props;
    const url = `${FIND_PROFILES}&merchantGroupsIds=${merchantGroupIds}`;
    const headers = {
      headers: {
        Authorization: getToken(),
        'Content-type': 'application/json',
      },
    };
    const getProfiles = await apiFetch(url, headers).then(res => res.json());
    const selectCreatorOptions = getProfiles._embedded.profiles
      .filter(profile => profile.values[0].displayable)
      .map(profile => ({
        label: profile.values[0].userValue,
        value: profile.profileId,
      }));
    const selectItems = [BLANK_DROPDOWN_ITEM, ...selectCreatorOptions];
    this.setState({ selectItems });
  }

  handleSelectCreator = ({ label, value }) => {
    const { name, onChange } = this.props;
    const isBlankItem = label === BLANK_DROPDOWN_ITEM.label;
    onChange({
      inputName: name,
      inputValue: {
        createdForProfileId: value,
        createdForProfileName: isBlankItem ? '' : label,
      },
    });
  };

  render() {
    const { selectItems } = this.state;
    if (selectItems.length < 0) {
      return null;
    }
    const { currentValue } = this.props;
    const { createdForProfileName } = currentValue;
    const selectedItem = selectItems.filter(item => {
      return item.label === createdForProfileName;
    })[0];
    return (
      <div className={styles.FormInput}>
        <label htmlFor="select-creator">Select a Creator</label>
        <ProfileSelect
          items={selectItems}
          handleChange={this.handleSelectCreator}
          value={selectedItem}
        />
      </div>
    );
  }
}
