import React, { Component } from 'react';
import { func, oneOf, string, shape } from 'prop-types';
import inputStyles from '../inputs.module.scss';

export default class RemoteServer extends Component {
  static propTypes = {
    deliveryMethod: shape({
      deliveryType: oneOf(['FTP', 'FTPS', 'SFTP']).isRequired,
      ftpPassword: string.isRequired,
      ftpUserName: string.isRequired,
      ftpUrl: string.isRequired,
    }).isRequired,
    onChange: func.isRequired,
  };

  handleChange = event => {
    const { name, value } = event.target;
    this.props.onChange(name, value);
  };

  render() {
    const {
      deliveryType,
      ftpPassword,
      ftpUrl,
      ftpUserName,
    } = this.props.deliveryMethod;
    return (
      <>
        <div className={inputStyles.FormInput}>
          <label htmlFor="ftpUrl">
            <span className="text-red">*</span>
            {deliveryType} address
          </label>
          <input
            className="form-control"
            id="ftpUrl"
            name="ftpUrl"
            onChange={this.handleChange}
            type="text"
            value={ftpUrl}
            placeholder={`e.g. ${deliveryType.toLowerCase()}.powerreviews.com`}
          />
        </div>
        <div className={inputStyles.FormInput}>
          <label htmlFor="ftpUserName">
            <span className="text-red">*</span>
            {deliveryType} username
          </label>
          <input
            className="form-control"
            id="ftpUserName"
            name="ftpUserName"
            onChange={this.handleChange}
            type="text"
            value={ftpUserName}
          />
        </div>
        <div className={inputStyles.FormInput}>
          <label htmlFor="ftpPassword">
            <span className="text-red">*</span>
            {deliveryType} password
          </label>
          <input
            className="form-control"
            id="ftpPassword"
            name="ftpPassword"
            onChange={this.handleChange}
            type="password"
            value={ftpPassword}
          />
        </div>
      </>
    );
  }
}
