const env = process.env.REACT_APP_BUILD_ENV;
export const reportingServicesUrl = `https://${
  env === 'prod' ? '' : 'qa-' // Default to QA since RP Services has no dev env
}reporting-services-api.powerreviews.com`;

// Auth and config
export const REGENERATE = `${reportingServicesUrl}/user-service/user/token/regenerate`;
export const IS_ADMIN = `${reportingServicesUrl}/admin/isAdmin`;
export const MERCHANT_GROUP_LIST = `${reportingServicesUrl}/admin/merchantGroups`;
export const JASPER_SERVER = `${reportingServicesUrl}/jasperserver-pro`;
export const WHOAMI = `${reportingServicesUrl}/user-service/user/whoami`;
export const AUTH_SERVICE = `https://${
  env === 'prod' ? '' : 'qa'
}auth.powerreviews.com/login`;

// Merchant and merchant group properties
export function makeMerchantGroupPropertiesEndpoint(
  merchantGroupId,
  properties = []
) {
  return `${reportingServicesUrl}/user-service/config-service/properties/g${merchantGroupId}?excludeDefaults=false&keys=${properties.join(
    ','
  )}`;
}
export function makeMerchantPropertiesEndpoint(merchantId, properties = []) {
  return `${reportingServicesUrl}/user-service/config-service/properties/m${merchantId}?excludeDefaults=false&keys=${properties.join(
    ','
  )}`;
}

//Finding profiles for saving columnar
// GET
export const FIND_PROFILES = `${reportingServicesUrl}/user-service/profiles/search/findProfiles?projection=partialProfileProjection`;

//Third Party
export const SATIS_METER = '//app.satismeter.com/satismeter.js';

// Find By Key (not sure what this does)
export const FIND_BY_KEY = `${reportingServicesUrl}/reportUserSetting/search/findByKey?key=SALES_REPORTING`;

// Get all non custom reports
export const GET_ALL_NON_CUSTOM_REPORTS = `${reportingServicesUrl}/reportTemplate/search/getAllNonCustom?projection=completeReportTemplateProjection`;
export const GET_ALL_CUSTOM_REPORTS = `${reportingServicesUrl}/reportTemplate/search/getCustom?projection=completeReportTemplateProjection`;
export const REPORT_SCHEDULE_BY_CREATED_PROFILE_ID = `${reportingServicesUrl}/reportSchedule/search/findByCreatedProfileId?projection=partialReportScheduleProjection`;
export const REPORT_SCHEDULE_BY_CREATED_FOR = `${reportingServicesUrl}/reportSchedule/search/findByCreatedFor?projection=partialReportScheduleProjection`;
export const REPORT_SCHEDULE_BY_MERCHANT_GROUP_ID = `${reportingServicesUrl}/reportSchedule/search/findByMerchantGroupIds?projection=partialReportScheduleProjection`;
export const REPORT_SCHEDULE_RUN = `${reportingServicesUrl}/reportScheduleRun/search/findByScheduleId`;

// Filters
export const BRAND = `${reportingServicesUrl}/filters/brands`;
export const CATEGORY = `${reportingServicesUrl}/filters/categories`;
// export const OBSERVATION_TYPES = `${reportingServicesUrl}/filters/observationTypes?groups=15868&merchants=&dateRange=LAST_3_MONTHS&search=&values=`;
// export const REVIEW_BOTTOM_LINES = `${reportingServicesUrl}/filters/reviewBottomlines?groups=15868&merchants=&dateRange=LAST_3_MONTHS&search=&values=`;

// Getting Report component group
// PUT? y tho
export const GET_REPORT_BOOKMARK_PAGES = `${reportingServicesUrl}/reportExportUnload/pagesNumber`;
export const GET_REPORT_BOOKMARK_COMPONENT = `${reportingServicesUrl}/reportExportUnload/previewData`;
export const REPORT_DOWNLOAD = `${reportingServicesUrl}/reportExportUnload`;
export const GET_REPORT_DOWNLOAD_URLS = `${reportingServicesUrl}/download/file`;
// https://qa-reporting-services.powerreviews-api.com//reportExportUnload/previewData/180?limit=50&page=1
/* Example payload:
  {
    end_date: "2019-02-25"
    merchant_group_list: "15868"
    merchant_list: null
    review_date: "LAST_3_MONTHS"
    start_date: "2018-11-25"
    table_columns: "created_date,product_name,brand_name,page_id,review_rating,review_headline,review_comments,review_bottomline,review_location,reviewer_type,merchant_user_email,site_status,is_pwr_publishable,page_id_variant,ugc_id"
    table_sort: "created_date asc"
  }
*/
export const TEST_FTP = `${reportingServicesUrl}/admin/testFtp`;
// Saving Bookmarks
// POST
export const REPORT_PARAMETER = `${reportingServicesUrl}/reportParameter`;
export const REPORT_BOOKMARK = `${reportingServicesUrl}/reportBookmark`;
export const REPORT_BOOKMARK_METADATA = `${reportingServicesUrl}/reportBookmark/api/touch`;
export const REPORT_BOOKMARK_PARAMETER = `${reportingServicesUrl}/reportBookmarkParameter`;
export const REPORT_SCHEDULE = `${reportingServicesUrl}/reportSchedule`;
export const REPORT_SCHEDULE_RECIPIENT = `${reportingServicesUrl}/reportScheduleRecipient`;
/* payload for saving bookmark
  {
    reportComponentGroup: "https://qa-reporting-services.powerreviews-api.com/reportComponentGroup/180{?projection}"
    visibleToAll: false
  }
  DB error: 
  ERROR: null value in column "name" violates not-null constraint
  Detail: Failing row contains (6849, 16237814, null, f, 16237814, 2019-02-25 07:50:24.468, 16237814, 2019-02-25 07:50:24.468, 0, Ben Taylor, 180)
*/
export const TABLEAU_SERVER_URL = `https://10az.online.tableau.com/t/powerreviews`;
// export const TABLEAU_SERVER_URL = `https://${
//   env !== 'prod' ? `qa-` : ''
// }tableau.powerreviews.com`;
export const GET_TABLEAU_TICKET_URL = `${reportingServicesUrl}/interactive-pulse/ticket`;
