import React from 'react';
import apiFetch from '../api/fetch';
import { getToken } from '@pwr/auth-provider';
import { WHOAMI, IS_ADMIN, GET_ALL_NON_CUSTOM_REPORTS } from 'api/endpoints';
import { BrowserPersistenceContext } from 'contexts/BrowserPersistenceContext';
import deepEquals from 'lodash.isequal';

const initialState = {
  activeReportId: '',
  apps: [],
  configHasError: false,
  configHasLoaded: false,
  deviceIds: null,
  env: 'dev',
  expiration: 1800,
  isAdmin: false,
  merchantGroupList: [],
  merchantProperties: {},
  needsDevice: null,
  nonCustomReports: null,
  oneloginRedirect: null,
  optional: null,
  programGroups: {},
  token: '',
  userDetails: null,
};

export const ConfigContext = React.createContext(initialState);
export const ConfigConsumer = ConfigContext.Consumer;

const BROWSER_CACHE_KEYS = {
  USER_DETAILS: 'user_details',
  NON_CUSTOM_REPORTS: 'non_custom_reports',
  IS_ADMIN: 'is_admin',
};

export class ConfigProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
    };
  }

  static contextType = BrowserPersistenceContext;

  componentWillMount() {
    this.getConfig();
  }

  setConfigFetchError = (name, error) => {
    console.error('Error while fetching config:', error);
    this.setState({
      configErrorName: name,
    });
  };

  fetchConfigValuesAndUpdateCache = cachedValues => {
    const headers = {
      headers: { Authorization: getToken() },
    };
    const browserCache = this.context;

    function writeToCacheIfValuesChange({ key, newValue, previousValue }) {
      // Only update cache when necessary
      if (!valuesDidChange(key, previousValue, newValue)) return;

      try {
        browserCache.setItemAsync(key, newValue);
        console.debug(`Updated ${key} in Analytics UI browser cache`);
      } catch (error) {
        console.warn('Unable to write to Analytics browser cache', error);
      }
    }

    return Promise.all([
      apiFetch(WHOAMI, headers)
        .then(res => res.json())
        .then(response => {
          writeToCacheIfValuesChange({
            key: BROWSER_CACHE_KEYS.USER_DETAILS,
            newValue: response,
            previousValue: cachedValues ? cachedValues.userDetails : null,
          });

          this.setState({
            email: response.email,
            name: response.name,
            userid: response.uid,
          });
          return response;
        })
        .catch(error => this.setConfigFetchError('user details', error)),
      apiFetch(GET_ALL_NON_CUSTOM_REPORTS, headers)
        .then(res => res.json())
        .then(response => {
          writeToCacheIfValuesChange({
            key: BROWSER_CACHE_KEYS.NON_CUSTOM_REPORTS,
            previousValue: cachedValues ? cachedValues.nonCustomReports : null,
            newValue: response,
          });
          this.setState({ nonCustomReports: response });
          return response;
        })
        .catch(error => this.setConfigFetchError('report templates', error)),
      apiFetch(IS_ADMIN, headers)
        .then(res => res.json())
        .then(response => {
          writeToCacheIfValuesChange({
            key: BROWSER_CACHE_KEYS.IS_ADMIN,
            previousValue: cachedValues ? cachedValues.isAdmin : null,
            newValue: response,
          });

          this.setState({ isAdmin: response });
          return response;
        })
        .catch(error => this.setConfigFetchError('admin status', error)),
    ]);
  };

  getConfig = async () => {
    const browserCache = this.context;

    const cachedValues = await Promise.all(
      Object.entries(BROWSER_CACHE_KEYS).map(([key, value]) =>
        browserCache
          .getItemAsync(value) // `null` will be returned if the values don't exist in cache
          .catch(error =>
            console.error(`Unable to retrieve ${key} due to: ${error}`)
          )
      )
    );

    const cachedValuesAvailable = cachedValues.every(value => value !== null);

    const [userDetails = {}, nonCustomReports, isAdmin] = cachedValuesAvailable
      ? cachedValues
      : await this.fetchConfigValuesAndUpdateCache();

    if (cachedValuesAvailable) {
      // If we have cached values, we will present them to the user
      // and fetch fresh values in the background. When that fetch
      // resolves, the fresh data will be updated in the app. These
      // values will rarely ever change, however.
      this.fetchConfigValuesAndUpdateCache({
        userDetails,
        nonCustomReports,
        isAdmin,
      });
    }

    const {
      apps,
      deviceIds,
      env,
      expiration,
      needsDevice,
      oneloginRedirect,
      optional,
      programGroups,
      token,
    } = this.props.authPayload;

    this.setState({
      apps,
      configHasLoaded: true,
      deviceIds,
      email: userDetails.email,
      env,
      expiration,
      isAdmin,
      name: userDetails.name,
      needsDevice,
      nonCustomReports,
      oneloginRedirect,
      optional,
      programGroups,
      token,
      userid: userDetails.uid,
    });
  };

  render() {
    return (
      <ConfigContext.Provider value={this.state}>
        {this.props.children}
      </ConfigContext.Provider>
    );
  }
}

export default { ConfigProvider, ConfigConsumer };

function valuesDidChange(key, prev, curr) {
  try {
    if (key === BROWSER_CACHE_KEYS.USER_DETAILS) {
      if (!prev) return true;

      // `createdTime` will changes for each request
      // so we shouldn't compare it
      prev['createdTime'] = null;
      curr['createdTime'] = null;

      return !deepEquals(prev, curr);
    }

    return !deepEquals(prev, curr);
  } catch (error) {
    return true;
  }
}
