export function formatNumberWithCommas(number) {
  const captureEveryThirdDigit = /\B(?=(\d{3})+(?!\d))/g;
  const numberWithCommas = number
    .toString()
    .replace(captureEveryThirdDigit, ',');

  return numberWithCommas;
}

export function handlePageButton(requestedPage, currentPage, callback) {
  if (requestedPage !== currentPage) {
    callback(requestedPage);
  }
}

export function handleNextButton(currentPage, totalPages, callback) {
  if (currentPage !== totalPages) {
    callback(currentPage + 1);
  }
}

export function handlePreviousButton(currentPage, callback) {
  if (currentPage !== 1) {
    callback(currentPage - 1);
  }
}

export function _makePageNumberArray(start, end) {
  const numbersArray = [];
  for (let i = start; i <= end; i++) {
    if (i < 1) {
      // There are no page numbers <= 0
      continue;
    }
    numbersArray.push(i);
  }
  return numbersArray;
}
/**
 * This function returns the page numbers needed to render the Pagination component.
 * @param {number} currentPage
 * @param {number} totalPages
 * @return {object} Object with `pagesBeforeElipsis` and `pagesAfterElipsis` properties. Each property contains an array
 * of 0..n number of page numbers.
 */
export function makePageNumbers(currentPage, totalPages) {
  let pagesAfterElipsis = [];
  let pagesBeforeElipsis = [];
  const hasElipsis = totalPages - currentPage >= 7;

  pagesBeforeElipsis = _makePageNumberArray(totalPages - 6, totalPages);

  if (hasElipsis) {
    pagesBeforeElipsis = _makePageNumberArray(currentPage, currentPage + 3);
    pagesAfterElipsis = _makePageNumberArray(totalPages - 1, totalPages);
  }

  return { pagesBeforeElipsis, pagesAfterElipsis };
}
