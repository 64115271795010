import React, { Component } from 'react';
import Modal from 'components/Modal';
import ModalCard from 'components/Modal/ModalCard';
import { bool, func, string, object, oneOf } from 'prop-types';
import {
  reportComponentGroup,
  reportParameters,
  reportSchedule,
} from 'utils/types';
import {
  createSubscription,
  createNotificationMessage,
  payloadToComponentState,
  validateSubscriptionInput,
  updateSubscription,
  updateReportBookmarkMetadata,
} from './SubscriptionUtils';
import {
  ButtonOrSuccess,
  DeliveryMethod,
  ExportOptions,
  Frequency,
  Name,
  SelectCreator,
  SubscriptionMetadata,
} from './inputs';
import styles from './SubscriptionForm.module.scss';

export default class SubscriptionForm extends Component {
  static propTypes = {
    displayCustomGrowler: func,
    filterState: object,
    isAdmin: bool,
    merchantGroupIds: string,
    onSubmit: func,
    reportComponentGroups: reportComponentGroup,
    reportParameters: reportParameters.isRequired,
    reportType: oneOf(['SNOWFLAKE', 'TABLEAU']),
    subscription: object,
    title: string.isRequired,
  };
  static defaultProps = {
    filterState: {},
  };

  initialState = {
    isValid: false,
    isSubmitting: false,
    formErrors: {},
    modalOpen: false,
    subscriptionInput: {
      name: '',
      metadataIncluded: true,
      exportOptions: {
        delimiter: 'COMMA',
        exportType: this.props.reportType === 'SNOWFLAKE' ? 'csv' : 'pdf',
      },
      selectCreator: {
        createdForProfileId: '',
        createdForProfileName: '',
      },
      frequency: {
        isUsingWeekday: false, // Used only to determine which values should be nulled in req body
        frequency: 'MONTHLY',
        monthDay: 0, // Report services expects the days of the month to be zero-indexed
        monthWeek: 'FIRST',
        weekDay: 'SUNDAY',
      },
      delivery: {
        deliveryType: 'EMAIL',
        email: '',
        ftpPassword: '',
        ftpUserName: '',
        ftpUrl: '',
        message: '',
      },
    },
    successfulSubmission: false,
    submissionError: null,
    subscription: reportSchedule,
  };

  state = {
    ...this.initialState,
  };
  componentDidMount = () => {
    if (this.props.subscription) {
      this.setState({
        subscriptionInput: payloadToComponentState(this.props.subscription),
      });
    }
  };
  handleOpenModal = () => {
    this.setState({ modalOpen: true });
  };
  handleCloseModal = () => {
    const { successfulSubmission } = this.state;
    if (successfulSubmission) {
      if (this.props.subscription) {
        return this.setState({ modalOpen: false, successfulSubmission: false });
      }
      return this.setState({
        ...this.initialState,
        subscriptionInput: {
          ...this.initialState.subscriptionInput,
          name: '',
        },
      });
    }
    this.setState({ modalOpen: false });
  };
  handleFormErrors({ formSection, hasError }) {
    const { formErrors } = this.state;
    this.setState({
      formErrors: {
        ...formErrors,
        [formSection]: hasError,
      },
    });
  }
  handleUpdateSubscriptionInput = ({ inputName, inputValue }) => {
    const { subscriptionInput } = this.state;
    const updatedSubscriptionInput = subscriptionInput;
    updatedSubscriptionInput[inputName] = inputValue;
    const isValid = validateSubscriptionInput(updatedSubscriptionInput);
    this.setState({
      subscriptionInput: { ...updatedSubscriptionInput },
      isValid: isValid,
    });
  };

  handleChangeMetadataIncluded = () => {
    const { subscriptionInput } = this.state;
    const nextValue = !subscriptionInput.metadataIncluded;
    this.handleUpdateSubscriptionInput({
      inputName: 'metadataIncluded',
      inputValue: nextValue,
    });
  };

  handleSubmit = async event => {
    event.preventDefault();
    const {
      subscription: existingReportSchedule,
      onSubscriptionEdit,
      onSubmit,
    } = this.props;
    if (onSubmit) {
      // TODO: Have <ReportContentColumnar /> define its own `onSubmit`
      return onSubmit({
        setModalOpen: modalOpen => this.setState({ modalOpen }),
        formInput: this.state.subscriptionInput,
        setIsSubmitting: isSubmitting => this.setState({ isSubmitting }),
        setHasSubmissionError: hasSubmissionError => {
          this.setState({ submissionError: hasSubmissionError });
        },
        resetForm: () => this.setState({ ...this.initialState }),
      });
    }
    const { subscriptionInput } = this.state;
    const isValid = validateSubscriptionInput(subscriptionInput);
    if (!isValid) {
      return;
    }
    const {
      merchantGroupIds,
      merchantIds,
      reportComponentGroups,
      reportParameters,
      filterState,
    } = this.props;
    this.setState({ isSubmitting: true });
    let successfulSubmission = false;
    let submissionError;
    try {
      existingReportSchedule
        ? await updateSubscription({
            existingReportSchedule,
            merchantGroupIds,
            merchantIds,
            reportComponentGroups,
            reportParameters,
            subscriptionInput,
          })
        : await createSubscription({
            merchantGroupIds,
            merchantList: merchantIds,
            reportComponentGroups,
            reportParameters,
            subscriptionInput,
            filterState,
          });
      successfulSubmission = true;

      updateReportBookmarkMetadata(existingReportSchedule.reportBookmark.id);

      onSubscriptionEdit && onSubscriptionEdit(subscriptionInput);
    } catch (error) {
      console.error(error);
      submissionError = error;
    }
    this.props.displayCustomGrowler({
      title: successfulSubmission ? 'Success' : 'Error',
      children: createNotificationMessage(
        successfulSubmission,
        subscriptionInput,
        existingReportSchedule || null
      ),
      type: successfulSubmission ? 'success' : 'alert',
    });
    this.setState(
      {
        isSubmitting: false,
        successfulSubmission,
        submissionError,
      },
      this.handleCloseModal
    );
  };

  render() {
    const {
      isValid,
      isSubmitting,
      modalOpen,
      submissionError,
      successfulSubmission,
      subscriptionInput,
    } = this.state;

    const {
      isAdmin,
      isLegacy,
      subscription: existingReportSchedule,
      title,
      reportType,
      disabled,
    } = this.props;

    const metadataIncludedInitialValue =
      existingReportSchedule &&
      typeof existingReportSchedule.metadataIncluded === 'boolean'
        ? existingReportSchedule.metadataIncluded
        : true;

    return (
      <>
        <button
          className={
            isLegacy ? 'btn mr-2 bg-gray-80' : 'text-gray-35 text-left w-100'
          }
          disabled={disabled}
          onClick={this.handleOpenModal}
        >
          {existingReportSchedule ? 'Edit Subscription Delivery' : 'Subscribe'}
        </button>
        <Modal
          closeOnBackgroundClick
          handleClose={this.handleCloseModal}
          open={modalOpen}
        >
          <ModalCard
            handleClose={this.handleCloseModal}
            maxWidth={685}
            overflow="scroll"
            padding={'1rem'}
          >
            <div className="w-full">
              <form
                className={styles.SubscriptionForm}
                name="create-subscription"
                onSubmit={this.handleSubmit}
              >
                <h3 className={styles.title}>{title}</h3>
                <Name
                  currentValue={subscriptionInput.name}
                  name="name"
                  onChange={this.handleUpdateSubscriptionInput}
                />
                {reportType !== 'TABLEAU' && (
                  <SubscriptionMetadata
                    initialValue={metadataIncludedInitialValue}
                    value={subscriptionInput.metadataIncluded}
                    onChange={this.handleChangeMetadataIncluded}
                  />
                )}
                <ExportOptions
                  currentValue={subscriptionInput.exportOptions}
                  key={Object.values(subscriptionInput.exportOptions).join('_')}
                  name="exportOptions"
                  onChange={this.handleUpdateSubscriptionInput}
                  reportType={reportType}
                />
                {isAdmin && (
                  <SelectCreator
                    currentValue={subscriptionInput.selectCreator}
                    key={
                      Object.values(subscriptionInput.selectCreator).join('_') +
                      this.props.merchantGroupIds
                    }
                    merchantGroupIds={this.props.merchantGroupIds}
                    name="selectCreator"
                    onChange={this.handleUpdateSubscriptionInput}
                  />
                )}
                <Frequency
                  currentValue={subscriptionInput.frequency}
                  name="frequency"
                  onChange={this.handleUpdateSubscriptionInput}
                />
                <DeliveryMethod
                  currentValue={subscriptionInput.delivery}
                  name="delivery"
                  onChange={this.handleUpdateSubscriptionInput}
                  key={subscriptionInput.delivery.deliveryType}
                />
                {submissionError && (
                  <p className="mb-2">
                    Error creating subscription. Please try again later.
                  </p>
                )}
                <ButtonOrSuccess
                  buttonText={existingReportSchedule ? 'Save changes' : 'Apply'}
                  disabled={!isValid}
                  isLoading={isSubmitting}
                  successMessage={
                    successfulSubmission
                      ? `Subscription ${
                          existingReportSchedule ? 'updated' : 'created'
                        }!`
                      : null
                  }
                />
              </form>
            </div>
          </ModalCard>
        </Modal>
      </>
    );
  }
}
