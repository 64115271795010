import React from 'react';
import { bool, func, string } from 'prop-types';
import styles from './Text.module.scss';

export default function Text({
  currentValue,
  isNumeric,
  filterName,
  filterDisplayName,
  filterType,
  onChange,
}) {
  const id = `${filterName}_input`;
  return (
    <div className={`${styles.TextFilter} form-group fl`}>
      <label htmlFor={id}>{filterDisplayName}</label>
      <input
        id={id}
        autoComplete="off"
        className="form-control"
        name={filterName}
        onChange={event => onChange(filterName, event.target.value)}
        placeholder={filterDisplayName}
        type={isNumeric ? 'number' : 'text'}
        value={currentValue}
      />
    </div>
  );
}

Text.propTypes = {
  currentValue: string,
  isNumeric: bool,
  filterDisplayName: string.isRequired,
  filterName: string.isRequired,
  filterType: string.isRequired,
  onChange: func,
};

Text.defaultProps = {
  currentValue: '',
};
