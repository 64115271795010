import { safeObjectDig } from 'utils/safeObjectDig';

function dateObjectToParams({ startDate, endDate, selectedPreset }) {
  if (selectedPreset) return selectedPreset.value;
  return `${startDate},${endDate}`;
}

function dropdownToParams(dropdownItems) {
  return dropdownItems.map(item => item.value).join(',');
}

function numericRangeToParams({ to, from }) {
  const toValue = to.value;
  const fromValue = from.value;
  return `${fromValue},${toValue}&${from.name}=${fromValue}&${to.name}=${toValue}`;
}

function formatValueToParam(value) {
  const isDropdown = Array.isArray(value);
  const isDateRange =
    typeof value === 'object' && !!safeObjectDig(value, 'startDate');
  const isNumericRange =
    typeof value === 'object' && !!safeObjectDig(value, 'to');
  if (isDropdown) return dropdownToParams(value);
  if (isDateRange) return dateObjectToParams(value);
  if (isNumericRange) return numericRangeToParams(value);
  return value;
}

export function encodeFilterState(filterState) {
  const filters = Object.entries(filterState);
  // return an array of filter=value
  // filter out those with value of null
  return filters
    .filter(([filterName, filterValue]) => {
      const isDropdown = Array.isArray(filterValue);
      return isDropdown ? !!filterValue.length : !!filterValue;
    })
    .map(([filterName, filterValue]) => {
      const encodedValue = formatValueToParam(filterValue);
      const urlParamName =
        filterName === 'review_date' ? 'date_range' : filterName;
      return filterValue ? `${urlParamName}=${encodedValue}` : null;
    })
    .join('&');
}

export function decodeFilterState(urlParams) {
  if (!urlParams) return {};
  return urlParams
    .split('&')
    .map(item => {
      const [urlParamName, filterValue] = item.split('=');
      const filterName =
        urlParamName === 'date_range' ? 'review_date' : urlParamName;
      return {
        [filterName]: decodeURIComponent(String(filterValue)),
      };
    })
    .reduce((filterState, filter) => {
      return { ...filterState, ...filter };
    }, {});
}
