import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { bool, string, object } from 'prop-types';
import { useQuery } from 'react-query';
import moment from 'moment';
import {
  EmailIcon,
  PencilAndPaperIcon,
  ListItemsIcon,
  TrashIcon,
} from 'icons/Icons';
import { Table, TableRow, TableCell } from 'components/Table';
import { TooltipButton } from 'components/TooltipButton';
import { ViewRuns } from '../ViewRuns';
import { Delete } from '../Delete';
import { MerchantName } from '../MerchantName';
import { LastRunAndStatus } from '../LastRunAndStatus';
import { fetchAllReportSchedules } from './SubscriptionSearchListUtils';
import { useBrowserPersistenceApi } from 'contexts/BrowserPersistenceContext';
import { usePrevious } from 'utils';

let lastRunAndStatusCache = {};

const properCase = word => {
  return `${word[0].toUpperCase()}${word.slice(1).toLowerCase()}`;
};
const SUBSCRIPTION_ACTIONS = {
  EDIT: 'edit',
  VIEW: 'view',
  DELETE: 'delete',
};

const tableHeaders = [
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'merchantGroupIds',
    label: 'Merchant Groups',
  },
  {
    key: 'createdTime',
    label: 'Created',
  },
  {
    key: 'updatedTime',
    label: 'Updated',
  },
  {
    key: 'frequency',
    label: 'Frequency',
  },
  {
    key: 'mostRecentRun',
    label: 'Last Run',
  },
  {
    key: 'status',
    label: 'Status',
  },
];

const compareMomentStrings = (a, b, property) => {
  let date1 = moment(a[property]);
  let date2 = moment(b[property]);

  if (!date1.isValid()) {
    date1 = moment(Date.now());
  }
  if (!date2.isValid()) {
    date2 = moment(Date.now());
  }

  if (date1.isAfter(date2)) return -1;
  if (!date1.isAfter(date2)) return 1;
  return 0;
};

const ErrorState = () => {
  return (
    <div
      style={{ height: 400 }}
      className="relative flex justify-center items-center"
    >
      <div className="flex flex-column justify-center items-center h-100">
        <p className="text-gray-35 mt-8">
          Failed to fetch subscriptions, please try again in a few moments
        </p>
      </div>
    </div>
  );
};

const LoadingState = () => {
  return (
    <div
      style={{ height: 400 }}
      className="relative flex justify-center items-center"
    >
      <div className="flex flex-column justify-center items-center h-100">
        <div className="spinner static" />
        <p className="text-gray-35 mt-8">Fetching subscriptions</p>
      </div>
    </div>
  );
};

const EmptyState = () => {
  return (
    <div className="py-8 px-12 flex flex-col items-center">
      <EmailIcon height="100" />
      <h1 className="my-2 text-gray-35">No subscriptions found</h1>
      <div className="pt-5 flex flex-column justify-center items-center">
        <p className="text-left mb-8 w-50 text-lg">
          Subscriptions allow you to take a snapshot of a report and have it
          delivered to you via Email or SFTP. To create a subscription, visit
          any report and look for the "Subscribe" button. Then, come back here
          to find and manage your subscription.
        </p>
        <Link className="btn btn--primary" to="/">
          Check out some reports
        </Link>
      </div>
    </div>
  );
};

export function SubscriptionSearchList({
  endpoint,
  isAdmin,
  mgIdNameDictionary,
  shouldFetch,
  setNotification,
  selectedMerchantGroupsByIds,
}) {
  const sortFns = {
    frequency: (a, b) => a.name.localeCompare(b.name),
    name: (a, b) => a.name.localeCompare(b.name),
    createdTime: (a, b) => compareMomentStrings(a, b, 'createdTime'),
    updatedTime: (a, b) => compareMomentStrings(a, b, 'updatedTime'),
    merchantGroupIds: (a, b) => {
      const mgIdsToNames = mgIds => {
        return mgIds
          .split(',')
          .map(id => mgIdNameDictionary[id])
          .join('');
      };

      const mgName1 = mgIdsToNames(a.merchantGroupIds);
      const mgName2 = mgIdsToNames(b.merchantGroupIds);
      return mgName1.localeCompare(mgName2);
    },
  };

  const reportSchedulesInitialState = {
    page: null,
    reportScheduleItems: null,
  };
  const [reportSchedules, setReportSchedules] = useState(
    reportSchedulesInitialState
  );
  const { reportScheduleItems } = reportSchedules;

  const [modalAction, setModalAction] = useState({
    type: null,
    subscriptionId: null,
    subscriptionName: null,
  });
  const closeModal = () => setModalAction({ type: null, subscriptionId: null });
  useEffect(() => {
    return () => (lastRunAndStatusCache = {});
  }, []);

  const browserCache = useBrowserPersistenceApi();
  const url = new URL(endpoint);

  const REPORT_SCHEDULES_RESPONSE_CACHE_KEY = `REPORT_SCHEDULES_${url.host +
    url.pathname}`;

  const [
    cachedFetchReportSchedulesResponse,
    setCachedFetchReportSchedulesResponse,
  ] = useState(null);

  const previouslySelectedMerchantGroupIds = usePrevious(
    serializeSelectedMerchantGroupsMap(selectedMerchantGroupsByIds)
  );
  const merchantGroupSelectionDidChange =
    previouslySelectedMerchantGroupIds &&
    previouslySelectedMerchantGroupIds !==
      serializeSelectedMerchantGroupsMap(selectedMerchantGroupsByIds);

  useEffect(() => {
    if (merchantGroupSelectionDidChange) {
      console.debug(`Clearing ${REPORT_SCHEDULES_RESPONSE_CACHE_KEY}`);
      setCachedFetchReportSchedulesResponse(null);
      setReportSchedules(reportSchedulesInitialState);

      browserCache
        .setItemAsync(REPORT_SCHEDULES_RESPONSE_CACHE_KEY, null)
        .then(() => {
          console.debug(`Cleared ${REPORT_SCHEDULES_RESPONSE_CACHE_KEY}`);
        })
        .catch(error => {
          console.debug(
            `Unable to clear ${REPORT_SCHEDULES_RESPONSE_CACHE_KEY} due to: ${error}`
          );
        });
    }
  }, [
    browserCache,
    merchantGroupSelectionDidChange,
    REPORT_SCHEDULES_RESPONSE_CACHE_KEY,
    reportSchedulesInitialState,
  ]);

  useEffect(
    function getReportScheduleResponseFromBrowserCache() {
      if (!cachedFetchReportSchedulesResponse) {
        console.debug(
          `Fetching report schedules from cache: ${REPORT_SCHEDULES_RESPONSE_CACHE_KEY}`
        );

        browserCache
          .getItemAsync(REPORT_SCHEDULES_RESPONSE_CACHE_KEY)
          .then(results => {
            setCachedFetchReportSchedulesResponse(results);
          })
          .catch(error =>
            console.debug(
              `Unable to set ${REPORT_SCHEDULES_RESPONSE_CACHE_KEY} due to: ${error}`
            )
          );
      }
    },
    [
      browserCache,
      cachedFetchReportSchedulesResponse,
      merchantGroupSelectionDidChange,
      REPORT_SCHEDULES_RESPONSE_CACHE_KEY,
      setCachedFetchReportSchedulesResponse,
    ]
  );

  const {
    data: fetchReportSchedulesResponse,
    error: fetchReportSchedulesErrorResponse,
  } = useQuery(shouldFetch && ['fetch_subscriptions', { endpoint }], () =>
    fetchAllReportSchedules(endpoint)
  );

  useEffect(
    function setReportScheduleResponseInBrowserCache() {
      if (fetchReportSchedulesResponse) {
        console.debug(
          `Setting value of ${REPORT_SCHEDULES_RESPONSE_CACHE_KEY}`
        );
        browserCache
          .setItemAsync(
            REPORT_SCHEDULES_RESPONSE_CACHE_KEY,
            fetchReportSchedulesResponse
          )
          .then(() => {
            console.debug(`Updated ${REPORT_SCHEDULES_RESPONSE_CACHE_KEY}`);
          })
          .catch(errorSettingReportSchedules => {
            console.debug(
              `Error setting ${REPORT_SCHEDULES_RESPONSE_CACHE_KEY} due to: ${errorSettingReportSchedules}`
            );
          });
      }
    },
    [
      browserCache,
      fetchReportSchedulesResponse,
      REPORT_SCHEDULES_RESPONSE_CACHE_KEY,
      setCachedFetchReportSchedulesResponse,
    ]
  );

  useEffect(
    function parseReportScheduleResponse() {
      if (
        !fetchReportSchedulesResponse &&
        !cachedFetchReportSchedulesResponse
      ) {
        return;
      }

      const staleOrFreshString = fetchReportSchedulesResponse
        ? 'fresh'
        : 'stale';

      console.debug(`Serving ${staleOrFreshString} subscriptions`);

      const {
        _embedded: { reportSchedule: reportScheduleItems },
        page,
      } = fetchReportSchedulesResponse || cachedFetchReportSchedulesResponse;

      setReportSchedules(current => ({
        page: page ? current.page : page,
        reportScheduleItems: reportScheduleItems,
      }));
    },
    [cachedFetchReportSchedulesResponse, fetchReportSchedulesResponse]
  );

  const [activeRow, setActiveRow] = useState(null);
  const renderTableBody = tableData => {
    return tableData.map((rowObj, index) => {
      const isActive = activeRow === rowObj.id;
      return (
        <TableRow
          isActive={isActive}
          onFocus={() => setActiveRow(rowObj.id)}
          onMouseEnter={() => setActiveRow(rowObj.id)}
          onMouseLeave={() => setActiveRow(null)}
          onBlur={() => setActiveRow(null)}
          key={rowObj.id}
        >
          {tableHeaders.map(headerObj => {
            const headerKey = headerObj.key;
            const value = rowObj[headerKey];
            const reactKey = rowObj.id + headerKey;
            if (headerKey === 'status') return null;
            if (headerKey === 'mostRecentRun') {
              return (
                <LastRunAndStatus
                  value={lastRunAndStatusCache[rowObj.id] || null}
                  onChange={(id, value) => (lastRunAndStatusCache[id] = value)}
                  key={headerKey}
                  subscriptionId={rowObj.id}
                />
              );
            }
            if (headerKey === 'name') {
              return (
                <TableCell key={reactKey} isTranslationEnabled={false}>
                  <Link to={`/subscriptions/${rowObj.id}`}>
                    <h4>{rowObj.name}</h4>
                  </Link>
                </TableCell>
              );
            }
            if (headerKey === 'frequency') {
              return (
                <TableCell key={rowObj.id + 'frequency'}>
                  {properCase(value)}
                </TableCell>
              );
            }
            if (headerKey === 'merchantGroupIds') {
              return (
                <TableCell key={reactKey} isTranslationEnabled={false}>
                  <MerchantName
                    merchantGroup={rowObj.merchantGroupIds}
                    mgIdNameDictionary={mgIdNameDictionary}
                  />
                </TableCell>
              );
            }
            if (headerKey === 'createdTime') {
              const createdAt = moment(value).format('MM/DD/YYYY');

              return (
                <TableCell key={reactKey} isTranslationEnabled={false}>
                  {createdAt}
                </TableCell>
              );
            }
            if (headerKey === 'updatedTime') {
              const reportScheduleEntityWasUpdated =
                rowObj.reportBookmark.updatedTime < rowObj.updatedTime;

              const subscriptionWasUpdatedByPowerReviews = reportScheduleEntityWasUpdated
                ? rowObj.updatedByPwr
                : rowObj.reportBookmark.updatedByPwr;

              const updatedAt = moment(
                reportScheduleEntityWasUpdated
                  ? rowObj.updatedTime
                  : rowObj.reportBookmark.updatedTime
              ).format('MM/DD/YYYY');

              const profileName = (function determineUpdatedByNameIfAvailable() {
                if (subscriptionWasUpdatedByPowerReviews) {
                  return 'PowerReviews';
                }

                // The ReportSchedule is the entity holding data about
                // the subscription (i.e., name, frequency, delivery details)
                if (reportScheduleEntityWasUpdated) {
                  return rowObj.updatedByProfileName;
                }

                // The ReportBookmark is the entity holding data about the
                // report being subscribed to (i.e., filter state and sort)
                return rowObj.reportBookmark.updatedByProfileName;
              })();

              return (
                <TableCell key={reactKey} isTranslationEnabled={false}>
                  {profileName ? `${updatedAt} by ${profileName}` : updatedAt}
                </TableCell>
              );
            }
            return <TableCell key={reactKey}>{value}</TableCell>;
          })}
          <TableCell hidden={!isActive}>
            <button
              aria-label={`View runs for ${rowObj.name}`}
              onClick={() =>
                setModalAction({
                  type: SUBSCRIPTION_ACTIONS.VIEW,
                  subscriptionId: rowObj.id,
                  scheduleName: rowObj.name,
                })
              }
            >
              <TooltipButton tooltipOrigin="top" tooltipText="View runs">
                <ListItemsIcon />
              </TooltipButton>
            </button>
            <Link
              aria-label={`Edit ${rowObj.name}`}
              style={{ color: 'black' }}
              to={`/subscriptions/${rowObj.id}`}
            >
              <TooltipButton tooltipOrigin="top" tooltipText="Edit">
                <PencilAndPaperIcon />
              </TooltipButton>
            </Link>
            <button
              aria-label={`Delete ${rowObj.name}`}
              onClick={() =>
                setModalAction({
                  type: SUBSCRIPTION_ACTIONS.DELETE,
                  subscriptionId: rowObj.id,
                  scheduleName: rowObj.name,
                })
              }
            >
              <TooltipButton tooltipOrigin="top" tooltipText="Delete">
                <TrashIcon />
              </TooltipButton>
            </button>
          </TableCell>
        </TableRow>
      );
    });
  };

  if (fetchReportSchedulesErrorResponse) return <ErrorState />;
  if (!shouldFetch || !reportScheduleItems) return <LoadingState />;

  return (
    <div className="p-4">
      <ViewRuns
        isOpen={modalAction.type === SUBSCRIPTION_ACTIONS.VIEW}
        closeModal={closeModal}
        scheduleId={modalAction.subscriptionId}
        scheduleName={modalAction.scheduleName}
      />
      <Delete
        isOpen={modalAction.type === SUBSCRIPTION_ACTIONS.DELETE}
        closeModal={closeModal}
        scheduleId={modalAction.subscriptionId}
        scheduleName={modalAction.scheduleName}
        setNotification={setNotification}
        onDelete={reportScheduleId => {
          setReportSchedules(reportSchedules => ({
            ...reportSchedules,
            reportScheduleItems: reportSchedules.reportScheduleItems.filter(
              ({ id }) => id !== reportScheduleId
            ),
          }));
        }}
      />

      {reportScheduleItems.length > 0 ? (
        <Table
          tableData={reportScheduleItems}
          tableHeaders={tableHeaders}
          paginationOptions={{ rowsPerPage: 25 }}
          searchOptions={{
            keys: ['name'],
            placeholder: `Search ${reportScheduleItems.length} items`,
          }}
          sortFns={sortFns}
          renderTableBody={renderTableBody}
        />
      ) : (
        <EmptyState />
      )}
    </div>
  );
}

SubscriptionSearchList.propTypes = {
  endpoint: string.isRequired,
  mgIdNameDictionary: object,
  shouldFetch: bool.isRequired,
};

function serializeSelectedMerchantGroupsMap(map) {
  return Array.from(map.keys())
    .sort()
    .join(',');
}
