export const exportTypeSelectItems = [
  {
    label: 'CSV',
    value: 'csv',
  },
  {
    label: 'Excel',
    value: 'xls',
  },
];
export const delimiterSelectItems = [
  {
    label: 'Comma-Separated',
    value: 'COMMA',
  },
  {
    label: 'Pipe-Separated',
    value: 'PIPE',
  },
  {
    label: 'Tab-Separated',
    value: 'TAB',
  },
  {
    label: 'Top-Of-Header',
    value: 'SOH',
  },
];
