import { getToken } from '@pwr/auth-provider';
import apiFetch from 'api/fetch';

const jsonArrayToJoinedString = array => JSON.parse(array).join(',');

export function constructFilterStateFromReportSchedule(
  reportSchedule,
  columnarReports
) {
  const {
    reportComponentGroup,
    reportBookmarkParameters,
  } = reportSchedule.reportBookmark;

  const reportDefinition = columnarReports._embedded.reportTemplate.find(
    ({ reportComponentGroups }) =>
      reportComponentGroups.find(({ id }) => id === reportComponentGroup.id)
  );

  const idNameDictionary = reportDefinition.reportParameters.reduce(
    (dictionary, { id, name }) => ({
      ...dictionary,
      [id]: name,
    }),
    {}
  );

  const filterState = reportBookmarkParameters.reduce(
    (filterState, { reportParameter, value }) => {
      const filterName = idNameDictionary[reportParameter.id];
      const validFilter = filterName && value;
      if (validFilter || filterName === 'merchant_list') {
        // Handle dropdowns, numeric range, and
        const isDropdown = value && value.indexOf('[') > -1;
        filterState[filterName] = isDropdown
          ? jsonArrayToJoinedString(value)
          : value;
      }
      return filterState;
    },
    {}
  );
  return filterState;
}

export function reportComponentGroupIdToReportTemplate(
  reportComponentGroupId,
  columnarReports
) {
  return columnarReports._embedded.reportTemplate.find(
    ({ reportComponentGroups }) =>
      reportComponentGroups.find(({ id }) => id === reportComponentGroupId)
  );
}

export function updateReportBookmarkParameters(
  reportBookmarkParameters,
  filterState
) {
  const requests = reportBookmarkParameters.map(
    ({ createdByProfileId, createdTime, identifier, _links }) => {
      const url = _links.self.href.replace('{?projection}', '');
      const options = {
        headers: {
          Authorization: getToken(),
          'Content-type': 'application/json',
        },
        method: 'PUT',
        body: JSON.stringify({
          createdByProfileId,
          createdTime,
          value: filterState[identifier],
        }),
      };
      return apiFetch(url, options);
    }
  );

  return Promise.all(requests);
}
