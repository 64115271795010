import { arrayOf, func, shape, string } from 'prop-types';
import React, { useState } from 'react';

import Modal from 'components/Modal';
import ModalCard from 'components/Modal/ModalCard';
import ColumnDndEditor from 'components/ColumnDndEditor';

export default function EditColumns({
  activeColumns,
  inactiveColumns,
  onChange,
}) {
  const activeColumnsList = {
    columnName: 'activeColumns',
    title: 'Active',
    items: activeColumns,
    listItemIcon: 'remove',
    onClickDestination: 'More Options',
  };
  const setOfActiveItems = new Set(
    activeColumnsList.items.map(item => item.propertyName)
  );
  const inactiveColumnsList = {
    columnName: 'inactiveColumns',
    title: 'More Options',
    items: inactiveColumns.filter(
      item => !setOfActiveItems.has(item.propertyName)
    ),
    listItemIcon: 'add',
    onClickDestination: 'Active',
    sorted: true,
  };
  const lists = [activeColumnsList, inactiveColumnsList];
  const [modalOpen, setModalOpen] = useState(false);
  const closeModal = () => setModalOpen(false);
  return (
    <>
      <button
        onClick={() => setModalOpen(true)}
        className="btn btn--tag text-sm"
      >
        Edit Columns
      </button>
      <Modal closeOnBackgroundClick handleClose={closeModal} open={modalOpen}>
        <ModalCard
          height={'90%'}
          maxWidth={685}
          padding={'1rem'}
          handleClose={closeModal}
        >
          <div data-translate={true}>
            <div className="w-100 p-4">
              <h2>Edit Columns</h2>
              <p>Drag columns to edit and reorder</p>
            </div>
            <ColumnDndEditor
              lists={lists}
              onChange={changes => {
                closeModal();
                onChange(changes);
              }}
              open={modalOpen}
            />
          </div>
        </ModalCard>
      </Modal>
    </>
  );
}
EditColumns.propTypes = {
  onChange: func.isRequired,
  activeColumns: arrayOf(shape({ propertyName: string.isRequired })),
  inactiveColumns: arrayOf(shape({ propertyName: string.isRequired })),
};
