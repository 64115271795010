import React from 'react';
import classnames from 'classnames';
import {
  arrayOf,
  func,
  node,
  number,
  oneOf,
  oneOfType,
  string,
} from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';

import { DraggableIcon } from '../../icons/Icons';
import ListItemButton from './ListItemButton';
import styles from './ColumnDndEditor.module.scss';

export default function ListItem({
  children,
  draggableId,
  droppableId,
  hidden,
  icon,
  index,
  onListItemClick,
}) {
  return (
    <Draggable draggableId={draggableId} index={index}>
      {(provided, snapshot) => {
        return (
          <div
            className={classnames(styles['ListItem'], {
              [styles[`ListItem--${icon}`]]: icon,
              [styles['ListItem--dragging']]: snapshot.isDragging,
              [styles['ListItem--hidden']]: hidden,
            })}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            draggable="true"
          >
            <DraggableIcon />
            <div className={styles['ListItem__content']}>{children}</div>
            <ListItemButton
              aria-label={`${icon} ${children} column`}
              icon={icon}
              index={index}
              droppableId={droppableId}
              onListItemClick={onListItemClick}
            />
          </div>
        );
      }}
    </Draggable>
  );
}

ListItem.propTypes = {
  children: oneOfType([arrayOf(node), node]),
  draggableId: string.isRequired,
  droppableId: string.isRequired,
  icon: oneOf(['add', 'remove']),
  index: number.isRequired,
  onListItemClick: func,
};
