import React, { useState } from 'react';
import { MultiSelect, useSimpleFilter } from '@pwr-ui/select';
import { arrayOf, shape, func, oneOfType, string } from 'prop-types';
import '@pwr-ui/select/dist/pwr-ui-select.css';
import styles from './Dropdown.module.scss';

const itemToKey = item => item.value;
const itemToString = item => (item != null ? item.label : '');
const arrayOfValuesToMap = item => [itemToKey(item), item];
const getSelectedItems = (currentValue, items) => {
  if (Array.isArray(currentValue)) {
    const arrayOfPrimitives = typeof currentValue[0] !== 'object';
    if (arrayOfPrimitives) {
      const selectedItems = items.filter(item =>
        currentValue.includes(item.value)
      );
      return new Map(selectedItems.map(arrayOfValuesToMap));
    }
    return new Map(currentValue.map(arrayOfValuesToMap));
  }
  const currentValuesFromUrl = currentValue.split(',');
  return new Map(
    items
      .filter(item => currentValuesFromUrl.includes(item.value))
      .map(arrayOfValuesToMap)
  );
};

export default function Dropdown({
  currentValue,
  filterDisplayName,
  filterName,
  filterType,
  items,
  onChange,
}) {
  const [inputValue, setInputValue] = useState('');
  const filteredItems = useSimpleFilter(items, itemToString, inputValue);
  const handleChange = selectedItems => {
    onChange(filterName, [...selectedItems.values()]);
  };
  return (
    <div className={`${styles.Dropdown} form-group fl`}>
      <label htmlFor={filterName}>{filterDisplayName}</label>
      <MultiSelect
        id={filterName}
        items={filteredItems || []}
        itemToKey={itemToKey}
        itemToString={itemToString}
        onChange={handleChange}
        value={getSelectedItems(currentValue, filteredItems)}
        inputValue={inputValue}
        onInputValueChange={setInputValue}
      />
    </div>
  );
}

Dropdown.propTypes = {
  currentValue: oneOfType([
    arrayOf(
      shape({
        label: string,
        value: string,
      })
    ),
    string, // This is the case where filter values come from URL params
  ]),
  filterDisplayName: string.isRequired,
  filterName: string.isRequired,
  filterType: string.isRequired,
  onChange: func,
};
