import React, { useEffect, useState } from 'react';
import { bool, func, number, string } from 'prop-types';
import moment from 'moment';
import Modal from 'components/Modal';
import ModalCard from 'components/Modal/ModalCard';
import Pagination from 'components/Pagination';
import { StatusDot } from 'components/StatusDot';

import { useScheduleIdToRuns } from './useScheduleIdToRuns';
import { tokenToDownload } from './tokenToDownload';
import style from './ViewRuns.module.scss';

function runStatusMessage(runStatus, runDate) {
  const formattedRunDate = moment(runDate).format('MM/DD/YYYY');
  if (runStatus === 'COMPLETED')
    return (
      <div className="flex">
        <StatusDot color="green" message={runStatus} />
        <p className="ml-2">Completed on {formattedRunDate}</p>
      </div>
    );
  if (runStatus === 'RUNNING')
    return (
      <div className="flex">
        <StatusDot color="yellow" message={runStatus} />
        <p className="ml-2">Subscription is being run right now</p>
      </div>
    );
  return (
    <div className="flex">
      <StatusDot color="red" message={runStatus} />
      <p className="ml-2">Failed on {formattedRunDate}</p>
    </div>
  );
}

export function ViewRuns({ closeModal, isOpen, scheduleId, scheduleName }) {
  const [pageNumber, setPageNumber] = useState(1);
  const { response, isLoading, error } = useScheduleIdToRuns(
    scheduleId,
    pageNumber
  );
  const [token, setToken] = useState(null);
  useEffect(() => {
    if (!token) return;
    tokenToDownload(token).catch(console.error);
  }, [token]);
  if (!isOpen) return null;
  const hasRuns = response && response._embedded.reportScheduleRun.length > 0;
  return (
    <Modal closeOnBackgroundClick handleClose={closeModal} open={isOpen}>
      <ModalCard
        height={hasRuns ? '85%' : 250}
        maxWidth={685}
        padding={'1rem'}
        handleClose={closeModal}
        isTranslationEnabled={true}
      >
        {error && (
          <p>
            Failed to load subscription runs for {scheduleName}, please try
            again in a few moments
          </p>
        )}
        {isLoading && <div className="spinner" />}
        {response && (
          <div className={style.RunsListContainer}>
            <h3 className={style.RunsListContainer__heading}>
              Subscription Runs
              {response.page.totalElements > 0
                ? ` (${response.page.totalElements})`
                : ''}
              :
            </h3>
            <div>
              <ul
                style={{
                  height: response._embedded.reportScheduleRun.length * 50,
                }}
                className={style.RunsList}
              >
                {!hasRuns && (
                  <>
                    <p className="mt-4">
                      The subscription{' '}
                      <span data-translate={false}>"{scheduleName}"</span> has
                      been created, but has no subscription runs yet.
                    </p>
                    <div className="flex mt-4">
                      <button onClick={closeModal} className="btn">
                        Close
                      </button>
                    </div>
                  </>
                )}
                {hasRuns &&
                  response._embedded.reportScheduleRun.map(
                    ({ runDate, runStatus, token, createdTime }) => (
                      <li key={createdTime + runStatus}>
                        {runStatusMessage(runStatus, runDate)}
                        {token && (
                          <button
                            onClick={() => setToken(token)}
                            className="btn btn--primary ml-6"
                          >
                            Download
                          </button>
                        )}
                      </li>
                    )
                  )}
              </ul>
            </div>
          </div>
        )}
        {response && response.page.totalPages > 1 && (
          <div className="flex justify-end w-100 mb-2" data-translate={true}>
            <Pagination
              currentPage={pageNumber}
              totalPages={response.page.totalPages}
              onChange={setPageNumber}
            />
          </div>
        )}
      </ModalCard>
    </Modal>
  );
}

ViewRuns.propTypes = {
  closeModal: func.isRequired,
  isOpen: bool.isRequired,
  scheduleId: number,
  scheduleName: string,
};
