import React from 'react';
import { func, number, oneOf, string } from 'prop-types';
import classnames from 'classnames';
import styles from './ColumnDndEditor.module.scss';

export default function ListItemButton({
  droppableId,
  icon,
  index,
  onListItemClick,
  ...restProps
}) {
  if (!icon) {
    return null;
  }
  return (
    <button
      className={classnames(
        styles['ListItem__button'],
        ([styles[`ListItem__button--${icon}`]]: icon)
      )}
      onClick={() => {
        onListItemClick({
          source: { index, droppableId },
        });
      }}
      {...restProps}
    >
      {icon}
    </button>
  );
}

ListItemButton.propTypes = {
  droppableId: string.isRequired,
  icon: oneOf(['add', 'remove']),
  index: number.isRequired,
  onListItemClick: func,
};
