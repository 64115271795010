import React from 'react';
import { func, number, oneOf } from 'prop-types';
import classnames from 'classnames';
import styles from './ExpandablePanelButton.module.scss';
import { PlusIcon, MinusIcon } from '../../../icons/Icons';

export default function ExpandablePanelButton({ count, onClick, type }) {
  return (
    <button
      aria-label="expand-panel-button"
      type="button"
      className={classnames(styles.ExpandablePanelButton, {
        [styles['ExpandablePanelButton--primary']]:
          count > 0 && type !== 'collapse',
        [styles['ExpandablePanelButton--gray']]:
          count < 1 || type === 'collapse',
      })}
      onClick={onClick}
    >
      <p className={classnames(styles['ExpandablePanelButton__sign'])}>
        {type === 'expand' ? <PlusIcon /> : <MinusIcon />}
        <span className={styles.ExpandablePanelButton__buttonText}>
          {type === 'expand' ? 'expand' : 'collapse'}
        </span>
      </p>
      {count > 0 && type === 'expand' && (
        <p className={styles['ExpandablePanelButton__count']}>{count}</p>
      )}
    </button>
  );
}

ExpandablePanelButton.propTypes = {
  count: number.isRequired,
  onClick: func.isRequired,
  type: oneOf(['collapse', 'expand']),
};
