import React from 'react';
import { arrayOf, func, shape, string } from 'prop-types';
import classnames from 'classnames';
import { Draggable } from 'react-beautiful-dnd';

import { DraggableIcon } from '../../icons/Icons';
import styles from './ColumnarGrid.module.scss';

export const GridHeader = ({
  activeColumn,
  activeColumnDirection,
  columns,
  innerRef,
  onClick,
  droppableProvided,
}) => {
  return (
    <div
      {...droppableProvided.draggableProps}
      {...droppableProvided.dragHandleProps}
      ref={droppableProvided.innerRef}
      className={classnames(styles['GridRow'], styles['GridHeader'])}
    >
      {columns.map(({ columnWidth, displayName, propertyName }, index) => {
        const isActive = activeColumn === propertyName;
        return (
          <Draggable
            direction="horizontal"
            draggableId={propertyName}
            index={index}
            key={propertyName}
          >
            {(draggableProvided, snapshot) => (
              <div
                className={classnames(
                  'text-sm',
                  styles['Cell'],
                  styles['Cell--header'],
                  { [styles[`Cell--${activeColumnDirection}`]]: isActive },
                  { [styles['Cell--active']]: isActive },
                  { [styles['Cell--dragging']]: snapshot.isDragging }
                )}
                onClick={() => onClick(propertyName)}
                ref={draggableProvided.innerRef}
                {...draggableProvided.draggableProps}
                {...draggableProvided.dragHandleProps}
                style={{
                  ...draggableProvided.draggableProps.style,
                  width: columnWidth,
                }}
                draggable={true}
              >
                <DraggableIcon className={styles.DraggableIcon} />
                <div>{displayName || propertyName}</div>
                {draggableProvided.placeholder}
              </div>
            )}
          </Draggable>
        );
      })}
      {droppableProvided.placeholder}
    </div>
  );
};

GridHeader.propTypes = {
  activeColumn: string,
  activeColumnDirection: string,
  columns: arrayOf(
    shape({
      propertyName: string.isRequired,
      displayName: string, // Optional property when the column name should look nicer
    })
  ).isRequired,
  onClick: func,
};
