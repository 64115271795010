import { safeObjectDig } from 'utils/safeObjectDig';
import apiFetch from 'api/fetch';
import axios from 'axios';
import {
  REPORT_BOOKMARK_PARAMETER,
  REPORT_BOOKMARK,
  REPORT_BOOKMARK_METADATA,
  REPORT_PARAMETER,
  REPORT_SCHEDULE_RECIPIENT,
  REPORT_SCHEDULE,
} from 'api/endpoints';
import { getToken } from '@pwr/auth-provider';
import { DEFAULT_DATE_RANGE } from 'components/DatePicker/constants';

function formatReportShares(
  sharedWithFormInput,
  sharedWithReportBookmark,
  url
) {
  if (!sharedWithFormInput) return [];
  return sharedWithFormInput.split(',').map(id => {
    const existingShare = sharedWithReportBookmark.find(
      profile => String(profile.sharedWith) === id
    );
    const newShare = {
      reportBookmark: url,
      sharedWith: String(id),
    };
    return existingShare || newShare;
  });
}

export async function updateReportBookmark({
  bookmarkName,
  reportBookmark,
  sharedWith,
}) {
  delete reportBookmark.reportBookmarkParameters;
  const url = `${REPORT_BOOKMARK}/${reportBookmark.id}`;
  const body = {
    ...reportBookmark, // reporting-services needs many of these properties
    name: bookmarkName,
    reportBookmarkShares: formatReportShares(
      sharedWith,
      reportBookmark.reportBookmarkShares,
      url
    ),
  };
  const options = {
    headers: {
      Authorization: getToken(),
      'Content-type': 'application/json',
    },
    method: 'PUT',
    body: JSON.stringify(body),
  };
  return await apiFetch(url, options).then(res => res.json());
}
export async function createSavedReport({
  filterState,
  formInput,
  merchantGroupIds,
  merchantIds,
  reportComponentGroups,
  reportParameters,
}) {
  const reportBookmark = await createReportBookmark(
    formInput.bookmarkName,
    reportComponentGroups
  );
  await createReportBookmarkParameters(
    reportBookmark.id,
    reportParameters,
    merchantGroupIds,
    filterState,
    merchantIds
  );
  const sharedWith = formInput.selectedProfileIds;

  if (sharedWith) {
    const reportBookmarkWithShares = await updateReportBookmark({
      reportBookmark,
      sharedWith,
      bookmarkName: reportBookmark.name,
    });
    return {
      ...reportBookmarkWithShares,
      reportComponentGroup: reportComponentGroups,
    };
  }
  return {
    ...reportBookmark,
    reportComponentGroup: reportComponentGroups,
  };
}

export function createSubscription({
  merchantGroupIds,
  merchantList,
  reportComponentGroups,
  reportParameters,
  subscriptionInput,
  filterState,
}) {
  return new Promise(async (resolve, reject) => {
    try {
      const reportBookmarkResponse = await createReportBookmark(
        subscriptionInput.name,
        reportComponentGroups
      );
      await createReportBookmarkParameters(
        reportBookmarkResponse.id,
        reportParameters,
        merchantGroupIds,
        filterState,
        merchantList
      );
      const reportSchedule = await createReportSchedule(
        reportBookmarkResponse.id,
        subscriptionInput
      );
      await createReportScheduleRecipient(reportSchedule, subscriptionInput);
      resolve();
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
}

function makeRequestPayloadWithBody(body) {
  return {
    headers: {
      Authorization: getToken(),
      'Content-type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(body),
  };
}

async function createReportBookmark(name, reportComponentGroups) {
  const requestBody = makeReportBookmarkBody(name, reportComponentGroups);
  const requestPayload = makeRequestPayloadWithBody(requestBody);
  try {
    const reportBookmark = await apiFetch(REPORT_BOOKMARK, requestPayload).then(
      res => res.json()
    );
    return reportBookmark;
  } catch (error) {
    return error;
  }
}

function makeReportBookmarkBody(name, reportComponentGroups) {
  const reportComponentGroup = safeObjectDig(
    reportComponentGroups,
    '_links.self.href'
  );
  return {
    name,
    reportComponentGroup,
    visibleToAll: false,
  };
}

function createReportBookmarkParameters(
  reportBookmarkId,
  reportParameters,
  merchantGroupIds,
  filterState,
  merchantList
) {
  return new Promise(async (resolve, reject) => {
    const requests = reportParameters.map(bookmarkParameter => {
      const requestBody = makeReportBookmarkParametersBody(
        bookmarkParameter,
        reportBookmarkId,
        reportParameters,
        merchantGroupIds,
        filterState,
        merchantList
      );
      const requestPayload = makeRequestPayloadWithBody(requestBody);
      return apiFetch(REPORT_BOOKMARK_PARAMETER, requestPayload).then(res =>
        res.json()
      );
    });
    try {
      await Promise.all(requests);
      resolve();
    } catch (error) {
      reject(error);
    }
  });
}

function dropdownValuesToString(dropdownItems) {
  return dropdownItems.length > 0
    ? JSON.stringify(dropdownItems.map(dropdownItem => dropdownItem.value))
    : null; // Empty arrays will not be sent as report bookmark parameters
}

function makeReportBookmarkParametersBody(
  bookmarkParameter,
  reportBookmarkId,
  reportParameters,
  merchantGroupIds,
  filterState,
  merchantList
) {
  const { id, identifier } = bookmarkParameter;
  let filterValue = filterState[identifier];
  const isDropdownItem = Array.isArray(filterValue);
  if (isDropdownItem) {
    filterValue = dropdownValuesToString(filterValue);
  }
  const isNumericRange =
    typeof filterValue === 'object' && safeObjectDig(filterValue, 'to');
  if (isNumericRange) {
    const { from, to } = filterValue;
    filterValue = `${from.value},${to.value}`;
  }
  const isMerchantList = identifier === 'merchant_list';

  let paramValue = filterValue;
  if (identifier === 'merchant_group_list') {
    paramValue = String(merchantGroupIds);
  }
  if (isMerchantList) {
    paramValue = merchantList ? String(merchantList) : null;
  }

  if (identifier === 'review_date' && typeof filterValue !== 'string') {
    // If date objects have a `selectedPreset` property,
    // that value should be chosen over the specific form
    // of the date (i.e., LAST_3_MONTHS over '2018-01-01, 2018-04-01')
    const hasSpecificReviewDate =
      safeObjectDig(filterValue, 'startDate') &&
      safeObjectDig(filterValue, 'endDate');
    const hasSelectedPreset = safeObjectDig(filterValue, 'selectedPreset');
    if (hasSpecificReviewDate) {
      paramValue = `${filterValue.startDate},${filterValue.endDate}`;
    }
    if (hasSelectedPreset) {
      paramValue = filterValue.selectedPreset.value;
    }
    if (!paramValue) {
      paramValue = DEFAULT_DATE_RANGE.value;
    }
  }
  const payload = {
    reportBookmark: `${REPORT_BOOKMARK}/${reportBookmarkId}`,
    reportParameter: `${REPORT_PARAMETER}/${id}`,
    value: paramValue || null,
  };

  if (!paramValue && !isMerchantList) {
    delete payload.value;
  }

  return payload;
}

function createReportSchedule(reportBookmarkId, subscriptionInput) {
  return new Promise(async (resolve, reject) => {
    const requestBody = makeReportScheduleBody(
      reportBookmarkId,
      subscriptionInput
    );
    const requestPayload = makeRequestPayloadWithBody(requestBody);
    try {
      const reportSchedule = await apiFetch(
        REPORT_SCHEDULE,
        requestPayload
      ).then(res => res.json());
      resolve(reportSchedule._links.self.href);
    } catch (error) {
      reject(error);
    }
  });
}
// All values from the form are saved in component state, but when the user submits,
// certain values need to be nulled out to prevent illegal combinations of inputs
function makeReportScheduleBody(reportBookmarkId, subscriptionInput) {
  const {
    exportOptions,
    frequency,
    metadataIncluded,
    name,
    selectCreator,
  } = subscriptionInput;
  let { monthDay, monthWeek, weekDay } = frequency;
  const selectedFrequency = frequency.frequency;
  const fileType = exportOptions.exportType;
  let delimiter = exportOptions.delimiter;

  if (selectedFrequency === 'DAILY') {
    monthDay = null;
    monthWeek = null;
    weekDay = null;
  }

  if (selectedFrequency === 'MONTHLY') {
    const usingDayOfWeek = frequency.isUsingWeekday;
    monthDay = usingDayOfWeek ? null : monthDay;
    monthWeek = usingDayOfWeek ? monthWeek : null;
    weekDay = usingDayOfWeek ? weekDay : null;
  }

  if (selectedFrequency === 'WEEKLY') {
    monthDay = 0; // Reporting services expects `monthDay` to be 0 when using weekly
    monthWeek = null;
  }

  if (fileType === 'xls') {
    delimiter = 'COMMA';
  }

  return {
    createdForProfileId: selectCreator.createdForProfileId,
    createdForProfileName: selectCreator.createdForProfileName,
    delimiter,
    exportType: fileType,
    frequency: selectedFrequency,
    metadataIncluded,
    monthDay,
    monthWeek,
    weekDay,
    name,
    reportBookmark: `${REPORT_BOOKMARK}/${reportBookmarkId}`,
  };
}

function createReportScheduleRecipient(reportSchedule, subscriptionInput) {
  return new Promise(async (resolve, reject) => {
    const requestBody = makeReportScheduleRecipientBody(
      reportSchedule,
      subscriptionInput
    );
    const requestPayload = makeRequestPayloadWithBody(requestBody);
    try {
      const reportScheduleRecipient = await apiFetch(
        REPORT_SCHEDULE_RECIPIENT,
        requestPayload
      ).then(res => res.json());
      resolve(reportScheduleRecipient);
    } catch (error) {
      reject(error);
    }
  });
}

function makeReportScheduleRecipientBody(reportSchedule, subscriptionInput) {
  let {
    deliveryType,
    email,
    ftpPassword,
    ftpUserName,
    ftpUrl,
    message,
  } = subscriptionInput.delivery;

  if (deliveryType === 'EMAIL') {
    ftpPassword = '';
    ftpUserName = '';
    ftpUrl = '';
  }

  if (deliveryType !== 'EMAIL') {
    email = '';
    message = '';
  }

  return {
    deliveryType,
    email,
    ftpPassword,
    ftpUserName,
    ftpUrl,
    message,
    reportSchedule,
  };
}

export function validateSubscriptionInput({ delivery, name }) {
  const deliveringByEmail = delivery.deliveryType === 'EMAIL';
  if (!name.trim()) {
    return false;
  }
  if (deliveringByEmail) {
    if (!delivery.email) {
      return false;
    }
  }
  if (!deliveringByEmail) {
    const { ftpPassword, ftpUrl, ftpUserName } = delivery;
    if (!ftpPassword || !ftpUrl || !ftpUserName) {
      return false;
    }
  }
  return true;
}

/**
 * The response payload for a subscription is a different shape than
 * the request payload. Then there's component state, which also
 * a different shape. This function transforms the request
 * payload into component state (for loading subscription data)
 * @param {object} payload
 * @returns {object} An object that can be used to hydrate
 * each inputs' state.
 */
export function payloadToComponentState(payload) {
  let {
    delimiter,
    exportType,
    frequency,
    metadataIncluded,
    monthDay,
    monthWeek,
    weekDay,
    createdForProfileId,
    createdForProfileName,
    name,
  } = payload;
  const {
    createdByProfileId,
    deliveryType,
    email,
    ftpPassword,
    ftpUrl,
    ftpUserName,
    // id, // Is this ever used?
    message,
  } = payload.reportScheduleRecipients[0];

  if (createdForProfileName) {
    // We have to trim the `createdForProfileName` that comes from the
    // response because a terminal space is appended to the end
    createdForProfileName = createdForProfileName.trim();
  }
  const isUsingWeekday = frequency === 'MONTHLY' && monthDay === null;
  return {
    name,
    metadataIncluded,
    exportOptions: {
      delimiter,
      exportType,
    },
    selectCreator: {
      createdByProfileId,
      createdForProfileId: createdForProfileId,
      createdForProfileName: createdForProfileName,
    },
    frequency: {
      frequency: frequency || 'MONTHLY',
      isUsingWeekday: isUsingWeekday || false,
      monthDay: monthDay || 0,
      monthWeek: monthWeek || 'FIRST',
      weekDay: weekDay || 'SUNDAY',
    },
    delivery: {
      deliveryType,
      email,
      ftpPassword,
      ftpUserName,
      ftpUrl,
      message,
    },
  };
}

export function createNotificationMessage(
  successful,
  subscriptionInput,
  isEditing
) {
  if (!successful) {
    return `Failed to create subscription: "${
      subscriptionInput.name
    }". Please try again in a few moments.`;
  }
  const { frequency } = subscriptionInput.frequency;
  if (isEditing) {
    return `Edited subscription delivery settings for ${
      subscriptionInput.name
    }`;
  }
  return `Created ${frequency.toLowerCase()} subscription: ${
    subscriptionInput.name
  }`;
}

/**
 * Updating subscriptions
 */
export function updateSubscription({
  existingReportSchedule,
  merchantGroupIds,
  merchantIds,
  reportComponentGroups,
  reportParameters,
  subscriptionInput,
}) {
  return new Promise(async (resolve, reject) => {
    try {
      await updateReportSchedule(existingReportSchedule, {
        ...subscriptionInput,
        merchantGroupIds,
        merchantIds,
      });
      await updateReportScheduleRecipient(existingReportSchedule, {
        ...subscriptionInput,
        merchantGroupIds,
        merchantIds,
      });
      resolve();
    } catch (error) {
      reject(error);
    }
  });
}
export function makeReportSchedulePutBody(reportSchedule, subscriptionInput) {
  const {
    delivery,
    selectCreator,
    frequency,
    exportOptions,
    isUsingWeekday,
    dateRangeValue,
    merchantGroupIds,
    merchantIds,
    name,
  } = subscriptionInput;
  const {
    createdTime,
    createdByProfileId,
    id,
    filename,
    encrypted,
    publicKey,
    updatedTime,
    reportBookmark,
    _links,
  } = reportSchedule;

  return {
    ...delivery,
    ...selectCreator,
    ...exportOptions,
    ...frequency,
    filename,
    encrypted,
    publicKey,
    isUsingWeekday,
    message: delivery.message ? delivery.message : null,
    dateRangeValue,
    merchantGroupIds,
    merchantIds,
    name,
    createdTime,
    createdByProfileId,
    id,
    reportBookmark: _links.self.href + `/${reportBookmark.id}`,
    updatedTime,
    ...makeReportScheduleBody(
      reportSchedule.reportBookmark.id,
      subscriptionInput
    ),
  };
}
export function makeReportScheduleRecipientPutBody(
  reportSchedule,
  subscriptionInput
) {
  const { delivery } = subscriptionInput;
  const { id, createdTime, createdByProfileId } = reportSchedule;
  return {
    ...delivery,
    id,
    createdByProfileId,
    createdTime,
  };
}
function updateReportSchedule(existingReportSchedule, subscriptionInput) {
  const url = `${REPORT_SCHEDULE}/${existingReportSchedule.id}`;
  const options = {
    headers: {
      Authorization: getToken(),
      'Content-type': 'application/json',
    },
    method: 'PUT',
    body: JSON.stringify(
      makeReportSchedulePutBody(existingReportSchedule, subscriptionInput)
    ),
  };
  return apiFetch(url, options);
}
function updateReportScheduleRecipient(reportSchedule, subscriptionInput) {
  const reportScheduleRecipientId =
    reportSchedule.reportScheduleRecipients[0].id;
  const url = `${REPORT_SCHEDULE_RECIPIENT}/${reportScheduleRecipientId}`;
  const options = {
    headers: {
      Authorization: getToken(),
      'Content-type': 'application/json',
    },
    method: 'PUT',
    body: JSON.stringify(
      makeReportScheduleRecipientPutBody(reportSchedule, subscriptionInput)
    ),
  };
  return apiFetch(url, options);
}

export async function updateReportBookmarkMetadata(reportBookmarkId) {
  // https://github.com/powerreviews/reporting/pull/1237/files
  // In order to enable accurate Report Bookmark data, we have to hit this
  // endpoint: @PutMapping(path = "/touch/{reportBookmarkId}")
  // This is done solely to modify the `updatedByProfileName`
  // in the backend. See this ticket for more details:
  // https://powerreviews.atlassian.net/jira/software/c/projects/RP/boards/146?modal=detail&selectedIssue=RP-2887

  try {
    await axios.put(
      `${REPORT_BOOKMARK_METADATA}/${reportBookmarkId}`,
      // Nothing is sent in the PUT body because RP Services
      // will adjust the `updatedByProfileName` via our JWT
      null,
      {
        headers: {
          Authorization: getToken(),
          'Content-type': 'application/json',
        },
      }
    );
  } catch (error) {
    console.error('Unable to update Report Bookmark metadata');
  }
}
