import { bool, number, shape, string } from 'prop-types';

export const reportComponentGroup = shape({
  createdByProfileId: number,
  createdTime: string,
  displayOrder: number,
  exportEngine: string,
  exportTypes: string,
  exportUri: string,
  id: number,
  identifier: string,
  name: string,
  // reportComponentColumns: arrayOf(),
  // reportComponents: arrayOf(),
  reportTemplate: shape({
    id: number,
    _links: shape({
      reportComponentGroups: shape({
        href: string,
      }),
      reportParameters: shape({
        href: string,
      }),
      reportTemplateMerchantGroups: shape({
        href: string,
        templated: bool,
      }),
    }),
  }),
  sqlClause: string,
  updateByProfileId: number,
  updatedTime: string,
  validateTableColumns: bool,
  version: number,
});
