import React, { useState, useEffect } from 'react';
import { bool, func, number, string } from 'prop-types';
import Modal from 'components/Modal';
import ModalCard from 'components/Modal/ModalCard';
import { useDeleteByScheduleId } from './useDeleteByScheduleId';

export function Delete({
  closeModal,
  isOpen,
  onDelete,
  scheduleId,
  scheduleName,
  setNotification,
}) {
  const [proceedToDelete, setProceedToDelete] = useState(false);
  let error, isLoading, response;

  ({ error, isLoading, response } = useDeleteByScheduleId(
    proceedToDelete ? scheduleId : null,
    onDelete
  ));
  const successfullyDeleted = response && response.ok;

  const handleClose = () => {
    closeModal();
    setProceedToDelete(false);
  };

  useEffect(() => {
    setProceedToDelete(false);
    closeModal();
    if (!response) return;

    setNotification({
      type: error ? 'alert' : 'success',
      title: 'Success!',
      message: `${
        successfullyDeleted ? 'Deleted' : 'Failed to delete'
      } subscription: "${scheduleName}"`,
    });
  }, [response]);

  return (
    <Modal closeOnBackgroundClick handleClose={handleClose} open={isOpen}>
      <ModalCard
        maxWidth={685}
        height={200}
        padding={'1rem'}
        handleClose={handleClose}
        isTranslationEnabled={true}
      >
        {scheduleName && !response && (
          <div className="mt-4 p-4 h-100 flex flex-column justify-around">
            <h3>Delete Subscription</h3>
            <p>
              You are about to delete subscription:{' '}
              <span data-translate={false}>"{scheduleName}"</span>
            </p>
            <div className="flex">
              <button onClick={handleClose} className="btn mr-4">
                Cancel
              </button>
              <button
                onClick={() => setProceedToDelete(true)}
                className="btn btn--negative"
                disabled={isLoading}
              >
                {isLoading ? 'Deleting' : 'Delete'} subscription
              </button>
            </div>
            <p className="pt-1" role="alert">
              {error
                ? 'Failed to delete subscription. Please refresh and try again in a few moments.'
                : null}
            </p>
          </div>
        )}
      </ModalCard>
    </Modal>
  );
}

Delete.propTypes = {
  closeModal: func.isRequired,
  isOpen: bool.isRequired,
  scheduleId: number,
  scheduleName: string,
};
