import React from 'react';
import Main from 'pages/Main';
import { REGENERATE, AUTH_SERVICE } from 'api/endpoints';
import { ConfigProvider, ConfigConsumer } from 'contexts/ConfigContext';
import { BrowserPersistenceProvider } from 'contexts/BrowserPersistenceContext';
import { MerchantGroupProvider } from 'contexts/MerchantGroupContext';
import { AuthProvider } from '@pwr/auth-provider';
import { BrowserRouter as Router } from 'react-router-dom';
import { getMerchantGroupFromCookie } from '@pwr/merchant-group-utils';

const merchantGroup = getMerchantGroupFromCookie(
  process.env.REACT_APP_BUILD_ENV
);
const userServiceEndpoint = !!merchantGroup
  ? `${REGENERATE}?merchantGroupId=${merchantGroup}`
  : `${REGENERATE}`;

const tokenRefresher = token => {
  return fetch(userServiceEndpoint, {
    headers: {
      Authorization: token,
      'Content-type': 'application/json',
    },
  })
    .then(res => res.json())
    .catch(err => err);
};

const App = () => (
  <AuthProvider
    authServiceUrl={AUTH_SERVICE}
    tokenRefresher={tokenRefresher}
    secure={process.env.NODE_ENV === 'production'}
  >
    {({ authStatus, authPayload }) => {
      if (authStatus === 'loggedIn') {
        return (
          <BrowserPersistenceProvider>
            <ConfigProvider authPayload={authPayload}>
              <ConfigConsumer>
                {({
                  apps,
                  configErrorName,
                  configHasLoaded,
                  email,
                  isAdmin,
                  name,
                  nonCustomReports,
                  programGroups,
                  userid,
                }) => {
                  return (
                    <Router>
                      <MerchantGroupProvider
                        apps={apps}
                        name={name}
                        configErrorName={configErrorName}
                        configHasLoaded={configHasLoaded}
                        programGroups={programGroups}
                      >
                        <Main
                          apps={apps}
                          email={email}
                          isAdmin={isAdmin}
                          name={name}
                          nonCustomReports={nonCustomReports}
                          programGroups={programGroups}
                          userid={userid}
                        />
                      </MerchantGroupProvider>
                    </Router>
                  );
                }}
              </ConfigConsumer>
            </ConfigProvider>
          </BrowserPersistenceProvider>
        );
      }

      return null;
    }}
  </AuthProvider>
);

export default App;
