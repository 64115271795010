import { array, arrayOf, bool, number, string, shape } from 'prop-types';
export const nonCustomReports = shape({
  _embedded: shape({
    reportTemplate: arrayOf(
      shape({
        createdByProfileId: number,
        createdTime: string,
        custom: bool,
        description: string,
        displayOrder: number,
        icon: string,
        id: number,
        name: string,
        reportContentGroups: arrayOf(
          shape({
            createdByProfileId: number,
            createdTime: string,
            displayOrder: number,
            exportEngine: string,
            exportTypes: string,
            exportUri: string,
            id: number,
            identifier: string,
            name: string,
            reportComponentColumns: arrayOf(
              shape({
                columnAlias: string,
                columnName: string,
                columnOrder: number,
                createdByProfileId: number,
                createdTime: string,
                displayHeader: string,
                externalLink: string,
                id: number,
                linkExpression: string,
                optional: bool,
                previewFormat: string,
                previewHiddenColumn: string,
                selectExpression: string,
                updateByProfileId: number,
                updatedTime: string,
                valueType: string,
                version: number,
                width: number,
              })
            ),
            reportComponents: arrayOf(
              shape({
                _links: arrayOf(
                  shape({
                    reportComponentParameters: shape({
                      href: string,
                      templated: bool,
                    }),
                    self: shape({ href: string }),
                  })
                ),
                createdByProfileId: number,
                createdTime: string,
                displayOrder: number,
                elementId: string,
                id: number,
                observesParameters: bool,
                reportComponentType: string,
                updateByProfileId: number,
                updatedTime: string,
                uri: string,
                version: number,
                visibilityScript: string,
              })
            ),
          })
        ),
        reportIdentifier: string,
        reportParameters: arrayOf(shape({})),
        reportTemplateMerchantGroups: array,
        reportTemplateType: string,
        titleReportUri: string,
        titleText: string,
        updateByProfileID: number,
        updatedTime: string,
        version: number,
      })
    ),
  }),
  _links: shape({
    reportComponentGroups: shape({}),
    reportParameters: shape({}),
    reportTemplate: shape({}),
    reportTemplateMerchantGroups: shape({}),
    self: shape({}),
  }),
});
