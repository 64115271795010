import React from 'react';
import { arrayOf, bool, node, oneOfType } from 'prop-types';
import classnames from 'classnames';

import styles from './ColumnarGrid.module.scss';

export default function Window({
  children,
  horizontalScrollShadow,
  maxHeight,
  maxWidth,
}) {
  return (
    <div
      style={{ maxWidth }}
      className={classnames(styles['WindowContainer'], {
        [styles['WindowContainer--shadow']]: horizontalScrollShadow,
      })}
    >
      <div style={{ maxHeight }} className={classnames(styles['Window'])}>
        <div className="Window__content">{children}</div>
      </div>
    </div>
  );
}
Window.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  horizontalScrollShadow: bool,
};
