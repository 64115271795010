import React, { Component } from 'react';
import { func, string } from 'prop-types';
import classnames from 'classnames';
import styles from './inputs.module.scss';
export class Name extends Component {
  static propTypes = {
    currentValue: string,
    name: string.isRequired,
    onChange: func.isRequired,
  };
  nameInput = React.createRef();
  state = { touched: false };

  isValid = value => {
    if (value.trim().length < 1) {
      return false;
    }
    return true;
  };

  handleChange = event => {
    const { touched } = this.state;
    if (!touched) {
      this.setState({ touched: true });
    }
    const { name, value, validity } = event.target;
    this.nameInput.current.setCustomValidity(
      validity.patternMismatch ? "Special characters aren't allowed" : ''
    );

    const { onChange } = this.props;
    onChange({
      inputName: name,
      inputValue: value,
    });
  };

  render() {
    const { touched } = this.state;
    const { currentValue, name } = this.props;
    const hasError = !this.isValid(currentValue, touched) && touched;
    return (
      <div className={styles.FormInput}>
        <label htmlFor="name">
          <span className="text-red">*</span>Name this subscription
        </label>
        <input
          autoFocus
          id="name"
          className={classnames('form-control', {
            'border-red': hasError,
          })}
          name={name}
          onChange={this.handleChange}
          placeholder="Give this subscription a name"
          type="text"
          value={currentValue}
          maxLength="250"
          pattern="[a-zA-Z0-9_\-  ]+"
          ref={this.nameInput}
        />
      </div>
    );
  }
}
