export function receiptReviewCollectPageProps() {
  const reports = [
    {
      reportIdentifier: 'receipt_review_collect',
      type: 'tableau_workbook',
      workbookTabs: [
        {
          tableauIframeHeight: 1300,
          tableauTabUri: 'Collect',
          tableauWorksheetName: 'Collect',
          urlFragment: 'collect',
          title: 'Collect',
        },
        {
          tableauIframeHeight: 1500,
          tableauTabUri: 'Display',
          tableauWorksheetName: 'Display',
          urlFragment: 'display',
          title: 'Display',
        },
        {
          tableauIframeHeight: 1400,
          tableauTabUri: 'Sentiment',
          tableauWorksheetName: 'Sentiment',
          urlFragment: 'sentiment',
          title: 'Sentiment',
        },
      ],
    },
    {
      reportIdentifier: 'rrc_reviews',
      urlFragment: 'rrc-reviews',
      title: 'RRC Reviews',
      type: 'columnar',
    },
  ];
  const DEFAULT_TAB = reports[0].workbookTabs[0].urlFragment;
  const PAGE_URL = '/receipt-review-collect';
  const PAGE_TITLE = 'Receipt Review Collect';
  const navigationProps = {
    crumbs: [
      {
        label: 'Analytics',
        href: `/`,
      },
      {
        label: PAGE_TITLE,
        href: `${PAGE_URL}/${DEFAULT_TAB}`,
      },
    ],
    activePrimary: 'Analytics',
    activeSecondary: PAGE_TITLE,
  };
  return {
    defaultTab: DEFAULT_TAB,
    navigationProps,
    pageUrl: PAGE_URL,
    reports,
  };
}
