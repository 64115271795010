import { getToken, redirectToAuthService } from '@pwr/auth-provider';
import { AUTH_SERVICE } from 'api/endpoints';
import checkResponse from './checkResponse';

export const fetchFilter = async (url, params) => {
  // TODO: Validate groups and throw error
  return await fetch(`${url}?${params}`, {
    headers: {
      Authorization: getToken(),
      'Content-type': 'application/json',
    },
  })
    .then(checkResponse)
    .then(data => data.json())
    .catch(err => {
      if (err.name === 'TypeError') redirectToAuthService(AUTH_SERVICE);
    });
};
