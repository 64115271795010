import React from 'react';
import { arrayOf, bool, element, string } from 'prop-types';
import classnames from 'classnames';
import styles from './FilterGrid.module.scss';
import FilterRows from './FilterRows';

export default function FilterGrid({ buttons, expanded, filters, id }) {
  const numberOfAlwaysVisibleFilters = 4;
  const numberOfFilters = filters.length;
  const filterWidth =
    numberOfFilters >= numberOfAlwaysVisibleFilters
      ? 25
      : Math.floor(100 / numberOfFilters);

  const filterGridItems = !!filters
    ? React.Children.map(filters, (child, index) => {
        return (
          <div
            data-testid={
              child.props.filterDisplayName.toLowerCase() + ' filter'
            }
            className={classnames(`fl w-${filterWidth} pa2`)}
          >
            {React.cloneElement(child, {
              index,
              ...child.props,
              key: child.key,
            })}
          </div>
        );
      })
    : null;

  return (
    <div
      className={classnames(styles['FilterGrid'], {
        [styles['FilterGrid--expanded']]: expanded,
      })}
    >
      <section className="w-100 ph3-ns">
        <FilterRows id={id} buttons={buttons} filters={filterGridItems} />
      </section>
    </div>
  );
}

FilterGrid.propTypes = {
  buttons: element,
  expanded: bool,
  filters: arrayOf(element),
  id: string,
};
