import React, { useState } from 'react';
import { arrayOf, shape, func, number, oneOfType, string } from 'prop-types';
import { MultiSelectWithRenderOptions } from '@pwr-ui/select';
import { useThrottle } from 'use-throttle';
import { useQuery } from 'react-query';
import { getQueryParams } from 'utils';
import { fetchFilter } from 'api/fetchFilter';
import styles from './CategoryMultiselect.module.scss';

const itemToString = item => (item !== null ? item.label : '');
const itemToKey = item => item.value;
const arrayOfValuesToMap = item => [itemToKey(item), item];
const getSelectedItems = (currentValue, items) => {
  if (!currentValue) return new Map();
  if (Array.isArray(currentValue)) {
    if (currentValue.every(value => typeof value === 'string')) {
      const selectedItems = items.filter(item =>
        currentValue.includes(item.value)
      );
      return new Map(selectedItems.map(arrayOfValuesToMap));
    }
    return new Map(currentValue.map(arrayOfValuesToMap));
  }
  const currentValuesFromUrl = currentValue.split(',');
  return new Map(
    items
      .filter(item => currentValuesFromUrl.includes(item.value))
      .map(arrayOfValuesToMap)
  );
};

const IndentGuide = ({ indentLevel, item }) => {
  return [...Array.from({ length: indentLevel })].map((_, index) => (
    <div
      className={styles['CategoryMultiselectContainer__indent-guide']}
      key={item.value + index + 'indent-guard'}
    />
  ));
};

export function CategoryMultiselect({
  currentValue,
  endpoint,
  filterDisplayName,
  filterName,
  merchantGroupIds,
  merchantIds,
  onChange,
}) {
  const isCurrentValueFromUrlParam = typeof currentValue === 'string';
  const isCurrentValueFromDb =
    Array.isArray(currentValue) &&
    currentValue.every(value => typeof value === 'string');
  const [inputValue, setInputValue] = useState('');
  const throttledInputValue = useThrottle(inputValue, 1500);

  const urlParams = getQueryParams({
    dateRange: '',
    groups: merchantGroupIds,
    merchants: merchantIds || '',
    search: throttledInputValue,
    values: (() => {
      if (isCurrentValueFromUrlParam) return currentValue;
      if (isCurrentValueFromDb) return currentValue.join(',');
      return '';
    })(),
  });

  const { data: items, isLoading } = useQuery(
    [
      'fetchItems',
      {
        endpoint,
        urlParams,
      },
    ],
    ({ endpoint, urlParams }) => {
      return fetchFilter(endpoint, urlParams);
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const { hierarchyItems, valueNameMap } = splitItemsIntoHierarchy(items || []);

  const formattedItems = hierarchyItems.map(item => {
    const selectedItemsMap = getSelectedItems(currentValue, hierarchyItems);
    if (!selectedItemsMap.has(item.value)) return item;
    const fullName = item.value
      .split('|')
      .map(id => valueNameMap.get(id))
      .join(' | ');
    return {
      label: fullName,
      value: item.value,
      indentLevel: 0,
    };
  });

  const handleSelection = selectedItems => {
    onChange(filterName, [...selectedItems.values()]);
  };

  return (
    <div
      id={filterName}
      className={`${styles.CategoryMultiselectContainer} form-group fl`}
    >
      <label htmlFor={filterName + '-menu'}>{filterDisplayName}</label>
      <MultiSelectWithRenderOptions
        id={filterName} // '-menu' is appended to this id
        itemToKey={itemToKey}
        itemToString={itemToString}
        inputValue={inputValue}
        onInputValueChange={setInputValue}
        items={formattedItems}
        isLoading={isLoading}
        value={getSelectedItems(currentValue || [], formattedItems || [])}
        onChange={handleSelection}
        menuHeight={500}
        menuWidth={500}
        renderItems={({ Checkbox, item, Label }) => {
          return (
            <>
              <IndentGuide item={item} indentLevel={item.indentLevel} />
              <Checkbox />
              <Label />
            </>
          );
        }}
      />
    </div>
  );
}

CategoryMultiselect.propTypes = {
  currentValue: oneOfType([
    arrayOf(
      shape({
        label: string,
        value: string,
      })
    ),
    string, // This is the case where filter values come from URL params
    arrayOf(string), // When the value comes from the DB
  ]),
  dateRange: string,
  endpoint: string.isRequired,
  filterName: string.isRequired,
  filterDisplayName: string.isRequired,
  filterType: string.isRequired,
  merchantGroupId: oneOfType([string, number]),
  merchantIds: oneOfType([string, number]),
  onChange: func,
};

function generateHierarchyForItem(item) {
  const labelParts = item.label.split('|');
  const valueParts = item.value.split('|');
  return labelParts.map((label, index) => {
    const value = valueParts.slice(0, index + 1).join('|');
    return {
      label,
      value,
      indentLevel: index,
    };
  });
}

export function splitItemsIntoHierarchy(dropdownItems) {
  const setOfDropdownValues = new Set();

  const hierarchyItems = dropdownItems.reduce((accumulator, dropdownItem) => {
    const hierarchy = generateHierarchyForItem(dropdownItem);
    const dedupedHierarchy = hierarchy.filter(({ value }) => {
      return !setOfDropdownValues.has(value);
    });
    hierarchy.forEach(({ value }) => setOfDropdownValues.add(value));
    return [...accumulator, ...dedupedHierarchy];
  }, []);
  const valueNameMap = hierarchyItems.reduce((accumulator, item) => {
    const leafMostCategoryId = item.value.split('|').pop();
    accumulator.set(String(leafMostCategoryId), item.label);
    return accumulator;
  }, new Map());
  return {
    hierarchyItems,
    valueNameMap,
  };
}
