import React from 'react';
import classnames from 'classnames';
import { bool, func, node, number, oneOf } from 'prop-types';

import styles from './Pagination.module.scss';

export function PageButton({
  active,
  children,
  className,
  currentPage,
  onClick,
  type,
  ...buttonProps
}) {
  return (
    <button
      {...buttonProps}
      type="button"
      className={classnames(className, styles['PageButton'], {
        'bg-blue text-white': active,
        [styles[`PageButton--previous`]]: type === 'previous',
        [styles[`PageButton--next`]]: type === 'next',
      })}
      onClick={onClick}
    >
      {children}
    </button>
  );
}
PageButton.propTypes = {
  active: bool,
  children: node.isRequired,
  currentPage: number,
  onClick: func.isRequired,
  type: oneOf(['next', 'previous']),
};
