import React, { Component } from 'react';
import { arrayOf, func, node, oneOfType } from 'prop-types';
import styles from './ExpandablePanel.module.scss';
import ExpandOrApply from './ExpandOrApply';
import FilterGrid from './FilterGrid';
import { safeObjectDig } from 'utils/safeObjectDig';

const numberOfAlwaysVisibleFilters = 4;
const hasValue = filterValue => {
  if (!filterValue) return false;
  if (Object.keys(filterValue).length === 0) return false;
  const isNumericRange =
    typeof filterValue === 'object' && safeObjectDig(filterValue, 'to');
  if (
    isNumericRange &&
    filterValue.to.value.length === 0 &&
    filterValue.from.value.length === 0
  ) {
    return false;
  }
  return true;
};

export default class ExpandablePanel extends Component {
  static propsTypes = {
    children: oneOfType([arrayOf(node), node]).isRequired,
    onSubmit: func.isRequired,
  };
  state = { expanded: false };

  getNumberOfSelectedFilters = (filterState, filters) => {
    // The first 4 filters are not counted in the expand button count
    return filters.slice(4).reduce((selectedFilterCount, filterComponent) => {
      return hasValue(filterState[filterComponent.props.filterName])
        ? selectedFilterCount + 1
        : selectedFilterCount;
    }, 0);
  };

  handleCollapse = () => this.setState({ expanded: false });

  toggleExpand = () =>
    this.setState(state => ({
      expanded: !state.expanded,
    }));

  render() {
    const { expanded } = this.state;
    const { children, filterState } = this.props;
    const alwaysVisibleFilters = children.slice(
      0,
      numberOfAlwaysVisibleFilters
    );
    const showExpandButton = children.length > numberOfAlwaysVisibleFilters;
    const buttons = (
      <ExpandOrApply
        expanded={expanded}
        numberOfSelectedFilters={this.getNumberOfSelectedFilters(
          filterState,
          children
        )}
        showExpandButton={showExpandButton}
        toggleExpand={this.toggleExpand}
        onSubmit={() => {
          this.handleCollapse();
          this.props.onSubmit();
        }}
      />
    );
    return (
      <div className={styles.ExpandablePanel}>
        <section className={styles.ExpandablePanel__filters}>
          <FilterGrid
            buttons={buttons}
            filters={alwaysVisibleFilters}
            id="always-visible-filters"
          />
          {expanded && (
            <FilterGrid
              buttons={buttons}
              expanded={expanded}
              filters={children}
              id="expandable-panel-filters"
            />
          )}
        </section>
      </div>
    );
  }
}
