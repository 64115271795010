import React, { useEffect, useState, useMemo } from 'react';
import { bool, instanceOf } from 'prop-types';
import { Tabs, TabList, TabPanels, TabPanel } from '@reach/tabs';
import { Tab } from 'components/Tab';
import { useParams, useHistory } from 'react-router-dom';
import { merchantGroupList } from 'utils/types';
import {
  REPORT_SCHEDULE_BY_CREATED_PROFILE_ID,
  REPORT_SCHEDULE_BY_CREATED_FOR,
  REPORT_SCHEDULE_BY_MERCHANT_GROUP_ID,
} from 'api/endpoints';
import { SubscriptionSearchList } from './SubscriptionSearchList';
import Growler from 'components/Growler';

const pageUrl = '/subscriptions';

export function Subscriptions({
  isAdmin,
  match,
  selectedMerchantGroupsByIds,
  merchantGroupList,
}) {
  const merchantGroupIds = [...selectedMerchantGroupsByIds.keys()].join(',');
  const merchantGroupIdUrlParam = `&merchantGroupIds=${merchantGroupIds}`;

  const [notification, setNotification] = useState(null);
  const mgIdNameDictionary = useMemo(() => {
    return merchantGroupList.reduce(
      (accumulator, { merchantGroupId, name }) => {
        accumulator[merchantGroupId] = name;
        return accumulator;
      },
      {}
    );
  }, [merchantGroupList]);

  const { push } = useHistory();
  const subscriptionTabs = [
    {
      id: 'my-subscriptions',
      title: 'My Subscriptions',
      urlFragment: '/my-subscriptions',
    },
    ...(isAdmin
      ? [
          {
            id: 'subscriptions-created-for-clients',
            title: 'Subscriptions Created for Clients',
            urlFragment: '/subscriptions-created-for-clients',
          },
        ]
      : []),
    {
      id: 'all-subscriptions',
      title: 'All Subscriptions',
      urlFragment: '/all-subscriptions',
    },
  ];

  const { tab } = useParams();
  const [currentSelectedTabIndex, setCurrentSelectedTabIndex] = useState(
    subscriptionTabs.findIndex(subscriptionTab => subscriptionTab.id === tab)
  );
  useEffect(() => {
    const nextIndex = subscriptionTabs.findIndex(
      subscriptionTab => subscriptionTab.id === tab
    );
    if (nextIndex === currentSelectedTabIndex) return;
    setCurrentSelectedTabIndex(nextIndex);
  }, [
    currentSelectedTabIndex,
    setCurrentSelectedTabIndex,
    subscriptionTabs,
    tab,
  ]);

  function handleTabSelection(selectedIndex) {
    if (selectedIndex === currentSelectedTabIndex) return;
    setCurrentSelectedTabIndex(selectedIndex);
    push({
      pathname: pageUrl + subscriptionTabs[selectedIndex].urlFragment,
    });
  }

  return (
    <div className="shadow" data-translate={true}>
      {notification && (
        <Growler
          dismissible
          onDismiss={() => setNotification()}
          title={notification.title}
          type={notification.type}
        >
          {notification.message}
        </Growler>
      )}
      <Tabs
        className="mt-4 bg-gray-93"
        index={currentSelectedTabIndex}
        onChange={handleTabSelection}
      >
        <TabList>
          {subscriptionTabs.map(tab => {
            return (
              <Tab data-testid={tab.title.toLowerCase()} key={tab.id + 'tab'}>
                {tab.title}
              </Tab>
            );
          })}
        </TabList>
        <div className="bg-white">
          <TabPanels>
            <TabPanel>
              <SubscriptionSearchList
                isAdmin={isAdmin}
                shouldFetch={currentSelectedTabIndex === 0}
                mgIdNameDictionary={mgIdNameDictionary}
                endpoint={REPORT_SCHEDULE_BY_CREATED_PROFILE_ID}
                setNotification={setNotification}
                selectedMerchantGroupsByIds={selectedMerchantGroupsByIds}
              />
            </TabPanel>
            {isAdmin && (
              <TabPanel>
                <SubscriptionSearchList
                  isAdmin={isAdmin}
                  selectedMerchantGroupsByIds={selectedMerchantGroupsByIds}
                  shouldFetch={currentSelectedTabIndex === 1}
                  mgIdNameDictionary={mgIdNameDictionary}
                  endpoint={`${REPORT_SCHEDULE_BY_CREATED_FOR}${merchantGroupIdUrlParam}`}
                  setNotification={setNotification}
                />
              </TabPanel>
            )}
            <TabPanel>
              <SubscriptionSearchList
                isAdmin={isAdmin}
                selectedMerchantGroupsByIds={selectedMerchantGroupsByIds}
                shouldFetch={
                  isAdmin
                    ? currentSelectedTabIndex === 2
                    : currentSelectedTabIndex === 1
                }
                mgIdNameDictionary={mgIdNameDictionary}
                endpoint={`${REPORT_SCHEDULE_BY_MERCHANT_GROUP_ID}${merchantGroupIdUrlParam}`}
                setNotification={setNotification}
              />
            </TabPanel>
          </TabPanels>
        </div>
      </Tabs>
    </div>
  );
}

Subscriptions.propTypes = {
  isAdmin: bool.isRequired,
  selectedMerchantGroupsByIds: instanceOf(Map).isRequired,
  merchantGroupList: merchantGroupList.isRequired,
};
