import React, { useEffect, useState } from 'react';
import { string, oneOf } from 'prop-types';
import classnames from 'classnames';
import style from './TooltipButton.module.scss';

export function TooltipButton({
  children,
  tooltipOrigin,
  tooltipText,
  disabled,
}) {
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    if (disabled) setShowTooltip(false);
  }, [disabled]);

  return (
    <div
      onMouseEnter={() => !disabled && setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
      className={style.TooltipButtonContainer}
    >
      <div className="py-1 px-2">{children}</div>
      <div className={style.TooltipContainer}>
        <div
          role="tooltip"
          className={classnames(style.Tooltip, {
            [style[`Tooltip--origin-${tooltipOrigin}`]]: showTooltip,
          })}
        >
          <div
            className={classnames(
              style.Arrow,
              style[`Arrow--origin-${tooltipOrigin}`]
            )}
          />
          {tooltipText}
        </div>
      </div>
    </div>
  );
}
TooltipButton.propTypes = {
  tooltipText: string.isRequired,
  tooltipOrigin: oneOf(['top', 'right', 'bottom', 'left']).isRequired,
};
