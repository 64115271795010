import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import SearchBar from 'components/SearchBar';

import ListItem from './ListItem';
import { alphabeticalSort } from './columnEditorUtils';
import styles from './ColumnDndEditor.module.scss';

export default function List({
  icon,
  items,
  onListItemClick,
  onSearch,
  sorted,
  title,
  hiddenItems,
}) {
  const listItems = sorted ? items.sort(alphabeticalSort) : items;
  return (
    <div className={styles['ColumnDndEditor__list']}>
      <h2 className={styles['ColumnDndEditor__list-header']}>
        {title} ({items.length} items)
      </h2>
      <SearchBar
        style={{ width: 'calc(100% - 1.5rem)' }}
        onSearch={results => onSearch(title, results)}
        searchOptions={{
          keys: ['item'],
        }}
        items={items.map(listItem => ({ item: listItem.propertyName }))}
      />
      {listItems.map((item, index) => (
        <ListItem
          draggableId={`${item.propertyName}-${index}-${title}`}
          droppableId={title}
          icon={icon}
          index={index}
          key={title + item.propertyName + index}
          onListItemClick={onListItemClick}
          hidden={hiddenItems.has(item)}
        >
          {item.displayName || item.propertyName}
        </ListItem>
      ))}
    </div>
  );
}

List.propTypes = {
  icon: string,
  items: arrayOf(
    shape({
      propertyName: string.isRequired,
      displayName: string,
    })
  ).isRequired,
  onListItemClick: func,
  sorted: bool,
  title: string,
};
